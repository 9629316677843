import { LayoutIntlProvider } from 'components'
import { useSelector, useStoreI18n } from 'hooks'
import { FC, useEffect } from 'react'
import { Container } from 'reactstrap'
import { selectStoreI18n } from 'state'

const Blank: FC = ({ children }) => {
  const storeI18n = useStoreI18n()
  const { isLoaded } = useSelector(selectStoreI18n)

  useEffect(() => {
    if (!isLoaded) {
      storeI18n.initialize()
    }
  }, [storeI18n, isLoaded])

  if (!isLoaded) {
    return <></>
  }

  return (
    <LayoutIntlProvider>
      <Container className="my-4" role="main" tag="main">
        {children}
      </Container>
    </LayoutIntlProvider>
  )
}

export default Blank
