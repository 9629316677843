import { Amount } from 'types'

export interface IApiOperation {
  '@context': string
  '@id': string
  '@type': string
  accountingExportStatus: string | null
  accountingRecordId: string | null
  amount: Amount
  appId: string
  bankAccountApiDto: { '@id': string; bankName: string } | null
  collectName: string
  invoiceId: string | null
  paymentRequestId: string | null
  person?: {
    '@id': string
    '@type': string
    firstName: string
    lastName: string
  }
  publicComment: string | null
  settledAt: string | null
  status: EOperationStatus
  transactionId: number | null
  transactionParentId: number
  type: EOperationType
}

export enum EOperationStatus {
  NEW = 'NEW',
  AUTHORIZED = 'AUTHORIZED',
  REFUSED = 'REFUSED',
  ERROR = 'ERROR',
  SETTLED = 'SETTLED',
  UNKNOWN = 'UNKNOWN',
  REQUESTED = 'REQUESTED',
  ABORTED = 'ABORTED',
}

export enum EOperationType {
  FEE = 'FEE',
  PAYOUT = 'PAYOUT',
  PAYMENT = 'PAYMENT',
  REFUND = 'REFUND',
  REGULARIZATION_IN = 'REGULARIZATION_IN',
  REGULARIZATION_OUT = 'REGULARIZATION_OUT',
  CHARGEBACK = 'CHARGEBACK',
}
