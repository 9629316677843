import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EAlertColor, IAlertCta } from 'components/Alert/type'
import { ReactNode } from 'react'
import { IStoreNotification, IStoreNotifications } from 'state/type'

const notificationsInitialState: IStoreNotifications = []

const notificationSlice = createSlice({
  name: 'notification',
  initialState: notificationsInitialState,
  reducers: {
    addNotification: (
      state,
      action: PayloadAction<{
        color: EAlertColor
        dismissible?: boolean
        onCloseCallback?: () => void
        text: ReactNode
        cta?: IAlertCta
      }>
    ) => [...state, { ...action.payload, id: new Date().getTime().toString() }],
    deleteNotification: (state, action: PayloadAction<IStoreNotification>) =>
      state.filter((notification) => notification.id !== action.payload.id),
  },
})

export const { addNotification, deleteNotification } = notificationSlice.actions
export default notificationSlice.reducer
