import useAscRouter from 'hooks/useAscRouter'
import { IRouteLayout } from 'hooks/useAscRouter/type'
import LayoutPayment from 'layouts/Payment'
import { EPaymentLayoutType } from 'layouts/Payment/type'
import LegacyRedirect from 'pages/LegacyRedirect'
import PaymentRequestInactive from 'pages/Payment/Request/Inactive'
import { FC, ReactNode } from 'react'
import { Route, Routes } from 'react-router'
import { ERouterType } from 'routers/type'

const RequestRouter: FC = () => {
  const { RouteLayout } = useAscRouter(ERouterType.PAYMENT)

  return (
    <Routes>
      <Route
        path=":requestId/error/other"
        element={
          <RouteLayout
            layout={({ children }: { children: ReactNode }) => (
              <LayoutPayment type={EPaymentLayoutType.ERROR}>
                {children}
              </LayoutPayment>
            )}
            component={(props: IRouteLayout['component']) => (
              <PaymentRequestInactive {...props} />
            )}
          />
        }
      />

      <Route path="/*" element={<LegacyRedirect />} />
    </Routes>
  )
}

export default RequestRouter
