import LayoutBlank from 'layouts/Blank'
import { FC } from 'react'

import * as Type from '../type'

const DefaultRouter: FC<Type.IRouteLayout> = ({
  component: Component,
  layout: Layout = LayoutBlank,
  ...props
}) => (
  <Layout>
    <Component {...props} />
  </Layout>
)

export default DefaultRouter
