import { createContext } from 'react'

import { INotificationsContext } from './type'

const NotificationsContext = createContext<INotificationsContext>({
  notifications: [],
  retry: () => undefined,
})

export default NotificationsContext
