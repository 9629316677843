import { ENotificationType } from 'api/type'
import {
  Background,
  Button,
  Col,
  InformationNotification,
  ListItem,
  PopupNotification,
  Row,
  Title,
} from 'components'
import { EAlertColor } from 'components/Alert/type'
import { ECtaType, ENotificationStatus } from 'providers/type'
import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addNotification } from 'state'
import { EColor } from 'types'
import { EBrand } from 'utils'

const text = `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus
            animi atque doloribus eius et facere hic in iste iusto magni
            mollitia nobis pariatur perspiciatis reiciendis reprehenderit saepe,
            sed similique suscipit!`

const StorybookPage: FC = () => {
  const dispatch = useDispatch()

  const [isReadMinor1, setIsReadMinor1] = useState(false)
  const [isReadMinor2, setIsReadMinor2] = useState(false)
  const [isReadInformation1, setIsReadInformation1] = useState(false)
  const [isReadInformation2, setIsReadInformation2] = useState(false)

  const addStoreNotification = (color: EAlertColor, dismissible: boolean) => {
    dispatch(
      addNotification({
        color,
        text,
        dismissible,
      })
    )
  }

  return (
    <>
      <Title type="h1" className="mb-4">
        Notifications
      </Title>

      <Background title="Information">
        <ul>
          <ListItem>
            Notifications are basically an Alert displayed above the content at
            the bottom of the screen
          </ListItem>
          <ListItem>
            Notifications can be "dismissible" (it will fade away) or not
            dismissible (it will be displayed until the user choose to close it)
          </ListItem>
          <ListItem>
            Dismissible notifications display duration depends on its content
            length. It will last at least 5s.
          </ListItem>
          <ListItem>
            Dismissible notifications will stay displayed if the user hovers
            them
          </ListItem>
        </ul>
      </Background>

      <Title type="h2" className="my-4">
        Toast
      </Title>
      <Row>
        {Object.values([
          EColor.RED,
          EColor.BLUE,
          EColor.GREEN,
          EColor.FLORIDA,
        ]).map((color, index) => {
          const dismissible = index % 2 === 0
          return (
            <Col key={color} xs="auto">
              <Button
                onClick={() =>
                  addStoreNotification(color as EAlertColor, dismissible)
                }
                color={color}
              >
                {color.toUpperCase()}{' '}
                {dismissible ? 'DIMISSIBLE' : 'NOT DISMISSIBLE'}
              </Button>
            </Col>
          )
        })}
      </Row>
      <Title type="h2" className="my-4">
        Popup
      </Title>
      <Row>
        <Col>
          <PopupNotification
            id="notification-1"
            type={ENotificationType.MINOR}
            title="🚀 Title + 1 CTA"
            content={text}
            ctas={[
              {
                onClick: () => {
                  // Do nothing
                },
                text: 'Gérer',
                type: ECtaType.PRIMARY,
              },
            ]}
            status={
              isReadMinor1
                ? ENotificationStatus.READ
                : ENotificationStatus.UNREAD
            }
            handleRead={() => {
              setIsReadMinor1(true)
            }}
            restrictions={{
              brand: EBrand.ASSOCONNECT,
              roles: [],
              subscriptions: [],
            }}
          />
        </Col>
        <Col>
          <PopupNotification
            id="notification-2"
            type={ENotificationType.MINOR}
            title="Title + 2 CTA"
            content={text}
            ctas={[
              {
                onClick: () => {
                  // Do nothing
                },
                text: 'Gérer',
                type: ECtaType.PRIMARY,
              },
              {
                onClick: () => {
                  // Do nothing
                },
                text: 'En savoir plus',
                type: ECtaType.SECONDARY,
              },
            ]}
            status={
              isReadMinor2
                ? ENotificationStatus.READ
                : ENotificationStatus.UNREAD
            }
            handleRead={() => {
              setIsReadMinor2(true)
            }}
            restrictions={{
              brand: EBrand.ASSOCONNECT,
              roles: [],
              subscriptions: [],
            }}
          />
        </Col>
      </Row>
      <Title type="h2" className="my-4">
        Notification
      </Title>
      <InformationNotification
        id="notification-3"
        title="Title no CTA"
        content={text}
        handleRead={() => {
          setIsReadInformation1(true)
        }}
        status={
          isReadInformation1
            ? ENotificationStatus.READ
            : ENotificationStatus.UNREAD
        }
        type={ENotificationType.INFORMATION}
        restrictions={{
          brand: EBrand.ASSOCONNECT,
          roles: [],
          subscriptions: [],
        }}
      />
      <InformationNotification
        id="notification-4"
        title="Title + 2 CTA"
        content={text}
        status={
          isReadInformation2
            ? ENotificationStatus.READ
            : ENotificationStatus.UNREAD
        }
        handleRead={() => {
          setIsReadInformation2(true)
        }}
        ctas={[
          {
            type: ECtaType.PRIMARY,
            text: 'Gérer',
            onClick: () => {
              // Do nothing
            },
          },
          {
            type: ECtaType.SECONDARY,
            text: 'En savoir plus',
            onClick: () => {
              // Do nothing
            },
          },
        ]}
        type={ENotificationType.INFORMATION}
        restrictions={{
          brand: EBrand.ASSOCONNECT,
          roles: [],
          subscriptions: [],
        }}
      />
    </>
  )
}

export default StorybookPage
