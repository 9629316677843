import { Background, Col, Link, ListItem, Row, Title } from 'components'
import { EBackgroundBorderColor } from 'components/Background/type'
import { FC } from 'react'
import { EColor } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Row / Col
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>
          <Link href="https://reactstrap.github.io/?path=/docs/components-layout--layout">
            Layout system from reactstrap
          </Link>
        </ListItem>
        <ListItem>
          Columns can be separated using showColsSeparation (only works
          horizontally)
        </ListItem>
        <ListItem>
          Cols border switch on lg(992px) breakpoint from vertical to horizontal
        </ListItem>
      </ul>
    </Background>

    {[false, true].map((showColsSeparation, key) => (
      <div key={key}>
        <Title type="h2" className="my-4">
          Component with showColsSeparation = {showColsSeparation.toString()}
        </Title>
        {[1, 2, 4, 6, 12].map((colsNumber, key) => (
          <div key={key}>
            <Title type="h6" className="mt-4">
              Number of columns : {colsNumber}
            </Title>
            <Row
              showColsSeparation={showColsSeparation}
              className="flex-column flex-lg-row"
            >
              {Array.from(Array(colsNumber).keys()).map((key) => (
                <Col key={key}>
                  <Background
                    border={EBackgroundBorderColor.SOLID}
                    borderColor={EColor.BLUE}
                    color={EColor.TRANSPARENT}
                    className="align-items-center text-center m-0"
                  >
                    {key + 1}
                  </Background>
                </Col>
              ))}
            </Row>
          </div>
        ))}
      </div>
    ))}
  </>
)

export default StorybookPage
