import {
  Container,
  EmptySpaceAccessDenied,
  HasAccess,
  Header,
  LayoutIntlProvider,
  NotificationsWrapper,
  SidePanel,
  Tabs,
} from 'components'
import { UseMasterAdmin } from 'hooks'
import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetSidePanel } from 'state'
import { EColor } from 'types'

import Menu from './components/Menu'

const Master: FC = ({ children }) => {
  const dispatch = useDispatch()
  const { isMasterAdmin } = UseMasterAdmin()

  // Reset the sidePanel store at each layout rerender.
  useEffect(() => {
    dispatch(resetSidePanel())
  })

  if (isMasterAdmin === undefined) {
    return null
  }

  return (
    <LayoutIntlProvider>
      <HasAccess
        access={() => isMasterAdmin}
        onAccessDenied={() => <EmptySpaceAccessDenied />}
      >
        <>
          <Menu color={EColor.RED} />
          <Header />
          <Container className="my-4" role="main" tag="main">
            <Tabs />
            {children}
          </Container>
          <NotificationsWrapper />
          <SidePanel />
        </>
      </HasAccess>
    </LayoutIntlProvider>
  )
}

export default Master
