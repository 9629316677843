import { Col } from 'components'
import styled from 'styled-components'

export const InstallmentBlock = styled((props) => <Col {...props} />)`
  border: 1px solid ${(props) => props.theme.darkGrey};
  margin-left: -1px;
  margin-top: -1px;
  max-width: calc(100% / 4);
  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    max-width: calc(100% / 6);
  }
`
