import {
  Background,
  Button,
  Col,
  DownloadButton,
  Icon,
  ListItem,
  ResponsiveButton,
  Row,
  Title,
} from 'components'
import { FC } from 'react'
import { EColor } from 'types'

const fullColors = [
  EColor.BLUE,
  EColor.LIGHT_GREY,
  EColor.GREEN,
  EColor.YELLOW,
  EColor.RED,
  EColor.TRANSPARENT,
  EColor.WHITE,
]
const outlineColors = [EColor.BLUE, EColor.YELLOW]
const sizes = { sm: '27px', md: '32.5px' }

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Button
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>The text contains string</ListItem>
        <ListItem>The icon are from Fontawesome</ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Button>Lorem ipsum</Button>

    <Title type="h2" className="my-4">
      Icons
    </Title>
    <Row className="align-items-center mb-1">
      <Col lg={2}>Left</Col>
      <Col lg={10}>
        <Button>
          <Icon icon="home" /> Lorem ipsum
        </Button>
      </Col>
    </Row>
    <Row className="align-items-center ">
      <Col lg={2}>Right</Col>
      <Col lg={10}>
        <Button>
          Lorem ipsum <Icon icon="home" />
        </Button>
      </Col>
    </Row>

    <Title type="h2" className="my-4">
      Full colors
    </Title>
    {fullColors.map((color, index) => (
      <Row key={index} className="align-items-center mb-1">
        <Col lg={2}>{color}</Col>
        <Col lg={10}>
          <Button color={color} className="mr-2">
            Lorem ipsum
          </Button>
          <Button color={color} disabled={true}>
            Lorem ipsum
          </Button>
        </Col>
      </Row>
    ))}

    <Title type="h2" className="my-4">
      Outline colors
    </Title>
    {outlineColors.map((color, index) => (
      <Row key={index} className="align-items-center mb-1">
        <Col lg={2}>{color}</Col>
        <Col lg={10}>
          <Button color={color} outline={true} className="mr-2">
            Lorem ipsum
          </Button>
          <Button color={color} outline={true} disabled={true}>
            Lorem ipsum
          </Button>
        </Col>
      </Row>
    ))}

    <Title type="h2" className="my-4">
      Sizes
    </Title>
    {Object.entries(sizes).map(([size, px], index) => (
      <Row key={index} className="align-items-center mb-1">
        <Col lg={2}>
          {size} (height: {px})
        </Col>
        <Col lg={10}>
          <Button size={size}>Lorem ipsum</Button>
        </Col>
      </Row>
    ))}
    <Title type="h2" className="my-4">
      Type download
    </Title>
    <DownloadButton label="Button text is customizable" />

    <Title type="h2" className="my-4">
      Responsive model
    </Title>
    <p>
      Resize the window to see the ResponsiveButton behaviour on different
      breakpoints.
    </p>
    <ResponsiveButton
      icon="home"
      color={EColor.BLUE}
      id="test-1"
      className="mr-2"
    >
      Lorem ipsum
    </ResponsiveButton>
    <ResponsiveButton
      icon="home"
      color={EColor.BLUE}
      id="test-2"
      className="mr-2"
      disabled={true}
    >
      Lorem ipsum
    </ResponsiveButton>
    <ResponsiveButton
      icon="home"
      color={EColor.BLUE}
      id="test-1"
      className="mr-2"
      outline={false}
    >
      Lorem ipsum
    </ResponsiveButton>
    <ResponsiveButton
      icon="home"
      color={EColor.BLUE}
      id="test-2"
      disabled={true}
      outline={false}
    >
      Lorem ipsum
    </ResponsiveButton>

    <Title type="h2" className="my-4">
      With Loader
    </Title>
    <Button isLoading={true} color={EColor.BLUE}>
      Lorem ipsum
    </Button>
  </>
)

export default StorybookPage
