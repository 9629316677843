import { Background, Form, Input, ListItem, SwitchControlled } from 'components'
import { createIntl } from 'hooks'
import Setting from 'pages/Settings/components/Setting'
import SettingGroup from 'pages/Settings/components/SettingGroup'
import { FC } from 'react'
import { RawIntlProvider } from 'react-intl'

const StorybookSetting: FC = () => {
  const intl = createIntl('pages_storybook')
  return (
    <div>
      <RawIntlProvider value={intl}>
        <Background title="Information">
          <ul>
            <ListItem>
              <strong>SettingGroup Component</strong>
              <ul>
                <li>
                  The SettingGroup Component is wraps an unlimited number of
                  settings
                </li>
                <li>The icon is customizable but not mandatory </li>
                <li>
                  The title and the description are customizable but not
                  mandatory
                </li>
              </ul>
            </ListItem>
            <ListItem>
              <strong>Setting Component</strong>
              <ul>
                <li>Three types of settings : simple, advanced and link</li>
                <li>
                  Simple settings
                  <ul>
                    <li>
                      Are directly displayed and can directly be interacted with
                    </li>
                    <li>The value shall be saved onChange</li>
                  </ul>
                </li>
                <li>
                  Advanced settings
                  <ul>
                    <li>Display a button to open a sidePanel</li>
                  </ul>
                </li>
                <li>
                  Link settings
                  <ul>
                    <li>Display a button with a link</li>
                  </ul>
                </li>
              </ul>
            </ListItem>
          </ul>
        </Background>

        <SettingGroup
          initialValues={{
            simple1: false,
            simple2: 'Foobar',
          }}
          children={() => (
            <Form>
              <Setting
                description="Simple setting description"
                title="Simple setting"
              >
                <SwitchControlled name="simple1" className="mb-0" />
              </Setting>
              <Setting
                description="Other simple setting example"
                title="Hello world!"
              >
                <Input name="simple2" />
              </Setting>
            </Form>
          )}
          title="Simple setting"
          description="Setting description"
          icon="lock-alt"
          validationFunction={() => ({})}
          validationCallback={async () => {
            // This is intentional to prevent eslint warning
          }}
        />

        <SettingGroup
          initialValues={{
            advanced: '',
          }}
          children={() => (
            <Form>
              <Setting
                description="Advanced setting description"
                title="Advanced setting"
                button={{
                  text: 'Action',
                  handleClick: () => alert('ACTION TRIGGERED'),
                }}
              />
            </Form>
          )}
          title="Advanced setting"
          icon="times"
          validationFunction={() => ({})}
          validationCallback={async () => {
            // This is intentional to prevent eslint warning
          }}
        />

        <SettingGroup
          initialValues={{}}
          children={() => (
            <Form>
              <Setting
                description="Link setting effect"
                title="Link setting"
                link={{ href: 'https://assoconnect.com', text: 'Example' }}
              />
            </Form>
          )}
          title="Link setting"
          icon="calendar"
          validationFunction={() => ({})}
          validationCallback={async () => {
            // This is intentional to prevent eslint warning
          }}
        />
      </RawIntlProvider>
    </div>
  )
}

export default StorybookSetting
