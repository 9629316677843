import classNames from 'classnames'
import { FormGroup, FormHelper } from 'components'
import { Field, FieldProps } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { IUseTranslation } from 'hooks/useTranslation/type'
import { FC, useRef } from 'react'
import { uniqId } from 'utils'

import { Checkbox as StyledCheckbox } from '../Checkbox/style'
import * as Type from './type'

const validateCheckbox = (
  required: boolean,
  translation: IUseTranslation,
  value = false
): undefined | string => {
  // Required
  if (required && !value) {
    return translation.translate('validate.required.individual')
  }
}

const CheckboxIndividual: FC<Type.IIndividualCheckbox> = ({
  className,
  helpText,
  label,
  name,
  required = false,
  ...rest
}) => {
  const refUniqueId = useRef(uniqId('checkbox'))
  const intl = createIntl('components_checkbox')
  const translation = useTranslation(intl)

  return (
    <Field
      name={name}
      validate={(checkboxValue = false) =>
        validateCheckbox(required, translation, checkboxValue)
      }
      children={({ field, form: { setFieldValue } }: FieldProps) => (
        <FormGroup>
          <StyledCheckbox
            {...field}
            id={refUniqueId.current}
            type="checkbox"
            checked={!!field.value}
            label={<span>{label}</span>}
            onChange={() => {
              setFieldValue(field.name, !field.value)
            }}
            className={classNames('d-flex', className)}
            {...rest}
          />
          <FormHelper fieldName={field.name} helpText={helpText} />
        </FormGroup>
      )}
    />
  )
}

export default CheckboxIndividual
