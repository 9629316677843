import { API } from 'api/connector'
import { IApiNonprofit } from 'api/type'
import { IFilterBag } from 'components/Table/type'
import { Dinero } from 'dinero.js'
import { FormikValues } from 'formik'
import { EPaymentMethods, IPageData } from 'pages/Payment/type'

import * as Type from './type'
import { ERetributionType, IApiPaymentRequest } from './type'

export const getPaymentRequest = async (id: string): Promise<IPageData> => {
  const paymentRequest = await API.get(
    `/api/v1/payment_page_configurations/${id}`
  )
  return paymentRequest.data
}

export const getPaymentRequestRefusalReason = async (
  id: string
): Promise<Type.IApiPaymentRequestRefusalReason> => {
  const refusalReason = await API.get(
    `/api/v1/payment_page_configurations/${id}/refusal_reason`
  )
  return refusalReason.data
}

const getTipAmount = (amount: number) =>
  parseInt(amount.toString()) < 0 ? 0 : amount

export const sendPayment = async (
  values: FormikValues,
  pageData: IPageData,
  paymentPageConfigurationId: string,
  tipAmount: Dinero | undefined
): Promise<Type.IApiPay> => {
  // Remove unused params for API params
  const {
    organization,
    payableEntityIdentifier,
    paymentMethods,
    price,
    publicContext,
    retributionMethod,
    retryCount,
    ...apiParams
  } = pageData
  delete apiParams['@context']
  delete apiParams['@id']
  delete apiParams['@type']

  // Create paymentParams object
  const paymentParams: Type.IApiPayload = {
    paymentMethod: values.paymentMethod,
    paymentPageConfigurationId,
    ...apiParams,
  }

  // Add paymentData
  if (values.paymentData) {
    paymentParams.paymentData = values.paymentData
    paymentParams.paymentAdditionalData = values.paymentAdditionalData
  }

  // Add existing tips values
  if (retributionMethod === ERetributionType.TIPS && tipAmount !== undefined) {
    paymentParams.tips = {
      amount: getTipAmount(tipAmount.getAmount()),
      currency: tipAmount.getCurrency(),
    }
  }

  // Apple Pay specific changes in API params
  if (values.paymentMethod === EPaymentMethods.ADYEN_APPLE_PAY) {
    // Overwrite payment method for API
    paymentParams.paymentMethod = EPaymentMethods.ADYEN
    // Remove unused parameters
    for (const key of ['layout', 'errorUrl', 'successUrl', 'processed']) {
      delete paymentParams[key as keyof Type.IApiPayload]
    }
  }

  const payment = await API.post('/api/v1/pay', paymentParams)
  return payment.data
}

export const getPaymentMethods = async ({
  organizationId,
  dinero,
  ...params
}: Type.IApiPaymentParams): Promise<Array<Type.IApiPaymentMethods>> => {
  const paymentConfig = await API.get(
    `/api/v1/pay/adyen/organization/${organizationId}/payment_methods`,
    {
      params: {
        amount: dinero.getAmount(),
        currencyCode: dinero.getCurrency(),
        ...params,
      },
    }
  )
  return paymentConfig.data.paymentMethods
}

export const getPaymentRequestList = async (
  nonProfitId: IApiNonprofit['id'],
  params: IFilterBag
): Promise<{
  data: Array<IApiPaymentRequest>
  totalItems: number
}> => {
  const PaymentRequestList = await API.get(
    `/api/v1/organizations/${nonProfitId}/payment_requests`,
    { params }
  )

  return {
    data: PaymentRequestList.data['hydra:member'],
    totalItems: PaymentRequestList.data['hydra:totalItems'],
  }
}

export const createPaymentLinkMultiple = async ({
  paymentRequests,
  description,
}: Type.IApiPaymentRequestCreateMultiple): Promise<
  Array<IApiPaymentRequest>
> => {
  const paymentConfig = await API.post('/api/v1/payment_requests/multiple', {
    paymentRequests,
    description,
  })
  return paymentConfig.data
}

export const disablePaymentLink = async (
  paymentRequestId: IApiPaymentRequest['id']
) => API.put(`/api/v1/payment_requests/${paymentRequestId}/disable`, {})

export const getPaymentHistory = async (
  paymentRequestId: IApiPaymentRequest['id']
): Promise<Type.IApiPaymentHistory> => {
  const paymentHistory = await API.get(
    `/api/v1/psp_aggregates/${paymentRequestId}/timeline`
  )

  return paymentHistory.data
}

export const getPaymentRequestDetails = async (
  id: string
): Promise<Type.IApiPaymentRequestDetails> => {
  const paymentRequest = await API.get(`/api/v1/payment_requests/${id}`)

  return paymentRequest.data
}
