import { Display, Link } from 'components'
import { navigation } from 'layouts/Storybook/data'
import { FC } from 'react'

const StorybookPage: FC = () => (
  <>
    {navigation.map((category, categoryIndex: number) => (
      <div key={categoryIndex}>
        <Display type="h3" className="my-3" uppercase={true}>
          {category.title}
        </Display>
        {category.items.map((item, itemIndex) => (
          <div key={itemIndex}>
            {item.items ? (
              <>
                <div key={itemIndex} className="pl-3">
                  {item.title}
                </div>
                <>
                  {item?.items.map((subItem, subItemIndex) => (
                    <Link
                      key={subItemIndex}
                      href={subItem.href}
                      className="pl-5 d-block"
                    >
                      {subItem.title}
                    </Link>
                  ))}
                </>
              </>
            ) : (
              <Link key={itemIndex} href={item.href} className="pl-3 d-block">
                {item.title}
              </Link>
            )}
          </div>
        ))}
      </div>
    ))}
  </>
)

export default StorybookPage
