import { EResponsiveButtonSize } from 'components/Button/Responsive/type'
import { FC, useRef } from 'react'
import { EColor } from 'types'
import { uniqId } from 'utils'

import * as Style from './style'
import * as Type from './type'

const ResponsiveButton: FC<Type.IResponsiveButton> = ({
  children,
  color = EColor.GREY,
  id,
  icon,
  outline = true,
  tooltipPosition = 'bottom',
  type = 'button',
  buttonSize = EResponsiveButtonSize.SMALL,
  ...rest
}) => {
  const prefixId = 'responsive-btn' // Prevent errors if the id starts with a number
  const refId = useRef(id || uniqId(prefixId))
  return (
    // Important : Keep ref on span to handle tooltips if disabled
    <span id={refId.current}>
      <Style.ResponsiveButton
        outline={outline}
        type={type}
        color={color}
        buttonSize={buttonSize}
        {...rest}
      >
        <Style.ResponsiveIcon
          icon={icon}
          color={color}
          buttonSize={buttonSize}
        />
        <Style.ButtonText color={color} buttonSize={buttonSize}>
          {children}
        </Style.ButtonText>
        <Style.Tooltip
          placement={tooltipPosition}
          target={refId.current}
          color={color}
          buttonSize={buttonSize}
        >
          {children}
        </Style.Tooltip>
      </Style.ResponsiveButton>
    </span>
  )
}

export default ResponsiveButton
