import { Background, Button, Col, Icon, Row, Title } from 'components'
import { Radio } from 'components/Form/Radio/style'
import { ECtaType, ENotificationStatus } from 'providers/type'
import { FC } from 'react'
import { EColor } from 'types'

import { IInformationNotification } from './type'
import { getBackgroundParams } from './utils'

const InformationNotification: FC<IInformationNotification> = ({
  id,
  ctas,
  content,
  title,
  type,
  handleRead,
  restrictions,
  status,
  ...rest
}) => {
  const handleChange = () => {
    handleRead(id, ENotificationStatus.READ)
  }

  const ctasList = {
    primary: ctas?.find((cta) => cta.type === ECtaType.PRIMARY),
    secondary: ctas?.find((cta) => cta.type === ECtaType.SECONDARY),
  }

  return (
    <Background {...getBackgroundParams(status)} {...rest}>
      <Row>
        <Col xs={1} className="text-center">
          <Radio
            id={id}
            type="radio"
            checked={status === ENotificationStatus.READ}
            onChange={handleChange}
          />
        </Col>
        <Col>
          <Row className="align-items-center">
            <Col xs="auto">
              <Title type="h6" color={EColor.DARK_GREY} className="mb-0">
                {title}
              </Title>
            </Col>
          </Row>

          <p className="mb-0">{content}</p>
          {ctasList.primary && (
            <div className="text-right mt-2">
              <Button
                onClick={() => {
                  ctasList.primary?.onClick()
                }}
                color={EColor.TRANSPARENT}
              >
                <strong>
                  {ctasList.primary.text}
                  <Icon icon={['far', 'long-arrow-right']} className="ml-3" />
                </strong>
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Background>
  )
}

export default InformationNotification
