import { isObject, isObjectEqual } from 'utils'

export const hasArrayIntersection = (a: string[], b: string[]): boolean => {
  const setA = new Set(a)
  const setB = new Set(b)
  const intersection = new Set([...setA].filter((x) => setB.has(x)))
  return !!Array.from(intersection).length
}

export const getArraySum = (array: number[]): number =>
  array.reduce((a, b) => a + b, 0)

export const isArraysEqual = (arrA: any[], arrB: any[]): boolean =>
  arrA.length === arrB.length &&
  arrA.every((item, index) => {
    if (isObject(item) && isObject(arrB[index])) {
      return isObjectEqual(item, arrB[index])
    }
    return Object.is(item, arrB[index])
  })
