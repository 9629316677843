import { getPaymentRequestRefusalReason } from 'api'
import { IApiPaymentRequestRefusalReason } from 'api/type'
import {
  Background,
  Button,
  Col,
  Display,
  EmptySpace,
  Link,
  Loader,
} from 'components'
import { EBackgroundBorderColor } from 'components/Background/type'
import { useTranslation } from 'hooks'
import { Background3DSecureExplanations } from 'pages/Payment/components'
import { EIntent, EPageDataLayout, IPayment } from 'pages/Payment/type'
import { RouterContext } from 'providers'
import { FC, useContext, useState } from 'react'
import { RawIntlProvider, useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { useAsync } from 'react-use'
import { Row } from 'reactstrap'
import { EColor, EYado } from 'types'
import { decodeUrlParameters } from 'utils'

import * as Type from './type'

const refusalParams = {
  max: 2,
  delay: 5000,
}

const Failure: FC<IPayment> = ({ pageData, paymentPageConfigurationId }) => {
  const intl = useIntl()
  const translation = useTranslation(intl)

  // Search parameter "payload" is used by payment failure and payment success pages
  const { urlSearchParameters } = useContext(RouterContext)
  const payload = decodeUrlParameters<Type.IPaymentFailurePayload>(
    urlSearchParameters?.payload
  )

  const [failureDetails, setFailureDetails] = useState<
    undefined | IApiPaymentRequestRefusalReason
  >(undefined)

  const getRefusalReasons = (tries: number) => {
    if (tries >= refusalParams.max) {
      setFailureDetails({
        is3DSRefusalReason: false,
        refusalReason: null,
      })
      return
    }

    setTimeout(async () => {
      try {
        const reasonDetails = await getPaymentRequestRefusalReason(
          paymentPageConfigurationId
        )
        if (reasonDetails.refusalReason === null) {
          getRefusalReasons(tries + 1)
        } else {
          setFailureDetails(reasonDetails)
        }
      } catch (error: unknown) {
        getRefusalReasons(tries + 1)
      }
    }, tries * refusalParams.delay)
  }

  useAsync(async () => {
    // There is no need for failure details without a retry url
    if (!payload?.retryUrl) {
      setFailureDetails({
        is3DSRefusalReason: false,
        refusalReason: null,
      })
      return
    }

    getRefusalReasons(0)
  }, [])

  const { status } = useParams()
  const failureStatus = status || 'other'

  const explanations = failureDetails?.is3DSRefusalReason
    ? Object.values(Type.E3DSRefusalReasons)
    : Object.values(Type.EClassicalRefusalReasons)

  const displayTextDependingOnLayout = () => {
    if (pageData.layout === EPageDataLayout.BACKOPS && pageData.errorUrl) {
      return (
        <div>
          {translation.translate('failure.message.backops.text')}
          <Link href={pageData.errorUrl} target="_self">
            <Button color={EColor.BLUE} className="mt-3">
              {translation.translate('failure.button.retry')}
            </Button>
          </Link>
        </div>
      )
    }

    return !payload?.retryUrl ? (
      <div>
        {translation.translate(`failure.message.noRetry.${failureStatus}`)}
      </div>
    ) : (
      <>
        <div>
          {translation.translate(
            `failure.message.retry${
              payload.paymentPageConfigurationIntent === EIntent.METHOD_CHANGE
                ? '.methodChange'
                : ''
            }`
          )}
        </div>
        <Link href={payload.retryUrl} target="_self">
          <Button color={EColor.BLUE} className="mt-3">
            {translation.translate('failure.button.retry')}
          </Button>
        </Link>
        {pageData.errorUrl &&
          payload.paymentRequestIntent !== EIntent.METHOD_CHANGE && (
            <div className="text-center pt-3">
              <Link href={pageData.errorUrl} target="_self">
                {translation.translate('link.errorUrl')}
              </Link>
            </div>
          )}
      </>
    )
  }

  const getTranslationIdForTitleDependingOnLayout = () => {
    if (pageData.layout === EPageDataLayout.BACKOPS) {
      return `failure.title.backops`
    }
    if (payload?.paymentRequestIntent === EIntent.METHOD_CHANGE) {
      return `failure.title.retry.methodChange`
    }

    return `failure.title.${
      payload?.retryUrl ? 'retry' : `noRetry.${failureStatus}`
    }`
  }

  return (
    <RawIntlProvider value={intl}>
      <div className="mb-4 py-3 py-lg-5">
        <Row className="justify-content-center text-center">
          <Col xs="auto" lg={7}>
            <EmptySpace
              title={translation.translate(
                getTranslationIdForTitleDependingOnLayout()
              )}
              yado={EYado.ACCESS_DENIED}
            >
              {displayTextDependingOnLayout()}
            </EmptySpace>
          </Col>
          {payload?.retryUrl && (
            <Col lg={5} className="pt-3 pt-lg-0">
              {!failureDetails ? (
                <div className="my-4 h-100">
                  <Loader
                    isRelative={true}
                    text={
                      <span className="px-2">
                        {translation.translate('failure.loader.message')}
                      </span>
                    }
                  />
                </div>
              ) : (
                <>
                  <Background
                    border={EBackgroundBorderColor.SOLID}
                    borderColor={EColor.BLUE}
                    className="text-left mt-3 mt-lg-0 mb-4"
                  >
                    {failureDetails.refusalReason && (
                      <>
                        <Display type="h5" color={EColor.BLUE}>
                          {translation.translate('failure.reason.title')}
                        </Display>
                        <p className="pb-3">{failureDetails.refusalReason}</p>
                      </>
                    )}
                    <Display type="h5" color={EColor.BLUE}>
                      {translation.translate(
                        `failure.${
                          failureDetails.is3DSRefusalReason ? '3DS' : 'generic'
                        }.explanations.title`
                      )}
                    </Display>
                    {explanations.map((explanation, index) => (
                      <div key={explanation}>
                        {index !== 0 && <hr />}
                        <p className="text-muted">
                          {failureDetails.is3DSRefusalReason && (
                            <strong className="text-primary mr-1">
                              {index + 1}/
                            </strong>
                          )}
                          {translation.translate(
                            `failure.${
                              failureDetails.is3DSRefusalReason
                                ? '3DS'
                                : 'generic'
                            }.explanations.text.${explanation}`
                          )}
                        </p>
                      </div>
                    ))}
                  </Background>

                  <Background3DSecureExplanations
                    showSupportedCreditCards={true}
                  />
                </>
              )}
            </Col>
          )}
        </Row>
      </div>
    </RawIntlProvider>
  )
}

export default Failure
