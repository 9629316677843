import { Button, Form, Phone, Title } from 'components'
import { ENumberType } from 'components/Form/Phone/type'
import { Formik } from 'formik'
import { FC } from 'react'
import { EColor } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Phone
    </Title>

    <Title type="h2" className="my-4">
      Component
    </Title>

    <Formik
      initialValues={{
        phone: '',
        phoneWithDefaultFRValueIntlFormat: '+33622987121',
        phoneWithDefaultFRValueFrenchFormat: '0622987121',
        phoneWithDefaultUSValue: '+12015554567',
        phoneNotRequired: '',
        phoneFixedLine: '',
        phoneMobile: '',
        phoneDisabled: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Phone
            name="phone"
            label="Phone"
            helpText="Free phone value"
            required={true}
          />
          <Phone
            name="phoneWithDefaultFRValueIntlFormat"
            label="Phone with default FR value (international format)"
            required={true}
          />
          <Phone
            name="phoneWithDefaultFRValueFrenchFormat"
            label="Phone with default FR value (french format)"
            required={true}
          />
          <Phone
            name="phoneWithDefaultUSValue"
            label="Phone with default US value"
            required={true}
          />
          <Phone
            name="phoneNotRequired"
            label="Not required phone"
            required={false}
          />
          <Phone
            name="phoneFixedLine"
            label="Phone with required & fixed line"
            required={true}
            numberType={ENumberType.FIXED_LINE}
          />
          <Phone
            name="phoneMobile"
            label="Phone with required & mobile"
            required={true}
            numberType={ENumberType.MOBILE}
          />
          <Phone name="phoneDisabled" label="Disabled Phone" disabled={true} />
          <Button color={EColor.BLUE} type="submit">
            Submit
          </Button>
        </Form>
      )}
    />
  </>
)

export default StorybookPage
