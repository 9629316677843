import {
  Background,
  Dropdown,
  DropdownItem,
  Icon,
  Link,
  ListItem,
  Title,
} from 'components'
import { FC } from 'react'
import { EColor } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Dropdown
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>
          The dropdown items contains a link / text / icon / divider
        </ListItem>
        <ListItem>The dropdown can be displayed from button</ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Dropdown
      button={{
        color: EColor.BLUE,
        children: <>Click to open</>,
        title: 'Button title',
      }}
    >
      <DropdownItem>
        <Link href="/">
          <Icon icon="home" className="mr-3" />
          Link with icon
        </Link>
      </DropdownItem>
      <DropdownItem>
        <Link href="/">Link</Link>
      </DropdownItem>
      <DropdownItem>Text</DropdownItem>
      <DropdownItem divider={true} />
      <DropdownItem>Divider visible above</DropdownItem>
    </Dropdown>
  </>
)

export default StorybookPage
