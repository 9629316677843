import { IconProp } from '@fortawesome/fontawesome-svg-core'
import * as Type from 'api/type'
import { ECollectType, ICollectsListDetails } from 'api/type'
import { ISetFieldValue, ISubmitForm } from 'components/Form/type'
import { IResultsCard } from 'components/Results/Card/type'
import { IResultsFilter } from 'components/Results/Filters/type'
import { FormikProps, FormikValues } from 'formik'
import { ENetworkType } from 'pages/Network/type'
import { ReactNode } from 'react'

type IDataType = Type.ICollectResults | Type.IGroupsResults

export type IFormikRef = FormikProps<Record<string, unknown>>

export interface IResultsWrapper {
  data: (filters: FormikValues) => Promise<IDataType>
  details?: (filters: FormikValues) => Promise<ICollectsListDetails>
  defaultFilters?: Array<string>
  emptySpaceNoItems: (
    setFieldValue: ISetFieldValue,
    submitForm: ISubmitForm
  ) => ReactNode
  filters?: IResultsFilter
  getCreateButton?: (values: FormikValues) => {
    href: string
    text: string
    icon: IconProp
    onClickEvent?: () => void
  }
  params?: FormikValues
  orderOptions?: null | Array<{ label: string; value: string }>
  display: (
    result: IResult,
    setFieldValue: ISetFieldValue,
    submitForm: () => void,
    details?: ICollectsListDetails
  ) => IResultsCard
  resultsType: IResultsType
  showItemsNumber?: boolean
}

export enum EChangingState {
  CHANGING_NEW = 'CHANGING_NEW',
  CHANGING_MORE = 'CHANGING_MORE',
  NOT_CHANGING = 'NOT_CHANGING',
}

type IResultsType = ECollectType | ENetworkType

type IResult = Type.ICollect | Type.IApiOrganizationGroup

export type IResults = Array<IResult>
