import { Background, Form, ListItem, Radio, Title } from 'components'
import { Formik } from 'formik'
import { FC } from 'react'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Radio
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>Size: 20px</ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        radio: '2',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Radio
            name="radio"
            label="Lorem ipsum 1..."
            value="1"
            helpText="Lorem ipsum..."
            required={true}
          />
          <Radio
            name="radio"
            label="Lorem ipsum 2..."
            value="2"
            helpText="Lorem ipsum..."
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Disabled state
    </Title>
    <Formik
      initialValues={{
        radioDisabled: '2',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Radio
            name="radioDisabled"
            label="Lorem ipsum 1..."
            helpText="Lorem ipsum..."
            value="1"
            disabled={true}
          />
          <Radio
            name="radioDisabled"
            label="Lorem ipsum 2..."
            helpText="Lorem ipsum..."
            value="2"
            disabled={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without help text
    </Title>
    <Formik
      initialValues={{
        radioWithoutHelpText: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Radio
            name="radioWithoutHelpText"
            label="Lorem ipsum 1..."
            value="1"
          />
          <Radio
            name="radioWithoutHelpText"
            label="Lorem ipsum 2..."
            value="2"
          />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
