import { BlobServiceClient } from '@azure/storage-blob'
import { API } from 'api/connector'
import { AxiosResponse } from 'axios'
import classNames from 'classnames'
import { CloseButton, FormGroup, FormHelper, Label, Svg } from 'components'
import { Field, FieldProps, useFormikContext } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import {
  FC,
  MouseEvent as ReactMouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FileError, FileRejection, useDropzone } from 'react-dropzone'
import { EColor } from 'types'
import { uniqId } from 'utils'

import * as Data from './data'
import * as Style from './style'
import * as Type from './type'

const getStatus = (
  invalid: boolean,
  pending: boolean,
  value: boolean
): Type.Status => {
  if (pending) {
    return 'pending'
  }
  if (invalid) {
    return 'error'
  }
  if (value) {
    return 'success'
  }
  return 'default'
}

const removeErrorDuplicates = (errors: FileError[]): FileError[] =>
  errors.filter(
    (error, index, fileErrors) =>
      fileErrors.findIndex((fileError) => fileError.code === error.code) ===
      index
  )

const getSizeRuleByMimeType = (
  sizesByMimeType: Type.IMimeTypeSizes,
  type: string
): Type.ITypeSizes | null => {
  const typeSize = Object.entries(sizesByMimeType).filter(
    (sizeByMimeType) => sizeByMimeType[0] === type
  )[0]

  return typeSize ? typeSize[1] : null
}

const File: FC<Type.IFile> = ({
  accept = ['application/pdf', 'image/jpeg', 'image/png'],
  className,
  helpText,
  label,
  multiple = false, // Multiple upload is not implemented
  name,
  required = false,
  //The unit is bytes
  sizesByMimeType = { '*': { min: 0, max: 10000000 } },
  ...rest
}) => {
  const refUniqueId = useRef(uniqId('file'))
  const [pending, setPending] = useState(false)
  const [isDrag, setIsDrag] = useState(false)
  const [uploadErrors, setUploadErrors] = useState('')
  const { setFieldTouched, setFieldValue } = useFormikContext()
  const refFilename = useRef('')
  const refBlobServiceClient = useRef<BlobServiceClient>()
  const intl = createIntl('components_file')
  const translation = useTranslation(intl)

  // Toggle the DragActive effect (don't use useDropzone > isDragActive because https://github.com/react-dropzone/react-dropzone/issues/911)
  const onDragOver = useCallback(() => setIsDrag(true), [setIsDrag])
  const onDragLeave = useCallback(() => setIsDrag(false), [setIsDrag])

  const getAzureBlobServiceClient = async () => {
    // Use ref if an Azure blob service client already exist
    if (refBlobServiceClient.current) {
      return refBlobServiceClient.current
    }
    // Use API Platform if no Azure blob service exist
    return API.get('/api/v1/azure/storage/url')
      .then((response: AxiosResponse) => {
        refBlobServiceClient.current = new BlobServiceClient(response.data.url)
        return refBlobServiceClient.current
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const validator = (file: File): FileError | FileError[] | null => {
    if (!sizesByMimeType) {
      return null
    }

    const rule =
      getSizeRuleByMimeType(sizesByMimeType, file.type) ||
      getSizeRuleByMimeType(sizesByMimeType, '*')

    if (!rule) {
      return null
    }

    const errors: { code: string; message: string }[] = []
    if (rule.min && file.size < rule.min) {
      errors.push({
        code: 'file-too-small',
        message: '',
      })
    }
    if (file.size > rule.max) {
      errors.push({
        code: 'file-too-large',
        message: '',
      })
    }
    return errors.length ? errors : null
  }

  const handleRejectedFiles = useCallback(
    (reason?: string, file?: FileRejection) => {
      if (reason === 'heic') {
        setUploadErrors(translation.translate('file-invalid-type'))
        return
      }

      if (!file) {
        return
      }

      if (!file.errors) {
        return
      }

      const errors = []
      const acceptedErrors = [
        'file-invalid-type',
        'file-too-large',
        'file-too-small',
        'heic-file',
      ]

      for (const error of removeErrorDuplicates(file.errors)) {
        const errorCode = acceptedErrors.includes(error.code)
          ? error.code
          : 'validate.genericError'
        errors.push(translation.translate(errorCode))
      }

      if (errors.length) {
        setUploadErrors(errors.join('<br />'))
        setFieldTouched(name)
      }
    },
    [name, setFieldTouched, translation]
  )

  useEffect(() => {
    if (uploadErrors && setFieldTouched) {
      setFieldTouched(name)
    }
  }, [uploadErrors, name, setFieldTouched])

  const handleAcceptedFiles = useCallback(
    async (file: File) => {
      setPending(true)

      const blobServiceClient = await getAzureBlobServiceClient()
      if (!blobServiceClient) {
        setUploadErrors(
          translation.translate('validate.azureConnectionFailure')
        )
        setFieldTouched(name)
        return
      }
      const fileName = uniqId()
      const containerClient = blobServiceClient.getContainerClient('kyc')
      const blockBlobClient = containerClient.getBlockBlobClient(fileName)
      try {
        await blockBlobClient.uploadBrowserData(file, {
          blobHTTPHeaders: { blobContentType: file.type },
        })
      } catch (error: unknown) {
        setUploadErrors(translation.translate('validate.uploadFailure'))
        setFieldTouched(name)
        setPending(false)
        return
      }
      setPending(false)
      setUploadErrors('')
      refFilename.current = file.name
      setFieldValue(name, fileName)
    },
    [name, setFieldTouched, setFieldValue, translation]
  )

  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setIsDrag(false)

      if (!acceptedFiles.length && !rejectedFiles.length) {
        return
      }

      if (rejectedFiles.length) {
        handleRejectedFiles('', rejectedFiles[0])
        return
      }

      const reader = new FileReader()
      reader.onloadend = async function () {
        const arr = new Uint8Array(reader.result as ArrayBuffer).subarray(0, 12)
        const signature = Array.from(arr)
          .map((b) => String.fromCharCode(b))
          .join('')

        if (signature.includes('ftypmif1') || signature.includes('ftypheic')) {
          handleRejectedFiles('heic')
        } else {
          await handleAcceptedFiles(acceptedFiles[0])
        }
      }

      reader.readAsArrayBuffer(acceptedFiles[0])
    },
    [handleRejectedFiles, handleAcceptedFiles]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple,
    onDragLeave,
    onDragOver,
    onDrop,
    validator,
    ...rest,
  })

  const validate = (value: string): undefined | string => {
    if (uploadErrors) {
      return uploadErrors
    }
    if (required && !value) {
      return translation.translate('validate.required')
    }
  }

  const handleClearClick = (event: ReactMouseEvent<SVGSVGElement>) => {
    event.stopPropagation()
    setUploadErrors('')
    setFieldValue(name, '')
  }

  return (
    <Field
      name={name}
      validate={validate}
      children={({ field, form: { errors, touched } }: FieldProps) => {
        const invalid = !!(touched[field.name] && errors[field.name])

        const status = getStatus(invalid, pending, field.value !== '')

        return (
          <FormGroup className={className}>
            {label && (
              <Label isRequired={required} for={refUniqueId.current}>
                {label}
              </Label>
            )}
            <Style.File
              status={status}
              statusParams={Data.statusParams}
              className={classNames('d-flex form-control', {
                'is-valid': status === 'success',
                'is-invalid': status === 'error',
              })}
              {...getRootProps({
                isDragActive: isDrag && status === 'default',
              })}
            >
              <input id={refUniqueId.current} {...getInputProps()} />
              <input type="hidden" {...field} />
              <Svg
                className="mr-4"
                src="common/icon/multicolor/cloud-arrow-up"
                width="46px"
                colorSecondary={Data.statusParams[status].iconColor}
              />
              <div className="d-flex flex-grow-1 align-items-center">
                {status === 'success' ? (
                  <>
                    <Style.Filename>
                      {refFilename.current}
                      <CloseButton
                        className="ml-2"
                        color={EColor.GREY}
                        onClick={handleClearClick}
                      />
                    </Style.Filename>
                  </>
                ) : (
                  translation.translate(`${status}.text`, {
                    a: (string: string) => (
                      <span
                        onClick={(e) => e.preventDefault()}
                        className="text-primary"
                      >
                        {string}
                        &nbsp;
                      </span>
                    ),
                  })
                )}
              </div>
              {Data.statusParams[status].iconSrc && (
                <Style.StatusIcon
                  className="ml-4"
                  src={Data.statusParams[status].iconSrc}
                  color={Data.statusParams[status].iconColor}
                  status={status}
                  width="37px"
                />
              )}
            </Style.File>
            <FormHelper fieldName={field.name} helpText={helpText} />
          </FormGroup>
        )
      }}
    />
  )
}

export default File
