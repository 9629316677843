import {
  Background,
  Form,
  Input,
  PublicButton,
  PublicRadioBackgrounds,
  PublicRadioButtons,
  PublicTitle,
  Title,
} from 'components'
import { Formik } from 'formik'
import { ContentWrapper } from 'layouts/Public/style'
import { ChangeEvent, FC, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { generateRandomHexColor, getTextColorFromColorHex } from 'utils'

const StorybookPage: FC = () => {
  const randomColor = generateRandomHexColor()
  const [customColor, setCustomColor] = useState({
    publicMainColor: randomColor,
    textColor: getTextColorFromColorHex(randomColor),
  })

  const radios = [
    {
      id: 'radio-20',
      value: '20',
      label: '20€',
      description: 'Don de 20€ pour la recherche',
    },
    {
      id: 'radio-30',
      value: '30',
      label: '30€',
      description: 'Don de 30€ pour la recherche',
    },
    {
      id: 'radio-40',
      value: '40',
      label: '40€',
      description: 'Don de 40€ pour la recherche',
    },
    {
      id: 'radio-60',
      value: '60',
      label: '60€',
      description: 'Don de 60€ pour la recherche',
    },
  ]

  return (
    <>
      <Formik
        initialValues={{
          test: '',
          color: customColor.publicMainColor,
          testButtons: '',
        }}
        onSubmit={() => {
          // This is intentional to prevent eslint warning
        }}
        children={() => (
          <Form>
            <Title type="h1">Pick a color (hexa)</Title>
            <Input
              name="color"
              placeholder="#316bf2"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.value.length === 7) {
                  setCustomColor({
                    publicMainColor: event.target.value,
                    textColor: getTextColorFromColorHex(event.target.value),
                  })
                }
              }}
            />
            <ThemeProvider
              theme={{ publicMainColor: customColor.publicMainColor }}
            >
              <ContentWrapper>
                <PublicTitle type="h1">Lorem ipsum</PublicTitle>
                <hr />
                <PublicButton>BUTTON</PublicButton>
                <hr />
                <PublicButton outline={true}>BUTTON OUTLINE</PublicButton>
                <hr />
                <p>
                  Lorem ipsum dolor sit amet,{' '}
                  <a href="https://assoconnect.com">consectetur</a> adipisicing
                  elit. Accusamus assumenda beatae, deserunt dicta dolores
                  dolorum error fugiat illo iste iusto labore magnam, nemo nihil
                  non pariatur quasi repellendus reprehenderit vel.
                </p>
                <ul>
                  <li>lorem</li>
                  <li>ipsum</li>
                </ul>
                <hr />
                <Background>
                  <PublicRadioBackgrounds name="test" radios={radios} />
                </Background>
                <hr />
                <Background>
                  <PublicRadioButtons
                    name="testButtons"
                    buttons={[
                      { text: 'Button 1', value: 'value1' },
                      { text: 'Button 2', value: 'value2' },
                    ]}
                  />
                </Background>
              </ContentWrapper>
            </ThemeProvider>
          </Form>
        )}
      />
    </>
  )
}

export default StorybookPage
