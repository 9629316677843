import { EApiCrmPersonRole } from 'api/type'
import { Loader } from 'components'
import {
  createIntl,
  useHistory,
  usePerson,
  useSettings,
  useStore,
  useTranslation,
} from 'hooks'
import { IRouteLayout } from 'hooks/useAscRouter/type'
import { handleAccessDenied } from 'hooks/useAscRouter/utils'
import useHasAccess from 'hooks/useHasAccess'
import { ESetting } from 'hooks/useSettings/type'
import { useOrganization } from 'providers'
import { FC, useMemo } from 'react'
import { Navigate, useLocation } from 'react-router'
import { useAsync, useEffectOnce } from 'react-use'

const AdminRouter: FC<IRouteLayout> = ({
  component: Component,
  app,
  roles,
  fullWidthStyle = false,
  hideMenu = false,
  requiredSpecifications,
  layout: Layout,
  ...rest
}) => {
  const intlPath = 'hooks_router'
  const intl = createIntl(intlPath)
  const translation = useTranslation(intl)
  const history = useHistory()
  const location = useLocation()
  const store = useStore()

  // Initialize store
  useEffectOnce(() => {
    if (!store.isLoaded) {
      void store.initialize()
    }
  })

  const access = useHasAccess({ app, roles, requiredSpecifications })
  const { organization } = useOrganization()
  const { hasRole, isLoaded } = usePerson()
  const { getSetting } = useSettings()

  const {
    value: platformSpecifications,
    loading: isLoadingPlatformSpecifications,
  } = useAsync(
    async () => getSetting(ESetting.PLATFORM_SPECIFICATIONS, false, {}),
    []
  )

  const isPlatformUnAvailable = useMemo(() => {
    if (isLoadingPlatformSpecifications) {
      return undefined
    }

    return (
      isLoaded &&
      !hasRole(EApiCrmPersonRole.MASTER_ADMIN) &&
      (platformSpecifications?.platformMustSignGtcus ||
        platformSpecifications?.platformIsSuspended)
    )
  }, [
    hasRole,
    isLoaded,
    isLoadingPlatformSpecifications,
    platformSpecifications?.platformIsSuspended,
    platformSpecifications?.platformMustSignGtcus,
  ])

  if (
    !isLoaded ||
    isLoadingPlatformSpecifications ||
    isPlatformUnAvailable === undefined
  ) {
    return null
  }

  if (
    isPlatformUnAvailable &&
    `/organization/${organization.idOld}/platform/gtcus` !== location.pathname
  ) {
    return (
      <Navigate
        to={
          platformSpecifications?.platformIsSuspended
            ? '/platform/suspended'
            : `/organization/${organization.idOld}/platform/gtcus`
        }
      />
    )
  }

  if (
    !organization.subscription.specifications.hasAnActiveSubscription &&
    ![
      `/organization/${organization.idOld}/subscription/plan/choice`,
      `/organization/${organization.idOld}/wallet/dashboard`,
    ].includes(location.pathname) &&
    !hasRole(EApiCrmPersonRole.MASTER_ADMIN)
  ) {
    return (
      <Navigate to="/organization/subscription/locked?cause=subscription" />
    )
  }

  const renderContent = () => {
    if (!access.isLoaded) {
      return <Loader />
    }

    if (!access.hasAccess) {
      return handleAccessDenied(
        access.accessDenied?.reason,
        translation,
        organization,
        history,
        app
      )
    }

    return <Component {...rest} />
  }

  if (!store.isLoaded) {
    return null
  }

  return (
    <Layout
      fullWidthStyle={fullWidthStyle}
      accessSettings={access}
      hideMenu={hideMenu}
    >
      {renderContent()}
    </Layout>
  )
}

export default AdminRouter
