import { Button, Icon, Tooltip as ReactTooltip } from 'components'
import { darken } from 'polished'
import styled, { css } from 'styled-components'
import { hexToRGBa } from 'utils'

import { EResponsiveButtonSize } from './type'

const ResponsiveButtonStyle = css`
  width: 30px;
  height: 30px;
  padding: 0;
`

export const ResponsiveButton = styled(
  ({ color, buttonSize, outline, ...props }) => <Button {...props} />
)`
  color: ${({ theme, color, outline }) =>
    outline ? theme[color] : theme.white};
  background: ${({ theme, color, outline }) =>
    outline ? 'transparent' : theme[color]};
  border-color: ${({ color, ...props }) => props.theme[color]};
  line-height: 26px;
  padding: 0 0.857rem;

  &:hover,
  &:focus {
    color: ${({ theme, color, outline }) =>
      outline ? darken(0.1, theme[color]) : theme.white};
    border-color: ${({ theme, color, outline }) =>
      outline ? darken(0.1, theme[color]) : 'transparent'};
    background: ${({ theme, color, outline }) =>
      outline ? 'transparent' : darken(0.1, theme[color])};
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem
      ${({ theme, color }) => hexToRGBa(theme[color], 0.2)} !important;
  }

  &:active {
    color: ${(props) => props.theme.white} !important;
    background-color: ${({ theme, color }) => theme[color]} !important;
    border-color: ${({ theme, color, outline }) =>
      outline ? theme[color] : 'transparent'} !important;
    box-shadow: none !important;
  }

  &:disabled {
    color: ${(props) => props.theme.lightGrey} !important;
    background-color: ${(props) => props.theme.backgroundColor} !important;
    border-color: ${(props) => props.theme.lightGrey} !important;
  }

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    padding: 0 1.1rem;
  }

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    ${({ buttonSize }) =>
      buttonSize === EResponsiveButtonSize.SMALL && ResponsiveButtonStyle};
  }
`

const LargeResponsiveIconCss = css`
  display: inline-block;
  margin-right: 10px;
`
const ResponsiveIconCss = css`
  display: none;
  margin-right: 0;
`

export const ResponsiveIcon = styled(({ color, buttonSize, ...props }) => (
  <Icon {...props} />
))`
  color: inherit !important;
  ${({ buttonSize }) =>
    buttonSize === EResponsiveButtonSize.BIG
      ? LargeResponsiveIconCss
      : ResponsiveIconCss};

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    display: inline-block;
  }
`

export const ButtonText = styled(({ children, buttonSize, ...props }) => (
  <span {...props}>{children}</span>
))`
  display: inline;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
      ${({ buttonSize }) =>
        buttonSize === EResponsiveButtonSize.SMALL && 'display: none;'}
`

export const Tooltip = styled(({ children, buttonSize, ...props }) => (
  <ReactTooltip {...props}>{children}</ReactTooltip>
))`
  display: none;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
      ${({ buttonSize }) =>
        buttonSize === EResponsiveButtonSize.SMALL && 'display: block;'}
`
