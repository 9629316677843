import { Form, FrenchSiren, Title } from 'components'
import { Formik } from 'formik'
import { FC } from 'react'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / FrenchSiren
    </Title>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        frenchSiren: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <FrenchSiren
            helpText="Lorem ipsum..."
            name="frenchSiren"
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Disabled state
    </Title>
    <Formik
      initialValues={{
        frenchSirenDisabled: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <FrenchSiren
            name="frenchSirenDisabled"
            helpText="Lorem ipsum..."
            disabled={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Custom label
    </Title>
    <Formik
      initialValues={{
        frenchSirenCustomLabel: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <FrenchSiren
            name="frenchSirenCustomLabel"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without help text
    </Title>
    <Formik
      initialValues={{
        frenchSirenWithoutHelptext: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <FrenchSiren name="frenchSirenWithoutHelptext" required={true} />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
