import {
  Background,
  Col,
  ListItem,
  Row,
  Select,
  Table,
  Title,
} from 'components'
import { IColumns, IFilterBag } from 'components/Table/type'
import Dinero from 'dinero.js'
import { EAccountingAccountType } from 'pages/Accounting/ChartOfAccounts/type'
import { FC } from 'react'
import { EColor } from 'types'
import { getDateObjectWithoutTime, iriToId } from 'utils'

import {
  accountingEntriesWithFilterAmount,
  accountingEntriesWithFilterExpense,
  accountingEntriesWithFilterRevenue,
  accountingEntriesWithoutFilter,
} from './data'
import * as Type from './type'

const columns: IColumns = [
  {
    Header: '',
    accessor: 'icon',
    type: 'icon',
    size: 30,
  },
  {
    Header: 'name',
    accessor: 'name',
    type: 'text',
    size: 160,
  },
  {
    Header: 'date',
    accessor: 'date',
    type: 'date',
    size: 100,
  },
  {
    Header: 'badge',
    accessor: 'badge',
    type: 'badge',
  },
  {
    Header: 'amount',
    accessor: 'amount',
    type: 'amount',
    size: 100,
  },
  {
    size: 100,
    Header: '',
    accessor: 'button',
    type: 'button',
  },
  {
    size: 100,
    Header: '',
    accessor: 'buttons',
    type: 'buttons',
  },
]

const StorybookPage: FC = () => {
  const selectData = (filter: IFilterBag): Type.ISelectData => {
    if (filter['amount[lte]']) {
      return accountingEntriesWithFilterAmount
    }

    if (!filter['category_type[]']) {
      return accountingEntriesWithoutFilter
    }

    if (filter['category_type[]'] === EAccountingAccountType.REVENUE) {
      return accountingEntriesWithFilterRevenue
    }
    if (filter['category_type[]'] === EAccountingAccountType.EXPENSE) {
      return accountingEntriesWithFilterExpense
    }
    return accountingEntriesWithoutFilter
  }

  const getCategoryOptions = () =>
    [EAccountingAccountType.REVENUE, EAccountingAccountType.EXPENSE].map(
      (option) => ({
        value: option,
        label: option,
        color: EColor.GREEN,
      })
    )

  return (
    <>
      <Title type="h1" className="mb-4">
        Table
      </Title>
      <Background title="Information">
        <ul>
          <ListItem>
            The Table component have 7 predefined type for the column
            <ul>
              <li>Icon = fontawesome icon / color / tooltip</li>
              <li>
                Badge = fontawesome icon / color / tooltip / text (can be
                positioned at the left or the right of the badge icon)
              </li>
              <li>
                Amount = montant aligné sur la droite / gestion de la currency
                automatique Amount = amount is aligned on the right / currency
                is automatic
              </li>
              <li>
                Text = bold or regular (color can be set with 'color' props)
              </li>
              <li>Button = 1 element of Button type</li>
              <li>Buttons = x elements of ResponsiveButton type</li>
              <li>Date = formated date</li>
            </ul>
          </ListItem>
          <ListItem>Pagination is managed in the component</ListItem>
          <ListItem>Filters are not generic</ListItem>
          <ListItem>
            Main filter can either be a Select component or a search using an
            Input component
          </ListItem>
        </ul>
      </Background>

      <Title type="h2" className="my-4">
        Component
      </Title>

      <Table
        batch={{
          onClick: (selection) => {
            console.log('selection is ', selection)
          },
          text: 'See result in console',
        }}
        data={async (filter) => {
          const dataEntries = selectData(filter)
          const resultMatch = dataEntries['hydra:member'].map((res) => ({
            ...res,
            rowData: iriToId(res['@id']),
            icon: {
              icon: ['fas', 'globe'],
              color: EColor.RED,
            },
            name: {
              isBold: true,
              text: res.name,
              color: EColor.BLUE,
            },
            badge: {
              textLeft: res.name,
              isBold: true,
              icon: ['fas', 'calendar'],
              color: EColor.GREEN,
            },
            amount: Dinero({
              amount: res.amount,
              currency: 'EUR',
            }),
            date: getDateObjectWithoutTime(),
            button: {
              text: res.name.substring(0, 3),
              color: EColor.BLUE,
              onClick: () => {
                console.log('Passage button')
              },
            },
            buttons: [
              {
                text: res.name.substring(0, 3),
                icon: ['fas', 'calendar'],
                color: EColor.BLUE,
                onClick: () => {
                  console.log('Passage button')
                },
              },
              {
                text: res.name.substring(0, 3),
                icon: 'home',
                color: EColor.BLUE,
                onClick: () => {
                  console.log('Passage button')
                },
              },
            ],
          }))
          return {
            data: resultMatch,
            totalItems: dataEntries['hydra:totalItems'],
          }
        }}
        columns={columns}
        filterMatch={{
          categoryType: 'category_type[]',
        }}
        initialValues={{ categoryType: '' }}
        filterForm={(_setFieldValue, submitForm) => (
          <Row>
            <Col sm={8} lg={6} xl={5}>
              <Select
                name="categoryType"
                onChangeCallback={submitForm}
                options={getCategoryOptions()}
                placeholder={'Category Type'}
              />
            </Col>
          </Row>
        )}
        filterSelect={(submitForm) => ({
          name: 'categoryType',
          placeholder: 'Category Type',
          onChangeCallback: submitForm,
          iSearchable: true,
          options: getCategoryOptions(),
        })}
      />
    </>
  )
}

export default StorybookPage
