import { Background, Button, ListItem, Title } from 'components'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { hideLoader, showLoader } from 'state'
import { EColor } from 'types'

const StorybookPage: FC = () => {
  const dispatch = useDispatch()
  return (
    <>
      <Title type="h1" className="mb-4">
        Loader
      </Title>

      <Background title="Information">
        <ul>
          <ListItem>The text is optional</ListItem>
          <ListItem>The text is customizable</ListItem>
        </ul>
      </Background>

      <Title type="h2" className="my-4">
        Spinner only
      </Title>
      <Button
        color={EColor.BLUE}
        onClick={() => {
          dispatch(showLoader({ text: null }))
          setTimeout(() => {
            dispatch(hideLoader())
          }, 5000)
        }}
      >
        Display for 5 seconds
      </Button>
      <Title type="h2" className="my-4">
        Spinner + text
      </Title>
      <Button
        color={EColor.BLUE}
        onClick={() => {
          dispatch(showLoader({ text: <span>Lorem ipsum</span> }))
          setTimeout(() => {
            dispatch(hideLoader())
          }, 5000)
        }}
      >
        Display for 5 seconds
      </Button>
    </>
  )
}

export default StorybookPage
