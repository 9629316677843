import { Background, Form, RadioBackgrounds, Title } from 'components'
import { Formik } from 'formik'
import { FC } from 'react'

const radios = [
  {
    id: 'radio-20',
    value: '20',
    label: '20€',
    description: 'Don de 20€ pour la recherche',
  },
  {
    id: 'radio-30',
    value: '30',
    label: '30€',
    description: 'Don de 30€ pour la recherche',
  },
  {
    id: 'radio-40',
    value: '40',
    label: '40€',
    description: 'Don de 40€ pour la recherche',
  },
  {
    id: 'radio-60',
    value: '60',
    label: '60€',
    description: 'Don de 60€ pour la recherche',
  },
]

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Radio backgrounds
    </Title>
    <Background>
      <Title type="h2" className="my-4">
        Component
      </Title>
      <Formik
        initialValues={{
          radioBackgrounds: '',
        }}
        onSubmit={(values) => {
          alert(JSON.stringify(values))
        }}
        children={() => (
          <Form>
            <RadioBackgrounds
              name="radioBackgrounds"
              label="Lorem ipsum"
              helpText="Lorem ipsum..."
              radios={radios}
              required={true}
            />
          </Form>
        )}
      />

      <Title type="h2" className="my-4">
        Disabled state
      </Title>
      <Formik
        initialValues={{
          radioBackgroundsUnchecked: '',
          radioBackgroundsChecked: '60',
        }}
        onSubmit={(values) => {
          alert(JSON.stringify(values))
        }}
        children={() => (
          <Form>
            <RadioBackgrounds
              name="radioBackgroundsUnchecked"
              radios={radios}
              disabled={true}
            />
            <RadioBackgrounds
              name="radioBackgroundsChecked"
              radios={radios}
              disabled={true}
            />
          </Form>
        )}
      />
    </Background>
  </>
)

export default StorybookPage
