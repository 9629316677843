import { AmericanEin, Form, Title } from 'components'
import { Formik } from 'formik'
import { FC } from 'react'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / American EIN
    </Title>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        americanEin: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <AmericanEin
            helpText="Lorem ipsum..."
            name="americanEin"
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Disabled state
    </Title>
    <Formik
      initialValues={{
        americanEinDisabled: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <AmericanEin
            name="americanEinDisabled"
            helpText="Lorem ipsum..."
            disabled={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Custom label
    </Title>
    <Formik
      initialValues={{
        americanEinCustomLabel: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <AmericanEin
            name="americanEinCustomLabel"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without help text
    </Title>
    <Formik
      initialValues={{
        americanEinWithoutHelptext: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <AmericanEin name="americanEinWithoutHelptext" required={true} />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
