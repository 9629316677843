import { Button, Dropdown as DropdownComponent } from 'components'
import { Table as ReactStrapTable } from 'reactstrap'
import styled, { css } from 'styled-components'

export const Table = styled(({ ...props }) => <ReactStrapTable {...props} />)`
  td,
  th {
    vertical-align: middle;
  }
  tbody {
    tr:nth-of-type(odd) {
      background-color: ${(props) => props.theme.backgroundColor};
    }
    tr:nth-of-type(even) {
      background-color: ${(props) => props.theme.white};
    }
    tr:first-of-type td,
    th {
      border-top: none;
    }
  }
`

export const TableRow = styled((props) => <tr {...props} />)`
  border-radius: 5px;
  background-color: ${(props) => props.theme.white};
  min-height: 60px;
  z-index: 0;

  &.row-selected,
  :hover {
    box-shadow: 0 5px 10px 0 ${(props) => props.theme.lightGrey};
    position: relative;
    z-index: 1;
  }
`

const TableCellResponsive = css`
  display: block;
  width: 100%;
  text-align: left !important;

  /* Fix cell amount alignment */
  > div {
    text-align: left !important;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.lightGrey};
  }
`

const TableCellSelectionResponsive = css`
  border-right: 1px solid ${(props) => props.theme.lightGrey};
`

export const TableCell = styled(({ size, type, ...props }) => (
  <td {...props} />
))`
  width: ${({ size }) => (typeof size === 'number' ? `${size}px` : size)};
  min-width: ${({ size }) => (typeof size === 'number' ? `${size}px` : size)};
  white-space: pre-wrap;
  word-wrap: break-word;
  ${({ type }) => type === 'link' && 'line-break: anywhere;'}
  text-align: ${({ align }) => align || 'left'};
  border-top: none !important;

  @media (max-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    ${({ type }) =>
      type === 'selection' ? TableCellSelectionResponsive : TableCellResponsive}
  }
`

export const StatusButton = styled(({ color, ...props }) => <div {...props} />)`
  border: 1px solid ${(props) => props.theme.grey};
  color: ${(props) => props.theme.darkGrey};
  border-radius: 50px;
  position: relative;
  padding: 0 15px 0 20px;
  width: fit-content;

  &::before {
    content: '';
    position: absolute;
    left: 8px;
    top: 7px;
    height: 5px;
    width: 5px;
    background-color: ${({ color, theme }) => theme[color]};
    border-radius: 50%;
  }
`

export const TableCellBackground = styled(({ background, ...props }) => (
  <div {...props} />
))`
  background-color: ${({ background, theme }) => theme[background]};
  display: inline-block;
  padding: 0.3rem 0.5rem;
  border-radius: 8px;
  text-align: center;
`

export const TableCellColor = styled(({ color, ...props }) => (
  <span {...props} />
))`
  color: ${({ color, theme }) => theme[color]};
`

export const BatchButton = styled((props) => <Button {...props} />)`
  z-index: 111;
  position: sticky;
  bottom: 50px;
`

export const Dropdown = styled((props) => <DropdownComponent {...props} />)`
  .dropdown-item {
    color: ${(props) => props.theme.black};
  }

  .btn {
    color: ${(props) => props.theme.blue};
    background-color: ${(props) => props.theme.white};
    border: 1px solid ${(props) => props.theme.blue};
    padding-right: 2.3rem;
    text-transform: none;
  }

  .btn::before {
    content: '';
    position: absolute;
    top: 8px;
    right: 26px;
    height: calc(100% - 16px);
    border-left: 1px solid;
  }

  .btn::after {
    content: '';
    position: absolute;
    top: 31%;
    right: 11px;
    transform: translateY(-50%);
    border: solid;
    border-width: 2px 2px 0 0;
    display: inline-block;
    width: 0.6rem;
    height: 0.6rem;
    transform: rotate(135deg);
  }

  &:hover {
    .btn {
      color: ${(props) => props.theme.white};
      background-color: ${(props) => props.theme.blue};
      border: 1px solid ${(props) => props.theme.blue};
    }
  }
`
