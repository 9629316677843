import { EApp } from 'api/type'
import { EmptySpace, EmptySpaceAccessDenied, Redirect } from 'components'
import { EDisabledApplication } from 'hooks/useHasAccess/type'
import { IUseHistory } from 'hooks/useHistory/type'
import { ERefusalReason } from 'hooks/useSpecifications/type'
import { IUseTranslation } from 'hooks/useTranslation/type'
import { ICheckAccess } from 'layouts/Admin/type'
import { IOrganization } from 'providers/type'
import { EYado } from 'types'
import { redirectTo } from 'utils'

export const handleAccessDenied = (
  reason: ICheckAccess['reason'],
  translation: IUseTranslation,
  organization: IOrganization,
  history: IUseHistory,
  app?: EApp
) => {
  const isDisabledApplicationReason = Object.values(
    EDisabledApplication
  ).includes(reason as EDisabledApplication)

  if (isDisabledApplicationReason) {
    return (
      <EmptySpace
        title={translation.translate('accessDenied.app.missing.title')}
        yado={EYado.ACCESS_DENIED}
        button={{
          text: translation.translate('accessDenied.app.link.text'),
          onClick: () =>
            redirectTo(
              `/organization/${
                organization.idOld
              }/applications/${app?.toLowerCase()}`,
              {
                redirectUrl: history.location.pathname,
              }
            ),
        }}
      />
    )
  }

  switch (reason) {
    case ERefusalReason.SETTING:
    case ERefusalReason.UNAUTHORIZED:
      return <EmptySpaceAccessDenied />
    case ERefusalReason.PSP_WALLET_SUSPENDED:
      return (
        <Redirect
          to={`/organization/${organization.idOld}/wallet/adyen/verification/account-suspended`}
        />
      )
    case ERefusalReason.PSP_WALLET_BLOCKED:
      return (
        <Redirect
          to={`/organization/${organization.idOld}/wallet/adyen/verification/account-blocked`}
        />
      )
    case ERefusalReason.APP_DISABLED:
      return (
        <EmptySpace
          title={translation.translate('accessDenied.app.missing.title')}
          yado={EYado.ACCESS_DENIED}
          button={{
            text: translation.translate('accessDenied.app.link.text'),
            href: `/organization/${organization.idOld}/applications`,
          }}
        />
      )
    case ERefusalReason.ACCOUNTING_PERIOD:
      return (
        <EmptySpace
          title={translation.translate(
            'accessDenied.accounting.noAccountingPeriod.title'
          )}
          message={translation.translate(
            'accessDenied.accounting.noAccountingPeriod.message'
          )}
          yado={EYado.ACCESS_DENIED}
          button={{
            text: translation.translate(
              'accessDenied.accounting.noAccountingPeriod.button'
            ),
            href: `/organization/${organization.idOld}/settings/accounting/accounting-periods`,
          }}
        />
      )
    case ERefusalReason.EMAILING_HAS_NO_CAMPAIGNS: {
      window.location.href = `/organization/${organization.idOld}/emailing/campaigns-onboarding`
      return <></>
    }
    case ERefusalReason.PRO_ACCOUNT_MEMBERSHIP_INACTIVE:
      return (
        <EmptySpace
          title={translation.translate(
            'accessDenied.accountMembership.inactive.title'
          )}
          yado={EYado.ACCOUNT_BLOCKED}
          message={translation.translate(
            'accessDenied.accountMembership.inactive.message',
            {
              mainOrganizationName: organization.mainOrganization.name,
            }
          )}
        />
      )
    case ERefusalReason.PRO_ACCOUNT_MEMBERSHIP_NOT_SETUP:
      return (
        <EmptySpace
          title={translation.translate(
            'accessDenied.accountMembership.setup.title'
          )}
          yado={EYado.ACCESS_DENIED}
          message={translation.translate(
            'accessDenied.accountMembership.setup.message',
            {
              mainOrganizationName: organization.mainOrganization.name,
            }
          )}
        />
      )
    default:
      return <EmptySpaceAccessDenied />
  }
}
