import { Link } from 'react-router'
import styled from 'styled-components'

export const RouterLink = styled(({ disabled, ...props }) => (
  <Link disabled={disabled} {...props} />
))`
  display: contents;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => props.disabled && props.theme.grey};
`
