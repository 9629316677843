import { Background, Title } from 'components'
import { ETitleFont } from 'components/Text/Title/type'
import { Formik, FormikValues } from 'formik'
import { FC } from 'react'
import { EColor } from 'types'
import { sleep } from 'utils'

import * as Type from './type'

const SettingGroup: FC<Type.ISettingGroup> = ({
  children,
  description,
  handleSubmit,
  icon,
  title,
  initialValues,
  validationFunction,
  validationCallback,
  ...rest
}) => {
  const validate = async (values: FormikValues) =>
    sleep().then(async () => {
      if (validationFunction) {
        const errors = await validationFunction(values)
        if (!Object.keys(errors).length && validationCallback) {
          await validationCallback(values)
        } else {
          return errors
        }
      } else if (validationCallback) {
        await validationCallback(values)
      }
    })

  return (
    <Background className="pl-0 pr-0 pb-4 pt-2" hoverShadow={true}>
      <div className=" px-4 pt-2">
        <div className="d-flex pt-2">
          <div>
            <Title
              className="d-flex m-0"
              color={EColor.BLACK}
              font={ETitleFont.ROBOTO}
              icon={icon}
              iconColor={EColor.BLUE}
              type={'h4'}
            >
              {title}
            </Title>
            <p className="text-muted my-1">{description}</p>
          </div>
        </div>
        <hr className="mt-2 mb-0" />
      </div>
      <Formik
        initialValues={initialValues || {}}
        onSubmit={(values, formikHelpers) => {
          formikHelpers.setSubmitting(false)
          handleSubmit && handleSubmit(values)
        }}
        children={children}
        validate={validate}
        enableReinitialize={true}
        {...rest}
      />
    </Background>
  )
}

export default SettingGroup
