import {
  Button,
  CheckboxCard,
  Col,
  Form,
  RadioCard,
  Row,
  Title,
} from 'components'
import { Formik } from 'formik'
import { FC } from 'react'
import { EColor } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / CheckboxCard & RadioCard
    </Title>

    <Title type="h2" className="my-4">
      Component
    </Title>
    {['radio', 'checkbox'].map((componentType) => {
      const params = {
        name: componentType,
        label: 'Lorem ipsum',
        required: true,
        svg: 'membership-card',
      }

      return (
        <div key={componentType}>
          <Title type="h3">{componentType.toUpperCase()}</Title>
          <Formik
            initialValues={{
              [componentType]: '',
            }}
            onSubmit={(values) => {
              alert(JSON.stringify(values))
            }}
            children={() => (
              <Form>
                <Row>
                  {[1, 2, 3, 4, 5].map((index) => (
                    <Col key={index} md={6}>
                      {componentType === 'radio' ? (
                        <RadioCard value={index} {...params} />
                      ) : (
                        <CheckboxCard value={index} {...params} />
                      )}
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button type="submit" outline={true} color={EColor.BLUE}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          />
        </div>
      )
    })}
    <Title type="h2" className="my-4">
      Disabled state
    </Title>
    <Formik
      initialValues={{
        checkboxDisabled: [2],
        radioDisabled: 2,
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          {[1, 2].map((index) => (
            <CheckboxCard
              key={index}
              name={`checkboxDisabled`}
              label={`Lorem ipsum ${index}...`}
              value={index}
              disabled={true}
              svg="calculator"
            />
          ))}
        </Form>
      )}
    />
  </>
)

export default StorybookPage
