import { getCurrentTimezone } from 'components/Form/Timezone'
import { getCountryForTimezone } from 'countries-and-timezones'
import { IUseTranslation } from 'hooks/useTranslation/type'
import { getExampleNumber, isValidPhoneNumber } from 'libphonenumber-js'
import parseMax from 'libphonenumber-js/max'
import { AsYouType } from 'libphonenumber-js/min'
import { formatPhoneNumber } from 'react-phone-number-input'
import { getLocaleCountryCode } from 'utils'
import { ECountry } from 'utils/Intl/type'

import { phoneNumberExamples } from './data'
import * as Type from './type'

export const getPhonePreferredCountries = (
  country: ECountry
): Array<ECountry> => {
  switch (country) {
    case ECountry.US:
      return [ECountry.US, ECountry.CA, ECountry.GB, ECountry.AU]
    case ECountry.FR:
    default:
      return [ECountry.FR, ECountry.BE, ECountry.CH, ECountry.LU]
  }
}

const getPhoneNumberExamples = (numberType: Type.ENumberType) => {
  switch (numberType) {
    case Type.ENumberType.FIXED_LINE:
      return phoneNumberExamples[Type.ENumberType.FIXED_LINE]
    case Type.ENumberType.MOBILE:
    case Type.ENumberType.FIXED_LINE_OR_MOBILE:
    default:
      return phoneNumberExamples[Type.ENumberType.MOBILE]
  }
}

export const getPhonePlaceholder = (
  country: ECountry,
  translation: IUseTranslation,
  numberType = Type.ENumberType.MOBILE
) => {
  const number = getExampleNumber(country, getPhoneNumberExamples(numberType))

  if (!number?.nationalNumber) {
    return ''
  }

  return translation.translate('placeholder', {
    number: number.formatNational(),
  })
}

export const getCountryFromPhoneNumber = (phoneNumber: string) => {
  const asYouType = new AsYouType()
  // // +1 is used for US and Canada ==> Fallback US
  const detectedCountry =
    asYouType.input(phoneNumber) === '+1' ? ECountry.US : asYouType.getCountry()

  if (detectedCountry === undefined) {
    return null
  }

  return detectedCountry as ECountry
}

export const getFormattedPhoneNumber = (phoneNumber: string) =>
  formatPhoneNumber(phoneNumber)

export const getParsedPhoneNumber = (
  phoneNumber: string | undefined,
  country: ECountry
) => {
  if (!phoneNumber || phoneNumber.length === 0) {
    return ''
  }
  const parsedPhoneNumber = parseMax(phoneNumber, country)
  return parsedPhoneNumber?.number || phoneNumber
}

export const isPhoneNumberValid = (
  phoneNumber: string | undefined,
  country: ECountry
) => {
  if (!phoneNumber || phoneNumber.length === 0) {
    return false
  }
  return isValidPhoneNumber(phoneNumber, country)
}

export const isPhoneNumberMatchingType = (
  phoneNumber: string,
  country: ECountry,
  numberType: Type.ENumberType
) => {
  const parsedPhoneNumberIntl = parseMax(phoneNumber, country)
  const phoneNumberType = parsedPhoneNumberIntl?.getType()

  // Only check mobile and fixed line, escape everything else
  return (
    (phoneNumberType !== Type.ENumberType.FIXED_LINE &&
      phoneNumberType !== Type.ENumberType.MOBILE) ||
    numberType === parsedPhoneNumberIntl?.getType()
  )
}

export const getInitialCountry = (fieldValue?: string) => {
  const currentTimezone = getCurrentTimezone()
  const countryFromTimezone = String(getCountryForTimezone(currentTimezone)?.id)
  return (
    (fieldValue && getCountryFromPhoneNumber(fieldValue)) ||
    getLocaleCountryCode(countryFromTimezone)
  )
}
