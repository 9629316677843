import { EProAccountAccountMembershipAdminStatus } from 'api/type'
import { createContext } from 'react'

import { IProAccountContext } from './type'

const ProAccountContext = createContext<IProAccountContext>({
  proAccountHolder: {
    '@id': '',
    '@type': '',
    '@context': '',
    swanId: '',
    name: '',
    account: {
      '@context': '',
      '@id': '',
      '@type': '',
      id: '',
      merchantProfileId: '',
      bankAccount: '',
      accountHolder: undefined,
      swanId: '',
    },
    organization: '',
    onboardingId: '',
    dashboardUrl: '',
  },
  proAccountMember: {
    '@id': '',
    '@type': '',
    swanId: '',
    id: '',
    user: undefined,
    account: undefined,
    adminStatus: EProAccountAccountMembershipAdminStatus.NO_ACCOUNT,
    consent: undefined,
    email: '',
  },
  loading: false,
  retry: () => undefined,
})

export default ProAccountContext
