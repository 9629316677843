import {
  Background,
  Button,
  Checkbox,
  CheckboxIndividual,
  Form,
  ListItem,
  Title,
} from 'components'
import { Formik } from 'formik'
import { FC } from 'react'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Checkbox
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>Size: 20px</ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        checkbox: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Checkbox
            name="checkbox"
            label="Lorem ipsum 1..."
            helpText="Lorem ipsum..."
            value="1"
            required={true}
          />
          <Checkbox
            name="checkbox"
            label="Lorem ipsum 2..."
            helpText="Lorem ipsum..."
            value="2"
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Disabled state
    </Title>
    <Formik
      initialValues={{
        checkboxDisabled: '2',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Checkbox
            name="checkboxDisabled"
            label="Lorem ipsum 1..."
            helpText="Lorem ipsum..."
            value="1"
            disabled={true}
          />
          <Checkbox
            name="checkboxDisabled"
            label="Lorem ipsum 2..."
            helpText="Lorem ipsum..."
            value="2"
            disabled={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without help text
    </Title>
    <Formik
      initialValues={{
        checkboxWithoutHelpText: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Checkbox
            name="checkboxWithoutHelpText"
            label="Lorem ipsum 1..."
            value="1"
          />
          <Checkbox
            name="checkboxWithoutHelpText"
            label="Lorem ipsum 2..."
            value="2"
          />
        </Form>
      )}
    />
    <Title type="h2" className="my-4">
      Individual Checkbox
    </Title>
    <Formik
      initialValues={{
        indiv1: true,
        indiv2: false,
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <CheckboxIndividual
            name="indiv1"
            label="Lorem ipsum 1..."
            required={true}
          />
          <CheckboxIndividual
            name="indiv2"
            label="Lorem ipsum 2..."
            required={true}
          />
          <Button type="submit">SUBMIT</Button>
        </Form>
      )}
    />
  </>
)

export default StorybookPage
