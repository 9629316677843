import { PaymentMethods, SubmitData } from '@adyen/adyen-web'
import { ERetributionType, IApiPaymentMethods } from 'api/type'
import { Dinero as TDinero } from 'dinero.js'
import { IUseApiError } from 'hooks/useApiError/type'
import { Amount } from 'types'
import { EBrand } from 'utils'
import { ECountry, ELocale } from 'utils/Intl/type'

export enum EPaymentMethods {
  ADYEN = 'ADYEN',
  ADYEN_APPLE_PAY = 'ADYEN_APPLE_PAY',
  ADYEN_CB_INSTALLMENT = 'ADYEN_CB_INSTALLMENT',
  ADYEN_SEPA = 'ADYEN_SEPA',
  CASH = 'CASH',
  CHECK = 'CHECK',
  OTHER = 'OTHER',
}

export enum EPaymentMethodType {
  APPLE_PAY = 'applepay',
}

export enum EIntent {
  PAY = 'PAY',
  CAPTURE = 'CAPTURE',
  METHOD_CHANGE = 'METHOD_CHANGE',
}

export enum EPaymentType {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export enum ESubscriptionType {
  FREE = 'FREE',
  NOT_FREE = 'NOT_FREE',
}

export interface IPageDataOrganization {
  brand: EBrand.ASSOCONNECT | EBrand.SPRINGLY
  id: string
  name: string
  nonprofit: {
    locale: ELocale
    country: ECountry
    timezone?: string
  }
  subscriptionType: string
}

export enum EPaymentTipValueType {
  MONEY = 'MONEY',
  PERCENT = 'PERCENT',
}

export interface IPaymentTip {
  max_tips: Amount
  max_tips_percent: number
  min_tips: Amount
  min_tips_percent: number
  tips: Amount
  tips_percent: number
  type: EPaymentTipValueType
}

export interface IPayment {
  submitHandler: {
    get: () => boolean
    set: (value: boolean) => void
  }
  pageData: IPageData
  paymentPageConfigurationId: string
}

export enum EPageDataLayout {
  BACKOPS = 'BACKOPS',
  COLLECT = 'COLLECT',
}

export interface IPageData {
  '@context'?: string
  '@id'?: string
  '@type'?: string
  errorUrl: string | null
  layout: EPageDataLayout
  organization: IPageDataOrganization
  payableEntityIdentifier: string
  paymentMethods?: Array<EPaymentMethods>
  processed: boolean
  price: Amount
  publicContext: {
    TIPS_SUGGESTIONS?: IPaymentTip
    INSTALLMENT?: {
      installmentNbPayments: string
      installmentNbMonths: string
    }
    INTENT: EIntent
    OFFLINE_INSTRUCTION?: Array<{ [key: string]: string }>
  }
  retributionMethod: ERetributionType
  retryCount: number
  successUrl: string | null
  iframe: string
}

export type IPaymentMethodsCollection = {
  [key: string]: Array<IApiPaymentMethods>
}

export interface IAdyenCheckoutState extends SubmitData {
  data: {
    browserInfo: SubmitData['data']['browserInfo']
    clientStateDataIndicator: boolean
    origin: string
    paymentMethod: { [key: string]: string | undefined }
    riskData?: SubmitData['data']['riskData']
    billingAddress: { [key: string]: string | undefined }
  }
  isValid: boolean
}

export type IMethodParams = {
  pageData: IPageData
  totalNoTip: TDinero
  tipAmount: TDinero
  apiError: IUseApiError
}

export type IApplePayComponent = InstanceType<PaymentMethods['applepay']>

export type TApplePayConfiguration = {
  merchantId: string
  merchantName: string
}
