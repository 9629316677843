import { EApiCrmPersonRole, EApiMasterAdminRole } from 'api/type'
import { useSelector } from 'hooks'
import { useOrganization } from 'providers'
import { selectPerson } from 'state'

import * as Type from './type'

const UsePerson = (): Type.IUsePerson => {
  const { organization } = useOrganization()
  const person = useSelector(selectPerson)
  const hasRole = (role: EApiCrmPersonRole): boolean =>
    person.roles?.[organization.id]?.includes(role)

  const hasMasterAdminRole = (role: EApiMasterAdminRole): boolean =>
    person.adminMasterRoles?.[organization.id]?.includes(role)

  return {
    hasRole,
    hasMasterAdminRole,
    isLoaded: Object.entries(person.roles).length > 0,
  }
}

export default UsePerson
