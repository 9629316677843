import { Temporal } from '@js-temporal/polyfill'
import {
  addDays,
  format as fnsFormat,
  lastDayOfMonth,
  parse,
  subDays,
} from 'date-fns'
import { fr } from 'date-fns/locale'
import { formatLocale, getLocaleShortcode, getNavigatorLanguage } from 'utils'
import { ECaseType, ELocale } from 'utils/Intl/type'

import * as Type from './type'
import { TemporalDate } from './type'

const dateFnsLocales: Type.IDateFnsLocales = { fr }

const getLocale = () => {
  const locale = window.locale || getNavigatorLanguage() || 'en_US'
  return formatLocale(locale, ECaseType.KEBAB)
}

/**
 * Get the current date from Temporal
 */
export const getCurrentDate = (): Temporal.PlainDate =>
  Temporal.Now.plainDateISO()

// Convert string date to Temporal.PlainDate
export const convertStringDateToTemporal = (date: string): Temporal.PlainDate =>
  Temporal.PlainDate.from(date)

/**
 * Format a date object or a string with given pattern
 * 'DEFAULT' => 09/29/1991 (FR) OR 9/29/1991 (US)
 * 'LONG' => 29 septembre 1991 (FR) OR September 29, 1991 (US)
 * 'YMD' => 1991-09-29
 */
export const formatDateTemporal = (
  date: TemporalDate,
  dateStyle?: Type.EDateFormat,
  locale?: ELocale
): string => {
  if (dateStyle === Type.EDateFormat.YMD) {
    return Temporal.PlainDate.from(date).toString()
  }

  const dateObject = new Date(date.year, date.month - 1, date.day)

  // get dateStyle to have default format

  const formatter = new Intl.DateTimeFormat(
    locale ? formatLocale(locale, ECaseType.KEBAB) : getLocale(),
    {
      dateStyle,
    }
  )

  return formatter.format(dateObject)
}
/**
 * Format a date object or a string with given pattern
 * 'P' => 09/29/1991 (FR) OR 9/29/91 (US)
 */
export const formatDate = (
  date: Date | string,
  pattern = 'P',
  locale?: string
): string => {
  if (typeof date === 'string') {
    const dateObject = getDateObjectWithoutTime(date)
    if (!dateObject) {
      return ''
    }
    date = dateObject
  }
  return fnsFormat(date, pattern, getDateFnsLocale(locale))
}

/**
 * Return the date in the yyyy-MM-dd format
 * @param date
 */
export const getDateYMDFormat = (date: Date): string =>
  fnsFormat(date, 'yyyy-MM-dd')

/**
 * Format limit dates with adding or substracting one day, start and end dates must not be equal
 * @param date - the limit date to be added or substracted one day
 * @param operation - add | sub
 */
export const formatStrictLimitDates = (
  date: string,
  operation: 'add' | 'sub'
): string => {
  if (date === '') {
    return ''
  }
  const formatedDate = getDateObjectWithoutTime(date)
  return operation === 'sub'
    ? getDateYMDFormat(subDays(formatedDate, 1))
    : getDateYMDFormat(addDays(formatedDate, 1))
}

/**
 * Returns a Date object with time in the user's current timezone
 * @param dateString
 * @param formatString
 */
export const getDateObject = (dateString: string, formatString: string): Date =>
  parse(dateString, formatString, new Date())

/**
 * Returns a Date object with time equals 00:00 in the user's current timezone
 * Function is used when a Date object without time is needed
 * Related bug https://assoconnect.atlassian.net/browse/AN-1935
 * @param dateString
 */
export const getDateObjectWithoutTime = (dateString?: string): Date =>
  dateString ? new Date(`${dateString}T00:00`) : new Date()

/**
 * Returns the date format according to the locale and the type of format needed
 * https://flatpickr.js.org/formatting/
 *
 * @param locale
 * @param type
 */
export const getDateFormat = (
  locale: ELocale,
  type: 'display' | 'parse'
): string => {
  if (locale === ELocale.EN_US) {
    return type === 'display' ? 'm/d/Y' : 'MM/dd/yyyy'
  }
  return type === 'display' ? 'd/m/Y' : 'dd/MM/yyyy'
}

/**
 * Get each first day of the 12 previous month
 */
export const getFirstDayOfTwelvePreviousMonth = (currentDate: Date): Date[] => {
  const months: Array<Date> = []

  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  for (let i = 0; i < 12; i++) {
    currentMonth - i < 0
      ? months.push(new Date(currentYear, currentMonth - i))
      : months.push(new Date(currentYear - 1, currentMonth - i + 12))
  }

  return months
}

/**
 * Get last day of month from given date
 */
export const getLastDayOfMonth = (date: Date): Date => lastDayOfMonth(date)

/**
 * Get month start and end dates from given date
 * @param dateString
 */
export const getMonthEndsFromDate = (
  dateString: string
): {
  start: string
  end: string
} => {
  const date = getDateObjectWithoutTime(dateString)
  if (!date) {
    return {
      start: '',
      end: '',
    }
  }
  const firstDay = getDateYMDFormat(
    new Date(date.getFullYear(), date.getMonth(), 1)
  )
  const lastDay = getDateYMDFormat(
    new Date(date.getFullYear(), date.getMonth() + 1, 0)
  )

  return {
    start: firstDay,
    end: lastDay,
  }
}

/**
 * Get difference in months between two dates
 * @param dateString
 */

export const getDifferenceInMonths = (date1: Date, date2: Date): number => {
  const monthDiff = date1.getMonth() - date2.getMonth()
  const yearDiff = date1.getFullYear() - date2.getFullYear()
  return Math.abs(monthDiff + yearDiff * 12)
}

/**
 * Retrieve the date-fns locale according to the window.locale global variable
 */
export const getDateFnsLocale = (locale?: string): Type.IFormalAllOptions => {
  const dateOptions: Type.IFormalAllOptions = {}
  const dateLocale = locale || window.locale || getNavigatorLanguage() || 'en' // "en" by default for tests not using the layout
  const localeShortcode = getLocaleShortcode(dateLocale)
  if (localeShortcode !== 'en') {
    dateOptions.locale = dateFnsLocales[localeShortcode]
  }

  return dateOptions
}
