export enum E3DSRefusalReasons {
  AUTHENTICATION = 'authentication',
  NOTIFICATION = 'notification',
  FINALIZE_TRANSACTION = 'finalizeTransaction',
}

export enum EClassicalRefusalReasons {
  CHANGE_CARD = 'changeCard',
  CONTACT_BANK = 'contactBank',
  TRY_AGAIN = 'tryAgain',
}

export interface IPaymentFailurePayload {
  retryUrl: string
  paymentPageConfigurationId: string
  paymentPageConfigurationIntent: string
  paymentRequestIntent: string
}
