import { getOrganizationNotifications } from 'api'
import { IApiNotification } from 'api/type'
import { useAsyncData } from 'hooks'
import { NotificationsContext } from 'providers'
import React from 'react'

const NotificationsProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const notifications = useAsyncData<Array<IApiNotification>>(async () =>
    getOrganizationNotifications()
  )

  if (notifications.isLoading) {
    return null
  }

  return (
    <NotificationsContext.Provider
      value={{
        notifications: notifications.isError ? [] : notifications.value, // Prevent blocking app and ignore notifications in case of error
        retry: notifications.isError ? () => [] : notifications.retry, // Prevent blocking app and ignore notifications in case of error
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsProvider
