import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStorePage } from 'state/type'

const pageInitialState: IStorePage = {
  menuItemActiveId: '',
  menuSubItemActiveId: '',
  backLinkHref: '',
  supportLabel: '',
  tabs: [],
  title: '',
  subtitle: '',
  pageId: undefined,
}

const pageSlice = createSlice({
  name: 'page',
  initialState: pageInitialState,
  reducers: {
    setPage: (state, action: PayloadAction<IStorePage>) =>
      Object.assign({}, state, action.payload),
  },
})

export const { setPage } = pageSlice.actions
export default pageSlice.reducer
