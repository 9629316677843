import axios, { AxiosError, AxiosHeaders } from 'axios'
import Cookies from 'universal-cookie'

const tokenCookies = new Cookies(null, {
  path: '/',
  secure: true,
  httpOnly: true,
})

const getAccessToken = () =>
  fetch(String(process.env.REACT_APP_API_TOKEN_HOST), {
    credentials: 'include',
  })

const getRefreshToken = () =>
  fetch(String(process.env.REACT_APP_API_TOKEN_REFRESH_HOST), {
    credentials: 'include',
  })

export const getTokens = async (): Promise<{
  refreshToken: string
  token: string
}> => {
  // Returns tokens
  const tokens = await getAccessToken()
    .then(async (response: Response) =>
      response.status === 401
        ? // Returns refresh token
          getRefreshToken().then((apiResponse: Response) =>
            apiResponse.status === 401 ? null : apiResponse.text()
          )
        : // Returns access token
          response.text()
    )
    .then((apiTokens) => (apiTokens ? JSON.parse(apiTokens) : null))
  // Create cookies
  if (tokens) {
    tokenCookies.set('_accessToken', tokens.token)
    tokenCookies.set('_refreshToken', tokens.refreshToken)
  }
  // Delete cookies
  else {
    tokenCookies.remove('_accessToken')
    tokenCookies.remove('_refreshToken')
  }
  return tokens
}

export const API = axios.create({
  withCredentials: true,
})

API.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    // 401 error
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (error.config.params?.skipRedirect) {
        return null
      }
      originalRequest._retry = true // Prevent infinite loop
      return getTokens().then((tokens) => {
        // Forward request
        if (tokens) {
          return API(originalRequest)
        }
        return Promise.reject(error)
      })
    }
    // Other error
    return Promise.reject(error)
  }
)

API.interceptors.request.use(async (config) => {
  // Set request headers
  config.headers = new AxiosHeaders({
    Accept: 'application/ld+json', // default value
    ...config.headers,
  })
  return config
})

export const isAxiosError = (error: unknown): error is AxiosError =>
  (error as AxiosError).isAxiosError !== undefined
