import classNames from 'classnames'
import {
  Container,
  Header,
  LayoutIntlProvider,
  NotificationsWrapper,
  SidePanel,
  Tabs,
} from 'components'
import {
  createIntl,
  useSelector,
  useStore,
  useTracking,
  useTranslation,
} from 'hooks'
import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { usePrevious } from 'react-use'
import {
  addNotification,
  resetSidePanel,
  selectNotifications,
  selectSidePanel,
  selectSupportMessage,
} from 'state'
import { EColor } from 'types'
import Cookies from 'universal-cookie'
import { getDomainName } from 'utils'

import Menu from './components/Menu'
import TourProvider from './components/TourProvider'
import * as Type from './type'

export const LayoutAdmin: FC<Type.ILayoutAdmin> = ({
  children,
  fullWidthStyle = false,
  hideMenu = false,
  accessSettings = null,
}) => {
  const intlPath = 'layout_admin'
  const intl = createIntl(intlPath)
  const translation = useTranslation(intl)

  const dispatch = useDispatch()
  const tracking = useTracking()
  const store = useStore()
  const location = useLocation()
  const prevLocation = usePrevious(location)
  const notifications = useSelector(selectNotifications)
  const supportMessage = useSelector(selectSupportMessage)
  const sidePanel = useSelector(selectSidePanel)

  // Reset the sidePanel store at each layout rerender.
  useEffect(() => {
    if (!sidePanel.isOpen) {
      dispatch(resetSidePanel())
    }
  }, [sidePanel, dispatch])

  // Tracking initialization
  useEffect(() => {
    if (store.isLoaded && !window.rudderanalytics) {
      tracking.initialize()
    }
  }, [store, tracking])

  // Page view tracking
  useEffect(() => {
    if (prevLocation?.pathname !== location.pathname) {
      tracking.pageView()
    }
  }, [tracking, location, prevLocation])

  useEffect(() => {
    if (supportMessage.message !== null) {
      const messageId = Object.keys(supportMessage.message)[0]
      const messageText = Object.values(supportMessage.message)[0]

      // Prevent displaying the supportMessage twice. It happens when navigating between two react page without dismissing the message
      if (
        !notifications
          .map((notification) => notification.text)
          .includes(messageText)
      ) {
        const cookies = new Cookies()
        // Check if message has already been displayed
        if (!cookies.get(`supportMessage-${messageId}`)) {
          // Display support message
          dispatch(
            addNotification({
              color: EColor.BLUE,
              text: messageText,
              dismissible: false,
              onCloseCallback: () => {
                // Set a cookie to prevent displaying this message once again
                const expires = new Date()
                expires.setTime(expires.getTime() + 86400) // 1 day
                cookies.set(`supportMessage-${messageId}`, true, {
                  path: '/',
                  expires,
                  domain: getDomainName(),
                })
              },
            })
          )
        }
      }
    }
    return undefined
  }, [dispatch, notifications, supportMessage])

  return (
    <>
      {store.isLoaded && (
        <LayoutIntlProvider value={intl}>
          {(!accessSettings || accessSettings.isLoaded) && (
            <TourProvider>
              <Menu isHidden={hideMenu} color={EColor.BLUE} />

              <Header
                fullWidthStyle={fullWidthStyle}
                isMenuHidden={hideMenu}
                {...(accessSettings?.isDenied && {
                  title: translation.translate('header.accessDenied'),
                })}
              />

              <Container
                className={classNames(fullWidthStyle ? 'mb-4' : 'my-4')}
                role="main"
                tag="main"
                fullWidthStyle={fullWidthStyle}
                isMenuHidden={hideMenu}
              >
                <Tabs fullWidthStyle={fullWidthStyle} />
                {children}
              </Container>

              <NotificationsWrapper />
              <SidePanel />
            </TourProvider>
          )}
        </LayoutIntlProvider>
      )}
    </>
  )
}
