import { Button, Col, Form, Input, Row } from 'components'
import { Formik } from 'formik'
import { useTranslation } from 'hooks'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { EColor } from 'types'

import { IRequiredKeywordModalForm } from './type'

const RequiredKeywordModalForm: FC<IRequiredKeywordModalForm> = ({
  confirmButtonText,
  handleConfirm,
  handleClose,
  isProcessingCallback,
  requiredKeyword,
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)
  return (
    <Formik
      validate={(values) => {
        if (values.keywords !== requiredKeyword) {
          return {
            keywords: translation.translate('submitKeywords.error.matching'),
          }
        }
        return undefined
      }}
      initialValues={{ keywords: '' }}
      onSubmit={handleConfirm}
      children={({ isSubmitting }) => (
        <Form>
          <Input
            label={translation.translate('submitKeywords.label', {
              keywords: requiredKeyword,
            })}
            type="text"
            name="keywords"
            placeholder={translation.translate('submitKeywords.placeholder')}
            className="mt-3"
            required={true}
          />
          <Row className="align-items-center justify-content-center">
            <Col xs="auto">
              <Button
                onClick={handleClose}
                color={EColor.TRANSPARENT}
                className="text-uppercase"
                disabled={isProcessingCallback || isSubmitting}
              >
                {translation.translate('button.cancel')}
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                type="submit"
                color={EColor.RED}
                className="text-uppercase"
                disabled={isProcessingCallback || isSubmitting}
              >
                {confirmButtonText || translation.translate('button.confirm')}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    />
  )
}

export default RequiredKeywordModalForm
