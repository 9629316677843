import { Button, Form, Money, Title } from 'components'
import Dinero from 'dinero.js'
import { Formik } from 'formik'
import { FC } from 'react'
import { ECurrency } from 'state/type'

const initialValues = {
  'money-eur': '',
  'money-eur-min-max': Dinero({ amount: 1000, currency: ECurrency.EUR }),
}

Dinero.globalLocale = 'fr-FR'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Money
    </Title>

    <Title type="h2" className="my-4">
      Component
    </Title>

    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Title type="h3" className="my-4">
            EUR (without default value)
          </Title>
          <Money name="money-eur" currency={ECurrency.EUR} />
          <hr />
          <Title type="h3" className="my-4">
            EUR (with required, min 10€ and max 100€)
          </Title>
          <Money
            name="money-eur-min-max"
            currency={ECurrency.EUR}
            min={Dinero({ amount: 1000, currency: ECurrency.EUR })}
            max={Dinero({ amount: 10000, currency: ECurrency.EUR })}
            label="Le label"
            helpText="Lorem ipsum"
            required={true}
          />
          <Button type="submit">SUBMIT</Button>
        </Form>
      )}
    />
  </>
)

export default StorybookPage
