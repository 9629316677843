import { API } from 'api/connector'

import { IAdminNotification, IApiAdminNotificationValues } from './type'

export const getAdminNotifications = async (): Promise<{
  data: Array<IAdminNotification>
  totalItems: number
}> => {
  const notifications = await API.get('/api/v1/settings/admin_notifications')

  return {
    data: notifications.data['hydra:member'],
    totalItems: notifications.data['hydra:totalItems'],
  }
}

export const getAdminNotification = async (
  notificationId: IAdminNotification['id']
): Promise<IAdminNotification> =>
  API.get(`/api/v1/settings/admin_notifications/${notificationId}`)

export const createAdminNotification = async (
  values: IApiAdminNotificationValues
) => API.post('/api/v1/settings/admin_notifications', values)
