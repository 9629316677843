import {
  IApiProAccountAccountHolder,
  IApiProAccountAccountMember,
  IPerson,
} from 'api/type'
import { IOrganization } from 'providers/Organization/type'
import { ISpecificationStatus } from 'state/type'

export enum ESpecification {
  ACCOUNTING_HAS_ONGOING_PERIOD = 'ACCOUNTING_HAS_ONGOING_PERIOD',
  ACCOUNTING_SETUP = 'ACCOUNTING_SETUP',
  COLLECT = 'COLLECT',
  SUBSCRIPTION = 'SUBSCRIPTION',
  WALLET_VERIFIED = 'WALLET_VERIFIED',
  WALLET_CREATED = 'WALLET_CREATED',
  EMAILING_HAS_CAMPAIGNS = 'EMAILING_HAS_CAMPAIGNS',
  IS_MAIN_ORGANIZATION = 'IS_MAIN_ORGANIZATION',
  HAS_ACTIVE_PRO_ACCOUNT_MEMBERSHIP = 'HAS_ACTIVE_PRO_ACCOUNT_MEMBERSHIP',
  HAS_SETUP_PRO_ACCOUNT_MEMBERSHIP = 'HAS_SETUP_PRO_ACCOUNT_MEMBERSHIP',
  HAS_ACCESS_TO_BULK_IMPORT = 'HAS_ACCESS_TO_BULK_IMPORT',
}

export type TSpecification = { [key: string]: ISpecificationStatus }

export enum ERefusalReason {
  APP_DISABLED = 'APP_DISABLED',
  OTHER = 'OTHER',
  PSP_WALLET_BLOCKED = 'PSP_WALLET_BLOCKED',
  PSP_WALLET_SUSPENDED = 'PSP_WALLET_SUSPENDED',
  SETTING = 'SETTING',
  UNAUTHORIZED = 'UNAUTHORIZED',
  ACCOUNTING_PERIOD = 'ACCOUNTING_PERIOD',
  EMAILING_HAS_NO_CAMPAIGNS = 'EMAILING_HAS_NO_CAMPAIGNS',
  PRO_ACCOUNT_MEMBERSHIP_INACTIVE = 'PRO_ACCOUNT_MEMBERSHIP_INACTIVE',
  PRO_ACCOUNT_MEMBERSHIP_NOT_SETUP = 'PRO_ACCOUNT_MEMBERSHIP_NOT_SETUP',
}

export interface IUseSpecifications {
  getSpecifications: <T extends ESpecification>(
    specificationsList: Array<ESpecification>,
    organization: IOrganization,
    person: IPerson,
    other?: SpecificProperties<T>
  ) => Promise<{ [key: string]: ISpecificationStatus }>
  getSpecification: <T extends ESpecification>(
    specificationType: ESpecification,
    organization: IOrganization,
    person: IPerson,
    other?: SpecificProperties<T>,
    refreshData?: boolean
  ) => Promise<ISpecificationStatus>
}

interface IProAccountProps {
  proAccountMember?: IApiProAccountAccountMember | null
  proAccountHolder?: IApiProAccountAccountHolder | null
}

export type SpecificProperties<T extends ESpecification> = T extends
  | ESpecification.HAS_ACTIVE_PRO_ACCOUNT_MEMBERSHIP
  | ESpecification.HAS_SETUP_PRO_ACCOUNT_MEMBERSHIP
  ? IProAccountProps
  : undefined
