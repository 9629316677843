import { FC, useState } from 'react'
import { DropdownMenu as ReactDropdownMenu } from 'reactstrap'
import { bootstrapColorParser } from 'utils'

import * as Style from './style'
import * as Type from './type'

const Dropdown: FC<Type.IDropdown> = ({
  children,
  button,
  toggleTag = 'button',
  right,
  isDisabled,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Style.ReactDropdown
      isDisabled={isDisabled}
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      {...rest}
    >
      <Style.Toggle
        color={bootstrapColorParser(button.color)}
        isRound={!!button.isRound}
        title={button.title}
        {...(toggleTag !== 'button' && { tag: toggleTag })}
      >
        {button.children}
      </Style.Toggle>
      <ReactDropdownMenu right={right} positionFixed={true}>
        {children}
      </ReactDropdownMenu>
    </Style.ReactDropdown>
  )
}

export default Dropdown
