import { Form, Stepper } from 'components'
import { Formik } from 'formik'
import { createIntl, useSetPage, useTranslation } from 'hooks'
import { FC } from 'react'
import { RawIntlProvider } from 'react-intl'
import { pageData } from 'state/Page/data'
import { EPageId } from 'state/type'

import * as Data from './data'

const BusinessAccountDiscover: FC = () => {
  const pageId = EPageId.BUSINESS_ACCOUNT_DETAILS

  const intlPath = pageData[pageId].intlPath
  const intl = createIntl(intlPath)
  const translation = useTranslation(intl)

  useSetPage({
    pageId: EPageId.BUSINESS_ACCOUNT_DETAILS,
  })

  return (
    <RawIntlProvider value={intl}>
      <Formik
        initialValues={{}}
        onSubmit={() => {
          console.log('submit')
        }}
        children={() => (
          <Form>
            <Stepper steps={Data.getSteps(translation)} />
          </Form>
        )}
      />
    </RawIntlProvider>
  )
}

export default BusinessAccountDiscover
