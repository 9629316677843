import { Form, SwitchControlled, SwitchUncontrolled, Title } from 'components'
import { Formik } from 'formik'
import { FC, useState } from 'react'

const StorybookPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [unControlledSwitch, setUnControlledSwitch] = useState(false)
  const [unControlledLoadingSwitch, setUnControlledLoadingSwitch] =
    useState(false)

  return (
    <>
      <Title type="h1" className="mb-4">
        Form / Switch
      </Title>

      <Title type="h2" className="mb-4">
        Controlled (used inside Forms)
      </Title>

      <Formik
        initialValues={{
          switch: false,
          switchDisabledUnchecked: false,
          switchDisabledChecked: true,
          switchWithoutLabel: true,
          switchLoading: false,
          switchWithLoader: false,
        }}
        onSubmit={(values) => {
          alert(JSON.stringify(values))
        }}
        children={() => (
          <Form>
            <Title type="h3">Regular</Title>
            <SwitchControlled
              name="switch"
              label="Lorem ipsum"
              required={true}
            />
            <Title type="h3" className="my-4">
              Disabled state off
            </Title>
            <SwitchControlled
              name="switchDisabledUnchecked"
              label="Lorem ipsum"
              disabled={true}
            />
            <Title type="h3" className="my-4">
              Disabled state on
            </Title>
            <SwitchControlled
              name="switchDisabledChecked"
              label="Lorem ipsum"
              disabled={true}
            />
            <Title type="h3" className="my-4">
              Without label
            </Title>
            <SwitchControlled name="switchWithoutLabel" />
            <Title type="h3" className="my-4">
              Loading (the switch cannot be interacted with while loading)
            </Title>
            <SwitchControlled name="switchLoading" loading={true} />
            <Title type="h3" className="my-4">
              With a 3s loading state on change
            </Title>
            <SwitchControlled name="switchWithLoader" />
          </Form>
        )}
      />

      <Title type="h2" className="my-4">
        Uncontrolled (used outside Forms)
      </Title>

      <SwitchUncontrolled
        checked={unControlledSwitch}
        onToggle={async () => {
          setUnControlledSwitch(!unControlledSwitch)
        }}
        label={`Value is: ${unControlledSwitch ? 'true' : 'false'}`}
      />

      <SwitchUncontrolled
        checked={unControlledLoadingSwitch}
        onToggle={async () => {
          setIsLoading(true)
          setTimeout(() => {
            setUnControlledLoadingSwitch(!unControlledLoadingSwitch)
            setIsLoading(false)
          }, 3000)
        }}
        loading={isLoading}
        label={`3s loading before changing, value is: ${
          unControlledLoadingSwitch ? 'true' : 'false'
        }`}
      />
    </>
  )
}

export default StorybookPage
