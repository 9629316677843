import { IAddress } from 'api/type'

export enum EApiCrmPersonRole {
  MASTER_ADMIN = 'MASTER_ADMIN',
  GENERAL_ADMIN = 'GENERAL_ADMIN',
  ORGANIZATION_READ = 'ORGANIZATION_READ',
  ORGANIZATION_WRITE = 'ORGANIZATION_WRITE',
  ACCOUNTING_WRITE = 'ACCOUNTING_WRITE',
  ACCOUNTING_READ = 'ACCOUNTING_READ',
  COMMUNICATION_WRITE = 'COMMUNICATION_WRITE',
  COMMUNICATION_READ = 'COMMUNICATION_READ',
  COMMUNICATION_AGENDA_WRITE = 'COMMUNICATION_AGENDA_WRITE',
  COMMUNICATION_DIRECTORY_WRITE = 'COMMUNICATION_DIRECTORY_WRITE',
  COMMUNICATION_EMAILING_WRITE = 'COMMUNICATION_EMAILING_WRITE',
  COMMUNICATION_GALLERY_WRITE = 'COMMUNICATION_GALLERY_WRITE',
  COMMUNICATION_POLL_WRITE = 'COMMUNICATION_POLL_WRITE',
  COMMUNICATION_POST_WRITE = 'COMMUNICATION_POST_WRITE',
  COMMUNICATION_SITE_WRITE = 'COMMUNICATION_SITE_WRITE',
  COMMUNICATION_AGENDA_READ = 'COMMUNICATION_AGENDA_READ',
  COMMUNICATION_DIRECTORY_READ = 'COMMUNICATION_DIRECTORY_READ',
  COMMUNICATION_EMAILING_READ = 'COMMUNICATION_EMAILING_READ',
  COMMUNICATION_GALLERY_READ = 'COMMUNICATION_GALLERY_READ',
  COMMUNICATION_POLL_READ = 'COMMUNICATION_POLL_READ',
  COMMUNICATION_POST_READ = 'COMMUNICATION_POST_READ',
  COMMUNICATION_SITE_READ = 'COMMUNICATION_SITE_READ',
  DONATION_WRITE = 'DONATION_WRITE',
  DONATION_READ = 'DONATION_READ',
  DONATION_MEMBERSHIP_ADMIN = 'DONATION_MEMBERSHIP_ADMIN',
  MEMBERSHIP_WRITE = 'MEMBERSHIP_WRITE',
  MEMBERSHIP_READ = 'MEMBERSHIP_READ',
  SUBVENTION_WRITE = 'SUBVENTION_WRITE',
  SUBVENTION_READ = 'SUBVENTION_READ',
  DEAL_WRITE = 'DEAL_WRITE',
  DEAL_READ = 'DEAL_READ',
  USER_ADMIN = 'USER_ADMIN', // User edition + user field edition
  USER_WRITE = 'USER_WRITE', // User edition
  USER_READ = 'USER_READ',
  COMPANY_WRITE = 'COMPANY_WRITE',
  COMPANY_READ = 'COMPANY_READ',
  PRO_ACCOUNT_WRITE = 'PRO_ACCOUNT_WRITE',
  PRO_ACCOUNT_READ = 'PRO_ACCOUNT_READ',
}

export enum EApiMasterAdminRole {
  MASTER_FULL_ADVANCED = 'MASTER_FULL_ADVANCED',
  MASTER_ACCOUNTING_ADVANCED = 'MASTER_ACCOUNTING_ADVANCED',
  MASTER_ACCOUNTING_INTERMEDIATE = 'MASTER_ACCOUNTING_INTERMEDIATE',
  MASTER_ACCOUNTING_BASIC = 'MASTER_ACCOUNTING_BASIC',
  MASTER_CRM_ADVANCED = 'MASTER_CRM_ADVANCED',
  MASTER_CRM_INTERMEDIATE = 'MASTER_CRM_INTERMEDIATE',
  MASTER_CRM_BASIC = 'MASTER_CRM_BASIC',
  MASTER_EMAILING_ADVANCED = 'MASTER_EMAILING_ADVANCED',
  MASTER_EMAILING_INTERMEDIATE = 'MASTER_EMAILING_INTERMEDIATE',
  MASTER_EMAILING_BASIC = 'MASTER_EMAILING_BASIC',
  MASTER_SECURITY_ADVANCED = 'MASTER_SECURITY_ADVANCED',
  MASTER_SECURITY_INTERMEDIATE = 'MASTER_SECURITY_INTERMEDIATE',
  MASTER_SECURITY_BASIC = 'MASTER_SECURITY_BASIC',
  MASTER_PAYMENT_ADVANCED = 'MASTER_PAYMENT_ADVANCED',
  MASTER_PAYMENT_INTERMEDIATE = 'MASTER_PAYMENT_INTERMEDIATE',
  MASTER_PAYMENT_BASIC = 'MASTER_PAYMENT_BASIC',
  MASTER_PRO_ACCOUNT_ADVANCED = 'MASTER_PRO_ACCOUNT_ADVANCED',
  MASTER_PRO_ACCOUNT_INTERMEDIATE = 'MASTER_PRO_ACCOUNT_INTERMEDIATE',
  MASTER_PRO_ACCOUNT_BASIC = 'MASTER_PRO_ACCOUNT_BASIC',
  MASTER_FINANCE_ADVANCED = 'MASTER_FINANCE_ADVANCED',
  MASTER_FINANCE_INTERMEDIATE = 'MASTER_FINANCE_INTERMEDIATE',
  MASTER_FINANCE_BASIC = 'MASTER_FINANCE_BASIC',
  MASTER_PLATFORM_ADVANCED = 'MASTER_PLATFORM_ADVANCED',
  MASTER_PLATFORM_INTERMEDIATE = 'MASTER_PLATFORM_INTERMEDIATE',
  MASTER_PLATFORM_BASIC = 'MASTER_PLATFORM_BASIC',
  MASTER_MISCELLANEOUS_ADVANCED = 'MASTER_MISCELLANEOUS_ADVANCED',
  MASTER_MISCELLANEOUS_INTERMEDIATE = 'MASTER_MISCELLANEOUS_INTERMEDIATE',
  MASTER_MISCELLANEOUS_BASIC = 'MASTER_MISCELLANEOUS_BASIC',
}

export enum ELegacyPrimaryKey {
  EXTEND_ID = 'extendId',
}

export enum ECommunityPrimaryKey {
  ID = 'id',
  EMAIL = 'email',
  CUSTOM_ID = 'customId',
}

export interface IApiCrmIndependentSettingsPrimaryKey {
  '@context': string
  '@id': string
  '@type': string
  primaryKey:
    | ECommunityPrimaryKey.ID
    | ECommunityPrimaryKey.EMAIL
    | ELegacyPrimaryKey.EXTEND_ID
    | string
}

export interface IApiCrmPerson {
  firstName: string
  lastName: string
  dateOfBirth: string
  contactId: string
  id: string
}

export interface IApiCrmPersonUpdate {
  firstName?: string
  lastName?: string
  dateOfBirth?: string
}

export interface IApiCrmPersonAddress extends IAddress {
  id: string
  formattedAddress: string
}

export type IApiCrmPersonAddressUpdate = IAddress

export interface IApiCrmPersonRoles {
  globalRoles: Array<EApiCrmPersonRole>
  isMasterAdmin: undefined | boolean
  roles: Array<EApiCrmPersonRole>
  adminMasterRoles: Array<EApiMasterAdminRole>
}

export interface IApiCrmPrimaryKeyDuplicates {
  '@context': string
  '@id': string
  '@type': string
  key: string
  organizationId: string
  duplicates: string[]
}

export type ICrmContactRelation = {
  organization: string
  transactionId: null | string
  type: string
  donatedAt: null | string
}

export interface IApiCrmContact {
  '@context': string
  '@id': string
  '@type': string
  type: string
  firstname: string
  lastname: string
  gender: string
  dateOfBirth: string | null
  email: string
  landlinePhone: null | string
  mobilePhone: null | string
  postalAddress?: null | string
  customFields: { [key: string]: string | Array<string> }
  relations: Array<ICrmContactRelation>
}

export interface IApiCrmSettings {
  '@context': string
  '@id': string
  '@type': string
  hideParentContact: boolean
  contactArchivableFromChapterOrSection: boolean
  contactDeletableFromChapterOrSection: boolean
}

export enum EApiCrmSettingsItems {
  HIDE_PARENT_CONTACT = 'hideParentContact',
  CONTACT_ARCHIVABLE_FROM_CHAPTER_OR_SECTION = 'contactArchivableFromChapterOrSection',
  CONTACT_DELETABLE_FROM_CHAPTER_OR_SECTION = 'contactDeletableFromChapterOrSection',
}

export enum ECustomFieldType {
  ADDRESS = 'ADDRESS',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  DATE_PAST = 'DATE_PAST',
  EMAIL = 'EMAIL',
  FILE = 'FILE',
  NUMERIC = 'NUMERIC',
  PHONE = 'PHONE',
  PHONE_LANDLINE = 'PHONE_LANDLINE',
  PHONE_MOBILE = 'PHONE_MOBILE',
  RADIO = 'RADIO',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  URL = 'URL',
}

export interface IApiCrmOrganizationCustomFields {
  '@id': string
  '@type': string
  category: string
  contactType: EApiCrmPersonRole
  id: string
  name: string
  possibleValues: Array<string>
  type: ECustomFieldType
}
