import { Background } from 'components'
import styled from 'styled-components'

export const PaymentBlockBackground = styled(({ isSelected, ...props }) => (
  <Background {...props} />
))`
  box-shadow: ${({ isSelected, ...props }) =>
    isSelected ? `0 2px 6px 0 ${props.theme.grey}` : 'none'};
  &:hover {
    box-shadow: 0 2px 6px 0 ${(props) => props.theme.grey};
    cursor: pointer;
  }

  .form-group {
    margin-bottom: 0;
  }
`

export const PaymentBlockDetails = styled((props) => <div {...props} />)`
  position: relative;
  padding-top: 25px;

  .adyen-checkout__card__brands__brand-wrapper img {
    display: block;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: 14px;
    left: 0;
    background: ${(props) => props.theme.lightGrey};
    opacity: 0.5;
  }
`

export const PaymentCardBackground = styled((props) => (
  <Background {...props} />
))`
  width: 40px;
  height: 30px;
  border-radius: 5px;
  padding: 0;
  margin: 0 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ApplePayBackground = styled((props) => (
  <PaymentCardBackground {...props} />
))`
  width: 50px;
`
