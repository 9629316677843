import { EBankAccountType } from 'api/type'
import { BankAccount, Form, Title } from 'components'
import { setBankAccountValues } from 'components/Form/BankAccount'
import { Formik } from 'formik'
import { EAccountAction } from 'pages/Accounting/Settings/BankAccounts/type'
import { FC } from 'react'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / BankAccount
    </Title>

    <Title type="h2" className="my-4">
      European component
    </Title>
    <Formik
      initialValues={{
        ...setBankAccountValues('european'),
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <BankAccount
            name="european"
            type={EBankAccountType.EUROPEAN}
            mode={EAccountAction.CREATE}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      American component
    </Title>
    <Formik
      initialValues={{
        ...setBankAccountValues('american'),
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <BankAccount
            name="american"
            type={EBankAccountType.US}
            mode={EAccountAction.CREATE}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Required mode
    </Title>
    <Formik
      initialValues={{
        ...setBankAccountValues('required'),
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <BankAccount
            name="required"
            type={EBankAccountType.EUROPEAN}
            mode={EAccountAction.CREATE}
            required={true}
          />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
