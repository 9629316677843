import { API } from './connector'

export const getPaginatedData = async <T,>(
  path: string,
  params: Record<string, unknown> = {},
  itemsPerPage = 25
): Promise<{ data: T[]; totalItems: number }> => {
  const fetchRecursive = async (
    currentPage: number,
    allData: T[]
  ): Promise<{ data: T[]; totalItems: number }> => {
    const response = await API.get(path, {
      params: { ...params, itemsPerPage, page: currentPage },
    })

    const newItems: T[] = response.data['hydra:member'] ?? []
    const totalItems: number = response.data['hydra:totalItems'] ?? 0
    const mergedData = [...allData, ...newItems]

    return mergedData.length < totalItems
      ? fetchRecursive(currentPage + 1, mergedData)
      : { data: mergedData, totalItems }
  }

  return fetchRecursive(1, [])
}
