import classNames from 'classnames'
import {
  Container,
  EmptySpaceAccessDenied,
  HasAccess,
  LayoutIntlProvider,
  Loader,
  NotificationsWrapper,
  SidePanel,
  Tabs,
} from 'components'
import { UseMasterAdmin } from 'hooks'
import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetSidePanel } from 'state'
import { EColor } from 'types'

import * as Data from './data'
import * as Style from './style'

const Storybook: FC = ({ children }) => {
  const dispatch = useDispatch()
  const { isMasterAdmin } = UseMasterAdmin()

  // Reset the sidePanel store at each layout rerender.
  useEffect(() => {
    dispatch(resetSidePanel())
  })

  if (isMasterAdmin === undefined) {
    return null
  }

  return (
    <LayoutIntlProvider>
      <HasAccess
        access={() => isMasterAdmin}
        onAccessDenied={() => <EmptySpaceAccessDenied />}
      >
        <>
          <Style.Background />
          <Style.Menu role="navigation" className="pt-3 pb-5">
            {Data.navigation.map((category, categoryIndex: number) => (
              <div key={categoryIndex}>
                <Style.ItemTitle
                  type="h6"
                  color={EColor.WHITE}
                  className={classNames(
                    categoryIndex !== 0 && 'border-top mt-4 pt-4'
                  )}
                  uppercase={true}
                >
                  {category.title}
                </Style.ItemTitle>
                <div>
                  {category.items.map((item, itemIndex) => (
                    <div key={itemIndex}>
                      {item.items ? (
                        <Style.ItemParent>
                          <Style.ItemParentTitle>
                            {item.title}
                          </Style.ItemParentTitle>
                          {item.items && (
                            <div>
                              {item.items.map((subItem, subItemIndex) => (
                                <Style.Item
                                  key={subItemIndex}
                                  href={subItem.href}
                                  className="pl-4 ml-2"
                                >
                                  {subItem.title}
                                </Style.Item>
                              ))}
                            </div>
                          )}
                        </Style.ItemParent>
                      ) : (
                        <Style.Item key={itemIndex} href={item.href}>
                          {item.title}
                        </Style.Item>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Style.Menu>
          <Container className="my-4" role="main" tag="main">
            <Tabs />
            {children}
          </Container>
          <NotificationsWrapper />
          <SidePanel />
          <Loader />
        </>
      </HasAccess>
    </LayoutIntlProvider>
  )
}

export default Storybook
