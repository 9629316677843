import { Background, Badge, Col, ListItem, Row, Title } from 'components'
import { EIconPosition, IBadgeSize } from 'components/Badge/type'
import { FC } from 'react'
import { EColor } from 'types'

const colors = [
  EColor.GREEN,
  EColor.RED,
  EColor.YELLOW,
  EColor.BLUE,
  EColor.DARK_BLUE,
]
const sizes = { xs: '15px', sm: '26px', md: '38px', lg: '52px', xl: '104px' }

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Badge
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>The left text contains string</ListItem>
        <ListItem>The icon are from Fontawesome</ListItem>
        <ListItem>Icon and Text are not mandatory</ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Badge icon="times" iconPosition={EIconPosition.LEFT} text="Lorem ipsum" />

    <Title type="h2" className="my-4">
      Icon position
    </Title>
    <Row className="align-items-center mb-1">
      <Col lg={2}>Right</Col>
      <Col lg={10}>
        <Badge icon="times" text="Lorem ipsum" />
      </Col>
    </Row>
    <Row className="align-items-center mb-1">
      <Col lg={2}>Left</Col>
      <Col lg={10}>
        <Badge
          icon="times"
          iconPosition={EIconPosition.LEFT}
          text="Lorem ipsum"
        />
      </Col>
    </Row>
    <Row className="align-items-center">
      <Col lg={2}>Without text</Col>
      <Col lg={10}>
        <Badge icon="times" />
      </Col>
    </Row>
    <Row className="align-items-center">
      <Col lg={2}>Without icon</Col>
      <Col lg={10}>
        <Badge text="Lorem ipsum" />
      </Col>
    </Row>

    <Title type="h2" className="my-4">
      Text weights
    </Title>
    <Row className="align-items-center mb-1">
      <Col lg={2}>Bold</Col>
      <Col lg={10}>
        <Badge
          icon="times"
          iconPosition={EIconPosition.LEFT}
          text="Lorem ipsum"
        />
      </Col>
    </Row>
    <Row className="align-items-center">
      <Col lg={2}>Regular</Col>
      <Col lg={10}>
        <Badge
          icon="times"
          iconPosition={EIconPosition.LEFT}
          isBold={false}
          text="Lorem ipsum"
        />
      </Col>
    </Row>

    <Title type="h2" className="my-4">
      Colors
    </Title>
    {colors.map((color, index) => (
      <Row key={index} className="align-items-center mb-1">
        <Col lg={2}>{color}</Col>
        <Col lg={10}>
          <Badge
            icon="times"
            color={color}
            iconPosition={EIconPosition.LEFT}
            text="Lorem ipsum"
          />
        </Col>
      </Row>
    ))}

    <Title type="h2" className="my-4">
      Sizes
    </Title>
    {Object.entries(sizes).map(([size, px], index) => (
      <Row key={index} className="align-items-center mb-1">
        <Col lg={2}>
          {size} ({px})
        </Col>
        <Col lg={10}>
          <Badge icon="times" size={size as IBadgeSize} />
        </Col>
      </Row>
    ))}
  </>
)

export default StorybookPage
