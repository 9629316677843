import { EBankAccountType, IBankAccountsList } from 'api/type'

const bankAccounts: IBankAccountsList = [
  {
    '@id': '/api/bank_accounts/1',
    id: 'UHSFDUJQYTHDSJQ6532',
    accountNumber: '17683467',
    bankName: 'Bank name',
    bic: 'bic9873468',
    branchCode: 'branchCodeoods',
    iban: 'IBAN89743KHDS09823',
    isArchived: false,
    isUnarchivable: false,
    isUsedOnKycProcess: false,
    isDeletable: false,
    name: 'Name979839034',
    organization: '2',
    ownBankAccount: true,
    ownerName: null,
    type: EBankAccountType.EUROPEAN,
    proAccountAccount: null,
  },
]

export default bankAccounts
