import { Background, Col, ResponsiveButton } from 'components'
import { EBackgroundBorderColor } from 'components/Background/type'
import { EResponsiveButtonSize } from 'components/Button/Responsive/type'
import { FC, useState } from 'react'
import { EColor } from 'types'

import LongCardContent from './Content'
import LongCardHeader from './Header'
import * as Style from './style'
import * as Type from './type'

const LongCard: FC<Type.ILongCard> = ({
  actions = [],
  alert,
  items,
  isArchived = false,
  isDeployable = false,
  isColumn = false,
  isOpen,
  status,
  title,
  ...rest
}) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(
    Boolean(isOpen) || !isDeployable
  )

  return (
    <div {...rest}>
      <Background
        color={isArchived ? EColor.TRANSPARENT : EColor.WHITE}
        border={
          isArchived
            ? EBackgroundBorderColor.SOLID
            : EBackgroundBorderColor.SHADOW
        }
      >
        <LongCardHeader
          actions={actions}
          isDeployable={isDeployable}
          isArchived={isArchived}
          isOpen={isCollapseOpen}
          setIsOpen={setIsCollapseOpen}
          status={status}
          title={title}
        />

        {items && (
          <LongCardContent
            alert={alert}
            items={items}
            isArchived={isArchived}
            isColumn={isColumn}
            isOpen={isCollapseOpen}
          />
        )}

        <Style.ResponsiveButtonsRow
          isArchived={isArchived}
          className="w-100 d-flex d-lg-none m-0 mt-3 pt-2 justify-content-between text-center"
        >
          {actions.map((action, key) => (
            <Col className="col-auto px-0" key={key}>
              <ResponsiveButton
                icon={action.icon}
                className="mx-1 mt-3 mb-1 my-lg-0"
                onClick={action.handler}
                disabled={action.isDisabled}
                outline={action.outline || true}
                color={action.color || EColor.GREY}
                buttonSize={action.buttonSize || EResponsiveButtonSize.SMALL}
              >
                {action.text}
              </ResponsiveButton>
            </Col>
          ))}
        </Style.ResponsiveButtonsRow>
      </Background>
    </div>
  )
}

export default LongCard
