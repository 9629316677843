import { updateOrganizationApps } from 'api'
import { EApp } from 'api/type'
import { Button, Icon, Link } from 'components'
import { useApplications, useTranslation } from 'hooks'
import { ApplicationsContext, useOrganization } from 'providers'
import { FC, useContext } from 'react'
import { useIntl } from 'react-intl'
import { EColor } from 'types'
import { redirectTo } from 'utils'
import { ERedirectionType } from 'utils/Url/type'

import { EProAccountDiscoverFeatures } from './type'

const ProAccountOnboardingIntroduction: FC = () => {
  const intl = useIntl()
  const translation = useTranslation(intl)
  const { organization } = useOrganization()
  const { isApplicationEnabled } = useApplications()

  const { applications } = useContext(ApplicationsContext)

  if (isApplicationEnabled(EApp.PRO_ACCOUNT)) {
    redirectTo(`/organization/${organization.idOld}/pro-account`)
  }

  const handleActivateBusinessAccount = async () => {
    await updateOrganizationApps(applications.iri, [
      { name: EApp.PRO_ACCOUNT, isEnabled: true },
    ])
    redirectTo(
      `/organization/${organization.idOld}/business-account/onboarding`
    )
  }

  const handleBookDemo = () =>
    redirectTo(
      `${process.env.REACT_APP_CALENDLY_BASE_URL}/compte-pro-asso/compte-pro-asso`,
      {},
      ERedirectionType.BLANK
    )

  return (
    <div>
      <p>{translation.translate('introduction.description')}</p>
      <ul>
        {Object.values(EProAccountDiscoverFeatures).map((feature) => (
          <li key={feature} className="py-2">
            <span>
              {translation.translate(`introduction.features.${feature}`)}
            </span>
          </li>
        ))}
      </ul>
      <div>
        <Button
          className="mx-4"
          color={EColor.BLUE}
          onClick={async () => handleActivateBusinessAccount()}
        >
          {translation.translate('introduction.action.createAccount')}
        </Button>
        <Button
          className="mx-4"
          color={EColor.TRANSPARENT}
          onClick={() => handleBookDemo()}
        >
          <Icon icon={['far', 'calendar-day']} className="mr-2" />
          {translation.translate('introduction.action.demo')}
        </Button>
      </div>
      <p>
        {translation.translate('introduction.action.network', {
          link: (
            <Link
              // TO DO SIMON: Waiting for market for link
              href={`/`}
              target="_blank"
            >
              {translation.translate('introduction.action.network.link')}
            </Link>
          ),
        })}
      </p>
    </div>
  )
}

export default ProAccountOnboardingIntroduction
