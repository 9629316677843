import { Col, Row, Svg } from 'components'
import { useTranslation } from 'hooks'
import { FC } from 'react'
import { useIntl } from 'react-intl'

import { getPaymentMethodIcons } from '../PaymentMethod/utils'
import * as Type from './type'

const Background3DSecureExplanations: FC<
  Type.IBackground3DSecureExplanations
> = ({ showSupportedCreditCards = false, isMobile = false }) => {
  const intl = useIntl()
  const translation = useTranslation(intl)
  return (
    <div className="px-2">
      <Row className="align-items-center justify-content-center flex-row">
        <Col xs="auto">
          <Svg
            src="common/icon/payment/3D-secure"
            width={isMobile ? '20px' : '40px'}
          />
        </Col>
        <Col xs="auto" className="pl-0">
          {translation.translate('3DSecure.description')}
        </Col>
      </Row>
      {showSupportedCreditCards && (
        <>
          <hr />
          <Row className="align-items-center">
            <Col xs="auto">
              <strong>{translation.translate('3DSecure.paymentCards')}</strong>
            </Col>
            <Col className="text-right mr-3">{getPaymentMethodIcons()}</Col>
          </Row>
        </>
      )}
    </div>
  )
}

export default Background3DSecureExplanations
