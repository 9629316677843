import { useCallback } from 'react'

import { IUseIframeListener } from './type'

const useIframeHeightListener = (): IUseIframeListener => {
  const isIframe = window.self !== window.top

  const resizeIframe = useCallback(() => {
    let lastHeight = 0
    const observer = new ResizeObserver(() => {
      const newHeight = document.body.scrollHeight

      // temporary fix for payment iframe (infinitely growing - may be due to the footer)
      if (Math.abs(newHeight - lastHeight) > 30) {
        lastHeight = newHeight
        window.parent.postMessage(
          {
            action: 'donation-iframe.height',
            height: newHeight,
          },
          '*'
        )
        window.parent.postMessage(
          {
            action: 'iframe.height',
            height: newHeight,
          },
          '*'
        )
      }
    })

    observer.observe(document.body)

    return () => {
      observer.disconnect()
    }
  }, [])

  return { isIframe, resizeIframe }
}

export default useIframeHeightListener
