import {
  IApiWorkflowAction,
  IProAccountAccount,
  IProAccountConsent,
  IProAccountUser,
} from 'api/type'

export interface IApiProAccountAccountMember {
  '@id': string
  '@type': string
  id: string
  user?: IProAccountUser
  account?: IProAccountAccount
  adminStatus: EProAccountAccountMembershipAdminStatus
  consent?: null | IProAccountConsent
  email: string
  swanId: string
  ['workflow:actions']?: IApiWorkflowAction<EProAccountAccountMembershipAction>[]
}

export interface IApiProAccountAccountBindingErrors {
  name: string
  currentValue: string
  expectedValue: string
  isOnError: boolean
}

export interface IApiProAccountAccountMembersFilters {
  status?:
    | EProAccountAccountMembershipAdminStatus[]
    | EProAccountAccountMembershipAdminStatusDisplay[]
    | null
  'status!'?: EProAccountAccountMembershipAdminStatus
  itemsPerPage?: number
}

export enum EProAccountAccountMembershipAction {
  REQUEST_CONSENT = 'request_consent',
  FIX_MISSING_DATA = 'fix_missing_data',
  DISABLED = 'disabled',
  FIX_BINDING_ERRORS = 'fix_binding_errors',
  SEND_INVITATION_EMAIL = 'send_invitation_email',
  SEND_IDENTIFICATION_NOT_VERIFIED_EMAIL = 'send_identification_not_verified_email',
  POSTPONE_CONSENT = 'postpone_consent',
  UPDATE = 'update',
}

export enum EProAccountAccountMembershipUserAction {
  SEND_IDENTIFICATION_NOT_VERIFIED_EMAIL = 'send_identification_not_verified_email',
}

export enum EProAccountAccountMembershipAdminStatus {
  NO_ACCOUNT = 'NO_ACCOUNT',
  POSTPONED = 'POSTPONED',
  INVITATION_SENT = 'INVITATION_SENT',
  IDENTIFICATION_NOT_VERIFIED = 'IDENTIFICATION_NOT_VERIFIED',
  IDENTIFICATION_FAILED = 'IDENTIFICATION_FAILED',
  CONFLICTS = 'CONFLICTS',
  ACTIVE_ACCOUNT = 'ACTIVE_ACCOUNT',
}

export enum EProAccountAccountMembershipAdminStatusDisplay {
  NO_ACCOUNT = 'NO_ACCOUNT',
  INVITATION_SENT = 'INVITATION_SENT',
  IDENTIFICATION_NOT_VERIFIED = 'IDENTIFICATION_NOT_VERIFIED',
  IDENTIFICATION_FAILED = 'IDENTIFICATION_FAILED',
  CONFLICTS = 'CONFLICTS',
  ACTIVE_ACCOUNT = 'ACTIVE_ACCOUNT',
}

export enum EProAccountAccountUserStatus {
  VERIFIED = 'VERIFIED',
  IDENTIFICATION_FAILED = 'IDENTIFICATION_FAILED',
  IDENTIFICATION_NOT_VERIFIED = 'IDENTIFICATION_NOT_VERIFIED',
  IDENTIFICATION_NOT_STARTED = 'IDENTIFICATION_NOT_STARTED',
}
