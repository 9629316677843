import {
  EBankStatementStatus,
  EThirdPartyType,
  IApiCheckRemittance,
  IPaymentRealization,
} from 'api/type'
import { Amount } from 'types'

export enum ECheckType {
  BANK = 'BANK',
  ANCV = 'ANCV',
}

export enum ECheckStatus {
  REJECTED = 'REJECTED',
  RECEIVED = 'RECEIVED',
  DEPOSITED = 'DEPOSITED',
}

export interface IApiReceivedCheck {
  '@id': string
  id: string
  organization: string
  entryName: string
  recordId?: string
  type: ECheckType
  date: string
  remittanceDate: string
  transmitterName: string
  checkRemittance: IApiCheckRemittance
  bankName: string
  bankStatementStatus: EBankStatementStatus
  number: string | null
  checkPaymentRealization?: IPaymentRealization
  amount: Amount
  rejectedCheck: {
    id: string
    date: string
  }
  thirdParty?: {
    id: string
    isArchived: boolean
    isDeletable: boolean
    name: string
    type: EThirdPartyType
  }
  status: ECheckStatus
}

export interface IApiRejectedCheck {
  '@id': string
  id: string
  receivedCheck: string
  date: {
    '@id': string
  }
  organization: {
    '@id': string
  }
}
