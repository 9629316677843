import { Background, Form, ListItem, RadioButtons, Title } from 'components'
import { Formik } from 'formik'
import { FC } from 'react'

const lorem1 = 'Lorem ipsum 1...'
const lorem2 = 'Lorem ipsum 2...'
const lorem3 = 'Lorem ipsum 3...'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Radio buttons
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>Height: 46px</ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        radioButtons: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <RadioButtons
            name="radioButtons"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            buttons={[
              { text: lorem1, value: '1' },
              { text: lorem2, value: '2' },
              { text: lorem3, value: '3' },
            ]}
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Disabled state
    </Title>
    <Formik
      initialValues={{
        radioButtonsDisabledUnchecked: '',
        radioButtonsDisabledChecked: '1',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <RadioButtons
            name="radioButtonsDisabledUnchecked"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            buttons={[
              { text: lorem1, value: '1' },
              { text: lorem2, value: '2' },
              { text: lorem3, value: '3' },
            ]}
            disabled={true}
          />
          <RadioButtons
            name="radioButtonsDisabledChecked"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            buttons={[
              { text: lorem1, value: '1' },
              { text: lorem2, value: '2' },
              { text: lorem3, value: '3' },
            ]}
            disabled={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without help text
    </Title>
    <Formik
      initialValues={{
        radioButtonsWithoutHelpText: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <RadioButtons
            name="radioButtonsWithoutHelpText"
            label="Lorem ipsum"
            buttons={[
              { text: lorem1, value: '1' },
              { text: lorem2, value: '2' },
              { text: lorem3, value: '3' },
            ]}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without label
    </Title>
    <Formik
      initialValues={{
        radioButtonsWithoutLabel: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <RadioButtons
            name="radioButtonsWithoutLabel"
            buttons={[
              { text: lorem1, value: '1' },
              { text: `${lorem2} ${lorem3}`, value: '2' },
            ]}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Equal button size (desktop only)
    </Title>
    <Formik
      initialValues={{
        radioButtonsWithEqualWidth: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <RadioButtons
            name="radioButtonsWithEqualWidth"
            buttons={[
              { text: lorem1, value: '1' },
              { text: `${lorem2} ${lorem3}`, value: '2' },
            ]}
            hasEqualWidth={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Vertical mode (desktop only, mobile is already vertical)
    </Title>
    <Formik
      initialValues={{
        radioButtonsVertical: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <RadioButtons
            name="radioButtonsVertical"
            buttons={[
              { text: lorem1, value: '1' },
              { text: `${lorem2} ${lorem3}`, value: '2' },
            ]}
            hasEqualWidth={true}
            isVertical={true}
          />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
