import { LongCard, Title } from 'components'
import { EResponsiveButtonSize } from 'components/Button/Responsive/type'
import { EContentType } from 'components/Card/LongCard/Content/type'
import { IAction, ILongCard } from 'components/Card/LongCard/type'
import { createIntl } from 'hooks'
import { FC } from 'react'
import { RawIntlProvider } from 'react-intl'
import { EColor } from 'types'

const StorybookLongCard: FC = () => {
  const intl = createIntl('pages_storybook')

  const items: ILongCard['items'] = [
    {
      icon: ['fas', 'pen'],
      label: 'Description',
      text: 'Lorem ipsum dolor sit amet',
      type: EContentType.ICON,
    },
    {
      icon: ['fas', 'user'],
      label: 'Utilisateur',
      text: 'John Doe',
      type: EContentType.ICON,
    },
    {
      icon: ['fas', 'calendar'],
      label: 'Date de naissance',
      text: '29/09/1991',
      type: EContentType.ICON,
    },
    {
      icon: ['fas', 'globe'],
      label: 'URL',
      text: 'https://assoconnect.com',
      type: EContentType.ICON,
    },
    {
      icon: ['fas', 'users'],
      label: 'Liste des groupes',
      text: 'Groupe 1 - Groupe 2 - Groupe 3',
      type: EContentType.ICON,
    },
  ]

  const actions: Array<IAction> = [
    {
      name: 'edit',
      text: 'Edit',
      handler: () => {
        // This is intentional to prevent eslint warning
      },
      icon: ['fas', 'pen'],
    },
    {
      name: 'delete',
      text: 'Delete',
      handler: () => {
        // This is intentional to prevent eslint warning
      },
      icon: ['fas', 'trash-alt'],
    },
    {
      name: 'verify',
      text: 'Verify',
      handler: () => {
        // This is intentional to prevent eslint warning
      },
      icon: ['fas', 'file-upload'],
    },
    {
      name: 'validate',
      text: 'Validate',
      buttonSize: EResponsiveButtonSize.BIG,
      color: EColor.GREEN,
      handler: () => {
        // This is intentional to prevent eslint warning
      },
      icon: ['far', 'check'],
    },
  ]

  return (
    <RawIntlProvider value={intl}>
      <Title type="h1" className="mb-4">
        Long card
      </Title>

      <Title type="h2" className="my-4">
        Component
      </Title>
      <LongCard actions={actions} items={items} title="Title" />
      <Title type="h2" className="my-4">
        Deployable
      </Title>
      <LongCard
        actions={actions}
        isDeployable={true}
        items={items}
        title="Title"
      />
      <LongCard
        actions={actions}
        isColumn={true}
        isDeployable={true}
        items={items}
        title="Title"
      />

      <Title type="h2" className="my-4">
        With status
      </Title>
      <LongCard
        actions={actions}
        items={items}
        status={{
          color: EColor.GREEN,
          icon: ['far', 'check'],
          text: 'Validé',
        }}
        title="Title"
      />

      <Title type="h2" className="my-4">
        With Alert
      </Title>
      <LongCard
        actions={actions}
        alert={{ color: EColor.RED, text: 'Lorem ipsum sit dolor amet' }}
        items={items}
        title="Title"
      />

      <Title type="h2" className="my-4">
        Archived
      </Title>
      <LongCard
        actions={actions}
        isArchived={true}
        items={items}
        title="Title"
      />
      <Title type="h2" className="my-4">
        Archived + deployable
      </Title>
      <LongCard
        actions={actions}
        isArchived={true}
        isDeployable={true}
        items={items}
        title="Title"
      />
    </RawIntlProvider>
  )
}

export default StorybookLongCard
