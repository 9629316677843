import { PaymentAction } from '@adyen/adyen-web'
import { IApiOrganization } from 'api/type'
import { Dinero } from 'dinero.js'
import { ERefusalReason } from 'hooks/useSpecifications/type'
import {
  EPaymentMethods,
  IPageData,
  TApplePayConfiguration,
} from 'pages/Payment/type'
import { Amount } from 'types'
import { ECountry, ELocale } from 'utils/Intl/type'

export type IApiPay = {
  action?: PaymentAction
  redirectUrl?: string
}

export type IApiPaymentParams = {
  organizationId: IApiOrganization['id']
  countryCode: ECountry
  shopperLocale: ELocale
  dinero: Dinero
}

export type IApiPaymentRequest = {
  '@id': string
  '@type': string
  id: string
  paymentUrl: string
  active: boolean
  disabledAt: string
  email: string | null
  person: {
    id: string
    firstname: string | null
    lastname: string | null
  } | null
  amount: Amount
  payments: Array<{
    '@id': string
    id: string
    recordId: string
    paymentRealization: {
      id: string
      reference: string
    }
  }>
  expiredAt: string
  lastPaymentEvent: {
    title: string
    description: string
    type: EPaymentRequestPaymentStatus
  }
}

export interface IApiPaymentRequestDetails extends IApiPaymentRequest {
  '@context': string
  '@id': string
  '@type': string
  createdAt: string
  createdBy: {
    id: string
    isDeletable: boolean
    person: {
      id: string
      firstname: string
      lastname: string
    }
  }
  description: string
  disabledBy: {
    id: string
    isDeletable: boolean
    person: {
      id: string
      firstname: string
      lastname: string
    }
  }
  paymentStatus: EPaymentRequestPaymentStatus
}

export enum EPaymentRequestPaymentStatus {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export type IApiPaymentRequestCreateMultiple = {
  paymentRequests: Array<{
    money: Dinero
    email?: string
    contact?: string
  }>
  description?: string
}

export interface IApiPaymentHistory {
  events: Array<{
    eventDate: string
    title: string
    type: EPaymentHistoryType
    description: string
  }>
}

enum EPaymentHistoryType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export type IApiPaymentMethods = {
  brands?: Array<string>
  configuration?: TApplePayConfiguration
  details?: Array<{ key: string; type: string; optional?: boolean }>
  name: string
  type: string
}

export interface IApiPayload
  extends Omit<
    IPageData,
    | 'publicContext'
    | 'paymentMethods'
    | 'organization'
    | 'payableEntityIdentifier'
    | 'price'
    | 'retributionMethod'
    | 'retryCount'
  > {
  paymentPageConfigurationId: string
  tips?: Amount
  paymentMethod?: EPaymentMethods
  paymentData?: '' | { [key: string]: string | undefined }
  paymentAdditionalData?: { [key: string]: string | undefined }
}

export interface IApiPaymentRequestRefusalReason {
  refusalReason: null | ERefusalReason
  is3DSRefusalReason: boolean
}

export enum ERetributionType {
  FEES = 'FEES',
  TIPS = 'TIPS',
}
