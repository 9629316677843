import { Input } from 'components'
import { FormikValues } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'

import * as Type from './type'

const BankAccountAba: FC<Type.IBankAccountAba> = ({
  disabled = false,
  required = false,
  name,
  ...props
}) => {
  const intl = createIntl('components_bankaccount')
  const translation = useTranslation(intl)

  const validateBranchCode = (value: FormikValues): undefined | string =>
    value.length && !branchCodeIsValid(String(value))
      ? translation.translate('branchCode.validate.incorrect')
      : ''

  return (
    <div {...props}>
      <Input
        disabled={disabled}
        name={`${name}_branchCode`}
        label={translation.translate('branchCode.label')}
        placeholder={translation.translate('branchCode.placeholder')}
        required={required}
        validate={validateBranchCode}
      />
      <Input
        disabled={disabled}
        name={`${name}_accountNumber`}
        label={translation.translate('accountNumber.label')}
        placeholder={translation.translate('accountNumber.placeholder')}
        required={required}
      />
    </div>
  )
}

/**
 * ABA Routing Numbers validator
 * http://www.brainjar.com/js/validation/
 */
const branchCodeIsValid = (branchCode: string): boolean => {
  let n = 0
  for (let i = 0; i < branchCode.length; i += 3) {
    n +=
      parseInt(branchCode.charAt(i), 10) * 3 +
      parseInt(branchCode.charAt(i + 1), 10) * 7 +
      parseInt(branchCode.charAt(i + 2), 10)
  }
  return n !== 0 && n % 10 === 0
}

export default BankAccountAba
