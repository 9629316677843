import { getFeatureFlags, getOrganizationEmailCampaigns } from 'api'
import {
  EAPIPspLifecycleStatus,
  IApiOrganizationPspWallet,
  IPerson,
} from 'api/type'
import { ESetting, TGetSetting } from 'hooks/useSettings/type'
import { IOrganization } from 'providers/Organization/type'
import { ISpecificationStatus } from 'state/type'

import * as Type from './type'
import { ERefusalReason } from './type'

export const getRefusalReason = (status: number) => {
  switch (status) {
    case 403:
      return Type.ERefusalReason.UNAUTHORIZED
    case 404:
      return Type.ERefusalReason.SETTING
    default:
      return Type.ERefusalReason.OTHER
  }
}

const getPspWalletRefusalReason = (
  pspWallet: IApiOrganizationPspWallet
): Type.ERefusalReason | null => {
  if (pspWallet.isBlocked) {
    return ERefusalReason.PSP_WALLET_BLOCKED
  }
  if (pspWallet.isSuspended) {
    return ERefusalReason.PSP_WALLET_SUSPENDED
  }
  return null
}

const getBankAccountsAccess = async (
  organization: IOrganization,
  getSetting: TGetSetting
) => {
  if (organization.isLegalIndependent) {
    return true
  }

  const bankingSettings = await getSetting(ESetting.BANKING_SETTINGS, false, {
    allowedErrorCodes: [403, 404],
  })

  return !!bankingSettings?.ownBankAccounts
}

export const getAccountingSetupSpecification = async (
  getSetting: TGetSetting
): Promise<ISpecificationStatus> => {
  const specification: ISpecificationStatus = {
    isValid: false,
    refusalReason: ERefusalReason.OTHER,
  }
  const accountingSetting = await getSetting(ESetting.ACCOUNTING, false, {
    allowedErrorCodes: [404, 403],
  })

  const isValid = Boolean(
    accountingSetting?.hasOngoingPeriod &&
      accountingSetting.accountingSetUp &&
      !accountingSetting.resetInProgress
  )
  specification.isValid = isValid
  specification.refusalReason = isValid ? null : ERefusalReason.SETTING

  // If the user has no ongoing period
  if (
    !isValid &&
    accountingSetting !== null &&
    accountingSetting.accountingSetUp &&
    !accountingSetting.hasOngoingPeriod
  ) {
    specification.refusalReason = ERefusalReason.ACCOUNTING_PERIOD
  }
  return specification
}

export const getWalletVerifiedSpecification = async (
  getSetting: TGetSetting,
  person: IPerson
): Promise<ISpecificationStatus> => {
  const specification: ISpecificationStatus = {
    isValid: false,
    refusalReason: ERefusalReason.OTHER,
  }
  const pspWallet = await getSetting(ESetting.PSP_WALLET, false, {
    allowedErrorCodes: [404],
  })

  const pspSubWallet = await getSetting(ESetting.PSP_SUB_WALLET, false, {
    allowedErrorCodes: [404],
  })

  if (!pspWallet || !pspSubWallet) {
    return { isValid: false, refusalReason: ERefusalReason.SETTING }
  }

  specification.isValid =
    person.isMasterAdmin ||
    (!pspWallet.isBlocked &&
      !pspWallet.isSuspended &&
      pspSubWallet.main !== null)

  specification.refusalReason = getPspWalletRefusalReason(pspWallet)
  return specification
}

export const getWalletCreatedSpecification = async (
  getSetting: TGetSetting,
  organization: IOrganization,
  person: IPerson
): Promise<ISpecificationStatus> => {
  const specification: ISpecificationStatus = {
    isValid: false,
    refusalReason: ERefusalReason.OTHER,
  }
  const pspWallet = await getSetting(ESetting.PSP_WALLET, false, {
    allowedErrorCodes: [404],
  })

  if (!pspWallet) {
    return { isValid: false, refusalReason: ERefusalReason.SETTING }
  }

  const hasBankAccountsAccess = await getBankAccountsAccess(
    organization,
    getSetting
  )

  const isValid =
    person.isMasterAdmin ||
    (!pspWallet.isSuspended &&
      hasBankAccountsAccess &&
      [EAPIPspLifecycleStatus.PENDING, EAPIPspLifecycleStatus.VALID].includes(
        pspWallet.pspLifecycleStatus
      ))

  specification.isValid = isValid
  specification.refusalReason = isValid ? null : ERefusalReason.SETTING

  return specification
}

export const getEmailingHasCampaignsSpecification = async (
  organization: IOrganization
): Promise<ISpecificationStatus> => {
  const specification: ISpecificationStatus = {
    isValid: false,
    refusalReason: ERefusalReason.OTHER,
  }
  const campaigns = await getOrganizationEmailCampaigns(organization.id, {
    page: 1,
    itemsPerPage: 1,
  })
  const isValid = campaigns.totalItems > 0

  specification.isValid = isValid
  specification.refusalReason = isValid
    ? null
    : ERefusalReason.EMAILING_HAS_NO_CAMPAIGNS
  return specification
}

export const getHasAccessToBulkImport = async (
  organization: IOrganization,
  person: IPerson
): Promise<ISpecificationStatus> => {
  const specification: ISpecificationStatus = {
    isValid: false,
    refusalReason: ERefusalReason.OTHER,
  }

  try {
    const featureFlags = await getFeatureFlags(organization.id)
    const organizationHasAccessToBulkImport =
      organization.isNonprofitMainGroup &&
      featureFlags.data.some((featureFlag) =>
        featureFlag.feature.includes('accounting_bulk_import')
      )

    specification.isValid =
      organizationHasAccessToBulkImport ||
      // temporary access for a client before feature flag deletion
      person.contactId === '01JCMZYV13W4P7TMSVXQAWJNND' ||
      person.isMasterAdmin
  } catch (error: unknown) {
    specification.refusalReason = ERefusalReason.UNAUTHORIZED
  }

  return specification
}
