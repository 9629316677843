import { Background, Button, ListItem, Popover, Title } from 'components'
import { FC } from 'react'
import { EColor } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Popover
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>The title and call to action is optional</ListItem>
        <ListItem>The call to action can contain only one button</ListItem>
        <ListItem>The message contains string and components</ListItem>
        <ListItem>
          The popover can be displayed from any components: button, span, div,
          ...
        </ListItem>
        <ListItem>
          The popover display position is automatic or customizable
        </ListItem>
        <ListItem color={EColor.RED}>
          The call to action button contains only string, no icon
        </ListItem>
        <ListItem color={EColor.RED}>
          The call to action color is not customizable
        </ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Button id="test-1" className="mr-2">
      Hover mode
    </Button>
    <Button id="test-2">Click mode</Button>

    <Popover
      delay={250}
      trigger="hover"
      target="test-1"
      placement="bottom"
      title="Lorem ipsum..."
      ctas={{ primary: { href: '#', label: 'Lorem ipsum...' } }}
    >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
      mattis.
    </Popover>
    <Popover
      delay={250}
      trigger="click"
      target="test-2"
      placement="bottom"
      title="Lorem ipsum..."
      ctas={{ primary: { href: '#', label: 'Lorem ipsum...' } }}
    >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
      mattis.
    </Popover>

    <Title type="h2" className="my-4">
      Without title
    </Title>
    <Button id="test-3" className="mr-2">
      Click to open
    </Button>
    <Popover
      delay={250}
      trigger="click"
      target="test-3"
      placement="bottom"
      ctas={{ primary: { href: '#', label: 'Lorem ipsum...' } }}
    >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
      mattis.
    </Popover>

    <Title type="h2" className="my-4">
      Without call to action
    </Title>
    <Button id="test-4" className="mr-2">
      Click to open
    </Button>
    <Popover delay={250} trigger="click" target="test-4" placement="bottom">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
      mattis.
    </Popover>
    <Title type="h2" className="my-4">
      With a secondary call to action
    </Title>
    <Button id="test-5" className="mr-2">
      Click to open
    </Button>
    <Popover
      trigger="click"
      target="test-5"
      placement="bottom"
      ctas={{
        primary: { href: '#', label: 'Lorem ipsum...' },
        secondary: { href: '#', label: 'Lorem' },
      }}
    >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
      mattis.
    </Popover>
  </>
)

export default StorybookPage
