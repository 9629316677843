import { Title } from 'components'
import { useSetPage } from 'hooks'
import { FC } from 'react'
import { EPageId } from 'state/type'

const StorybookPage: FC = () => {
  useSetPage({
    pageId: EPageId.TABS,
    customParameters: {
      tabs: [
        {
          label: 'Foundations home',
          href: '/storybook/foundations/home',
        },
        {
          label: 'Components row',
          href: '/storybook/components/row',
        },
        {
          label: 'Disabled tab',
          href: '/',
          disabled: true,
        },
      ],
    },
  })

  return (
    <>
      <Title type="h1" className="mb-4">
        Tabs
      </Title>
      <p>Checkout tabs above</p>
    </>
  )
}

export default StorybookPage
