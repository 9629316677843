import * as Sentry from '@sentry/react'
import App from 'App'
import { createBrowserHistory } from 'history'
import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router'
import Cookies from 'universal-cookie'

// Sentry
export const browserHistory = createBrowserHistory()

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_RATE),
  environment: process.env.REACT_APP_SENTRY_ENV,
  ignoreErrors: [
    // Email link Microsoft Outlook crawler compatibility error
    // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
    '_AutofillCallbackHandler',
  ],
})
const cookies = new Cookies()
Sentry.setContext('Cookies', cookies.getAll())

// After
const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
root.render(<App />)
