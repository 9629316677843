import { getOrganizationCollectPages, setCollectDuplicate } from 'api'
import {
  EApiCrmPersonRole,
  EApp,
  ECollectStatus,
  ECollectType,
  IApiNonprofit,
  ICollect,
  ICollectApiDetails,
  ICollectDetails,
  ICollectsListDetails,
  IDuplicateParams,
} from 'api/type'
import { Button, EmptySpace } from 'components'
import {
  IBlock,
  ICollectBadge,
  IDirectOption,
  IOption,
  IResultCardStatuses,
  IResultsCard,
} from 'components/Results/Card/type'
import { IResultsFilter } from 'components/Results/Filters/type'
import { FormikValues } from 'formik'
import { IUseTranslation } from 'hooks/useTranslation/type'
import { IOrganization } from 'providers/Organization/type'
import { ReactNode } from 'react'
import { Dispatch } from 'redux'
import { addNotification } from 'state'
import { EColor, EOrderBy, EYado } from 'types'
import {
  convertObjectToDinero,
  EBrand,
  formatLocale,
  formatNumber,
  getFormattedAmount,
  iriToId,
} from 'utils'
import { ECaseType, ECountry, ELocale } from 'utils/Intl/type'

import * as Data from './data'
import * as Type from './type'
import { EDateType } from './type'

export const getCollectFilteredEmptySpace = (
  translation: IUseTranslation,
  callback: () => void
): ReactNode => (
  <EmptySpace
    title={translation.translate('emptySearch.title')}
    message={translation.translate('emptySearch.message')}
    yado={EYado.EMPTY_SEARCH}
    button={{
      text: translation.translate('emptySearch.button.resetFilters'),
      onClick: () => callback(),
    }}
  />
)

const getStatusColor = (status: ECollectStatus): EColor => {
  switch (status) {
    case ECollectStatus.PUBLISHED:
      return EColor.GREEN
    case ECollectStatus.DRAFT:
      return EColor.DARK_GREY
    case ECollectStatus.SUSPENDED:
      return EColor.FLORIDA
    case ECollectStatus.DELETED:
      return EColor.RED
    default:
      return EColor.DARK_GREY
  }
}

export const callbackCopyUrl = (
  dispatch: Dispatch,
  translation: IUseTranslation,
  hasBeenCopied: boolean
): void => {
  dispatch(
    addNotification({
      color: hasBeenCopied ? EColor.GREEN : EColor.RED,
      text: translation.translate(
        `notification.copyUrl.${hasBeenCopied ? 'success' : 'failure'}`
      ),
    })
  )
}

const getDateType = (
  start?: string | null,
  end?: string | null
): Type.EDateType => {
  if (start && end) {
    return EDateType.INTERVAL
  }
  return start ? EDateType.START : EDateType.END
}

const getCollectDatesDisplay = (
  start: string | null | undefined,
  end: string | null | undefined,
  locale: ELocale
): { start?: string; end?: string; type: Type.EDateType } => {
  const formattedLocale = formatLocale(locale, ECaseType.KEBAB)

  const dates: { start?: string; end?: string } = {}
  if (start) {
    dates.start = new Date(start).toLocaleString(formattedLocale, {
      dateStyle: 'short',
      timeStyle: 'short',
    })
  }
  if (end) {
    dates.end = new Date(end).toLocaleString(formattedLocale, {
      dateStyle: 'short',
      timeStyle: 'short',
    })
  }

  return { ...dates, type: getDateType(start, end) }
}

export const getCollectCreationLink = (
  organizationId: number,
  collectType: ECollectType,
  isFirstCollectOfType: boolean,
  websitePageId: string
): string => {
  if (isFirstCollectOfType) {
    return `/organization/${organizationId}/collects/${collectType.toLowerCase()}/introduction${
      websitePageId && `?pageId=${websitePageId}`
    }`
  }

  const baseLink = getCollectEditLink(organizationId, collectType)
  return `${baseLink}${websitePageId && `&pageId=${websitePageId}`}`
}

const getCollectEditLink = (
  organizationId: number,
  collectType: ECollectType
): string => {
  switch (collectType) {
    case ECollectType.DONATION:
      return `/collect/edit-donation?vendorId=${organizationId}`
    case ECollectType.EVENT:
      return `/collect/edit-ticketing?vendorId=${organizationId}`
    case ECollectType.MEMBERSHIP:
      return `/collect/edit-membership?vendorId=${organizationId}`
    case ECollectType.PRODUCT:
      return `/collect/edit-product?vendorId=${organizationId}`
    default:
      return ''
  }
}

export const getCollectNoItemsEmptySpace = (
  href: string,
  text: string,
  title: string,
  addArchivedStatus: () => void,
  includeArchivedText: string
): ReactNode => (
  <>
    <EmptySpace
      button={{
        href,
        text,
      }}
      title={title}
      yado={EYado.EMPTY_SEARCH}
    />
    <div className="text-center mt-3">
      <Button color={EColor.TRANSPARENT} onClick={() => addArchivedStatus()}>
        {includeArchivedText}
      </Button>
    </div>
  </>
)

const getWebsiteCollectPages = async (
  organizationId: IOrganization['id'],
  translation: IUseTranslation
): Promise<Array<{ label: string; value: string }>> => {
  const collectPages = await getOrganizationCollectPages(organizationId)
  const options = collectPages.map((option) => ({
    label: option.pageName,
    value: option.pageId.toString(),
  }))

  options.push({
    label: translation.translate(
      'form.websitePageId.options.ONLY_BY_LINK.label'
    ),
    value: 'ONLY_BY_LINK',
  })

  return options
}

const getCollectStatusOptions = (
  translation: IUseTranslation
): Array<{
  label: string
  value: string
}> =>
  Object.values(ECollectStatus).map((status) => ({
    label: translation.translate(`form.status.${status}.label`),
    value: status,
  }))

const getCollectDefaultStatusValue = (): Array<ECollectStatus> =>
  Object.values(ECollectStatus).filter(
    (option) => option !== ECollectStatus.DELETED
  )

export const getCollectFilters = async (
  organizationId: IOrganization['id'],
  translation: IUseTranslation,
  specificFilters: Array<string>,
  collectType: ECollectType,
  pageIdParam?: string | null
): Promise<IResultsFilter> => {
  const filters: IResultsFilter = [
    {
      name: 'name',
      type: 'main-search',
      label: translation.translate(`form.name.label.${collectType}`),
      placeholder: translation.translate('form.name.placeholder', {
        formType: translation.translate(`formType.${collectType}`),
      }),
    },
    {
      name: 'status',
      type: 'select',
      label: translation.translate('form.status.label'),
      placeholder: translation.translate('form.status.placeholder'),
      options: getCollectStatusOptions(translation),
      isMulti: true,
      value: getCollectDefaultStatusValue(),
    },
    {
      name: 'createdAt',
      type: 'date-range',
      label: translation.translate('form.createdAt.label'),
    },
  ]

  if (specificFilters.includes('websitePageId')) {
    const value = pageIdParam || ''

    filters.splice(2, 0, {
      name: 'websitePageId',
      type: 'select',
      label: translation.translate(`form.websitePageId.label.${collectType}`),
      placeholder: translation.translate('form.websitePageId.placeholder'),
      options: await getWebsiteCollectPages(organizationId, translation),
      value,
    })
  }

  if (specificFilters.includes('contactType')) {
    filters.push({
      name: 'contactType',
      type: 'select',
      label: translation.translate('form.contactType.label'),
      options: [
        {
          label: translation.translate('form.contactType.options.PERSON.label'),
          value: 'PERSON',
        },
        {
          label: translation.translate(
            'form.contactType.options.STRUCTURE.label'
          ),
          value: 'STRUCTURE',
        },
      ],
    })
  } else {
    filters.push({
      name: 'contactType',
      type: 'hidden',
      value: 'PERSON',
    })
  }

  return filters
}

export const getAccessRules = (
  collectType: ECollectType,
  roles: Array<EApiCrmPersonRole>
): boolean => {
  switch (collectType) {
    case ECollectType.DONATION:
      return roles.includes(EApiCrmPersonRole.DONATION_WRITE)
    case ECollectType.EVENT:
      return roles.includes(EApiCrmPersonRole.DEAL_WRITE)
    case ECollectType.MEMBERSHIP:
      return roles.includes(EApiCrmPersonRole.MEMBERSHIP_WRITE)
    case ECollectType.PRODUCT:
      return roles.includes(EApiCrmPersonRole.DEAL_WRITE)
    default:
      return false
  }
}

export const getDefaultFilters = (
  collectType: ECollectType,
  isStructureEnabled: boolean
): Array<string> => {
  const filters = ['status']
  if (collectType !== ECollectType.MEMBERSHIP || !isStructureEnabled) {
    filters.push('contactType')
  }

  return filters
}

export const getNonprofitTaxReceiptsPageUrl = (
  nonProfitCountry: IApiNonprofit['country'],
  organizationId: number
): string =>
  nonProfitCountry === ECountry.US
    ? `/organization/${organizationId}/tax-receipts`
    : `/organization/billing/${organizationId}`

export const duplicateCollect = async (
  collectId: string,
  params: IDuplicateParams = {}
): Promise<string> => {
  const newCollect = await setCollectDuplicate(collectId, params)
  return newCollect.collectId
}

export const getSpecificFilters = (
  collectType: ECollectType,
  isApplicationEnabled: (application: EApp) => boolean
) => {
  const specificFilters: Array<string> = []
  if (
    collectType === ECollectType.MEMBERSHIP &&
    isApplicationEnabled(EApp.STRUCTURE)
  ) {
    specificFilters.push('contactType')
  }
  if (isApplicationEnabled(EApp.SITE)) {
    specificFilters.push('websitePageId')
  }
  return specificFilters
}

const getCollectDatesBlock = (
  collect: ICollect,
  locale: ELocale,
  translation: IUseTranslation
) => {
  if (!collect.bookingStartsAt && !collect.bookingEndsAt) {
    return []
  }
  const blocks: Array<Array<IBlock>> = []

  const collectDates = getCollectDatesDisplay(
    collect.bookingStartsAt,
    collect.bookingEndsAt,
    locale
  )
  const dateBlock: { [key: string]: IBlock } = {}
  if (collect.type === ECollectType.EVENT) {
    dateBlock.title = {
      blockTitle: translation.translate('block.title.registration'),
    }
  }

  dateBlock.dates = {
    content: translation.translate(`date.display.${collectDates.type}`, {
      start: collectDates.start,
      end: collectDates.end,
      span: (string: string) => <span className="text-nowrap">{string}</span>,
    }),
    icon: {
      color: EColor.DARK_GREY,
      icon: ['fas', 'calendar-day'],
    },
  }

  blocks.push([...Object.values(dateBlock)])

  return blocks
}

const getEventDates = (
  eventDates: { [key: string]: string },
  translation: IUseTranslation
) => {
  if (!eventDates.start || !eventDates.end) {
    return {}
  }

  const dates: { [key: string]: IBlock } = {}

  if (eventDates.start) {
    dates.start = {
      content: translation.translate('order.display.start', {
        date: eventDates.start,
        span: (string: string) => <span className="text-nowrap">{string}</span>,
      }),
      icon: {
        color: EColor.DARK_GREY,
        icon: ['far', 'clock'],
      },
    }
  }

  if (eventDates.end) {
    dates.end = {
      content: translation.translate('order.display.end', {
        date: eventDates.end,
        span: (string: string) => <span className="text-nowrap">{string}</span>,
      }),
      icon: {
        color: EColor.DARK_GREY,
        icon: ['fas', 'clock'],
      },
    }
  }

  return dates
}

export const getCollectDirectOptions = (
  collect: ICollect,
  translation: IUseTranslation
) => {
  const directOptions: Array<IDirectOption> = []
  if (collect.availableOnline && collect.status === ECollectStatus.PUBLISHED) {
    directOptions.push({
      href: collect.publicUrl,
      icon: ['fas', 'eye'],
      text: translation.translate('options.publicUrl.text'),
      target: '_blank',
    })
  }

  if (collect.status === ECollectStatus.DRAFT) {
    directOptions.push({
      icon: ['fas', 'pen'],
      text: translation.translate('options.edit.text'),
      href: `/collect/edit/${iriToId(collect['@id'])}`,
    })
  }

  directOptions.push({
    icon: ['fas', 'user-friends'],
    text: translation.translate(`options.registrants.${collect.type}.text`),
    href: `/collect/registrants/${iriToId(collect['@id'])}`,
  })

  return directOptions
}

export const getVideoId = (
  brand: EBrand,
  lifecycle: Type.ELifeCycleType,
  collectType: ECollectType
) => {
  if (brand === EBrand.SPRINGLY) {
    return null
  }

  switch (collectType) {
    case ECollectType.MEMBERSHIP:
      return lifecycle === Type.ELifeCycleType.INTRODUCTION
        ? 'mu5s8adtmi'
        : 'c49v8npec2'
    case ECollectType.DONATION:
      return lifecycle === Type.ELifeCycleType.INTRODUCTION
        ? 'kinivn2ztw'
        : 'xhcrsida2x'
    case ECollectType.EVENT:
      return lifecycle === Type.ELifeCycleType.INTRODUCTION
        ? '7a40kne235'
        : 'ckt1somvoq'
    case ECollectType.PRODUCT:
      return lifecycle === Type.ELifeCycleType.INTRODUCTION
        ? 'v2ehi8hi51'
        : '0fkwunrcd4'
    default:
      return null
  }
}

const getCollectBadges = (
  collect: ICollect,
  details: undefined | ICollectDetails,
  locale: ELocale,
  translation: IUseTranslation
) => {
  const badges: Array<ICollectBadge> = []
  badges.unshift({
    icon: Data.getCollectSalesCountIcon(collect.type),
    isLoading: details === undefined,
    text:
      details !== undefined && details.nbSold !== null
        ? translation.translate(`badge.salesCount.${collect.type}`, {
            value: formatNumber(details.nbSold, locale),
          })
        : translation.translate('NC'),
    tooltip:
      details !== undefined && details.nbSold !== null
        ? translation.translate(`badge.salesCount.${collect.type}.tooltip`, {
            value: details.nbSold,
          })
        : translation.translate('NC.nbSold.tooltip', {
            type: translation.translate(`NC.collect.${collect.type}`),
          }),
  })

  badges.push({
    icon: ['fas', 'coins'],
    isLoading: details === undefined,
    text: translation.translate(`badge.amountCommitted.${collect.type}`, {
      value:
        details !== undefined &&
        details.amountCommitted !== undefined &&
        details.amountCommitted !== null
          ? getFormattedAmount(convertObjectToDinero(details.amountCommitted))
          : translation.translate('NC'),
    }),
    tooltip:
      details !== undefined
        ? translation.translate(`badge.amountCommitted.${collect.type}.tooltip`)
        : translation.translate('NC.amountCommitted.tooltip'),
  })

  return badges
}

export const getCollectDisplay = (
  collect: ICollect,
  details: undefined | ICollectsListDetails,
  locale: ELocale,
  updateValue: (
    name: ICollect['status'] | ICollect['contactType'],
    value: string | FormikValues
  ) => void,
  isApplicationEnabled: (application: EApp) => boolean,
  translation: IUseTranslation,
  options: {
    directOptions: Array<IDirectOption>
    menuOptions: Array<IOption>
  }
): IResultsCard => {
  const blocks = getCollectDatesBlock(collect, locale, translation)

  if (
    collect.type === ECollectType.EVENT &&
    (collect.eventStartsAt || collect.eventEndsAt)
  ) {
    const eventDates = getCollectDatesDisplay(
      collect.eventStartsAt,
      collect.eventEndsAt,
      locale
    )

    const eventDatesBlock = getEventDates(eventDates, translation)
    if (Object.values(eventDatesBlock).length) {
      blocks.unshift(Object.values(eventDatesBlock))
    }
  }

  const badges: Array<ICollectBadge> = getCollectBadges(
    collect,
    details && details[collect.id],
    locale,
    translation
  )

  const statuses: IResultCardStatuses = [
    {
      text: translation.translate(`status.${collect.status}`),
      icon: {
        icon: ['fas', 'circle'],
        color: getStatusColor(collect.status),
      },
      onClick: () =>
        updateValue('status' as ICollect['status'], [collect.status]),
    },
  ]

  if (
    isApplicationEnabled(EApp.STRUCTURE) &&
    collect.type === ECollectType.MEMBERSHIP
  ) {
    statuses.push({
      text: translation.translate(`status.contactType.${collect.contactType}`),
      onClick: () =>
        updateValue(
          'contactType' as ICollect['contactType'],
          collect.contactType
        ),
    })
  }

  return {
    badges,
    blocks,
    image: collect.thumbUrl || '',
    directOptions: options.directOptions,
    menuOptions: options.menuOptions,
    optionsTooltip: translation.translate('options.tooltip.text'),
    statuses,
    title: { text: collect.name },
  }
}

export const getCollectSortOptions = (
  translation: IUseTranslation,
  collectType: ECollectType
) => {
  const sortOptions: Array<{ label: string; value: string }> = [
    {
      label: translation.translate(
        `sortOption.option.${Type.ESortingOptions.NONE}`
      ),
      value: Type.ESortingOptions.NONE,
    },
  ]
  const sortItems = [Type.ESortingOptions.CREATED_AT]
  if (collectType === ECollectType.EVENT) {
    sortItems.push(Type.ESortingOptions.STARTS_AT)
  }

  for (const item of sortItems) {
    for (const order of [EOrderBy.ASC, EOrderBy.DESC]) {
      sortOptions.push({
        label: translation.translate(`sortOption.option.${item}.${order}`),
        value: `${item}.${order}`,
      })
    }
  }

  return sortOptions
}

export const getCollectType = (type?: string) => {
  const typeParam = type?.toUpperCase() as ECollectType
  const collectTypes = Object.values(ECollectType)
  if (!collectTypes.includes(typeParam)) {
    return collectTypes[1]
  }
  return typeParam
}

export const getCollectDetailsFormatted = (
  collectDetails: Array<ICollectApiDetails>
): ICollectsListDetails => {
  const details: ICollectsListDetails = {}
  for (const detail of collectDetails) {
    details[detail.id] = {
      amountCommitted: detail.amountCommitted,
      nbSold: detail.nbSold,
    }
  }
  return details
}
