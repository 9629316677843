import { useAscRouter, useSetPage } from 'hooks'
import StorybookComponentsAlert from 'pages/Storybook/Components/Alert'
import StorybookComponentsBackground from 'pages/Storybook/Components/Background'
import StorybookComponentsBadge from 'pages/Storybook/Components/Badge'
import StorybookComponentsButton from 'pages/Storybook/Components/Button'
import StorybookComponentsButtonCard from 'pages/Storybook/Components/ButtonCard'
import StorybookComponentsCard from 'pages/Storybook/Components/Card'
import StorybookComponentsDropdown from 'pages/Storybook/Components/Dropdown'
import StorybookComponentsEmptySpace from 'pages/Storybook/Components/EmptySpace'
import StorybookComponentsHTMLContent from 'pages/Storybook/Components/HTMLContent'
import StorybookComponentsHelper from 'pages/Storybook/Components/Helper'
import StorybookComponentsList from 'pages/Storybook/Components/List'
import StorybookComponentsLoader from 'pages/Storybook/Components/Loader'
import StorybookComponentsLongCard from 'pages/Storybook/Components/LongCard'
import StorybookComponentsModalAlert from 'pages/Storybook/Components/Modal/ModalAlert'
import StorybookComponentsModalBlank from 'pages/Storybook/Components/Modal/ModalBlank'
import StorybookComponentsModalConfirm from 'pages/Storybook/Components/Modal/ModalConfirm'
import StorybookComponentsModalProvider from 'pages/Storybook/Components/Modal/ModalProvider'
import StorybookComponentsModalSubmitKeywords from 'pages/Storybook/Components/Modal/ModalSubmitKeywords'
import StorybookComponentsNotifications from 'pages/Storybook/Components/Notifications'
import StorybookComponentsPopover from 'pages/Storybook/Components/Popover'
import StorybookComponentsProgress from 'pages/Storybook/Components/Progress'
import StorybookComponentsResultCard from 'pages/Storybook/Components/ResultCard'
import StorybookComponentsRow from 'pages/Storybook/Components/Row'
import StorybookComponentsSetting from 'pages/Storybook/Components/Setting'
import StorybookComponentsSidePanel from 'pages/Storybook/Components/SidePanel'
import StorybookComponentsSplitButton from 'pages/Storybook/Components/SplitButton'
import StorybookComponentsTable from 'pages/Storybook/Components/Table'
import StorybookComponentsTooltip from 'pages/Storybook/Components/Tooltip'
import StorybookComponentsToucan from 'pages/Storybook/Components/Toucan'
import StorybookFormAbsoluteDate from 'pages/Storybook/Form/AbsoluteDate'
import StorybookFormAbsoluteDateRange from 'pages/Storybook/Form/AbsoluteDateRange'
import StorybookFormAddress from 'pages/Storybook/Form/Address'
import StorybookFormAmericanEin from 'pages/Storybook/Form/AmericanIEN'
import StorybookFormAutocomplete from 'pages/Storybook/Form/Autocomplete'
import StorybookFormBankAccount from 'pages/Storybook/Form/BankAccount'
import StorybookFormCard from 'pages/Storybook/Form/Card'
import StorybookFormCheckNumber from 'pages/Storybook/Form/CheckNumber'
import StorybookFormCheckbox from 'pages/Storybook/Form/Checkbox'
import StorybookFormEmail from 'pages/Storybook/Form/Email'
import StorybookFormFile from 'pages/Storybook/Form/File'
import StorybookFormFrenchRna from 'pages/Storybook/Form/FrenchRna'
import StorybookFormFrenchSiren from 'pages/Storybook/Form/FrenchSiren'
import StorybookFormFrenchSiret from 'pages/Storybook/Form/FrenchSiret'
import StorybookFormInput from 'pages/Storybook/Form/Input'
import StorybookFormMoney from 'pages/Storybook/Form/Money'
import StorybookFormNumeric from 'pages/Storybook/Form/Numeric'
import StorybookFormPhone from 'pages/Storybook/Form/Phone'
import StorybookFormRadio from 'pages/Storybook/Form/Radio'
import StorybookFormRadioBackgrounds from 'pages/Storybook/Form/RadioBackgrounds'
import StorybookFormRadioButtons from 'pages/Storybook/Form/RadioButtons'
import StorybookFormRlmc from 'pages/Storybook/Form/Rlmc'
import StorybookFormSelect from 'pages/Storybook/Form/Select'
import StorybookFormSelectBankAccounts from 'pages/Storybook/Form/SelectBankAccounts'
import StorybookFormSocialSecurityNumber from 'pages/Storybook/Form/SocialSecurityNumberLast4Digits'
import StorybookFormSwitch from 'pages/Storybook/Form/Switch'
import StorybookFormTimezone from 'pages/Storybook/Form/Timezone'
import StorybookFormUrl from 'pages/Storybook/Form/Url'
import StorybookFoundationsColors from 'pages/Storybook/Foundations/Colors'
import StorybookFoundationsHome from 'pages/Storybook/Foundations/Home'
import StorybookFoundationsIcons from 'pages/Storybook/Foundations/Icons'
import StorybookFoundationsTabs from 'pages/Storybook/Foundations/Tabs'
import StorybookFoundationsTypography from 'pages/Storybook/Foundations/Typography'
import StorybookFoundationsYado from 'pages/Storybook/Foundations/Yado'
import StorybookComponentsPublic from 'pages/Storybook/PublicComponents'
import { FC } from 'react'
import { Route, Routes } from 'react-router'
import { EPageId } from 'state/type'

import { ERouterType } from './type'

const StorybookRouter: FC = () => {
  const { RouteLayout } = useAscRouter(ERouterType.STORYBOOK)
  useSetPage({
    pageId: EPageId.STORYBOOK,
  })
  return (
    <Routes>
      <Route
        path="/foundations/home"
        element={<RouteLayout component={StorybookFoundationsHome} />}
      />
      <Route
        path="/foundations/colors"
        element={<RouteLayout component={StorybookFoundationsColors} />}
      />
      <Route
        path="/foundations/icons"
        element={<RouteLayout component={StorybookFoundationsIcons} />}
      />
      <Route
        path="/foundations/tabs"
        element={<RouteLayout component={StorybookFoundationsTabs} />}
      />
      <Route
        path="/foundations/typography"
        element={<RouteLayout component={StorybookFoundationsTypography} />}
      />
      <Route
        path="/foundations/yado"
        element={<RouteLayout component={StorybookFoundationsYado} />}
      />

      <Route
        path="/components/alert"
        element={<RouteLayout component={StorybookComponentsAlert} />}
      />
      <Route
        path="/components/background"
        element={<RouteLayout component={StorybookComponentsBackground} />}
      />
      <Route
        path="/components/badge"
        element={<RouteLayout component={StorybookComponentsBadge} />}
      />
      <Route
        path="/components/button"
        element={<RouteLayout component={StorybookComponentsButton} />}
      />
      <Route
        path="/components/button-dropdown"
        element={<RouteLayout component={StorybookComponentsSplitButton} />}
      />
      <Route
        path="/components/button-card"
        element={<RouteLayout component={StorybookComponentsButtonCard} />}
      />
      <Route
        path="/components/card"
        element={<RouteLayout component={StorybookComponentsCard} />}
      />

      <Route
        path="/components/modal/modal-alert"
        element={<RouteLayout component={StorybookComponentsModalAlert} />}
      />
      <Route
        path="/components/modal/modal-blank"
        element={<RouteLayout component={StorybookComponentsModalBlank} />}
      />
      <Route
        path="/components/modal/modal-confirm"
        element={<RouteLayout component={StorybookComponentsModalConfirm} />}
      />
      <Route
        path="/components/modal/modal-submit-keywords"
        element={
          <RouteLayout component={StorybookComponentsModalSubmitKeywords} />
        }
      />
      <Route
        path="/components/modal/modal-provider"
        element={<RouteLayout component={StorybookComponentsModalProvider} />}
      />

      <Route
        path="/components/dropdown"
        element={<RouteLayout component={StorybookComponentsDropdown} />}
      />
      <Route
        path="/components/empty-space"
        element={<RouteLayout component={StorybookComponentsEmptySpace} />}
      />
      <Route
        path="/components/helper"
        element={<RouteLayout component={StorybookComponentsHelper} />}
      />
      <Route
        path="/components/html-content"
        element={<RouteLayout component={StorybookComponentsHTMLContent} />}
      />
      <Route
        path="/components/list"
        element={<RouteLayout component={StorybookComponentsList} />}
      />
      <Route
        path="/components/loader"
        element={<RouteLayout component={StorybookComponentsLoader} />}
      />
      <Route
        path="/components/long-card"
        element={<RouteLayout component={StorybookComponentsLongCard} />}
      />
      <Route
        path="/components/notifications"
        element={<RouteLayout component={StorybookComponentsNotifications} />}
      />
      <Route
        path="/components/popover"
        element={<RouteLayout component={StorybookComponentsPopover} />}
      />
      <Route
        path="/components/progress"
        element={<RouteLayout component={StorybookComponentsProgress} />}
      />
      <Route
        path="/public/components"
        element={<RouteLayout component={StorybookComponentsPublic} />}
      />
      <Route
        path="/components/result-card"
        element={<RouteLayout component={StorybookComponentsResultCard} />}
      />
      <Route
        path="/components/side-panel"
        element={<RouteLayout component={StorybookComponentsSidePanel} />}
      />
      <Route
        path="/components/row"
        element={<RouteLayout component={StorybookComponentsRow} />}
      />
      <Route
        path="/components/setting"
        element={<RouteLayout component={StorybookComponentsSetting} />}
      />
      <Route
        path="/components/table"
        element={<RouteLayout component={StorybookComponentsTable} />}
      />
      <Route
        path="/components/tooltip"
        element={<RouteLayout component={StorybookComponentsTooltip} />}
      />

      <Route
        path="/form/address"
        element={<RouteLayout component={StorybookFormAddress} />}
      />
      <Route
        path="/form/american-ein"
        element={<RouteLayout component={StorybookFormAmericanEin} />}
      />
      <Route
        path="/form/autocomplete"
        element={<RouteLayout component={StorybookFormAutocomplete} />}
      />
      <Route
        path="/form/bank-account"
        element={<RouteLayout component={StorybookFormBankAccount} />}
      />
      <Route
        path="/form/card"
        element={<RouteLayout component={StorybookFormCard} />}
      />
      <Route
        path="/form/checkbox"
        element={<RouteLayout component={StorybookFormCheckbox} />}
      />
      <Route
        path="/form/check-number"
        element={<RouteLayout component={StorybookFormCheckNumber} />}
      />
      <Route
        path="/form/absolute-date"
        element={<RouteLayout component={StorybookFormAbsoluteDate} />}
      />
      <Route
        path="/form/absolute-date-range"
        element={<RouteLayout component={StorybookFormAbsoluteDateRange} />}
      />
      <Route
        path="/form/email"
        element={<RouteLayout component={StorybookFormEmail} />}
      />
      <Route
        path="/form/file"
        element={<RouteLayout component={StorybookFormFile} />}
      />
      <Route
        path="/form/french-rna"
        element={<RouteLayout component={StorybookFormFrenchRna} />}
      />
      <Route
        path="/form/french-siren"
        element={<RouteLayout component={StorybookFormFrenchSiren} />}
      />
      <Route
        path="/form/french-siret"
        element={<RouteLayout component={StorybookFormFrenchSiret} />}
      />
      <Route
        path="/form/input"
        element={<RouteLayout component={StorybookFormInput} />}
      />
      <Route
        path="/form/money"
        element={<RouteLayout component={StorybookFormMoney} />}
      />
      <Route
        path="/form/numeric"
        element={<RouteLayout component={StorybookFormNumeric} />}
      />
      <Route
        path="/storybook/form/phone"
        element={<RouteLayout component={StorybookFormPhone} />}
      />
      <Route
        path="/form/radio"
        element={<RouteLayout component={StorybookFormRadio} />}
      />
      <Route
        path="/form/radio-backgrounds"
        element={<RouteLayout component={StorybookFormRadioBackgrounds} />}
      />
      <Route
        path="/form/radio-buttons"
        element={<RouteLayout component={StorybookFormRadioButtons} />}
      />
      <Route
        path="/form/rlmc"
        element={<RouteLayout component={StorybookFormRlmc} />}
      />
      <Route
        path="/form/select"
        element={<RouteLayout component={StorybookFormSelect} />}
      />
      <Route
        path="/form/select-bank-accounts"
        element={<RouteLayout component={StorybookFormSelectBankAccounts} />}
      />
      <Route
        path="/form/ssn"
        element={<RouteLayout component={StorybookFormSocialSecurityNumber} />}
      />
      <Route
        path="/form/switch"
        element={<RouteLayout component={StorybookFormSwitch} />}
      />
      <Route
        path="/form/timezone"
        element={<RouteLayout component={StorybookFormTimezone} />}
      />
      <Route
        path="/form/url"
        element={<RouteLayout component={StorybookFormUrl} />}
      />
      <Route
        path="/components/toucan"
        element={<RouteLayout component={StorybookComponentsToucan} />}
      />
    </Routes>
  )
}

export default StorybookRouter
