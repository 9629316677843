import { Button, Col, Row } from 'components'
import { FC } from 'react'
import { EColor } from 'types'

import { IAlertModalConfirmButtonText } from './type'

const AlertModalConfirmButton: FC<IAlertModalConfirmButtonText> = ({
  confirmButtonText,
  handleConfirm,
}) => (
  <Row className="align-items-center justify-content-center">
    <Col xs="auto">
      <Button onClick={handleConfirm} color={EColor.BLUE}>
        {confirmButtonText}
      </Button>
    </Col>
  </Row>
)

export default AlertModalConfirmButton
