import { EApiCrmPersonRole } from 'api/Crm/type'
import classNames from 'classnames'
import { Icon, Menu as MenuParent, Svg } from 'components'
import MenuItemHelp from 'components/Menu/Item/Help'
import {
  createIntl,
  useMenu,
  usePerson,
  useSelector,
  useTranslation,
} from 'hooks'
import { ISettingsMenuItem } from 'hooks/useMenuSettings/type'
import { IMenuItem } from 'layouts/Admin/components/Menu/type'
import { useOrganization } from 'providers'
import { FC } from 'react'
import { RawIntlProvider } from 'react-intl'
import { useDispatch } from 'react-redux'
import { selectPerson, setSidePanel } from 'state'
import { EColor } from 'types'

import * as Style from './style'

const Menu: FC<{
  items: { [key: string]: ISettingsMenuItem }
}> = ({ items }) => {
  const intl = createIntl('layouts_settings_menu')
  const translation = useTranslation(intl)
  const menu = useMenu()
  const { organization } = useOrganization()
  const { hasRole } = usePerson()
  const dispatch = useDispatch()
  const person = useSelector(selectPerson)

  return (
    <MenuParent menu={menu} color={EColor.LIGHT_BLUE} border={EColor.BLUE}>
      <RawIntlProvider value={intl}>
        <Style.MenuWrapper>
          <Style.BackButton
            href={`/admin/${organization.idOld}`}
            className="d-flex flex-column"
          >
            <div className="d-flex">
              <div className="mr-2">
                <Icon icon={['fas', 'home']} />
              </div>
              {translation.translate('backButton')}
            </div>
          </Style.BackButton>
          <div id="menu__items">
            {Object.entries(items).map(
              ([itemId, { children: itemChildren, icon: itemIcon }]: [
                string,
                IMenuItem
              ]) => (
                <div key={itemId}>
                  {!!Object.values(itemChildren).length && (
                    <div className="pb-4">
                      <Style.MenuLabel className="d-flex align-items-center">
                        <Style.MenuIcon
                          src={`common/icon/unicolor/${itemIcon}`}
                          color={EColor.BLUE}
                          className="mr-1 flex-shrink-0"
                        />
                        <span className="mt-1">
                          {translation.translate(`item.${itemId}.label`)}
                        </span>
                      </Style.MenuLabel>
                      <Style.MenuItemsList>
                        {Object.entries(itemChildren).map((item) => (
                          <Style.MenuItem
                            key={item[0]}
                            className={classNames('d-flex', {
                              active:
                                item[1].href === window.location.pathname ||
                                window.location.pathname.includes(item[0]),
                            })}
                          >
                            <Style.MenuLink href={item[1].href}>
                              {translation.translate(
                                `itemChild.${item[0]}.label`
                              )}
                            </Style.MenuLink>
                          </Style.MenuItem>
                        ))}
                      </Style.MenuItemsList>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
          {organization.isAdvanced &&
            hasRole(EApiCrmPersonRole.GENERAL_ADMIN) && (
              <Style.ApplicationsLink
                href={`/organization/${organization.idOld}/applications`}
              >
                <Svg
                  src="common/icon/unicolor/plus-circle"
                  width="18px"
                  className="mr-1 mb-1 flex-shrink-0"
                />
                {translation.translate('applicationsLink.label')}
              </Style.ApplicationsLink>
            )}

          <MenuItemHelp
            onClick={() => {
              dispatch(
                setSidePanel({
                  actions: {},
                  options: {},
                  isOpen: true,
                  template: 'HelpPanel',
                  title: translation.translate('sidePanel.title', {
                    firstname: person.firstName,
                  }),
                  subtitle: translation.translate('sidePanel.subtitle'),
                })
              )
            }}
            origin="settings"
            color={EColor.BLACK}
          />
        </Style.MenuWrapper>
      </RawIntlProvider>
    </MenuParent>
  )
}

export default Menu
