import { useHistory } from 'hooks'
import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const getTarget = (target: string | undefined) => {
  if (target === 'blank') {
    return {
      target: 'blank',
      rel: 'noreferrer',
    }
  }
  return {
    target,
  }
}

const Link: FC<Type.ILink> = ({
  children,
  href,
  target,
  preventQueryParameter,
  parameters,
  redirectUrl,
  disabled = false,
  ...rest
}) => {
  const history = useHistory()
  const params = new URLSearchParams(history.location.search)

  // Internal link
  if (href && href.indexOf('/') === 0) {
    const hrefUrl = new URL(href, window.location.origin)
    const hrefParams = new URLSearchParams(hrefUrl.search)

    hrefParams.forEach((value, key) => {
      params.set(key, value)
    })

    if (parameters) {
      Object.entries(parameters).forEach(([key, value]) => {
        params.set(key, value ?? '')
      })
    }

    if (redirectUrl) {
      params.set('redirect', redirectUrl)
    }

    const searchParameters =
      params.toString().length > 0 ? params.toString() : history.location.search

    return (
      <Style.RouterLink
        disabled={disabled}
        {...(!disabled && {
          to: `${href.split('?')[0]}${
            searchParameters ? `?${searchParameters}` : ''
          }`,
        })}
        {...(!disabled && getTarget(target))}
        {...rest}
      >
        {children}
      </Style.RouterLink>
    )
  }
  // External link
  else {
    return (
      <a {...rest} href={href} {...getTarget(target ? target : '_blank')}>
        {children}
      </a>
    )
  }
}

export default Link
