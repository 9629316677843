import { getOrganizationNonprofit } from 'api'
import { IApiNonprofit } from 'api/type'
import { useAsyncData } from 'hooks'
import { NonprofitContext } from 'providers'
import React from 'react'

const NonprofitProvider: React.FC<{
  organizationId: string
  children: React.ReactNode
}> = ({ organizationId, children }) => {
  const nonprofitData = useAsyncData<IApiNonprofit>(async () =>
    getOrganizationNonprofit(organizationId)
  )

  if (nonprofitData.isLoading) {
    return null
  }

  if (nonprofitData.isError) {
    return null
  }

  return (
    <NonprofitContext.Provider
      value={{
        nonprofit: nonprofitData.value,
      }}
    >
      {children}
    </NonprofitContext.Provider>
  )
}

export default NonprofitProvider
