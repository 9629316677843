import {
  Background,
  Col,
  List,
  ListItem,
  ListStats,
  Row,
  Title,
} from 'components'
import { createIntl } from 'hooks'
import { FC } from 'react'
import { RawIntlProvider } from 'react-intl'
import { EColor, EListType } from 'types'

const colors = [
  EColor.GREEN,
  EColor.BLUE,
  EColor.DARK_BLUE,
  EColor.YELLOW,
  EColor.RED,
  EColor.DARK_RED,
]
const translationId = 'list.text'

const StorybookPage: FC = () => {
  const intl = createIntl('pages_storybook')

  return (
    <RawIntlProvider value={intl}>
      <Title type="h1" className="mb-4">
        List
      </Title>

      <Background title="Information">
        <ul>
          <ListItem>The list contains string and components</ListItem>
          <ListItem>
            Two types:
            <ul>
              <ListItem>Bullet</ListItem>
              <ListItem type={EListType.CHECK}>Check</ListItem>
            </ul>
          </ListItem>
        </ul>
      </Background>

      <Title type="h2" className="my-4">
        Bullet list
      </Title>
      <ListItem>Lorem ipsum...</ListItem>

      <Title type="h2" className="my-4">
        Check list
      </Title>
      <ListItem type={EListType.CHECK}>Lorem ipsum...</ListItem>

      <Title type="h2" className="my-4">
        Multi-column
      </Title>
      <List
        cols={4}
        translationIds={[
          translationId,
          translationId,
          translationId,
          translationId,
          translationId,
          translationId,
          translationId,
          translationId,
        ]}
      />

      <Title type="h2" className="my-4">
        Colors
      </Title>
      {colors.map((color, index) => (
        <Row key={index} className="align-items-center mb-1">
          <Col lg={2}>{color}</Col>
          <Col>
            <ListItem color={color}>Lorem ipsum...</ListItem>
          </Col>
          <Col>
            <ListItem type={EListType.CHECK} color={color}>
              Lorem ipsum...
            </ListItem>
          </Col>
        </Row>
      ))}

      <Title type="h2" className="my-4">
        Stats
      </Title>
      <Row>
        <Col md={6}>
          <Background>
            <ListStats
              color={EColor.DARK_GREY}
              total={15}
              items={[
                { id: 'statistic-1', text: 'Statistic 1', share: 10 },
                { id: 'statistic-2', text: 'Statistic 2', share: 3 },
                { id: 'statistic-3', text: 'Statistic 3', share: 2 },
                { id: 'statistic-4', text: 'Statistic 4', share: 1 },
              ]}
            />
          </Background>
        </Col>
        <Col md={6}>
          <Background>
            <ListStats
              color={EColor.RED}
              total={15}
              items={[
                { id: 'statistic-1', text: 'Statistic 1', share: 10 },
                { id: 'statistic-2', text: 'Statistic 2', share: 5 },
                { id: 'statistic-3', text: 'Statistic 3', share: 0 },
              ]}
            />
          </Background>
        </Col>
      </Row>
    </RawIntlProvider>
  )
}

export default StorybookPage
