import { IApiOrganizationSubscription } from 'api/type'
import Dinero, { Currency, Dinero as TDinero } from 'dinero.js'
import { EPaymentMethods } from 'pages/Payment/type'
import { IntlShape } from 'react-intl'

// Get currency symbol, TEMPORARY SOLUTION
export const getCurrencySymbol = (currency: string): string => {
  if (currency === 'EUR') {
    return '€'
  } else if (currency === 'USD') {
    return '$'
  }
  return ''
}

export const convertObjectToDinero = (moneyObject: {
  amount: number | string
  currency: string
}): TDinero =>
  Dinero({
    amount: parseInt(moneyObject.amount.toString()),
    currency: moneyObject.currency as Currency,
  })

export const getFees = (
  intl: IntlShape,
  fees: IApiOrganizationSubscription['fees'] | undefined,
  paymentType = EPaymentMethods.ADYEN_CB_INSTALLMENT
): string => {
  if (fees === undefined) {
    return ''
  }

  const amount = Dinero({
    amount:
      paymentType === EPaymentMethods.ADYEN_SEPA
        ? fees.sepa.base.amount
        : fees.card.base.amount,
    currency:
      paymentType === EPaymentMethods.ADYEN_SEPA
        ? fees.sepa.base.currency
        : fees.card.base.currency,
  })

  const percent = fees.card.percent

  return `
    ${percent} %
    +
    ${getFormattedAmount(amount)}`
}

// $0,0.00 is just a pattern, no need to adapt to local/currency
// https://v1.dinerojs.com/module-dinero#~toFormat
export const getFormattedAmount = (
  amount: TDinero,
  withCurrency = true
): string => amount.toFormat(withCurrency ? '$0,0.00' : '0.00')
