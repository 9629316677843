import { Background, Col, Display, Link, Money, Row, Select } from 'components'
import Dinero from 'dinero.js'
import { FormikValues, useFormikContext } from 'formik'
import { useTranslation } from 'hooks'
import { EPaymentTipValueType } from 'pages/Payment/type'
import {
  getBrandTipsHelpCenterArticle,
  getCentsValueFromFloat,
  getPaymentAmount,
  getSelectTipValues,
  getTipAmountFromPercentage,
  getTipsPlaceHolder,
} from 'pages/Payment/utils'
import { ChangeEvent, FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { EColor } from 'types'
import { getBrandName, getFormattedAmount } from 'utils'

import * as Style from './style'
import * as Type from './type'

const TipsBlock: FC<Type.ITipsBlock> = ({
  tipAmountMethods,
  tips,
  organization,
  totalNoTip,
  installmentPlan,
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)
  const [isCustomTipAmount, setIsCustomTipAmount] = useState(false)
  const { setFieldValue, values } = useFormikContext<FormikValues>()

  const tipZero = Dinero({
    amount: 0,
    currency: totalNoTip.getCurrency(),
  })

  return (
    <div className="m-0 p-0">
      <div className="d-none d-md-block">
        <Row className="pb-0">
          <Col>
            <Display type="h5" className="font-weight-bold mb-0 mt-2">
              {translation.translate('tips.title')}
            </Display>
          </Col>
        </Row>
        <hr />
      </div>
      <Row>
        <Col>
          <span className="font-weight-bold">
            {translation.translate('tips.total.summary.withBrand', {
              organization: organization.name,
            })}
          </span>
        </Col>
        <Col className="text-right" xs="auto">
          <span className="font-weight-bold">
            {getFormattedAmount(totalNoTip)}
          </span>
        </Col>
      </Row>
      <Background className="pb-1 mb-3" color={EColor.LIGHT_BLUE}>
        <Row>
          <Col xs={12}>
            <p>
              <strong>
                {translation.translate(`tips.explanation`, {
                  organization: organization.name,
                })}
              </strong>
            </p>

            {translation.translate(`tips.explanation.content`, {
              brand: getBrandName(organization.brand),
              organization: organization.name,
            })}

            <br />
            <Link
              className="d-inline-block py-2"
              href={getBrandTipsHelpCenterArticle(organization.brand)}
            >
              {translation.translate(`tips.why`, {
                brand: getBrandName(organization.brand),
              })}
            </Link>
          </Col>
          <Col className="pt-3">
            <Select
              name="tip"
              placeholder={getTipsPlaceHolder(tips)}
              options={getSelectTipValues(tips, translation)}
              onChangeCallback={async (value) => {
                if (value !== 'other') {
                  setIsCustomTipAmount(false)
                  const stringValue = String(value)
                  tipAmountMethods.set(
                    Dinero({
                      amount:
                        tips.type === EPaymentTipValueType.PERCENT
                          ? getCentsValueFromFloat(
                              getTipAmountFromPercentage(
                                totalNoTip.getAmount(),
                                parseFloat(stringValue)
                              )
                            )
                          : parseInt(stringValue),
                      currency: totalNoTip.getCurrency(),
                    })
                  )
                } else if (!isCustomTipAmount) {
                  // Prevent calculating customTip when the user has already selected "other"
                  setIsCustomTipAmount(true)
                  const customTip = Dinero({
                    amount:
                      tips.type === EPaymentTipValueType.PERCENT
                        ? getCentsValueFromFloat(
                            getTipAmountFromPercentage(
                              totalNoTip.getAmount(),
                              tips.tips_percent
                            )
                          )
                        : tips.tips.amount,
                    currency: totalNoTip.getCurrency(),
                  })
                  tipAmountMethods.set(customTip)
                  void setFieldValue('tip_custom', customTip)
                }
              }}
            />
            {isCustomTipAmount && (
              <Money
                className="ml-0 mw-100"
                currency={totalNoTip.getCurrency()}
                name="tip_custom"
                onBlur={(event: ChangeEvent<HTMLInputElement>) => {
                  if (!event.target.value.length) {
                    void setFieldValue('tip_custom', tipZero)
                    tipAmountMethods.set(tipZero)
                  }
                }}
                handleChange={(value) => {
                  // Empty or negative tip
                  if (typeof value === 'string' || value.getAmount() < 0) {
                    tipAmountMethods.set(tipZero)
                    // Negative tip
                    if (typeof value !== 'string' && value.getAmount() < 0) {
                      void setFieldValue('tip_custom', tipZero)
                    }
                    return
                  }

                  tipAmountMethods.set(value)
                }}
              />
            )}
          </Col>
        </Row>
      </Background>
      <Row className="d-flex align-items-baseline">
        <Col>
          <Display type="h5">
            {translation.translate('tips.total.summary')}
          </Display>
        </Col>
        <Col className="text-right" xs="auto">
          <Style.Total color={EColor.BLUE} className="font-weight-bold">
            {getFormattedAmount(
              getPaymentAmount(
                values.paymentMethod,
                totalNoTip,
                tipAmountMethods.get(),
                installmentPlan
              )
            )}
          </Style.Total>
        </Col>
      </Row>
    </div>
  )
}

export default TipsBlock
