import {
  EApiKycStatus,
  IApiBankAccount,
  IApiBankAccountWithPspDetails,
} from 'api/type'
import { Badge, FormGroup } from 'components'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'
import { components, SingleValueProps } from 'react-select'
import { ESize } from 'types'

import * as Data from './data'
import * as Style from './style'
import * as Type from './type'

const SelectBankAccounts: FC<Type.ISelectBankAccounts> = ({
  bankAccounts,
  displayKycStatus = false,
  ...rest
}) => {
  const intl = createIntl('components_select_bankaccounts')
  const translation = useTranslation(intl)

  const displaySelectOption = (
    bankAccount: IApiBankAccount | IApiBankAccountWithPspDetails
  ) => {
    const isBankAccountWithPspDetails = 'pspBankAccount' in bankAccount

    return (
      <>
        <div className="d-flex align-items-center">
          {isBankAccountWithPspDetails &&
            displayKycStatus &&
            bankAccount.pspBankAccount?.kycStatus &&
            bankAccount.pspBankAccount.kycStatus !== EApiKycStatus.NEW && (
              <Badge
                className="mr-2"
                color={
                  Data.bankAccountBadges[bankAccount.pspBankAccount.kycStatus]
                    .color
                }
                icon={
                  Data.bankAccountBadges[bankAccount.pspBankAccount.kycStatus]
                    .icon
                }
                size={ESize.XS}
              />
            )}
          <span className="flex">{bankAccount.name}</span>
        </div>
        {bankAccount.iban && (
          <Style.Iban>
            {translation.translate('label.iban')}
            {bankAccount.iban}
          </Style.Iban>
        )}
      </>
    )
  }

  const setSelectBankAccountsOptions = () => {
    const organizationBankAccounts: Type.IBankAccountOption[] = []
    const groupBankAccounts: Type.IBankAccountOption[] = []

    bankAccounts.forEach((bankAccount) => {
      bankAccount.ownBankAccount
        ? organizationBankAccounts.push({
            chipLabel: bankAccount.name,
            label: displaySelectOption(
              bankAccount as IApiBankAccountWithPspDetails
            ),
            value: bankAccount.id,
          })
        : groupBankAccounts.push({
            chipLabel: bankAccount.name,
            label: displaySelectOption(
              bankAccount as IApiBankAccountWithPspDetails
            ),
            value: bankAccount.id,
          })
    })

    return [
      {
        label: translation.translate('title.organization'),
        options: organizationBankAccounts,
      },
      {
        label: translation.translate('title.group'),
        options: groupBankAccounts,
      },
    ]
  }

  const getSelectedBankAccountName = (
    props: SingleValueProps<Type.IBankAccountOption>
  ) => (
    <components.SingleValue {...props}>
      {props.data.chipLabel}
    </components.SingleValue>
  )

  return (
    <FormGroup>
      <Style.Select
        hasIban={true}
        options={setSelectBankAccountsOptions()}
        components={getSelectedBankAccountName}
        isSearchable={true}
        {...rest}
      />
    </FormGroup>
  )
}

export default SelectBankAccounts
