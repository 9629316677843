import {
  Background,
  Button,
  ListItem,
  ModalSubmitKeywords,
  Title,
} from 'components'
import { EConfirmIntent } from 'components/Modal/Confirm/type'
import { createIntl, useTranslation } from 'hooks'
import { FC, useState } from 'react'
import { RawIntlProvider } from 'react-intl'

const lorem = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
elementum, augue in porta interdum, lacus ligula interdum massa, et vulputate
felis turpis id mi. Praesent libero mi, volutpat dignissim ipsum et, porttitor
pharetra ligula. Etiam sem magna, iaculis id maximus et, laoreet nec velit.
In fermentum magna vitae arcu dictum, eget ornare enim volutpat. Phasellus
non nisi a turpis laoreet porta auctor ac odio. Maecenas et lorem ut justo
vulputate fringilla. Nulla viverra lacus urna, a malesuada felis cursus a.
Praesent sit amet lacinia felis. Vivamus scelerisque enim non condimentum
consequat. Nullam ac tellus varius, iaculis ligula et, consectetur sem.
Vivamus lacus lacus, porttitor ut sodales sit amet, aliquam id quam. Sed
lacinia pulvinar nulla consequat rutrum. Fusce vitae tellus ligula. Sed vitae
enim turpis. Fusce non ipsum nisi. Nulla ac mi vitae lorem elementum consequat.`

const StorybookPage: FC = () => {
  const intl = createIntl('pages_storybook')
  const translation = useTranslation(intl)
  const [confirmIsOpen, setConfirmIsOpen] = useState(false)
  const [confirmDeleteIsOpen, setDeleteIsOpen] = useState(false)

  return (
    <RawIntlProvider value={intl}>
      <Title type="h1" className="mb-4">
        ModalSubmitKeywords
      </Title>

      <Background title="Information">
        <ul>
          <ListItem>
            This modal is using ModalConfirm components (same properties)
          </ListItem>
          <ListItem>An additional "requiredText" properties is added</ListItem>
        </ul>
      </Background>

      <Title type="h3" className="my-4">
        ModalConfirm with button type = confirm + required word
      </Title>
      <Button onClick={() => setConfirmIsOpen(true)}>Click to open</Button>
      <ModalSubmitKeywords
        title="Lorem ipsum"
        description={<>{lorem}</>}
        isOpen={confirmIsOpen}
        callbackCancel={async () => setConfirmIsOpen(false)}
        callbackConfirm={async () => setConfirmIsOpen(false)}
        requiredText={translation.translate('modal.keywords.requiredText')}
      />

      <Title type="h3" className="my-4">
        ModalConfirm with button type = delete + required word
      </Title>
      <Button onClick={() => setDeleteIsOpen(true)}>Click to open</Button>
      <ModalSubmitKeywords
        title="Lorem ipsum"
        description={<>{lorem}</>}
        isOpen={confirmDeleteIsOpen}
        callbackCancel={async () => setDeleteIsOpen(false)}
        callbackConfirm={async () => setDeleteIsOpen(false)}
        type={EConfirmIntent.DELETE}
        requiredText={translation.translate('modal.keywords.requiredText')}
      />
    </RawIntlProvider>
  )
}

export default StorybookPage
