import { Button, Col, Row } from 'components'
import { useTranslation } from 'hooks'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { EColor } from 'types'

import { IConfirmModalButtons } from './type'

const ConfirmModalButtons: FC<IConfirmModalButtons> = ({
  confirmButtonText,
  handleClose,
  handleConfirm,
  isProcessingCallback,
  isDelete = false,
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)
  return (
    <Row className="align-items-center justify-content-center">
      <Col xs="auto">
        <Button
          onClick={handleClose}
          color={EColor.TRANSPARENT}
          className="text-uppercase"
          disabled={isProcessingCallback}
        >
          {translation.translate('button.cancel')}
        </Button>
      </Col>
      <Col xs="auto">
        <Button
          onClick={handleConfirm}
          color={isDelete ? EColor.RED : EColor.BLUE}
          className="text-uppercase"
          disabled={isProcessingCallback}
        >
          {confirmButtonText || translation.translate('button.confirm')}
        </Button>
      </Col>
    </Row>
  )
}

export default ConfirmModalButtons
