import { Background, Button, Col, ListItem, Title } from 'components'
import { EBackgroundBorderColor } from 'components/Background/type'
import { FC, useState } from 'react'
import { Row } from 'reactstrap'
import { EColor } from 'types'

const borderColors = [EColor.BLUE, EColor.GREEN]
const colors = [EColor.WHITE, EColor.BLUE, EColor.GREEN]
const borders: EBackgroundBorderColor[] = Object.values(EBackgroundBorderColor)
const bordersWithColors: EBackgroundBorderColor[] = [
  EBackgroundBorderColor.DASHED,
  EBackgroundBorderColor.SOLID,
]
const text =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque arcu mauris, varius a mauris nec, ornare pharetra ligula. Maecenas porta, mauris vitae blandit eleifend, nibh neque suscipit neque, vel pretium lorem lorem nec ex. Lorem ipsum dolor sit amet, consectetur.'
const textShort =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque arcu mauris.'

const StorybookPage: FC = () => {
  const [isActive, setIsActive] = useState(false)

  return (
    <>
      <Title type="h1" className="mb-4">
        Background
      </Title>

      <Background title="Information">
        <ul>
          <ListItem>
            <strong>
              This component should be used for forms or very specifics
              displays. To display data you must use the Table component or the
              Card components
            </strong>
          </ListItem>
          <ListItem>The title, description contains string</ListItem>
          <ListItem>The icon, title, description are optional</ListItem>
          <ListItem>
            All colors of the Foundations {'>'} Colors section are available if
            needed for the background color and the icon color
          </ListItem>
        </ul>
      </Background>

      <Title type="h2" className="my-4">
        Component
      </Title>
      <Background
        icon="home"
        title="Lorem ipsum..."
        description="Lorem ipsum..."
      >
        {text}
      </Background>

      <Title type="h2" className="my-4">
        Without icon
      </Title>
      <Background title="Lorem ipsum..." description="Lorem ipsum...">
        {text}
      </Background>

      <Title type="h2" className="my-4">
        Without description
      </Title>
      <Background title="Lorem ipsum...">{text}</Background>

      <Title type="h2" className="my-4">
        Without title
      </Title>
      <Background>{text}</Background>

      <Title type="h2" className="my-4">
        Border types
      </Title>

      {borders.map((border, index) => (
        <Row key={index} className="align-items-center">
          <Col lg={2}>{border}</Col>
          <Col lg={10}>
            <Background border={border}>{text}</Background>
          </Col>
        </Row>
      ))}

      <Title type="h2" className="my-4">
        Border colors
      </Title>
      {borderColors.map((color, i) => (
        <Row key={i} className="align-items-center">
          <Col lg={2}>{color}</Col>
          {bordersWithColors.map((border, j) => (
            <Col key={j} lg={5}>
              <Background borderColor={color} border={border}>
                {textShort}
              </Background>
            </Col>
          ))}
        </Row>
      ))}

      <Title type="h2" className="my-4">
        Icon colors
      </Title>
      {borderColors.map((color, index) => (
        <Row key={index} className="align-items-center">
          <Col lg={2}>{color}</Col>
          <Col lg={10}>
            <Background title="Lorem ipsum..." icon="home" iconColor={color}>
              {text}
            </Background>
          </Col>
        </Row>
      ))}

      <Title type="h2" className="my-4">
        Colors
      </Title>
      {colors.map((color, index) => (
        <Row key={index} className="align-items-center">
          <Col lg={2}>{color}</Col>
          <Col lg={10}>
            <Background color={color}>{text}</Background>
          </Col>
        </Row>
      ))}

      <Title type="h2" className="my-4">
        Hover effects
      </Title>
      <Row className="align-items-center">
        <Col lg={2}>shadow</Col>
        <Col lg={10}>
          <Background hoverShadow={true}>{text}</Background>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col lg={2}>transform</Col>
        <Col lg={10}>
          <Background hoverTransform={true}>{text}</Background>
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col lg={2}>shadow + transform</Col>
        <Col lg={10}>
          <Background hoverShadow={true} hoverTransform={true}>
            {text}
          </Background>
        </Col>
      </Row>

      <Title type="h2" className="my-4">
        Flip effect
      </Title>
      <Background
        flip={{
          active: isActive,
          children: {
            front: (
              <div className="text-center w-100">
                <p>{text}</p>
                <Button onClick={() => setIsActive(!isActive)}>
                  Flip front
                </Button>
              </div>
            ),
            back: (
              <div className="text-center w-100">
                <p>{text}</p>
                <Button onClick={() => setIsActive(!isActive)}>
                  Flip back
                </Button>
              </div>
            ),
          },
        }}
      />

      <Title type="h2" className="my-4">
        With CTA (arrow icon visible only on hover) - only for Regular
        background
      </Title>
      <Background hasCTA={true}>{text}</Background>
    </>
  )
}

export default StorybookPage
