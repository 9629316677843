import { IApiOrganization, IApiWorkflowAction } from 'api/type'

export enum ECommunicationEmailingAction {
  DELETE_DRAFT = 'delete_draft',
  DELETE_PROGRAMMED = 'delete_programmed',
  CANCEL_SCHEDULE = 'cancel_schedule',
}

export enum ESendingSystem {
  LEGACY = 'LEGACY',
  BREVO = 'BREVO',
}

enum EFlagReason {
  SPAM = 'SPAM',
  HARD_BOUNCE = 'HARD_BOUNCE',
}

export enum ECommunicationEmailingStatus {
  DRAFT = 'DRAFT',
  PROGRAMMED = 'PROGRAMMED',
  IN_PROGRESS = 'IN_PROGRESS',
  SENT = 'SENT',
}

export enum ECommunicationEmailingState {
  SENT = 'SENT',
  IN_PROGRESS = 'IN_PROGRESS',
  SIGNALED = 'SIGNALED',
  BLOCKED = 'BLOCKED',
}

export enum EPercentageStat {
  CLICKED = 'CLICKED',
  OPENED = 'OPENED',
}

export enum EPerformanceMetric {
  REJECTED = 'REJECTED',
  UNDELIVERED = 'UNDELIVERED',
}

export type TGlobalStats = {
  emailsNotSent: number
  emailsClicked: number
  emailsDelivered: number
  emailsHardBounced: number
  emailsMarkedAsSpam: number
  emailsOpened: number
  emailsRejected: number
  emailsSent: number
  emailsSoftBounced: number
  emailsTotal: number
  emailsUnsubscribed: number
}

enum ERecipientType {
  COMMUNITY = 'COMMUNITY',
  CRM_SELECTION = 'CRM_SELECTION',
  STATIC = 'STATIC',
}

export type TRecipientsList = {
  '@type': string
  type: ERecipientType
  name: string
  link: string
}

type TStats = {
  '@type': string
  url: string
  clickedCount: number
  uniqueClickedCount: number
}

export enum EEmailsNotSentStats {
  DUPLICATE = 'DUPLICATE',
  INVALID = 'INVALID',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
}

export type TEmailsNotSentStats = {
  [EEmailsNotSentStats.DUPLICATE]: number
  [EEmailsNotSentStats.INVALID]: number
  [EEmailsNotSentStats.UNSUBSCRIBE]: number
}

export interface IEmailingCampaign {
  '@id': string
  '@type': string
  id: string
  emailsNotSentStats: TEmailsNotSentStats
  flagged: boolean
  flagReason: null | EFlagReason
  globalStats: null | TGlobalStats
  lists: Array<TRecipientsList>
  linkStats: Array<TStats>
  subject: string
  sender: {
    address: string
    name: string
  }
  sendingSystem: ESendingSystem
  schedule: string | null
  createdAt: string | null
  organization: IApiOrganization
  replyTo: string
  status: ECommunicationEmailingStatus
  ['workflow:actions']: IApiWorkflowAction<ECommunicationEmailingAction>[]
}

type TEmailingCampaignRecipient = {
  address: string
  name?: string
}

type TEmailingCampaignContact = {
  '@context': string
  '@id': string
  '@type': string
  firstname: string
  id: string
  lastname: string
}

export enum EEmailingCampaignMessageStatus {
  BLOCKED_INVALID = 'BLOCKED_INVALID',
  BLOCKED_UNSUBSCRIBE = 'BLOCKED_UNSUBSCRIBE',
  BLOCKED_DUPLICATE = 'BLOCKED_DUPLICATE',
  REJECTED = 'REJECTED',
  HARD_BOUNCED = 'HARD_BOUNCED',
  SOFT_BOUNCED = 'SOFT_BOUNCED',
  MARKED_AS_SPAM = 'MARKED_AS_SPAM',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  CLICKED = 'CLICKED',
  OPENED = 'OPENED',
  DELIVERED = 'DELIVERED',
  SENT = 'SENT',
}

export interface IEmailingCampaignMessage {
  '@id': string
  '@type': string
  contact: TEmailingCampaignContact
  messageId: string
  bounceReason: null | string
  recipient: TEmailingCampaignRecipient
  lastActivity: string
  status: EEmailingCampaignMessageStatus
  transBounceReason: null | string
}

type TEmailingCampaignEvent = {
  '@type': string
  status: EEmailingCampaignMessageStatus
  date: string
}

export interface IEmailingCampaignMessageActivity
  extends IEmailingCampaignMessage {
  events: Array<TEmailingCampaignEvent>
  lists: Array<TRecipientsList>
}
