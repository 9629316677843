import { getCrmPersonRoles } from 'api'
import { EmptySpaceAccessDenied } from 'components'
import { useSelector } from 'hooks'
import { useOrganization } from 'providers'
import { FC } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useDispatch } from 'react-redux'
import { useAsync } from 'react-use'
import {
  selectPerson,
  setPersonAdminMasterRoles,
  setPersonGlobalRoles,
  setPersonRoles,
} from 'state'

import * as Type from './type'

const HasAccess: FC<Type.IHasAccess> = ({
  access,
  children,
  onAccessDenied,
  organization,
}) => {
  const dispatch = useDispatch()
  const person = useSelector(selectPerson)
  const { organization: organizationStore } = useOrganization()
  const organizationFinal = organization || organizationStore

  // Search person roles for a given organization
  useAsync(async () => {
    if (
      typeof person.roles[organizationFinal.id] === 'undefined' &&
      person.contactId &&
      organizationFinal.id
    ) {
      try {
        const personRoles = await getCrmPersonRoles(
          person.contactId,
          organizationFinal.id
        )
        // TODO: React 18 unstable_batchedUpdates: https://github.com/reactwg/react-18/discussions/21
        unstable_batchedUpdates(() => {
          dispatch(
            setPersonGlobalRoles({
              ...person.globalRoles,
              [organizationFinal.id]: personRoles.globalRoles,
            })
          )
          dispatch(
            setPersonRoles({
              ...person.roles,
              [organizationFinal.id]: personRoles.roles,
            })
          )
          dispatch(
            setPersonAdminMasterRoles({
              ...person.adminMasterRoles,
              [organizationFinal.id]: personRoles.adminMasterRoles,
            })
          )
        })
      } catch (error: unknown) {
        // TODO: React 18 unstable_batchedUpdates: https://github.com/reactwg/react-18/discussions/21
        unstable_batchedUpdates(() => {
          dispatch(
            setPersonGlobalRoles({
              ...person.globalRoles,
              [organizationFinal.id]: [],
            })
          )
          dispatch(
            setPersonRoles({
              ...person.roles,
              [organizationFinal.id]: [],
            })
          )
          dispatch(
            setPersonAdminMasterRoles({
              ...person.adminMasterRoles,
              [organizationFinal.id]: [],
            })
          )
        })
      }
    }
  }, [])

  // Pending request
  if (
    typeof person.roles[organizationFinal.id] === 'undefined' &&
    person.contactId &&
    organizationFinal.id
  ) {
    return <></>
  }
  // Access granted
  if (access(person.roles[organizationFinal.id])) {
    return children
  }
  // Access denied
  if (onAccessDenied) {
    return onAccessDenied()
  }
  return <EmptySpaceAccessDenied />
}

export default HasAccess
