import {
  Background,
  Col,
  Form,
  Input,
  ListItem,
  Row,
  Select,
  Title,
} from 'components'
import { Formik } from 'formik'
import { FC } from 'react'
import { EColor } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Input
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>Input height: 32.5px</ListItem>
      </ul>
    </Background>

    <Formik
      initialValues={{
        inputSimple: '',
        inputSimpleClearable: '',
        inputPrependTextShort: '',
        inputPrependTextLong: '',
        inputPrependIcon: '',
        inputPrependIconClearable: '',
        inputAppendTextShort: '',
        inputAppendTextLong: '',
        inputAppendIcon: '',
        inputAppendIconClearable: '',
        status: 'regular',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={({ values, setFieldError, setFieldTouched }) => (
        <Form>
          <Row>
            <Col xs={6}>
              <Select
                name="status"
                label="Input status"
                options={['regular', 'disabled', 'invalid'].map((status) => ({
                  value: status,
                  label: status,
                }))}
                onChangeCallback={(value) => {
                  setTimeout(() => {
                    if (value === 'invalid') {
                      const fields = [
                        'inputSimple',
                        'inputSimpleClearable',
                        'inputPrependTextShort',
                        'inputPrependTextLong',
                        'inputPrependIcon',
                        'inputPrependIconClearable',
                        'inputAppendTextShort',
                        'inputAppendTextLong',
                        'inputAppendIcon',
                        'inputAppendIconClearable',
                      ]
                      for (const field of fields) {
                        setFieldTouched(field, true, false)
                        setFieldError(field, 'Erreur')
                      }
                    }
                  }, 500)
                }}
              />
            </Col>
          </Row>

          <hr />

          <Input name="inputSimple" disabled={values.status === 'disabled'} />
          <Input
            name="inputSimpleClearable"
            isClearable={true}
            disabled={values.status === 'disabled'}
          />
          <Input
            name="inputPrependTextShort"
            prepend={{ text: 'lorem' }}
            disabled={values.status === 'disabled'}
          />
          <Input
            name="inputPrependTextLong"
            prepend={{ text: 'lorem ipsum sit dolor' }}
            disabled={values.status === 'disabled'}
          />
          <Input
            name="inputPrependIcon"
            prepend={{ icon: ['fas', 'search'] }}
            disabled={values.status === 'disabled'}
          />
          <Input
            name="inputPrependIconClearable"
            prepend={{ icon: ['fas', 'search'] }}
            isClearable={true}
            disabled={values.status === 'disabled'}
          />
          <Input
            name="inputAppendTextShort"
            append={{ text: 'lorem' }}
            disabled={values.status === 'disabled'}
          />
          <Input
            name="inputAppendTextLong"
            append={{ text: 'lorem ipsum sit dolor' }}
            disabled={values.status === 'disabled'}
          />
          <Input
            name="inputAppendIcon"
            append={{ icon: ['fas', 'search'] }}
            disabled={values.status === 'disabled'}
          />
          <Input
            name="inputAppendIconClearable"
            append={{ icon: ['fas', 'search'] }}
            isClearable={true}
            disabled={values.status === 'disabled'}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        input: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Input
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            name="input"
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Prepend text and icon + custom color
    </Title>
    <Formik
      initialValues={{
        inputPrependText: '',
        inputPrependIcon: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Input
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            prepend={{ text: 'Text', color: EColor.RED }}
            name="inputPrependText"
            required={true}
          />
          <Input
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            prepend={{ icon: 'search', color: EColor.RED }}
            name="inputPrependIcon"
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Append text and icon + disabled
    </Title>
    <Formik
      initialValues={{
        inputAppendText: '',
        inputAppendIcon: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Input
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            append={{ text: 'Text' }}
            name="inputAppendText"
            disabled={true}
            required={true}
          />
          <Input
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            append={{ icon: 'search' }}
            name="inputAppendIcon"
            disabled={true}
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Disabled state
    </Title>
    <Formik
      initialValues={{
        inputDisabled: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Input
            name="inputDisabled"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            disabled={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without help text
    </Title>
    <Formik
      initialValues={{
        inputWithoutHelptext: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Input
            name="inputWithoutHelptext"
            label="Lorem ipsum"
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without label
    </Title>
    <Formik
      initialValues={{
        inputWithoutLabel: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Input name="inputWithoutLabel" required={true} />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
