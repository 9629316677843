import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMenuItems } from 'api/type'

const menuInitialState: IMenuItems | [] = []

const setSlice = createSlice({
  name: 'menu',
  initialState: menuInitialState,
  reducers: {
    setMenu: (state, action: PayloadAction<IMenuItems>) =>
      Object.assign([], state, action.payload),
  },
})

export const { setMenu } = setSlice.actions
export default setSlice.reducer
