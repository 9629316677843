import { Alert, Col, Link, Row, Title } from 'components'
import Icon, { icons } from 'components/Icon'
import { FC } from 'react'
import { EColor, ESize } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-2">
      Icons
    </Title>

    <Alert color={EColor.RED} titme="Images">
      The AssoConnect application can only contain SVGs, no images !
    </Alert>

    <Title type="h2" className="my-4">
      Fontawesome
    </Title>
    <p className="mb-4">
      <Link href="https://fontawesome.com/icons?d=gallery">
        https://fontawesome.com/
      </Link>
    </p>
    <Row>
      {icons.map((icon, index) => (
        <Col key={index} lg={2} className="text-center my-2">
          <Icon size={ESize.XL} icon={icon} />
          <br />
          {icon.iconName}
        </Col>
      ))}
    </Row>

    <Title type="h2" className="my-4">
      CDN
    </Title>
    <p className="mb-4">
      <Link href="https://portal.azure.com/#home">
        https://portal.azure.com/#home
      </Link>
      {' > '}
      <strong>"cdnwebprodas"</strong> application{' > '}
      <strong>"software/common/icon"</strong> folder
    </p>
  </>
)

export default StorybookPage
