import { EModalType, TModal } from 'providers/type'

export const isAlertModal = (
  modal: TModal
): modal is TModal<EModalType.ALERT> => modal.type === EModalType.ALERT

export const isConfirmModal = (
  modal: TModal
): modal is TModal<EModalType.CONFIRM> => modal.type === EModalType.CONFIRM

export const isDeleteModal = (
  modal: TModal
): modal is TModal<EModalType.DELETE> => modal.type === EModalType.DELETE

export const isSubmitKeywordsModal = (
  modal: TModal
): modal is TModal<EModalType.REQUIRED_KEYWORD> =>
  modal.type === EModalType.REQUIRED_KEYWORD
