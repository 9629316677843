import { Form as FormikForm, FormikValues, useFormikContext } from 'formik'
import { createIntl, useTracking, useTranslation } from 'hooks'
import { FC, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { addNotification } from 'state'
import { EColor } from 'types'

import * as Type from './type'
import { GLOBAL_FORM_ERROR_NAME } from './type'

const Form: FC<Type.IForm> = ({
  preventErrorTracking = false,
  displayGenericError = true,
  ...props
}) => {
  const tracking = useTracking()
  const dispatch = useDispatch()
  const intl = createIntl('components_form')
  const translation = useTranslation(intl)

  const { values, errors, touched, isSubmitting, isValidating, setFieldError } =
    useFormikContext<FormikValues>()
  const timer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    if (!isSubmitting || isValidating || Object.keys(errors).length === 0) {
      return
    }

    const globalError = errors[GLOBAL_FORM_ERROR_NAME]

    if (globalError) {
      dispatch(
        addNotification({
          color: EColor.RED,
          text: globalError,
        })
      )
      setFieldError(GLOBAL_FORM_ERROR_NAME, undefined)
    } else if (displayGenericError) {
      dispatch(
        addNotification({
          color: EColor.RED,
          text: translation.translate('genericErrorMessage'),
        })
      )
    }
  }, [
    isSubmitting,
    isValidating,
    errors,
    displayGenericError,
    translation,
    dispatch,
    setFieldError,
  ])

  useEffect(() => {
    if (!preventErrorTracking && Object.keys(errors).length) {
      timer.current = setTimeout(() => {
        Object.keys(errors).forEach((key) => {
          if (touched[key]) {
            tracking.track('Field error', {
              field: key,
              value: values[key],
              error: errors[key],
              url: window.location.href,
            })
          }
        })
      }, 1000)
    }
    return () => timer.current && window.clearTimeout(timer.current)
  }, [values, errors, touched, preventErrorTracking, tracking])

  return <FormikForm {...props} />
}

export default Form
