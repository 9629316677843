import { API } from 'api/connector'

import * as Type from './type'
import { IPlatformLevel } from './type'

export const getPlatformSpecifications = async (
  platformId: string
): Promise<Type.IApiPlatformSpecifications> => {
  const platformSpecifications = await API.get(
    `/api/v1/platforms/${platformId}/platform_specifications`
  )
  return platformSpecifications.data
}

export const signPlatformGtcus = async (
  platformId: string
): Promise<Type.IApiPlatformSpecifications> => {
  const signature = await API.post(`/api/v1/platforms/${platformId}/gtcus/sign`)
  return signature.data
}

export const getPlatformLevels = async (
  platformId: string
): Promise<Array<IPlatformLevel>> => {
  const levels = await API.get(
    `/api/v1/platforms/${platformId}/platform_organization_levels`
  )
  return levels.data['hydra:member']
}

export const updatePlatformLevel = async (
  platformLevel: IPlatformLevel,
  name: IPlatformLevel['name']
) =>
  API.put(`/api/v1/platform_organization_levels/${platformLevel.id}`, {
    name,
  })

export const createPlatformLevel = async (
  name: IPlatformLevel['name'],
  level: IPlatformLevel['level'],
  platform: string
) =>
  API.post('/api/v1/platform_organization_levels', {
    name,
    level,
    platform,
  })

export const deletePlatformLevel = async (platformLevel: IPlatformLevel) =>
  API.delete(platformLevel['@id'])
