import {
  Background,
  Col,
  Display,
  Link,
  ListItem,
  Row,
  Title,
} from 'components'
import { IDisplayType } from 'components/Text/Display/type'
import { FC } from 'react'

const types = {
  'display-1': '64px',
  'display-2': '60px',
  'display-3': '40px',
  'display-4': '49px',
  h1: '32px',
  h2: '22px',
  h3: '20px',
  h4: '18px',
  h5: '17.5px',
  h6: '14px',
}

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Typography
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>
          Customizable elements of the title sizes:
          <ul>
            <ListItem>Uppercase</ListItem>
            <ListItem>Color (All colors of Foundations {'>'} Colors)</ListItem>
            <ListItem>Font family</ListItem>
          </ul>
        </ListItem>
      </ul>
    </Background>

    <Title type="h2">Families</Title>
    <p className="display-3">Roboto</p>
    <p className="display-3" style={{ fontFamily: 'Poppins' }}>
      Poppins
    </p>

    <Title type="h2" className="my-4">
      Title sizes
    </Title>
    {Object.entries(types).map(([type, px], index) => (
      <Row key={index} className="align-items-center mb-1">
        <Col lg={2}>
          {type} ({px})
        </Col>
        <Col lg={10}>
          <Display type={type as IDisplayType}>Lorem ipsum...</Display>
        </Col>
      </Row>
    ))}

    <Title type="h2" className="my-4">
      Text sizes
    </Title>
    <Row className="align-items-center mb-1">
      <Col lg={2}>p (14px)</Col>
      <Col lg={10}>Lorem ipsum...</Col>
    </Row>
    <Row className="align-items-center mb-1">
      <Col lg={2}>strong (14px)</Col>
      <Col lg={10}>
        <strong>Lorem ipsum...</strong>
      </Col>
    </Row>
    <Row className="align-items-center mb-1">
      <Col lg={2}>link (14px)</Col>
      <Col lg={10}>
        <Link href="/">Lorem ipsum</Link>
      </Col>
    </Row>
  </>
)

export default StorybookPage
