import { Background, ListItem, SplitButton, Title } from 'components'
import { FC } from 'react'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      SplitButton
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>
          There is a mainAction that contains a string label and an onClick
          event
        </ListItem>
        <ListItem>
          There are otherActions, an array of string labels and onClick events
        </ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <SplitButton
      mainAction={{
        label: 'Lorem ipsum',
        onClick: () => console.log('main action'),
      }}
      otherActions={[
        {
          label: 'Lorem ipsum',
          onClick: () => console.log('other action'),
        },
      ]}
    >
      Lorem ipsum
    </SplitButton>

    <Title type="h2" className="my-4">
      With a disable other action
    </Title>
    <SplitButton
      mainAction={{
        label: 'Lorem ipsum',
        onClick: () => console.log('main action'),
      }}
      otherActions={[
        {
          label: 'Lorem ipsum 1',
          onClick: () => console.log('other action'),
        },
        {
          label: 'Lorem ipsum 2',
          onClick: () => console.log('other action'),
          disabled: true,
        },
      ]}
    >
      Lorem ipsum
    </SplitButton>
  </>
)

export default StorybookPage
