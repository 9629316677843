import * as Sentry from '@sentry/react'
import useAscRouter from 'hooks/useAscRouter'
import LayoutBlank from 'layouts/Blank'
import Payment from 'pages/Payment'
import { ModalProvider, OrganizationProvider } from 'providers'
import { FC, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router'

import AdminRouter from './Admin'
import AdminProviders from './Admin/data/AdminProviders'
import OrderRouter from './Order'
import RequestRouter from './Request'
import { ERouterType } from './type'

const Errors403 = lazy(() => import('pages/Errors/403'))
const NoContent = lazy(() => import('pages/NoContent'))
const LegacyRedirect = lazy(() => import('pages/LegacyRedirect'))

const CollectPublicRouter = lazy(() => import('./CollectPublicRouter'))
const MasterRouter = lazy(() => import('./MasterRouter'))
const PaymentRouter = lazy(() => import('./Payment'))

const AppRouter: FC = () => {
  const { RouteLayout } = useAscRouter()
  const { RouteLayout: PaymentLayout } = useAscRouter(ERouterType.PAYMENT)
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

  return (
    <Suspense fallback="">
      <ModalProvider>
        <SentryRoutes>
          <Route
            path="/organization/:organizationId/*"
            element={
              <OrganizationProvider>
                <AdminProviders>
                  <AdminRouter />
                </AdminProviders>
              </OrganizationProvider>
            }
          />
          <Route path="/collect/*" element={<CollectPublicRouter />} />
          <Route path="/master/*" element={<MasterRouter />} />
          <Route
            path="/payment-method"
            element={<PaymentLayout component={Payment} />}
          />
          <Route path="/order/*" element={<OrderRouter />} />
          <Route path="/payment/*" element={<PaymentRouter />} />
          <Route path="/request/*" element={<RequestRouter />} />
          <Route
            path="/no-content"
            element={<RouteLayout component={NoContent} layout={LayoutBlank} />}
          />
          <Route
            path="/errors/403"
            element={<RouteLayout component={Errors403} layout={LayoutBlank} />}
          />
          <Route path="/*" element={<LegacyRedirect />} />
        </SentryRoutes>
      </ModalProvider>
    </Suspense>
  )
}

export default AppRouter
