import { IApiBankAccount, IApiPettyCash, IDetailedBankAccount } from 'api//type'
import { EAccountType } from 'pages/Accounting/Settings/components/AccountDetails/type'

export enum EAccountsType {
  OWN = 'OWN',
  CHILDREN = 'CHILDREN',
  BOTH = 'BOTH',
}

export interface IBankAccounts {
  pageContext?: IPageContexts
  accountsList: EAccountsType
}

export type IPageContexts =
  | EAccountType.BANK_ACCOUNT
  | EAccountType.BANK_ACCOUNT_WITH_PSP_DETAILS
  | EAccountType.PETTY_CASH

export type IActionAccount = IDetailedBankAccount | IApiPettyCash | null

export type IConfirmParams = {
  action: EAccountAction
  account: IApiBankAccount | IApiPettyCash
  isOpen: boolean
} | null

export enum EAccountAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  VERIFY = 'VERIFY',
  ARCHIVE = 'ARCHIVE',
  UNARCHIVE = 'UNARCHIVE',
  DELETE = 'DELETE',
  CONFIRM_DELETE = 'CONFIRM_DELETE',
  CONFIRM_ARCHIVE = 'CONFIRM_ARCHIVE',
}
