import { ECurrency } from 'components/Form/Money/type'

export const accountingEntriesWithFilterAmount = {
  '@context': '/api/v1/contexts/AccountingEntry',
  '@id': '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries',
  '@type': 'hydra:Collection',
  'hydra:member': [
    {
      '@id': '/api/v1/accounting_entries/f1699046-db0a-11eb-a012-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Recette - Paiement ultérieur - paiement en attente',
      amount: 0,
      currency: ECurrency.EUR,
      date: '2020-09-30',
      recordId: 'f162a06a-db0a-11eb-ad2c-0242ac160007',
      accountingCategory: null,
      credit: 0,
      debit: 0,
    },
    {
      '@id': '/api/v1/accounting_entries/f08821c4-db0a-11eb-bac1-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Créance test - paiement',
      amount: 0,
      currency: ECurrency.EUR,
      date: '2021-07-03',
      recordId: 'f084900e-db0a-11eb-a38c-0242ac160007',
      accountingCategory: null,
      credit: 0,
      debit: 0,
    },
    {
      '@id': '/api/v1/accounting_entries/f0d3fa7c-db0a-11eb-8ccc-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Achat paiement ultérieur - paiement en attente',
      amount: 0,
      currency: ECurrency.EUR,
      date: '2021-07-03',
      recordId: 'f0cff29c-db0a-11eb-ada0-0242ac160007',
      accountingCategory: null,
      credit: 0,
      debit: 0,
    },
    {
      '@id': '/api/v1/accounting_entries/f1d9f1e2-db0a-11eb-b72e-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Recette en N+1 - paiement en attente',
      amount: 0,
      currency: ECurrency.EUR,
      date: '2021-07-03',
      recordId: 'f1d21706-db0a-11eb-a74b-0242ac160007',
      accountingCategory: null,
      credit: 0,
      debit: 0,
    },
  ],
  'hydra:totalItems': 4,
  'hydra:view': {
    '@id':
      '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries?itemsPerPage=25&amount%5Blte%5D=200',
    '@type': 'hydra:PartialCollectionView',
  },
  'hydra:search': {
    '@type': 'hydra:IriTemplate',
    'hydra:template':
      '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries{?amount[between],amount[gt],amount[gte],amount[lt],amount[lte],has_breakdown,date[before],date[strictly_before],date[after],date[strictly_after],account,category_type,accountingRecord.organization,accountingRecord}',
    'hydra:variableRepresentation': 'BasicRepresentation',
    'hydra:mapping': [
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[between]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[gt]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[gte]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[lt]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[lte]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'has_breakdown',
        property: 'has_breakdown',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[before]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[strictly_before]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[after]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[strictly_after]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'account',
        property: 'account',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'category_type',
        property: 'category_type',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'accountingRecord.organization',
        property: 'accountingRecord.organization',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'accountingRecord',
        property: 'accountingRecord',
        required: false,
      },
    ],
  },
}

export const accountingEntriesWithFilterExpense = {
  '@context': '/api/v1/contexts/AccountingEntry',
  '@id': '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries',
  '@type': 'hydra:Collection',
  'hydra:member': [
    {
      '@id': '/api/v1/accounting_entries/f1178ef4-db0a-11eb-a94f-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Achat avec CCA - Ouverture d’exercice (CCA)',
      amount: 10000,
      currency: ECurrency.EUR,
      date: '2020-09-08',
      recordId: 'f1118bbc-db0a-11eb-ab71-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 486000,
      debit: 622000,
    },
    {
      '@id': '/api/v1/accounting_entries/f10da66e-db0a-11eb-ac97-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Achat avec CCA - Part constatée d avance',
      amount: 10000,
      currency: ECurrency.EUR,
      date: '2020-09-07',
      recordId: 'f1079e54-db0a-11eb-8154-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 622000,
      debit: 486000,
    },
    {
      '@id': '/api/v1/accounting_entries/f268c91c-db0a-11eb-b717-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Test',
      amount: 10000,
      currency: ECurrency.EUR,
      date: '2020-09-07',
      recordId: 'f25f82e4-db0a-11eb-afa6-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/681100',
        '@type': 'AccountingCategory',
        name: 'Dotations aux amortissements sur immobilisations incorporelles et corporelles',
      },
      credit: 151000,
      debit: 681100,
    },
    {
      '@id': '/api/v1/accounting_entries/f0cc1046-db0a-11eb-a096-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Achat paiement ultérieur',
      amount: 90000,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f0c6caa0-db0a-11eb-8ac0-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 401000,
      debit: 622000,
    },
    {
      '@id': '/api/v1/accounting_entries/f0e18430-db0a-11eb-a05c-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Achat payé',
      amount: 9000,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f0dc4970-db0a-11eb-85ca-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 467000,
      debit: 622000,
    },
    {
      '@id': '/api/v1/accounting_entries/f0f9c504-db0a-11eb-802c-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Achat avec CCA',
      amount: 90000,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f0f3ff66-db0a-11eb-ab1b-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 401000,
      debit: 622000,
    },
    {
      '@id': '/api/v1/accounting_entries/f126f7c2-db0a-11eb-b910-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Achat avec Stock',
      amount: 678800,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f120e9f4-db0a-11eb-9282-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 467000,
      debit: 622000,
    },
    {
      '@id': '/api/v1/accounting_entries/f212998e-db0a-11eb-bf7c-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Saisie avancée',
      amount: 90000,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f20aaa30-db0a-11eb-aaa3-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 0,
      debit: 622000,
    },
    {
      '@id': '/api/v1/accounting_entries/f218e2ee-db0a-11eb-b292-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Saisie avancée',
      amount: 90000,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f20aaa30-db0a-11eb-aaa3-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/681100',
        '@type': 'AccountingCategory',
        name: 'Dotations aux amortissements sur immobilisations incorporelles et corporelles',
      },
      credit: 681100,
      debit: 0,
    },
    {
      '@id': '/api/v1/accounting_entries/f22d8370-db0a-11eb-b5a0-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Saisie avancée tiers',
      amount: 13500,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f224f2c8-db0a-11eb-a752-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 0,
      debit: 622000,
    },
    {
      '@id': '/api/v1/accounting_entries/f249bb3a-db0a-11eb-9b2c-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Saisie avancée tiers',
      amount: 13500,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f24091ea-db0a-11eb-b48b-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 0,
      debit: 622000,
    },
    {
      '@id': '/api/v1/accounting_entries/f0ae0326-db0a-11eb-95d0-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Ouverture d’exercice (FNP) - FNP test',
      amount: 9000,
      currency: ECurrency.EUR,
      date: '2020-01-01',
      recordId: 'f0a8cb0e-db0a-11eb-8f8a-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 622000,
      debit: 408000,
    },
  ],
  'hydra:totalItems': 12,
  'hydra:view': {
    '@id':
      '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries?itemsPerPage=25&category_type%5B%5D=EXPENSE',
    '@type': 'hydra:PartialCollectionView',
  },
  'hydra:search': {
    '@type': 'hydra:IriTemplate',
    'hydra:template':
      '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries{?amount[between],amount[gt],amount[gte],amount[lt],amount[lte],has_breakdown,date[before],date[strictly_before],date[after],date[strictly_after],account,category_type,accountingRecord.organization,accountingRecord}',
    'hydra:variableRepresentation': 'BasicRepresentation',
    'hydra:mapping': [
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[between]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[gt]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[gte]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[lt]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[lte]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'has_breakdown',
        property: 'has_breakdown',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[before]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[strictly_before]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[after]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[strictly_after]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'account',
        property: 'account',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'category_type',
        property: 'category_type',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'accountingRecord.organization',
        property: 'accountingRecord.organization',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'accountingRecord',
        property: 'accountingRecord',
        required: false,
      },
    ],
  },
}

export const accountingEntriesWithFilterRevenue = {
  '@context': '/api/v1/contexts/AccountingEntry',
  '@id': '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries',
  '@type': 'hydra:Collection',
  'hydra:member': [
    {
      '@id': '/api/v1/accounting_entries/ef2cd522-db0a-11eb-8698-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: {
        '@id': '/api/v1/crm/people/01F9K3F5H8N34NNHN7SMWD36H7',
        '@type': 'Person',
        firstName: 'Admin',
        lastName: 'E2E TEST',
      },
      name: 'Deal settled - Transaction #1',
      amount: 83333,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'ef1e80ee-db0a-11eb-b84f-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/756000',
        '@type': 'AccountingCategory',
        name: 'Adhésions',
      },
      credit: 756000,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/ef6ebf0a-db0a-11eb-bd1e-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: {
        '@id':
          '/api/v1/accounting_third_parties/ef5dce02-db0a-11eb-84cf-0242ac160007',
        '@type': 'AccountingThirdParty',
        name: 'Test ThirdParty',
      },
      person: null,
      name: 'Deal settled - Transaction #2',
      amount: 83333,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'ef64dfbc-db0a-11eb-b710-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/756000',
        '@type': 'AccountingCategory',
        name: 'Adhésions',
      },
      credit: 756000,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/efa83e9c-db0a-11eb-8883-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Deal no order - Transaction #3',
      amount: 83333,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'ef9e3ff0-db0a-11eb-b3bb-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/756000',
        '@type': 'AccountingCategory',
        name: 'Adhésions',
      },
      credit: 756000,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/efe37dfe-db0a-11eb-baed-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Deal dropped - Transaction #4',
      amount: 83333,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'efd8dc1e-db0a-11eb-96f4-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/756000',
        '@type': 'AccountingCategory',
        name: 'Adhésions',
      },
      credit: 756000,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/f02626cc-db0a-11eb-bfb3-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Deal in_progress - Transaction #5',
      amount: 83333,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'f01b24b6-db0a-11eb-b0d2-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/756000',
        '@type': 'AccountingCategory',
        name: 'Adhésions',
      },
      credit: 756000,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/f1cc6432-db0a-11eb-a7c9-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Recette en N+1',
      amount: 678800,
      currency: ECurrency.EUR,
      date: '2020-09-30',
      recordId: 'f1c37a2a-db0a-11eb-a8c4-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/756000',
        '@type': 'AccountingCategory',
        name: 'Adhésions',
      },
      credit: 756000,
      debit: 411000,
    },
    {
      '@id': '/api/v1/accounting_entries/f141b602-db0a-11eb-b955-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Recette - Paiement ultérieur',
      amount: 90000,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f13b82e6-db0a-11eb-9252-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/754000',
        '@type': 'AccountingCategory',
        name: 'Dons et Collectes',
      },
      credit: 754000,
      debit: 411000,
    },
    {
      '@id': '/api/v1/accounting_entries/f15dc978-db0a-11eb-8b35-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Recette - Paiement ultérieur',
      amount: 90000,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f1573de2-db0a-11eb-a7db-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/754000',
        '@type': 'AccountingCategory',
        name: 'Dons et Collectes',
      },
      credit: 754000,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/f17c6f7c-db0a-11eb-8b11-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Recette - Paiement ultérieur N+1',
      amount: 678800,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f1759166-db0a-11eb-be69-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/754000',
        '@type': 'AccountingCategory',
        name: 'Dons et Collectes',
      },
      credit: 754000,
      debit: 411000,
    },
    {
      '@id': '/api/v1/accounting_entries/f27e1cae-db0a-11eb-b113-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Recette - Paiement ultérieur - Avoir',
      amount: 90000,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f2759c1e-db0a-11eb-af20-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/754000',
        '@type': 'AccountingCategory',
        name: 'Dons et Collectes',
      },
      credit: 754000,
      debit: 411000,
    },
    {
      '@id': '/api/v1/accounting_entries/f28e065a-db0a-11eb-820d-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Avoir recette',
      amount: 9000,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f2840862-db0a-11eb-a08a-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/754000',
        '@type': 'AccountingCategory',
        name: 'Dons et Collectes',
      },
      credit: 411000,
      debit: 754000,
    },
  ],
  'hydra:totalItems': 11,
  'hydra:view': {
    '@id':
      '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries?itemsPerPage=25&category_type%5B%5D=REVENUE',
    '@type': 'hydra:PartialCollectionView',
  },
  'hydra:search': {
    '@type': 'hydra:IriTemplate',
    'hydra:template':
      '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries{?amount[between],amount[gt],amount[gte],amount[lt],amount[lte],has_breakdown,date[before],date[strictly_before],date[after],date[strictly_after],account,category_type,accountingRecord.organization,accountingRecord}',
    'hydra:variableRepresentation': 'BasicRepresentation',
    'hydra:mapping': [
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[between]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[gt]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[gte]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[lt]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[lte]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'has_breakdown',
        property: 'has_breakdown',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[before]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[strictly_before]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[after]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[strictly_after]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'account',
        property: 'account',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'category_type',
        property: 'category_type',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'accountingRecord.organization',
        property: 'accountingRecord.organization',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'accountingRecord',
        property: 'accountingRecord',
        required: false,
      },
    ],
  },
}

export const accountingEntriesWithoutFilter = {
  '@context': '/api/v1/contexts/AccountingEntry',
  '@id': '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries',
  '@type': 'hydra:Collection',
  'hydra:member': [
    {
      '@id': '/api/v1/accounting_entries/ef4bf9d4-db0a-11eb-9a60-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Paiement Deal settled - Transaction #1',
      amount: 100000,
      currency: ECurrency.EUR,
      date: '2021-07-03',
      recordId: 'ef401b1e-db0a-11eb-af99-0242ac160007',
      accountingCategory: null,
      credit: 467000,
      debit: 517001,
    },
    {
      '@id': '/api/v1/accounting_entries/ef8a247a-db0a-11eb-9f99-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Paiement Deal settled - Transaction #2',
      amount: 100000,
      currency: ECurrency.EUR,
      date: '2021-07-03',
      recordId: 'ef7dfaba-db0a-11eb-a293-0242ac160007',
      accountingCategory: null,
      credit: 467000,
      debit: 517001,
    },
    {
      '@id': '/api/v1/accounting_entries/f001e47e-db0a-11eb-805d-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Paiement Deal dropped - Transaction #4',
      amount: 50000,
      currency: ECurrency.EUR,
      date: '2021-07-03',
      recordId: 'eff38852-db0a-11eb-bdbe-0242ac160007',
      accountingCategory: null,
      credit: 467000,
      debit: 517001,
    },
    {
      '@id': '/api/v1/accounting_entries/efc1a346-db0a-11eb-9757-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Paiement Deal no order - Transaction #3',
      amount: 100000,
      currency: ECurrency.EUR,
      date: '2021-07-03',
      recordId: 'efb661d4-db0a-11eb-bd54-0242ac160007',
      accountingCategory: null,
      credit: 467000,
      debit: 517001,
    },
    {
      '@id': '/api/v1/accounting_entries/ef390a9a-db0a-11eb-9756-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Deal settled - Transaction #1 - TVA',
      amount: 16667,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'ef1e80ee-db0a-11eb-b84f-0242ac160007',
      accountingCategory: null,
      credit: 445730,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/ef2cd522-db0a-11eb-8698-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: {
        '@id': '/api/v1/crm/people/01F9K3F5H8N34NNHN7SMWD36H7',
        '@type': 'Person',
        firstName: 'Admin',
        lastName: 'E2E TEST',
      },
      name: 'Deal settled - Transaction #1',
      amount: 83333,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'ef1e80ee-db0a-11eb-b84f-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/756000',
        '@type': 'AccountingCategory',
        name: 'Adhésions',
      },
      credit: 756000,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/f02e8e8e-db0a-11eb-b17d-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Deal in_progress - Transaction #5 - TVA',
      amount: 16667,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'f01b24b6-db0a-11eb-b0d2-0242ac160007',
      accountingCategory: null,
      credit: 445730,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/f02626cc-db0a-11eb-bfb3-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Deal in_progress - Transaction #5',
      amount: 83333,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'f01b24b6-db0a-11eb-b0d2-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/756000',
        '@type': 'AccountingCategory',
        name: 'Adhésions',
      },
      credit: 756000,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/efec411e-db0a-11eb-bdad-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Deal dropped - Transaction #4 - TVA',
      amount: 16667,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'efd8dc1e-db0a-11eb-96f4-0242ac160007',
      accountingCategory: null,
      credit: 445730,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/efe37dfe-db0a-11eb-baed-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Deal dropped - Transaction #4',
      amount: 83333,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'efd8dc1e-db0a-11eb-96f4-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/756000',
        '@type': 'AccountingCategory',
        name: 'Adhésions',
      },
      credit: 756000,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/efaf5a60-db0a-11eb-890b-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Deal no order - Transaction #3 - TVA',
      amount: 16667,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'ef9e3ff0-db0a-11eb-b3bb-0242ac160007',
      accountingCategory: null,
      credit: 445730,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/efa83e9c-db0a-11eb-8883-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Deal no order - Transaction #3',
      amount: 83333,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'ef9e3ff0-db0a-11eb-b3bb-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/756000',
        '@type': 'AccountingCategory',
        name: 'Adhésions',
      },
      credit: 756000,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/ef7694b4-db0a-11eb-9fbe-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Deal settled - Transaction #2 - TVA',
      amount: 16667,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'ef64dfbc-db0a-11eb-b710-0242ac160007',
      accountingCategory: null,
      credit: 445730,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/ef6ebf0a-db0a-11eb-bd1e-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: {
        '@id':
          '/api/v1/accounting_third_parties/ef5dce02-db0a-11eb-84cf-0242ac160007',
        '@type': 'AccountingThirdParty',
        name: 'Test ThirdParty',
      },
      person: null,
      name: 'Deal settled - Transaction #2',
      amount: 83333,
      currency: ECurrency.EUR,
      date: '2021-07-02',
      recordId: 'ef64dfbc-db0a-11eb-b710-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/756000',
        '@type': 'AccountingCategory',
        name: 'Adhésions',
      },
      credit: 756000,
      debit: 467000,
    },
    {
      '@id': '/api/v1/accounting_entries/f1cc6432-db0a-11eb-a7c9-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Recette en N+1',
      amount: 678800,
      currency: ECurrency.EUR,
      date: '2020-09-30',
      recordId: 'f1c37a2a-db0a-11eb-a8c4-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/756000',
        '@type': 'AccountingCategory',
        name: 'Adhésions',
      },
      credit: 756000,
      debit: 411000,
    },
    {
      '@id': '/api/v1/accounting_entries/f1959eb6-db0a-11eb-a8a2-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Recette - Paiement ultérieur N+1 - Paiement du 30/09/2020',
      amount: 89000,
      currency: ECurrency.EUR,
      date: '2020-09-30',
      recordId: 'f18d9a22-db0a-11eb-9f0b-0242ac160007',
      accountingCategory: null,
      credit: 411000,
      debit: 512001,
    },
    {
      '@id': '/api/v1/accounting_entries/f1699046-db0a-11eb-a012-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Recette - Paiement ultérieur - paiement en attente',
      amount: 0,
      currency: ECurrency.EUR,
      date: '2020-09-30',
      recordId: 'f162a06a-db0a-11eb-ad2c-0242ac160007',
      accountingCategory: null,
      credit: 0,
      debit: 0,
    },
    {
      '@id': '/api/v1/accounting_entries/f14d3ab8-db0a-11eb-87e6-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Recette - Paiement ultérieur - Paiement du 30/09/2020',
      amount: 90000,
      currency: ECurrency.EUR,
      date: '2020-09-30',
      recordId: 'f146b5a8-db0a-11eb-b324-0242ac160007',
      accountingCategory: null,
      credit: 411000,
      debit: 512001,
    },
    {
      '@id': '/api/v1/accounting_entries/f1178ef4-db0a-11eb-a94f-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Achat avec CCA - Ouverture d’exercice (CCA)',
      amount: 10000,
      currency: ECurrency.EUR,
      date: '2020-09-08',
      recordId: 'f1118bbc-db0a-11eb-ab71-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 486000,
      debit: 622000,
    },
    {
      '@id': '/api/v1/accounting_entries/f268c91c-db0a-11eb-b717-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Test',
      amount: 10000,
      currency: ECurrency.EUR,
      date: '2020-09-07',
      recordId: 'f25f82e4-db0a-11eb-afa6-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/681100',
        '@type': 'AccountingCategory',
        name: 'Dotations aux amortissements sur immobilisations incorporelles et corporelles',
      },
      credit: 151000,
      debit: 681100,
    },
    {
      '@id': '/api/v1/accounting_entries/f10da66e-db0a-11eb-ac97-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Achat avec CCA - Part constatée d avance',
      amount: 10000,
      currency: ECurrency.EUR,
      date: '2020-09-07',
      recordId: 'f1079e54-db0a-11eb-8154-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 622000,
      debit: 486000,
    },
    {
      '@id': '/api/v1/accounting_entries/f218e2ee-db0a-11eb-b292-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Saisie avancée',
      amount: 90000,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f20aaa30-db0a-11eb-aaa3-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/681100',
        '@type': 'AccountingCategory',
        name: 'Dotations aux amortissements sur immobilisations incorporelles et corporelles',
      },
      credit: 681100,
      debit: 0,
    },
    {
      '@id': '/api/v1/accounting_entries/f212998e-db0a-11eb-bf7c-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Saisie avancée',
      amount: 90000,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f20aaa30-db0a-11eb-aaa3-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 0,
      debit: 622000,
    },
    {
      '@id': '/api/v1/accounting_entries/f1fd1c12-db0a-11eb-a6c6-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Retrait d espèces - compte Compte sans IBAN',
      amount: 9000,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f1f53dee-db0a-11eb-a0fb-0242ac160007',
      accountingCategory: null,
      credit: 580000,
      debit: 531001,
    },
    {
      '@id': '/api/v1/accounting_entries/f22d8370-db0a-11eb-b5a0-0242ac160007',
      '@type': 'AccountingEntry',
      accountingThirdParty: null,
      person: null,
      name: 'Saisie avancée tiers',
      amount: 13500,
      currency: ECurrency.EUR,
      date: '2020-09-01',
      recordId: 'f224f2c8-db0a-11eb-a752-0242ac160007',
      accountingCategory: {
        '@id': '/api/v1/accounting_categories/622000',
        '@type': 'AccountingCategory',
        name: "Rémunérations d'intermédiaires et honoraires",
      },
      credit: 0,
      debit: 622000,
    },
  ],
  'hydra:totalItems': 57,
  'hydra:view': {
    '@id':
      '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries?itemsPerPage=25&page=1',
    '@type': 'hydra:PartialCollectionView',
    'hydra:first':
      '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries?itemsPerPage=25&page=1',
    'hydra:last':
      '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries?itemsPerPage=25&page=3',
    'hydra:next':
      '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries?itemsPerPage=25&page=2',
  },
  'hydra:search': {
    '@type': 'hydra:IriTemplate',
    'hydra:template':
      '/api/v1/organizations/01F9K3EY0JZP1QB9BPJSKSBTW7/accounting_entries{?amount[between],amount[gt],amount[gte],amount[lt],amount[lte],has_breakdown,date[before],date[strictly_before],date[after],date[strictly_after],account,category_type,accountingRecord.organization,accountingRecord}',
    'hydra:variableRepresentation': 'BasicRepresentation',
    'hydra:mapping': [
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[between]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[gt]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[gte]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[lt]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'amount[lte]',
        property: 'amount',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'has_breakdown',
        property: 'has_breakdown',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[before]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[strictly_before]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[after]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'date[strictly_after]',
        property: 'date',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'account',
        property: 'account',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'category_type',
        property: 'category_type',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'accountingRecord.organization',
        property: 'accountingRecord.organization',
        required: false,
      },
      {
        '@type': 'IriTemplateMapping',
        variable: 'accountingRecord',
        property: 'accountingRecord',
        required: false,
      },
    ],
  },
}
