import { Display } from 'components'
import { Header, ModalContainer } from 'components/Modal/Confirm/style'
import { createIntl } from 'hooks'
import { useModal } from 'providers'
import { EModalType, TNewModal } from 'providers/type'
import { RawIntlProvider } from 'react-intl'
import { ModalBody } from 'reactstrap'

import AlertModalConfirmButton from './components/AlertModalButton'
import ConfirmModalButtons from './components/ConfirmModalButtons'
import RequiredKeywordModalForm from './components/SubmitKeywordsModalForm'
import {
  isAlertModal,
  isConfirmModal,
  isDeleteModal,
  isSubmitKeywordsModal,
} from './utils'

const NewModal = <T extends EModalType>({ ...modal }: TNewModal<T>) => {
  const { closeModal, isProcessingCallback, setIsProcessingCallback } =
    useModal()
  const intl = createIntl('components_new_modal')

  const { content, size, title } = modal

  const handleClose = () => {
    setIsProcessingCallback(true)
    modal.callbackClose && modal.callbackClose()
    setIsProcessingCallback(false)
    closeModal()
  }

  const handleConfirm = async () => {
    if (isProcessingCallback) {
      return
    }
    setIsProcessingCallback(true)
    if (isConfirmModal(modal) || isSubmitKeywordsModal(modal)) {
      modal.callbackConfirm()
    }
    setIsProcessingCallback(false)
    closeModal()
  }

  return (
    <RawIntlProvider value={intl}>
      <ModalContainer
        centered={true}
        isOpen={true}
        size={size}
        toggle={handleClose}
      >
        <Header className="pb-0" toggle={handleClose}>
          {title && (
            <Display type="h5" className="mb-0">
              {title}
            </Display>
          )}
        </Header>
        <ModalBody>
          {content}

          {isAlertModal(modal) && (
            <AlertModalConfirmButton
              confirmButtonText={modal.confirmButtonText}
              handleConfirm={handleConfirm}
            />
          )}

          {(isConfirmModal(modal) || isDeleteModal(modal)) && (
            <ConfirmModalButtons
              handleClose={handleClose}
              isProcessingCallback={isProcessingCallback}
              confirmButtonText={modal.confirmButtonText}
              handleConfirm={handleConfirm}
              isDelete={isDeleteModal(modal)}
            />
          )}

          {isSubmitKeywordsModal(modal) && (
            <RequiredKeywordModalForm
              confirmButtonText={modal.confirmButtonText}
              handleConfirm={handleConfirm}
              handleClose={handleClose}
              isProcessingCallback={isProcessingCallback}
              requiredKeyword={modal.requiredKeyword}
            />
          )}
        </ModalBody>
      </ModalContainer>
    </RawIntlProvider>
  )
}
export default NewModal
