import { Col, Row, Svg } from 'components'
import { useTranslation } from 'hooks'
import * as Utils from 'pages/Payment/utils'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { Collapse } from 'reactstrap'
import { EColor } from 'types'
import { EBrand, getBrandName } from 'utils'

import * as Type from './type'

const BrandDisplay: FC<Type.IBrandDisplay> = ({ pageData, isCollapseOpen }) => (
  <>
    <div className="d-none d-md-block">
      <BrandContent pageData={pageData} />
    </div>
    <Collapse isOpen={isCollapseOpen} className="d-md-none">
      <BrandContent pageData={pageData} />
    </Collapse>
  </>
)

const BrandContent: FC<Type.IBrandContent> = ({ pageData }) => {
  const intl = useIntl()
  const translation = useTranslation(intl)
  return (
    <div className="pt-4">
      {translation.translate(
        `explanations.${Utils.getSubscriptionPricingType(
          pageData.organization.subscriptionType
        )}.${pageData.retributionMethod}.description`,
        {
          brand: getBrandName(pageData.organization.brand),
        }
      )}
      <Row className="mt-4 pt-1">
        {pageData.organization.brand !== EBrand.SPRINGLY && (
          <Col>
            <div className="d-flex align-items-center">
              <Svg
                src="common/logo/logo-simple"
                width="38px"
                color={EColor.BLUE}
              />
              <span className="pl-2 text-primary">
                {translation.translate('brand.baseline', {
                  brand: getBrandName(pageData.organization.brand),
                })}
              </span>
            </div>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default BrandDisplay
