import {
  AbsoluteDateRange,
  Background,
  Col,
  Form,
  ListItem,
  Row,
  Title,
} from 'components'
import { Formik } from 'formik'
import { FC } from 'react'
import { EColor } from 'types'

const StorybookPage: FC = () => {
  const min = '2022-05-01'
  const max = '2022-07-01'

  return (
    <>
      <Title type="h1" className="mb-4">
        Form / AbsoluteDateRange
      </Title>

      <Background title="Information">
        <ul>
          <ListItem color={EColor.RED}>
            This component does not have a disabled state
          </ListItem>
        </ul>
      </Background>

      <Title type="h2" className="my-4">
        AbsoluteDateRange
      </Title>
      <Formik
        initialValues={{
          dateRange_start: '',
          dateRange_end: '',
        }}
        onSubmit={(values) => {
          alert(JSON.stringify(values))
        }}
        children={() => (
          <Form>
            <Row>
              <Col>
                <AbsoluteDateRange
                  label="Date range"
                  names={{
                    base: 'dateRange',
                    start: 'dateRange_start',
                    end: 'dateRange_end',
                  }}
                  required={true}
                />
              </Col>
            </Row>
          </Form>
        )}
      />

      <Title type="h2" className="my-4">
        AbsoluteDateRange with min = {min} & max = {max}
      </Title>
      <Formik
        initialValues={{
          dateRange_start: '',
          dateRange_end: '',
        }}
        onSubmit={(values) => {
          alert(JSON.stringify(values))
        }}
        children={() => (
          <Form>
            <Row>
              <Col>
                <AbsoluteDateRange
                  min={min}
                  max={max}
                  label="Date range"
                  names={{
                    base: 'dateRange',
                    start: 'dateRange_start',
                    end: 'dateRange_end',
                  }}
                  required={true}
                />
              </Col>
            </Row>
          </Form>
        )}
      />

      <Title type="h2" className="my-4">
        AbsoluteDateRange with values = {min} - {max}
      </Title>
      <Formik
        initialValues={{
          dateRange_start: min,
          dateRange_end: max,
        }}
        onSubmit={(values) => {
          alert(JSON.stringify(values))
        }}
        children={() => (
          <Form>
            <Row>
              <Col>
                <AbsoluteDateRange
                  label="Date range"
                  names={{
                    base: 'dateRange',
                    start: 'dateRange_start',
                    end: 'dateRange_end',
                  }}
                  required={true}
                />
              </Col>
            </Row>
          </Form>
        )}
      />
    </>
  )
}

export default StorybookPage
