import { Background, Form, ListItem, Timezone, Title } from 'components'
import { Formik } from 'formik'
import { FC } from 'react'
import { EColor } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Timezone
    </Title>

    <Background title="Information">
      <ul>
        <ListItem color={EColor.BLUE}>
          This component display depends on the given timezone :
          <ul>
            <ListItem color={EColor.BLUE}>
              "manual" with a Select element if the current timezone is "UTC" or
              undefined
            </ListItem>
            <ListItem color={EColor.BLUE}>
              "automatic" with a hidden input field for all the other timezones
            </ListItem>
          </ul>
        </ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component with timezone type = manual (automatic is a hidden field)
    </Title>
    <Formik
      initialValues={{
        timezone: '',
        timezoneRequired: '',
        timezoneAutomatic: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Timezone name="timezone" timezone="UTC" />
          <Timezone name="timezoneRequired" timezone="UTC" required={true} />
          <Timezone name="timezoneAutomatic" />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
