import {
  getOrganization,
  getOrganizationLegacyDetails,
  getOrganizationSubscription,
  getOrganizationSubscriptionSpecifications,
} from 'api'
import { IApiOrganization } from 'api/type'
import { useApiError } from 'hooks'
import LegacyRedirect from 'pages/LegacyRedirect'
import { OrganizationContext } from 'providers'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useAsyncRetry } from 'react-use'

const OrganizationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const params = useParams()
  const apiError = useApiError()

  const [currentOrganizationId, setCurrentOrganizationId] = useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    if (!params.organizationId) {
      return
    }

    const organizationId = parseInt(params.organizationId)

    if (params.organizationId !== currentOrganizationId) {
      setCurrentOrganizationId(organizationId.toString())
    }
  }, [params.organizationId, currentOrganizationId])

  const {
    value: organization,
    loading,
    retry,
  } = useAsyncRetry(async () => {
    try {
      if (!currentOrganizationId) {
        return undefined
      }

      const organizationData = await getOrganization(currentOrganizationId)

      const getMainOrganization = (
        parent: IApiOrganization
      ): IApiOrganization => {
        if (parent.parent) {
          return getMainOrganization(parent.parent)
        }
        return parent
      }

      const [subscriptionSpecifications, subscription] = await Promise.all([
        getOrganizationSubscriptionSpecifications(organizationData.id).catch(
          () => ({
            '@context': '',
            '@id': '',
            '@type': '',
            canSubscribeToAPayingSubscription: false,
            hasAnActiveSubscription: false,
            hasAllInOneSubscription: false,
            hasFreeSubscription: false,
            hasPayingSubscription: false,
            hasTrialSubscription: false,
            isInRecoverySystem: false,
            isRecoverySystemSuspended: false,
            platformIsImportedFromJoinly: false,
            possibleNewPlans: undefined,
            nonprofitCanHaveAPspWallet: false,
          })
        ),
        getOrganizationSubscription(organizationData.id).catch(() => null),
      ])

      // legacy details
      const legacyDetails = await getOrganizationLegacyDetails(
        organizationData.id
      )

      return {
        ...organizationData,
        idOld: parseInt(params.organizationId || ''),
        subscription: {
          data: subscription,
          specifications: subscriptionSpecifications,
        },
        mainOrganization: getMainOrganization(organizationData),
        hasIndependentCommunity: legacyDetails.hasIndependentCommunity,
        hasIndependentSite: legacyDetails.hasIndependentSite,
        logo: {
          url: organizationData.logoUrl,
          height: legacyDetails.logo?.height,
          width: legacyDetails.logo?.width,
        },
        slug: legacyDetails.slug,
      }
    } catch (error: unknown) {
      apiError.handleApiError(error)
      return null
    }
  }, [currentOrganizationId])

  if (params.organizationId && isNaN(parseInt(params.organizationId))) {
    return <LegacyRedirect />
  }

  if (!organization) {
    return <></>
  }

  return (
    <OrganizationContext.Provider value={{ organization, loading, retry }}>
      {children}
    </OrganizationContext.Provider>
  )
}

export default OrganizationProvider
