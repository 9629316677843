import { createElement } from 'react'
import styled, { css } from 'styled-components'

import * as Type from './type'
import { ETitleFont } from './type'

const H1 = css`
  font-family: ${(props) => props.theme.fontSecondary};
  font-weight: 600;

  @media (max-width: ${(props) => props.theme['gridBreakpoints-sm']}) {
    font-size: 1.6rem;
  }
`
const H2 = css`
  color: ${(props) => props.theme.blue};
`
const H3 = css`
  font-family: ${(props) => props.theme.fontPrimary};
  font-weight: 700;
`
const H4 = css`
  font-family: ${(props) => props.theme.fontSecondary};
  color: ${(props) => props.theme.blue};
  font-weight: 600;
`
const H5 = css`
  @media (max-width: ${(props) => props.theme['gridBreakpoints-sm']}) {
    font-size: 1.2rem;
  }
`
const Hn: Type.IHn = { h1: H1, h2: H2, h3: H3, h4: H4, h5: H5 }

export const Title = styled(
  ({
    children,
    color,
    font,
    icon,
    iconColor,
    type,
    typeElement,
    uppercase,
    ...rest
  }) => createElement(typeElement || type, rest, children)
)`
  ${({ type }) => Hn[type] || ''}
  ${({ font }) => {
    if (font) {
      return `
        font-family: ${font};
        font-weight: ${font === ETitleFont.ROBOTO ? '700' : '600'};
      `
    }
  }}
  color: ${({ color, theme }) => color && theme[color]};
  ${({ uppercase }) =>
    (uppercase === true && 'text-transform: uppercase;') ||
    (uppercase === false && 'text-transform: none;')}

  & > sup {
    font-size: 1rem;
    vertical-align: super;
  }
  & > sub {
    font-size: 1rem;
    font-weight: 400;
    vertical-align: middle;
  }
`
