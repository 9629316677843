import { Button, Icon, Svg, Title } from 'components'
import { createIntlWithNavigator, useTranslation } from 'hooks'
import { EColor, EYado } from 'types'

const Fallback = () => {
  const intl = createIntlWithNavigator('components_fallback')
  const translation = useTranslation(intl)
  return (
    <div className="container text-center my-5">
      <Title className="mb-4" type="h1">
        {translation.translate('title')}
      </Title>
      <Svg src={`common/yado/${EYado.LAPTOP}`} className="mb-3" width="300px" />
      <br />
      <Button color={EColor.BLUE} onClick={() => window.history.back()}>
        <Icon icon={['far', 'chevron-left']} className="mr-2" />
        {translation.translate('backButton')}
      </Button>
    </div>
  )
}

export default Fallback
