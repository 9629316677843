import { Background, ListItem, Svg, Title } from 'components'
import { FC } from 'react'
import { EYado } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Yado
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>Yado component</ListItem>
      </ul>
    </Background>

    {Object.values(EYado).map((yado) => (
      <div key={yado} className="text-center my-4">
        <Title type="h5">{yado}</Title>
        <Svg src={`common/yado/${yado}`} width="350px" />
        <hr />
      </div>
    ))}
  </>
)

export default StorybookPage
