import { Background, Col, Row, Title } from 'components'
import { FC } from 'react'
import { EColor } from 'types'

const colors = {
  black: '#333333',
  white: '#ffffff',
  lightGrey: '#cacaca',
  grey: '#999999',
  darkGrey: '#626262',
  blue: '#316bf2',
  darkBlue: '#0040d7',
  lightBlue: '#e6edfd',
  green: '#00c1a2',
  darkGreen: '#00aa8e',
  lightGreen: '#99e5df',
  red: '#f36052',
  darkRed: '#e0574a',
  lightRed: '#fabfba',
  cosmopolitan: '#c40016',
  yellow: '#f6c131',
  lightYellow: '#f7e3a9',
  darkYellow: '#f9ac29',
  florida: '#f29849',
  lightFlorida: '#f8c598',
}

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Colors
    </Title>
    <Row>
      {Object.entries(colors).map(([color, hexa], index) => (
        <Col key={index} lg={4}>
          <Background
            color={color as EColor}
            title={`${color}: ${hexa}`}
          ></Background>
        </Col>
      ))}
    </Row>
  </>
)

export default StorybookPage
