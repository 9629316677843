import { Background, Button, ListItem, ModalConfirm, Title } from 'components'
import { EConfirmIntent } from 'components/Modal/Confirm/type'
import { createIntl } from 'hooks'
import { FC, useState } from 'react'
import { RawIntlProvider } from 'react-intl'
import { EColor } from 'types'

const lorem = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
elementum, augue in porta interdum, lacus ligula interdum massa, et vulputate
felis turpis id mi. Praesent libero mi, volutpat dignissim ipsum et, porttitor
pharetra ligula. Etiam sem magna, iaculis id maximus et, laoreet nec velit.
In fermentum magna vitae arcu dictum, eget ornare enim volutpat. Phasellus
non nisi a turpis laoreet porta auctor ac odio. Maecenas et lorem ut justo
vulputate fringilla. Nulla viverra lacus urna, a malesuada felis cursus a.
Praesent sit amet lacinia felis. Vivamus scelerisque enim non condimentum
consequat. Nullam ac tellus varius, iaculis ligula et, consectetur sem.
Vivamus lacus lacus, porttitor ut sodales sit amet, aliquam id quam. Sed
lacinia pulvinar nulla consequat rutrum. Fusce vitae tellus ligula. Sed vitae
enim turpis. Fusce non ipsum nisi. Nulla ac mi vitae lorem elementum consequat.`

const StorybookPage: FC = () => {
  const intl = createIntl('pages_storybook')
  const [confirmConfirmIsOpen, setConfirmConfirmIsOpen] = useState(false)
  const [confirmDeleteIsOpen, setDeleteIsOpen] = useState(false)

  return (
    <RawIntlProvider value={intl}>
      <Title type="h1" className="mb-4">
        ModalConfirm
      </Title>

      <Background title="Information">
        <ul>
          <ListItem>The title is optional and contains string</ListItem>
          <ListItem>The text contains string and components</ListItem>
          <ListItem>
            The modal can be displayed from any event: button click, link click,
            api request callback, ...
          </ListItem>
          <ListItem>
            The button styles are not customizable
            <ul>
              <ListItem color={EColor.BLACK}>
                <strong>link</strong> for a cancel button
              </ListItem>
              <ListItem color={EColor.BLUE}>
                <strong>blue</strong> for a confirm button
              </ListItem>
              <ListItem color={EColor.RED}>
                <strong>red</strong> for a delete button
              </ListItem>
            </ul>
          </ListItem>
          <ListItem>
            The buttons (confirm/delete and cancel) texts have a default value
            but are customizable
          </ListItem>
        </ul>
      </Background>

      <Title type="h3" className="my-4">
        ModalConfirm with button type = confirm
      </Title>
      <Button onClick={() => setConfirmConfirmIsOpen(true)}>
        Click to open
      </Button>
      <ModalConfirm
        title="Lorem ipsum"
        description={<>{lorem}</>}
        isOpen={confirmConfirmIsOpen}
        callbackCancel={async () => setConfirmConfirmIsOpen(false)}
        callbackConfirm={async () => setConfirmConfirmIsOpen(false)}
      />

      <Title type="h3" className="my-4">
        ModalConfirm with button type = delete
      </Title>
      <Button onClick={() => setDeleteIsOpen(true)}>Click to open</Button>
      <ModalConfirm
        title="Lorem ipsum"
        description={<>{lorem}</>}
        isOpen={confirmDeleteIsOpen}
        callbackCancel={async () => setDeleteIsOpen(false)}
        callbackConfirm={async () => setDeleteIsOpen(false)}
        type={EConfirmIntent.DELETE}
      />
    </RawIntlProvider>
  )
}

export default StorybookPage
