import { EmptySpace } from 'components'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'
import { RawIntlProvider } from 'react-intl'
import { EYado } from 'types'

const PaymentRequestInactive: FC = () => {
  const intlPath = 'pages_payment_request_inactive'
  const intl = createIntl(intlPath)
  const translation = useTranslation(intl)

  return (
    <RawIntlProvider value={intl}>
      <EmptySpace
        message={translation.translate('description')}
        title={translation.translate('title')}
        yado={EYado.ACCESS_DENIED}
      />
    </RawIntlProvider>
  )
}

export default PaymentRequestInactive
