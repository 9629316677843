import { Form, Numeric, Title } from 'components'
import { ENumericType } from 'components/Form/Numeric/type'
import { Formik } from 'formik'
import { FC } from 'react'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Numeric
    </Title>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        numeric: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Numeric
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            name="numeric"
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Currency type
    </Title>
    <Formik
      initialValues={{
        numericCurrency: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Numeric
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            name="numericCurrency"
            numberType={ENumericType.CURRENCY}
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Percentage type
    </Title>
    <Formik
      initialValues={{
        numericPercentage: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Numeric
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            name="numericPercentage"
            numberType={ENumericType.PERCENTAGE}
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Append / Prepend
    </Title>
    <Formik
      initialValues={{
        numericAppendPrepend: '',
        numericAppendPrependIcon: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Numeric
            name="numericAppendPrepend"
            label="Lorem ipsum"
            prepend={{ text: 'Text' }}
            append={{ text: 'Text' }}
          />
          <br />
          <Numeric
            name="numericAppendPrependIcon"
            label="Lorem ipsum"
            prepend={{ icon: 'search' }}
            append={{ icon: 'pen' }}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Clear
    </Title>
    <Formik
      initialValues={{
        numericClearable: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Numeric
            name="numericClearable"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            isClearable={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Disabled state
    </Title>
    <Formik
      initialValues={{
        numericDisabled: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Numeric
            name="numericDisabled"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            disabled={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without help text
    </Title>
    <Formik
      initialValues={{
        numericWithoutHelptext: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Numeric
            name="numericWithoutHelptext"
            label="Lorem ipsum"
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without label
    </Title>
    <Formik
      initialValues={{
        numericWithoutLabel: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Numeric name="numericWithoutLabel" required={true} />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
