import { getPaginatedData } from 'api'
import { API } from 'api/connector'
import {
  IApiBankAccount,
  IDetailedBankAccount,
  IDetailedBankAccounts,
} from 'api/type'
import { FormikValues } from 'formik'
import { IOrganization } from 'providers/Organization/type'
import { electronicFormatIBAN } from 'utils'

const formatBankAccountForAPI = (values: FormikValues): FormikValues => {
  const bankAccountValues = values
  // Format IBAN if needed
  if (values.iban) {
    bankAccountValues.iban = electronicFormatIBAN(values.iban)
  }
  return bankAccountValues
}

export const getBankAccount = async (
  bankAccountId: string
): Promise<IApiBankAccount> => {
  const bankAccount = await API.get(`/api/v1/bank_accounts/${bankAccountId}`)
  return bankAccount.data
}

export const createBankAccount = async (
  id: string,
  values: FormikValues
): Promise<IApiBankAccount> => {
  const bankAccount = await API.post(`/api/v1/bank_accounts`, {
    ...formatBankAccountForAPI(values),
    organization: `/api/v1/organizations/${id}`,
  })
  return bankAccount.data
}

export const deleteBankAccount = async (
  id: string
): Promise<{ status: number }> => API.delete(`/api/v1/bank_accounts/${id}`)

export const updateBankAccount = async (
  id: string,
  values: FormikValues
): Promise<IApiBankAccount> => {
  const bankAccount = await API.put(`/api/v1/bank_accounts/${id}`, {
    ...formatBankAccountForAPI(values),
  })
  return bankAccount.data
}

export const getDetailedBankAccountsCollection = async (
  organizationId: IOrganization['id']
): Promise<IDetailedBankAccounts> =>
  getPaginatedData<IDetailedBankAccount>(
    `/api/v1/organizations/${organizationId}/bank_accounts`,
    { detailed: true }
  )
