import { Background, Col, ListItem, ResultsCard, Row, Title } from 'components'
import { createIntl } from 'hooks'
import { FC } from 'react'
import { RawIntlProvider } from 'react-intl'
import { EColor } from 'types'

const StorybookPage: FC = () => {
  const intl = createIntl('pages_storybook')
  return (
    <RawIntlProvider value={intl}>
      <Title type="h1" className="mb-4">
        Result Card
      </Title>

      <Background title="Information">
        <ul>
          <ListItem>
            The component is composed of:
            <ul>
              <ListItem>
                A first block with a status, a warning text and a menu with
                different items. All optional.
              </ListItem>
              <ListItem>An optional image.</ListItem>
              <ListItem>
                An array of blocks containing an icon, a title (if you want bold
                text), a text and a list of items. All are optional.
              </ListItem>
              <ListItem>An optional array of badges (icon + text)</ListItem>
              <ListItem>An optional text footer aligned on the right.</ListItem>
            </ul>
          </ListItem>
          <ListItem>Concerning images: </ListItem>
          <ul>
            <ListItem>
              If you want to add a specific image, pass the link to the
              component{' '}
            </ListItem>
            <ListItem>
              If you want to add the default image, pass '' to the component{' '}
            </ListItem>
            <ListItem>
              If you don't want to add an image, don't pass anything to the
              component (undefined){' '}
            </ListItem>
          </ul>
        </ul>
      </Background>

      <Title type="h2" className="my-4">
        Component
      </Title>
      <Row>
        <Col lg={5}>
          <ResultsCard
            statuses={[
              {
                text: 'Published',
                icon: { icon: ['fas', 'circle'], color: EColor.GREEN },
              },
            ]}
            title={{
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
              icon: { icon: ['fas', 'eye'] },
            }}
            subtitle="Lorem ipsum dolor sit amet"
            tooltip={{
              color: EColor.RED,
              icon: ['fas', 'exclamation-circle'],
              text: 'Warning text',
            }}
            image=""
            menuOptions={[
              { text: 'Lorem ipsum' },
              { text: 'Other ipsum', href: '/test', hasDivider: true },
              {
                text: 'Lorem',
                onClick: () => {
                  console.log('click')
                },
              },
            ]}
            blocks={[
              [
                {
                  blockTitle:
                    'From 08/09/2022 at 3pm until 10/15/2022 at 4pm, meeting at Place de la Bastille',
                  content: 'Place de la Bastille, Paris',
                  icon: { color: EColor.BLUE, icon: ['fas', 'exclamation'] },
                },
              ],
              [
                {
                  blockTitle: 'From 08/09/2022 at 3pm until 10/15/2022',
                  content: 'Somewhere in Paris',
                },
              ],
              [
                {
                  content: 'Lorem ipsum dolor sit amet',
                  icon: { color: EColor.GREEN, icon: ['fas', 'exclamation'] },
                },
                {
                  content: 'Place de la Concorde, Paris',
                  icon: { color: EColor.GREEN, icon: ['fas', 'exclamation'] },
                },
              ],
              [
                {
                  content: 'Lorem ipsum dolor sit amet without icon',
                },
                {
                  content: 'Lorem ipsum',
                },
              ],
              [
                {
                  blockTitle: 'Your list',
                  icon: { color: EColor.BLUE, icon: ['fas', 'coins'] },
                  list: [
                    { listContent: '100 contacts can receive an email' },
                    { listContent: '2 contacts unsubscribed' },
                    { listContent: '98 contacts have no email' },
                  ],
                },
              ],
            ]}
            badges={[
              {
                icon: ['fas', 'users'],
                text: '180',
                tooltip: 'Number of subscribers',
                isLoading: true,
              },
              {
                icon: ['fas', 'coins'],
                text: '500€',
                tooltip: 'Amount collected',
                isLoading: false,
              },
            ]}
            footer="Modified on September 9th, 2022 at 2pm"
          />
        </Col>
      </Row>
    </RawIntlProvider>
  )
}

export default StorybookPage
