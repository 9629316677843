import { IApiWorkflowAction } from 'api/WorkflowActions/type'
import { IApiOrganizationAccountingYear } from 'api/type'
import { Amount } from 'types'

export enum EAccountingYearStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CLOSING_STEP1 = 'CLOSING_STEP1',
  CLOSING_STEP2 = 'CLOSING_STEP2',
  CANCEL_CLOSING = 'CANCEL_CLOSING',
}

export enum EClosingStage {
  UNCLOSED = 'UNCLOSED',
  LATE_CLOSING = 'LATE_CLOSING',
  CLOSING_ONGOING = 'CLOSING_ONGOING',
  CLOSED = 'CLOSED',
}

export enum EAccountingPeriodStage {
  FUTURE = 'FUTURE',
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
}

export interface IApiAccountingYearsClosing
  extends IApiOrganizationAccountingYear {
  result: Amount
}

export enum EAccountingYearsClosingActions {
  TO_CHECK = 'to_check',
  TO_REQUEST_CLOSING = 'to_request_closing',
  TO_CLOSE = 'to_close',
  TO_CANCEL = 'to_cancel',
  REOPEN = 'reopen',
}

export enum EAccountingYearsClosingCheckActions {
  CHECK = 'check',
  UNCHECK = 'uncheck',
}

export interface IApiAccountingYearsCheck {
  '@id': string
  type: EAccountingYearsCheckType
  checked: boolean
  isDeletable: boolean
  ['workflow:actions']: IApiWorkflowAction<EAccountingYearsClosingCheckActions>[]
  children: Array<IApiAccountingYearsCheckOption>
}

interface IApiAccountingYearsCheckOption {
  '@id': string
  type: EAccountingYearsCheckOptions
  checked: boolean
  canBeChecked: boolean
  errors: string[] | undefined
  ['workflow:actions']: IApiWorkflowAction<EAccountingYearsClosingCheckActions>[]
}

enum EAccountingYearsCheckType {
  HISTORY = 'history',
  TREASURY = 'treasury',
  PAYABLES_RECEIVABLES = 'payablesReceivables',
  EXPENSES_INCOMES = 'expensesIncomes',
  INVENTORY = 'inventory',
}

export enum EAccountingYearsCheckOptions {
  OPENING_BALANCE = 'openingBalance',
  BANKS = 'banks',
  PETTY_CASH = 'pettyCash',
  ONLINE_ACCOUNT = 'onlineAccount',
  EXPENSE_REVENUE_JUSTIFICATION = 'expenseRevenueJustification',
  DEFERRED_REVENUE_OR_PREPAID_EXPENSES = 'deferredRevenueOrPrepaidExpenses',
  PROVISIONS = 'provisions',
  INVOICES_NOT_RECEIVED = 'invoicesNotReceived',
  INVENTORY_VALUATION = 'inventoryValuation',
}
