import { ERetributionType } from 'api/type'
import { Background, Col, Display, Row } from 'components'
import Dinero from 'dinero.js'
import { useTranslation } from 'hooks'
import TipsBlock from 'pages/Payment/components/Tips'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { EColor } from 'types'
import { getFormattedAmount } from 'utils'

import * as Style from './style'
import * as Type from './type'

const PaymentSummary: FC<Type.IPaymentSummary> = ({
  tipAmountMethods,
  areTipsAuthorized,
  installmentPlan,
  pageData,
  totalNoTip,
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)

  const noTip = Dinero({
    amount: 0,
    currency: pageData.price.currency,
  })
  const tipAmount = areTipsAuthorized ? tipAmountMethods.get() : noTip

  return (
    <>
      {(pageData.retributionMethod !== ERetributionType.TIPS ||
        !areTipsAuthorized) && (
        <Background className="px-4 py-3">
          <Row className="d-flex align-items-baseline justify-content-between">
            <Col xs="auto" lg={12}>
              <Display type="h5" className="font-weight-bold mb-0 mt-2">
                {translation.translate('total')}
              </Display>
            </Col>
            <Col xs="auto">
              <Style.Total color={EColor.BLUE} className="font-weight-bold">
                {getFormattedAmount(totalNoTip.add(tipAmount))}
              </Style.Total>
            </Col>
          </Row>
        </Background>
      )}
      {pageData.retributionMethod === ERetributionType.TIPS &&
        areTipsAuthorized &&
        pageData.publicContext.TIPS_SUGGESTIONS && (
          <Background className="px-4 py-3">
            <TipsBlock
              tipAmountMethods={tipAmountMethods}
              tips={pageData.publicContext.TIPS_SUGGESTIONS}
              organization={pageData.organization}
              totalNoTip={totalNoTip}
              installmentPlan={installmentPlan}
              callbackConfirm={async (confirmValues) => {
                if (confirmValues?.tip === undefined) {
                  return
                }

                tipAmountMethods.set(
                  Dinero({
                    amount: confirmValues.tip,
                    currency: pageData.price.currency,
                  })
                )
              }}
            />
          </Background>
        )}
    </>
  )
}

export default PaymentSummary
