import {
  EAccountingPeriodStage,
  EAccountingYearsClosingActions,
  EAccountingYearStatus,
  EBankStatementStatus,
  EClosingStage,
  EPspWalletAdyenJobTitle,
  ERetributionType,
  ESubscriptionPlan,
  EThirdPartyType,
  IAddress,
  IApiBankAccount,
  IApiBankAccountWithPspDetails,
  IApiReceivedCheck,
  IApiWorkflowAction,
  INonprofit,
  TKycError,
} from 'api/type'
import { IOrganization } from 'providers/type'
import { Amount } from 'types'
import { EBrand } from 'utils'

export interface IApiOrganizationAddress extends IAddress {
  id: string
  formattedAddress: string | null
  organization: string
}

export interface IApiOrganizationAddressUpdate extends IAddress {
  id?: string
  organization?: string
}

export interface IApiOrganizationInvoices {
  '@id': string
  accountingExportStatus: string
  accountingRecordId: string
  amount: Amount
  date: string
  endsAt: string
  id: string
  number: string
  startsAt: string
}

export interface IApiOrganizationPspWalletsPspBankAccounts {
  id: string
  bankAccount: string
  kycError: TKycError
  kycStatus: EApiKycStatus
}
export enum EAPIPspLifecycleStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  VALID = 'VALID',
  CLOSED = 'CLOSED',
  ERROR = 'ERROR',
  FROZEN = 'FROZEN',
}

export enum EApiKycStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  VALID = 'VALID',
  REJECTED = 'REJECTED',
}

export enum EMerchantAccount {
  ASSOCONNECT_ECOM = 'AssoConnectECOM',
  ASSOCONNECT_US = 'AssoConnectUS',
}

export enum EPayoutRiskScore {
  EXTREME = 'EXTREME',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
  NONE = 'NONE',
}

export interface IApiPayment {
  '@id': string
  areTipsAuthorized: boolean
  retributionMethod: ERetributionType
  organization: string
}

export interface IApiOrganization {
  '@id': string
  '@type': string
  brand: EBrand
  createdAt: string
  id: string
  isAccountingConfigured: boolean
  isAdvanced: boolean
  isLegalIndependent: boolean
  isNonprofitMainGroup: boolean
  logoUrl: string
  name: string
  parent: null | IApiOrganization
  phoneNumber: null | string
  url: string
}

export enum ESEPAComplianceStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  REFUSED = 'REFUSED',
  VALIDATED = 'VALIDATED',
}
export interface IApiOrganizationPspWallet {
  '@id': string
  appId: string
  pspId: string
  blockedAt: string
  createdAt: string
  compliance: {
    '@id': string
    '@type': 'PspWalletCompliance'
    id: string
    isDeletable: boolean
    periodicReviewDeadline: null | string
    sepaComplianceStatus: ESEPAComplianceStatus
  }
  id: string
  jobTitle: EPspWalletAdyenJobTitle | null
  isBalancePlatform: boolean
  isBlocked: boolean
  isSuspended: boolean
  lastDigitsUsSocialSecurityNumber: string | null
  kycStatus: EApiKycStatus
  organization: string
  pspLifecycleStatus: EAPIPspLifecycleStatus
  pspName: string
  suspendedAt: string
  person: string
  merchantAccount: EMerchantAccount
  riskScore: EPayoutRiskScore
}

export type IApiOrganizationPspWalletWithMandatoryReview = Omit<
  IApiOrganizationPspWallet,
  'compliance'
> & {
  compliance: Omit<
    IApiOrganizationPspWallet['compliance'],
    'periodicReviewDeadline'
  > & {
    periodicReviewDeadline: string
  }
}

export interface IApiOrganizationPspSubWallet {
  '@id': string
  id: string
  isProcessingPayout: boolean
  canRequestNewPayout: boolean
  isCurrent: boolean
}

export interface IApiOrganizationAccountingThirdParty {
  id: string
  humanId: string
  name: string
  type: EThirdPartyType
  isArchived: boolean
  isDeletable: boolean
  nonprofit: Array<INonprofit>
}

export interface IApiOrganizationAccountingYear {
  '@id': string
  '@type': string
  id: string
  endsAt: string
  startsAt: string
  status: EAccountingYearStatus
  periodStage: EAccountingPeriodStage
  isEditable: boolean
  isDeletable: boolean
  closingStage: EClosingStage
  closingAt: string
  nonprofit: Array<{
    '@id': INonprofit['@id']
    '@type': INonprofit['@type']
    id: INonprofit['id']
  }>
  ['workflow:actions']: IApiWorkflowAction<EAccountingYearsClosingActions>[]
}

export interface IApiOrganizationGroup {
  '@id': string
  '@type': string
  id: string
  name: string
  isAdvanced: boolean
  isLegalIndependent: boolean
  logoUrl: string
  brand: EBrand
}

export type IBankAccountsList = Array<
  IApiBankAccount | IApiBankAccountWithPspDetails
>

export enum ETaxReceiptStatus {
  VALID = 'VALID',
  CANCELLED = 'CANCELLED',
}

export interface IApiTaxReceipts {
  '@id': string
  '@type': string
  number: string
  personFullName: string
  email: string
  amount: Amount
  date: string
  collectName: string
  status: ETaxReceiptStatus
  transactionId: number
}

export interface ITaxReceiptsGlobalSettings {
  isEnabled: boolean
  startDate: string | null
}

export interface IApiOrganizationStatsCrm {
  structures: number
  people: number
  inSubscription: number
}

type IApiSubscriptionFees = {
  card: {
    base: Amount
    percent: number
  }
  sepa: {
    base: Amount
    percent: number
  }
}

export interface IApiOrganizationSubscription {
  '@context': string
  '@id': string
  '@type': string
  id: string
  startsAt: string | null
  endsAt: string | null
  type: string
  planReference: string | ESubscriptionPlan
  fees: IApiSubscriptionFees
}

export interface IApiOrganizationSubscriptionSpecifications {
  '@context': string
  '@id': string
  '@type': string
  canSubscribeToAPayingSubscription: boolean
  hasAnActiveSubscription: boolean
  hasFreeSubscription: boolean
  hasPayingSubscription: boolean
  hasTrialSubscription: boolean
  hasAllInOneSubscription: boolean
  isInRecoverySystem: boolean
  isRecoverySystemSuspended: boolean
  platformIsImportedFromJoinly: boolean
  possibleNewPlans:
    | undefined
    | {
        COMMUNICATION?: string
        COMPTABILITE?: string
        LIBERTE?: string
        LIBERTY_US?: string
        PAIEMENT?: string
        PRO?: string
        PRO_US?: string
        SERENITE?: string
        SERENITY_US?: string
      }
  nonprofitCanHaveAPspWallet: boolean // equivalent to "can access to online payments"
}

export interface IApiOrganizationCollectSpecifications {
  '@context': string
  '@id': string
  '@type': string
  firstDonationCollect: boolean
  firstEventCollect: boolean
  firstMembershipCollect: boolean
  firstProductCollect: boolean
  hasAtLeastOneCollectRelatedAppEnabled: boolean
  hasAtLeastOneCollect: boolean
}

export interface IApiOrganizationCrmSpecifications {
  '@context': string
  '@id': string
  '@type': string
  hasCrm: boolean
  hasFullCrm: boolean
  hasSimplifiedCrm: boolean
  hasAtLeastOneAdvancedChild: boolean
  hasMoreThanOneContact: boolean
}

export interface IApiOrganizationEmailingSpecifications {
  hasAtLeastOneEmailCampaign: boolean
}

enum EEmailingAppLockReason {
  REASON_CAMPAIGN_BLOCK = 'CAMPAIGN_BLOCK',
  REASON_HARD_BOUNCE = 'HARD_BOUNCE',
  REASON_SPAM = 'SPAM',
}

export interface IApiOrganizationAppEmailing {
  '@context': string
  '@id': string
  '@type': string
  locked: boolean
  quota: number
  lockReason: EEmailingAppLockReason
  id: string
}

interface IApiOrganizationMember {
  '@context': string
  '@id': string
  '@type': string
  id: string
  name: string
  contactType: string
  type: string
  category: string
  possibleValues: Array<string>
}

export interface IApiCustomFieldsSchema {
  '@context': string
  '@id': string
  '@type': string
  member: IApiOrganizationMember[]
  'hydra:totalItems': number
}

export enum EApiContactType {
  PERSON = 'PERSON',
  STRUCTURE = 'STRUCTURE',
}

export interface IApiOrganizationExport {
  succes: boolean
}

export interface IApiCheckRemittance {
  '@id': string
  '@context': string
  '@type': string
  id: string
  humanId: string
  bankAccount: IApiBankAccount
  bankStatementStatus: EBankStatementStatus
  date: string
  isDeletable: boolean
  organization: string
  organizationName: string
  receivedChecks: Array<IApiReceivedCheck>
  amount: Amount
  recordId: string
}

export interface INotificationSpecifications {
  isInRecovery: boolean
  canBookDemo: boolean
  generateMeetingMinutes: boolean
  canBookWebinar: boolean
  isWebsiteUnderMaintenance: boolean
  isWalletBlocked: boolean
  isWalletPending: boolean
  isWalletRefused: boolean
  isWalletSuspended: boolean
  isWalletFrozen: boolean
  isWalletNew: boolean
  paymentRequestPromotion: boolean
  walletRedirection: string
}

export interface IGroupsResults {
  data: Array<IApiOrganizationGroup>
  totalItems: number
}

export type IMenuItems = Array<
  IMenuHeaderBlock | IMenuLinkBlock | IGroupMenuBlock
>

export interface IMenuHeaderBlock {
  '@id': string
  '@type': EMenuBlockType.HEADER_BLOCK
  id: string
  links: Array<IMenuBlockLinks>
  organizationName: string
  picture: string
  priority: number
  trialBlock: null | {
    '@type': string
    '@id': string
    id: string
    remainingTrialDaysNumber: number
    subscriptionPagePath: string
  }
  userFullname: string
}

export interface IMenuLinkBlock {
  '@id': string
  '@type': EMenuBlockType.LINK_MENU_BLOCK
  id: string
  highlighted: boolean // items as settings or header
  icon: string
  label: string
  path: string
  priority: number
}

export interface IGroupMenuBlock {
  '@id': string
  '@type': EMenuBlockType.GROUP_MENU_BLOCK
  id: string
  icon: string
  label: string
  links: Array<IMenuBlockLinks>
  priority: number
}

export enum EMenuBlockType {
  HEADER_BLOCK = 'HeaderBlock',
  LINK_MENU_BLOCK = 'LinkMenuBlock',
  GROUP_MENU_BLOCK = 'GroupMenuBlock',
}

interface IMenuBlockLinks {
  '@id': string
  '@type': 'GroupLinkMenuBlock'
  id: string
  label: string
  path: string
  icon: string
  links?: Array<IMenuBlockLinks>
}

export interface IPaymentSpecifications {
  '@context': string
  '@id': string
  '@type': 'PaymentSpecifications'
  organizationCanUseSepa: boolean
  nonprofitCanUseSepa: boolean
}

export interface IOrganizationLegacyDetails {
  idOld: number
  logo: IOrganization['logo'] | null
  hasIndependentCommunity: boolean
  hasIndependentSite: boolean
  slug: string
  independentServices: {
    community: {
      idOld: number
    }
  }
}

enum EAlgoliaKeyType {
  CONTACT = 'ContactAlgoliaKey',
  THIRD_PARTY = 'ThirdPartyAlgoliaKey',
}
export interface IAlgoliaKey {
  '@context': string
  '@id': string
  '@type': EAlgoliaKeyType
  appId: string
  expiresAt: string
  key: string
  indexName: string
}

export interface IApiOrganizationEmailCampaignsResults {
  data: Array<IApiOrganizationEmailCampaigns>
  totalItems: number
}

export interface IApiOrganizationEmailCampaigns {
  '@id': string
  '@type': string
  '@context': string
  id: string
  subject: string
  sender: {
    '@context': string
    '@id': string
    '@type': string
  }
  schedule: string
  organization: {
    '@context': string
    '@id': string
    '@type': string
    name: string
  }
  status: string
}
