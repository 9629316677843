import { Form, Title, Url } from 'components'
import { Formik } from 'formik'
import { FC } from 'react'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Url
    </Title>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        url: '',
        urlRequired: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Url name="url" />
          <Url name="urlRequired" required={true} />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
