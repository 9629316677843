import { Display } from 'components'
import styled from 'styled-components'

export const Total = styled(({ children, ...props }) => (
  <Display {...props}>{children}</Display>
))`
  font-size: 18px;

  @media (min-width: ${(props) => props.theme['gridBreakpoints-lg']}) {
    font-size: 60px;
  }
`
