import * as Sentry from '@sentry/react'
import { combineReducers, createStore } from 'redux'
import i18n from 'state/I18n/slice'
import loader from 'state/Loader/slice'
import menu from 'state/Menu/slice'
import notifications from 'state/Notifications/slice'
import page from 'state/Page/slice'
import person from 'state/Person/slice'
import settings from 'state/Settings/slice'
import sidePanel from 'state/SidePanel/slice'
import specifications from 'state/Specifications/slice'
import supportMessage from 'state/SupportMessage/slice'

const reducer = combineReducers({
  i18n,
  loader,
  notifications,
  page,
  person,
  settings,
  sidePanel,
  specifications,
  supportMessage,
  menu,
})

const sentryReduxEnhancer = Sentry.createReduxEnhancer()

export default createStore(reducer, sentryReduxEnhancer)
