import { Alert, Background, Col, ListItem, Row, Title } from 'components'
import { EAlertColor } from 'components/Alert/type'
import { FC } from 'react'
import { EColor } from 'types'

const text = `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus
            animi atque doloribus eius et facere hic in iste iusto magni
            mollitia nobis pariatur perspiciatis reiciendis reprehenderit saepe,
            sed similique suscipit!`
const colors: Array<EAlertColor> = [
  EColor.BLUE,
  EColor.GREEN,
  EColor.FLORIDA,
  EColor.RED,
]

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Alert
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>The message contains string and components</ListItem>
        <ListItem color={EColor.RED}>
          The icon depends on the color and is not customizable
        </ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Alert title="Lorem ipsum" dismissible={true}>
      {text}
    </Alert>
    <Alert title="Lorem ipsum" outline={false} dismissible={true}>
      {text}
    </Alert>
    <Title type="h2" className="my-4">
      Without title
    </Title>
    <Alert dismissible={true}>{text}</Alert>
    <Alert outline={false} dismissible={true}>
      {text}
    </Alert>
    <Title type="h2" className="my-4">
      Without dismissible cross button
    </Title>
    <Alert title="Lorem ipsum">{text}</Alert>
    <Alert outline={false} title="Lorem ipsum">
      {text}
    </Alert>
    <Title type="h2" className="my-4">
      Colors
    </Title>
    {colors.map((color, index) => (
      <Row key={index}>
        <Col>
          <Alert color={color} key={color}>
            {color}
          </Alert>
        </Col>
        <Col>
          <Alert color={color} key={color} outline={false}>
            {color}
          </Alert>
        </Col>
      </Row>
    ))}
  </>
)

export default StorybookPage
