import { IApiPaymentRequestRefusalReason } from 'api/type'
import { Amount } from 'types'

export enum EOrderPaymentStatus {
  ABORTED = 'ABORTED',
  AUTHORIZED = 'AUTHORIZED',
  ERROR = 'ERROR',
  FAILED = 'FAILED',
  PLANNED = 'PLANNED',
  REFUSED = 'REFUSED',
  REQUESTED = 'REQUESTED',
  SETTLED = 'SETTLED',
  UNKNOWN = 'UNKNOWN',
}

export enum EInstallmentPaymentStatus {
  PLANNED = 'PLANNED',
  IN_PROGRESS = 'IN_PROGRESS',
  SETTLED = 'SETTLED',
  DROPPED = 'DROPPED',
}

export interface IPspAggregate extends IApiPaymentRequestRefusalReason {
  appId: string
  status: EOrderPaymentStatus
  date: string
}

export interface IApiOrderPayment {
  '@id': string
  pspAggregate: IPspAggregate
}

export interface IApiOrderInstallment {
  '@id': string
  dueAt: string
  amount: Amount
  status: EInstallmentPaymentStatus
  payments: IApiOrderPayment[]
  paymentMethodNeedToBeChanged: boolean
  paymentErrorMessage: null | string
}

export interface IApiOrder {
  '@id': string
  amount: Amount
  transactionId: number
  installments: IApiOrderInstallment[]
}
