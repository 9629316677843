import { Badge, Button, Col, Link, Row } from 'components'
import { EIconPosition } from 'components/Badge/type'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'

const Setting: FC<Type.ISetting> = ({
  button,
  children,
  description,
  dynamicWidth = true,
  link,
  status,
  title,
}) => {
  const getSettingContent = () => {
    if (button) {
      return (
        <Button
          color={EColor.BLUE}
          outline={true}
          onClick={() => {
            button.handleClick()
          }}
        >
          {button.text}
        </Button>
      )
    } else if (link) {
      return (
        <Link href={link.href}>
          <Button color={EColor.BLUE} outline={true}>
            {link.text}
          </Button>
        </Link>
      )
    } else if (status) {
      return (
        <div className="d-flex justify-content-start justify-content-lg-end">
          <Badge
            icon={status.icon}
            color={status.color}
            text={status.text}
            iconPosition={EIconPosition.LEFT}
          />
        </div>
      )
    }
    return children
  }

  return (
    <Style.Setting className="mt-4 pt-4 pl-4 pr-4">
      <Row className="align-items-center">
        <Col lg={9}>
          <strong color={EColor.DARK_GREY} className="mb-0 d-block">
            {title}
          </strong>
          <small>{description}</small>
        </Col>
        <Col lg={3} className="mt-lg-0 mt-3">
          <Row className="flex-column align-items-end">
            <Col {...(dynamicWidth && { xs: 'auto' })}>
              {getSettingContent()}
            </Col>
          </Row>
        </Col>
      </Row>
    </Style.Setting>
  )
}

export default Setting
