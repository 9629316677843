import {
  AbsoluteDate,
  Background,
  Col,
  Form,
  ListItem,
  Row,
  Title,
} from 'components'
import { Formik } from 'formik'
import { FC } from 'react'
import { EColor } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / AbsoluteDate
    </Title>

    <Background title="Information">
      <ul>
        <ListItem color={EColor.RED}>
          This component does not have a disabled state
        </ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        datetime: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <AbsoluteDate
            helpText="Lorem ipsum..."
            label="Lorem ipsum"
            name="datetime"
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Disabled state
    </Title>
    <Formik
      initialValues={{
        datetimeDisabled: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <AbsoluteDate
            helpText="Lorem ipsum..."
            label="Lorem ipsum"
            name="datetimeDisabled"
            disabled={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without help text
    </Title>
    <Formik
      initialValues={{
        datetimeWithoutHelptext: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <AbsoluteDate
            name="datetimeWithoutHelptext"
            label="Lorem ipsum"
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without label
    </Title>
    <Formik
      initialValues={{
        datetimeWithoutLabel: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <AbsoluteDate name="datetimeWithoutLabel" required={true} />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Manual date range
    </Title>
    <Formik
      initialValues={{
        datetimeStart: '',
        datetimeEnd: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={({ values }) => (
        <Form>
          <Row>
            <Col>
              <AbsoluteDate
                label="Start date"
                max={values.datetimeEnd}
                name="datetimeStart"
                required={true}
              />
            </Col>
            <Col>
              <AbsoluteDate
                label="End date"
                min={values.datetimeStart}
                name="datetimeEnd"
                required={true}
              />
            </Col>
          </Row>
        </Form>
      )}
    />
  </>
)

export default StorybookPage
