import { ISortedAccount } from 'api/type'

export interface IAccountingAccount {
  '@id': string
  officialName: string
  displayName: string
  shortCustomName: string
  type: EAccountingAccountType
  status: EAccountingAccountStatus
  category: string
  accountNumber: string
  isArchived: boolean
}

export enum EAccountingAccountType {
  REVENUE = 'REVENUE',
  EXPENSE = 'EXPENSE',
  TREASURY = 'TREASURY',
  OTHER = 'OTHER',
}

export enum EAccountingAccountStatus {
  DEFAULT = 'DEFAULT',
  CUSTOM = 'CUSTOM',
  ARCHIVED = 'ARCHIVED',
}

export enum EAccountingAccountAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  EXPORT = 'EXPORT',
  UNARCHIVE = 'UNARCHIVE',
  ARCHIVE = 'ARCHIVE',
}

export interface IUnifiedAccounts {
  data: ISortedAccount[]
  taxonNames: string[]
}
