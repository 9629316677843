import dompurify from 'dompurify'
import { useIframeHeightListener } from 'hooks'
import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const HTMLContent: FC<Type.IHTMLContent> = ({ html, ...rest }) => {
  const { isIframe } = useIframeHeightListener()
  const purifiedDOM = dompurify.sanitize(html, {
    ADD_ATTR: ['target'],
  })

  const cleanDOM = isIframe
    ? purifiedDOM.replaceAll('target="_self"', 'target="_blank"')
    : purifiedDOM

  return (
    <Style.HTMLContentContainer
      dangerouslySetInnerHTML={{
        __html: cleanDOM,
      }}
      {...rest}
    />
  )
}

export default HTMLContent
