import { format as fnsFormat } from 'date-fns'

import { getDateFnsLocale } from '../Date'

export const formatTime = (date: Date, locale?: string): string => {
  const dateLocale = getDateFnsLocale(locale)
  return fnsFormat(date, 'p', dateLocale)
}

export const formatDateTime = (
  date: Date,
  locale?: string,
  pattern = 'PPP, p'
): string => fnsFormat(date, pattern, getDateFnsLocale(locale))

/**
 * Get milliseconds in days from given number of days
 */
export const getMillisecondsInDays = (numberOfDays: number): number =>
  Math.abs(86400 * 1000 * numberOfDays)
