import { useAscRouter } from 'hooks'
import LegacyRedirect from 'pages/LegacyRedirect'
import BusinessAccountDiscover from 'pages/ProAccount/Discover'
import { ProAccountProvider } from 'providers'
import { FC } from 'react'
import { Route, Routes } from 'react-router'
import { ERouterType } from 'routers/type'

const ProAccount: FC = () => {
  const { RouteLayout } = useAscRouter(ERouterType.ADMIN)

  return (
    <ProAccountProvider>
      <Routes>
        <Route
          path="/discover"
          element={
            <RouteLayout
              component={BusinessAccountDiscover}
              fullWidthStyle={true}
            />
          }
        />
        <Route path="/*" element={<LegacyRedirect />} />
      </Routes>
    </ProAccountProvider>
  )
}

export default ProAccount
