import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPerson } from 'api/type'
import {
  IStorePerson,
  IStorePersonAdminMasterRoles,
  IStorePersonRoles,
} from 'state/type'

const personInitialState: Omit<IStorePerson, '@context' | '@id' | '@type'> = {
  email: '',
  dateOfBirth: null,
  firstName: '',
  lastName: '',
  globalRoles: {},
  isMasterAdmin: undefined,
  roles: {},
  adminMasterRoles: {},
  contactId: '',
}

const personSlice = createSlice({
  name: 'person',
  initialState: personInitialState,
  reducers: {
    setPerson: (state, action: PayloadAction<IPerson>) =>
      Object.assign({}, state, action.payload),
    setPersonRoles: (state, action: PayloadAction<IStorePersonRoles>) => ({
      ...state,
      roles: action.payload,
    }),
    setPersonAdminMasterRoles: (
      state,
      action: PayloadAction<IStorePersonAdminMasterRoles>
    ) => ({
      ...state,
      adminMasterRoles: action.payload,
    }),
    setPersonGlobalRoles: (
      state,
      action: PayloadAction<IStorePersonRoles>
    ) => ({
      ...state,
      globalRoles: action.payload,
    }),
  },
})

export const {
  setPerson,
  setPersonRoles,
  setPersonGlobalRoles,
  setPersonAdminMasterRoles,
} = personSlice.actions
export default personSlice.reducer
