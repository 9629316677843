import { Background, Col, Helper, Link, ListItem, Row, Title } from 'components'
import { FC } from 'react'
import { EColor } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Helper
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>The helper is a button containing an icon</ListItem>
        <ListItem>Link and Tooltip are optional</ListItem>
        <ListItem>Size can be "md" or "lg"</ListItem>
        <ListItem>
          Background color and icon color can be everything from{' '}
          <Link href="/storybook/foundations/colors">the color list</Link>
        </ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Helper />

    <Title type="h2" className="my-4">
      Sizes
    </Title>
    <Row className="align-items-center mb-1">
      <Col lg={2}>md</Col>
      <Col lg={10}>
        <Helper
          button={{
            size: 'md',
            backgroundColor: EColor.DARK_GREY,
            textColor: EColor.WHITE,
          }}
        />
      </Col>
      <Col lg={2}>lg</Col>
      <Col lg={10}>
        <Helper
          button={{
            size: 'lg',
            backgroundColor: EColor.DARK_GREY,
            textColor: EColor.WHITE,
          }}
        />
      </Col>
    </Row>

    <Title type="h2" className="my-4">
      With link
    </Title>
    <Helper link={{ href: 'https://assoconnect.com' }} />

    <Title type="h2" className="my-4">
      With tooltip
    </Title>
    <Helper tooltip={{ text: 'lorem' }} />
  </>
)

export default StorybookPage
