import { Background, Button, ListItem, Select, Title } from 'components'
import { Formik } from 'formik'
import { createIntl, useSelector, useTranslation } from 'hooks'
import { FC } from 'react'
import { useDispatch } from 'react-redux'
import { selectPerson, setSidePanel } from 'state'
import { EColor } from 'types'

const lorem = 'Lorem ipsum...'

const templates = [
  {
    label: 'HelpPanel',
    value: 'HelpPanel',
  },
  {
    label: 'Operation',
    value: 'Operation',
  },
  {
    label: 'Payout',
    value: 'Payout',
  },
]

const StorybookPage: FC = () => {
  const intl = createIntl('pages_storybook')
  const translation = useTranslation(intl)
  const dispatch = useDispatch()
  const person = useSelector(selectPerson)

  return (
    <>
      <Title type="h1" className="mb-4">
        Side panel
      </Title>

      <Background title="Information">
        <ul>
          <ListItem>The title and subtitle contains string</ListItem>
          <ListItem>The subtitle is optional</ListItem>
          <ListItem>
            The confirm can be displayed from any event: button click, link
            click, api request callback, ...
          </ListItem>
          <ListItem>Width: 428px</ListItem>
        </ul>
      </Background>

      <Title type="h2" className="my-4">
        Component
      </Title>
      <Button
        onClick={() => {
          dispatch(
            setSidePanel({
              actions: {},
              options: {},
              isOpen: true,
              template: 'HelpPanel',
              title: lorem,
              subtitle: lorem,
            })
          )
        }}
      >
        Fixed mode
      </Button>

      <Title type="h2" className="my-4">
        Without subtitle
      </Title>
      <Button
        className="mr-2"
        onClick={() => {
          dispatch(
            setSidePanel({
              actions: {},
              options: {},
              isOpen: true,
              template: 'HelpPanel',
              title: lorem,
            })
          )
        }}
      >
        Relative mode
      </Button>
      <Button
        onClick={() => {
          dispatch(
            setSidePanel({
              actions: {},
              options: {},
              isOpen: true,
              template: 'HelpPanel',
              title: lorem,
            })
          )
        }}
      >
        Fixed mode
      </Button>

      <Title type="h2" color={EColor.RED} className="my-4">
        Developper section (Non-regression tests)
      </Title>
      <Formik
        onSubmit={() => {
          // This is intentional to prevent eslint warning
        }}
        initialValues={{
          select: '',
        }}
        children={() => (
          <>
            <Select
              name="select"
              onChangeCallback={(template) => {
                const templateValue = String(template)
                dispatch(
                  setSidePanel({
                    actions: {},
                    options: {
                      id: 'test',
                    },
                    isOpen: true,
                    template: templateValue || '',
                    title: translation.translate(
                      `sidePanel.title.${template}`,
                      { firstname: person.firstName }
                    ),
                    subtitle:
                      intl.messages[`sidePanel.subtitle.${template}`] &&
                      translation.translate(`sidePanel.subtitle.${template}`),
                  })
                )
              }}
              options={templates}
            />
          </>
        )}
      />
    </>
  )
}

export default StorybookPage
