import { EApiKycStatus, IApiOrganizationPspWallet } from 'api/type'

export type TKycError = null | {
  code: number
  message: string
}

export enum EKycCheck {
  BUSINESS = 'business',
  REPRESENTATIVE = 'representative',
  BANK_ACCOUNTS = 'bankAccounts',
  PCI = 'pci',
}

type TSummary = {
  status: EApiKycStatus
  error: TKycError
}

export interface IApiPspWalletAdyenKycSummary {
  [EKycCheck.BANK_ACCOUNTS]: TSummary | null
  [EKycCheck.BUSINESS]: TSummary | null
  [EKycCheck.PCI]: TSummary | null
  [EKycCheck.REPRESENTATIVE]: TSummary | null
}

export interface ISendPspWalletAdyenBankAccountDocument {
  pspWalletId: string
  bankAccountId: string
  documentId: string
}

export interface ISendPspWalletAdyenShareholderDocument {
  pspWalletId: IApiOrganizationPspWallet['id']
  idCardFrontId: string
  idCardBackId: string
  passportId: string
  drivingLicenseFrontId: string
  drivingLicenseBackId: string
  isPeriodicReview?: boolean
}

export interface ISendPspWalletAdyenOrganizationDocument {
  pspWalletId: string
  documentId: string
  isPeriodicReview?: boolean
}

export enum EPspWalletAdyenJobTitle {
  PRESIDENT = 'PRESIDENT',
  VICE_PRESIDENT = 'VICE_PRESIDENT',
  TREASURER = 'TREASURER',
  GENERAL_SECRETARY = 'GENERAL_SECRETARY',
  ADMINISTRATIVE_EMPLOYEE = 'ADMINISTRATIVE_EMPLOYEE',
}

export interface IPspWalletAdyenPciLink {
  redirectUrl: string
}
