import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IAlert } from 'components/Alert/type'
import { EColor } from 'types'

export interface ILongCardContent {
  alert?: IAlert
  items: IContentItems[]
  isArchived: boolean
  isColumn: boolean
  isOpen: boolean
}

export enum EContentType {
  ICON = 'ICON',
  LIST = 'LIST',
}

export type IContentItems =
  | {
      type: EContentType.ICON
      icon: IconProp
      color?: EColor
      label: string
      text: string
    }
  | {
      type: EContentType.LIST
      label: string
      items: Array<string>
    }
