import { IPageData } from 'pages/Payment/type'
import { ReactNode } from 'react'

export interface IPaymentLayout {
  children: ReactNode
  type?: EPaymentLayoutType
}

export enum EPaymentLayoutType {
  ERROR = 'ERROR',
}

export interface IPaymentPayload extends IPageData {
  paymentPageConfigurationId?: string
}
