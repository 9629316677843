export type TStatisticPage = {
  '@id': string
  '@type': string
  id: EStatisticType
  path: string
  name: string
}

export enum EStatisticType {
  GLOBAL = 'StatisticGeneral',
  SALES = 'StatisticSales',
  WEBSITE = 'StatisticCommunication',
  CRM = 'StatisticContacts',
  CUSTOM = 'CustomDashboard',
}
