import { LayoutAdmin } from 'layouts/Admin'
import LayoutMaster from 'layouts/Master'
import LayoutPayment from 'layouts/Payment'
import { LayoutPublic } from 'layouts/Public'
import LayoutSettings from 'layouts/Settings'
import LayoutSignup from 'layouts/Signup'
import LayoutStorybook from 'layouts/Storybook'
import { RouterProvider } from 'providers'
import { FC } from 'react'
import { ERouterType } from 'routers/type'

import * as Type from './type'
import AdminRouter from './types/adminRouter'
import DefaultRouter from './types/defaultRouter'

const useAscRouter = (
  type: ERouterType = ERouterType.DEFAULT,
  keepUrlSearchParameters = false
): { RouteLayout: FC<Type.IRouteLayout> } => {
  const getRoute = (props: Type.IRouteLayout) => {
    switch (type) {
      case ERouterType.ADMIN:
        return <AdminRouter layout={props.layout ?? LayoutAdmin} {...props} />
      case ERouterType.ADMIN_SETTINGS:
        return <AdminRouter layout={LayoutSettings} {...props} />
      case ERouterType.PAYMENT:
        return (
          <DefaultRouter layout={props.layout ?? LayoutPayment} {...props} />
        )
      case ERouterType.PUBLIC:
        return <DefaultRouter layout={LayoutPublic} {...props} />
      case ERouterType.MASTER:
        return <DefaultRouter layout={LayoutMaster} {...props} />
      case ERouterType.SIGNUP:
        return <DefaultRouter layout={LayoutSignup} {...props} />
      case ERouterType.STORYBOOK:
        return <DefaultRouter layout={LayoutStorybook} {...props} />
      case ERouterType.DEFAULT:
      default:
        return <DefaultRouter {...props} />
    }
  }

  const RouteLayout: FC<Type.IRouteLayout> = (props) => (
    <RouterProvider keepUrlSearchParameters={keepUrlSearchParameters}>
      {getRoute(props)}
    </RouterProvider>
  )

  return { RouteLayout }
}

export default useAscRouter
