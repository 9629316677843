import React, { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const ButtonGroup: FC<Type.IButtonGroup> = ({
  children,
  hasEqualWidth,
  isVertical = false,
  ...props
}) => {
  const childrenWidth = !hasEqualWidth
    ? 'auto'
    : (100 / React.Children.count(children)).toFixed(2) + '%'

  return (
    <Style.ButtonGroup
      isVertical={isVertical}
      childrenWidth={childrenWidth}
      {...props}
    >
      {children}
    </Style.ButtonGroup>
  )
}

export default ButtonGroup
