import { API } from 'api/connector'

import { IApiWorkflowAction } from './type'

const getActionPathname = <T,>(
  actions: Array<IApiWorkflowAction<T>>,
  targetAction: IApiWorkflowAction<T>['name']
): IApiWorkflowAction<T>['path'] | undefined =>
  actions.find((action) => action.name === targetAction)?.path

export const isApiActionAvailable = <T,>(
  actions: IApiWorkflowAction<T>[],
  targetAction: IApiWorkflowAction<T>['name']
): boolean => actions.some((action) => action.name === targetAction)

export const applyApiAction = async <T,>(
  actions: IApiWorkflowAction<T>[],
  targetAction: IApiWorkflowAction<T>['name']
): Promise<T | void> => {
  const actionPathname = getActionPathname(actions, targetAction)
  try {
    const response = actionPathname && (await API.put(actionPathname, {}))
    return response && response.data
  } catch {
    throw new Error('Action is not possible')
  }
}
