import { API } from 'api/connector'
import {
  IApiBankAccount,
  IApiCheckRemittance,
  IApiCreateCheckRemittance,
} from 'api/type'

export const getCheckRemittance = async (
  id: IApiCheckRemittance['id']
): Promise<IApiCheckRemittance> => {
  const bankAccount = await API.get(`/api/v1/check_remittances/${id}`)

  return bankAccount.data
}

export const createCheckRemittances = async (
  params: IApiCreateCheckRemittance
): Promise<IApiCheckRemittance> => {
  const checkRemittances = await API.post(`/api/v1/check_remittances`, params)
  return checkRemittances.data
}

export const updateCheckRemittance = async (
  id: IApiCheckRemittance['id'],
  date: string,
  bankAccountId: IApiBankAccount['@id']
): Promise<IApiCheckRemittance> => {
  const updatedCheckRemittance = await API.put(
    `/api/v1/check_remittances/${id}`,
    { date, bankAccount: bankAccountId }
  )
  return updatedCheckRemittance.data
}

export const deleteCheckRemittances = async (
  id: string
): Promise<IApiCheckRemittance> => API.delete(`/api/v1/check_remittances/${id}`)
