import * as Sentry from '@sentry/react'
import { getCrmPersonRoles, getMenuItems, getSupportMessages } from 'api'
import { useSelector, useStoreI18n } from 'hooks'
import { useOrganization, usePerson } from 'providers'
import { useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useDispatch } from 'react-redux'
import {
  selectPerson,
  setPerson,
  setPersonAdminMasterRoles,
  setPersonGlobalRoles,
  setPersonRoles,
} from 'state'
import { setMenu } from 'state/Menu/slice'

import * as Type from './type'

const UseStore = (): Type.IUseStore => {
  const dispatch = useDispatch()
  const storeI18n = useStoreI18n()

  const { organization } = useOrganization()
  const person = useSelector(selectPerson)

  const { person: personProvider } = usePerson()

  const isLoaded = !!(organization.id && person.contactId)
  const [isLoading, setIsLoading] = useState(false)

  const initialize = async () => {
    if (!isLoaded && !isLoading && organization.id && person) {
      setIsLoading(true)

      // Parallel API requests to increase performance
      Promise.all([
        // 0. Person roles
        Promise.resolve(
          getCrmPersonRoles(personProvider.contactId, organization.id)
        ),
        // 1. Support message
        Promise.resolve(getSupportMessages()),
        // 2. Menu Items
        Promise.resolve(getMenuItems(organization.id)),
      ]).then((values) => {
        // Person roles
        const personRoles = values[0]

        // Support message
        const supportMessages = values[1]

        // menuItems
        const menuItems = values[2]

        // Initialize I18n store
        storeI18n.initialize(supportMessages, organization)

        // Set sentry user
        Sentry.setUser({
          email: person.email,
          id: person.contactId,
          username: `${person.firstName} ${person.lastName}`,
        })

        // Set store
        // TODO: React 18 unstable_batchedUpdates: https://github.com/reactwg/react-18/discussions/21
        unstable_batchedUpdates(() => {
          dispatch(setMenu(menuItems))
          dispatch(
            setPerson({
              ...person,
              ...personProvider,
              isMasterAdmin: personRoles.isMasterAdmin,
            })
          )
          dispatch(
            setPersonRoles({
              ...person.roles,
              [organization.id]: personRoles.roles,
            })
          )
          dispatch(
            setPersonGlobalRoles({
              ...person.globalRoles,
              [organization.id]: personRoles.globalRoles,
            })
          )
          dispatch(
            setPersonAdminMasterRoles({
              ...person.adminMasterRoles,
              [organization.id]: personRoles.adminMasterRoles,
            })
          )
        })
        setIsLoading(false)
      })
    }
  }

  return { isLoaded, initialize }
}

export default UseStore
