import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { EColor, ESize } from 'types'

export interface IBadge {
  icon?: IconProp
  className?: string
  color?: EColor
  iconPosition?: EIconPosition
  isBold?: boolean
  size?: IBadgeSize
  text?: string
  textColor?: EColor
  id?: string
}

export enum EIconPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export type IBadgeSize = ESize.XS | ESize.SM | ESize.MD | ESize.LG | ESize.XL

interface IBadgeSizePixel {
  badge: string
  icon: string
}
export interface IBadgeSizes {
  xs: IBadgeSizePixel
  sm: IBadgeSizePixel
  md: IBadgeSizePixel
  lg: IBadgeSizePixel
  xl: IBadgeSizePixel
}
