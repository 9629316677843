import { additionalElements } from 'components/Form/Input/type'
import Dinero, { Currency, Dinero as TDinero } from 'dinero.js'
import { ECurrency } from 'state/type'
import { getCurrencySymbol, getFormattedAmount } from 'utils'

export const getCentsFromFloatValue = (value: string) =>
  Math.round(parseFloat(String(value)) * 100)

const getDisplayCurrency = (currency: Currency) => ({
  [currency === ECurrency.USD ? 'prepend' : 'append']: {
    text: getCurrencySymbol(currency),
  },
})

export const getMoneyDisplayForInput = (value?: TDinero) => {
  const formattedAmount = value
    ? getFormattedAmount(value, false).replace(',', '.')
    : ''
  if (Dinero.globalLocale !== 'fr-FR') {
    return formattedAmount
  }
  return formattedAmount.replace(',', '.')
}

export const getAppendage = (
  currency: Dinero.Currency,
  append?: additionalElements,
  prepend?: additionalElements
) =>
  (append && { append }) ??
  (prepend && { prepend }) ??
  getDisplayCurrency(currency)
