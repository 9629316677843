import { Email, Form, Title } from 'components'
import { Formik } from 'formik'
import { FC } from 'react'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Email
    </Title>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Email helpText="Lorem ipsum..." name="email" required={true} />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Disabled state
    </Title>
    <Formik
      initialValues={{
        emailDisabled: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Email
            helpText="Lorem ipsum..."
            name="emailDisabled"
            disabled={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Custom label
    </Title>
    <Formik
      initialValues={{
        emailCustomLabel: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Email
            name="emailCustomLabel"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without help text
    </Title>
    <Formik
      initialValues={{
        emailWithoutHelptext: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Email name="emailWithoutHelptext" required={true} />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
