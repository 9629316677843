import { ButtonGroup, FormGroup, FormHelper, Helper, Label } from 'components'
import { Field, FieldProps } from 'formik'
import { FC, MouseEvent } from 'react'
import { EColor, ESize } from 'types'

import * as Style from './style'
import * as Type from './type'

const RadioButtons: FC<Type.IRadioButtons> = ({
  buttons,
  color = EColor.GREEN,
  className,
  hasEqualWidth = false,
  helpText,
  isVertical = false,
  label,
  name,
  onChange,
  required = false,
  size = 'lg',
  tooltip,
  ...rest
}) => (
  <Field
    name={name}
    children={({ field, form: { setFieldValue } }: FieldProps) => (
      <FormGroup className={className}>
        {label && (
          <Label isRequired={required} className="d-block">
            {label}
            {tooltip && (
              <Helper
                tooltip={{
                  text: tooltip.text,
                }}
                {...(tooltip.link && { link: tooltip.link })}
                button={{
                  size: ESize.SM,
                  backgroundColor: EColor.DARK_GREY,
                  textColor: EColor.WHITE,
                }}
              />
            )}
          </Label>
        )}

        <ButtonGroup
          size={size}
          hasEqualWidth={hasEqualWidth}
          className="w-100"
          isVertical={isVertical}
        >
          {buttons.map((button, i) => (
            <Style.RadioButtonsButton
              key={i}
              color={color}
              onClick={(event: MouseEvent<HTMLButtonElement>) => {
                setFieldValue(field.name, button.value)
                onChange && onChange(event)
              }}
              active={field.value === button.value}
              {...rest}
            >
              {button.text}
            </Style.RadioButtonsButton>
          ))}
        </ButtonGroup>
        <input {...field} type="hidden" required={required} />
        <FormHelper fieldName={field.name} helpText={helpText} />
      </FormGroup>
    )}
  />
)

export default RadioButtons
