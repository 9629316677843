import { Background, Col, Form, ListItem, Row, Select, Title } from 'components'
import { Formik } from 'formik'
import { FC } from 'react'
import { EChipPosition, EColor } from 'types'

const options = [
  { label: 'Lorem ipsum 1...', value: '1' },
  { label: 'Lorem ipsum 2...', value: '2' },
  { label: 'Lorem ipsum 3...', value: '3' },
]
const optionsGroups = [
  {
    label: 'Lorem ipsum 1...',
    options: [
      { label: 'Lorem ipsum 1...', value: '1' },
      { label: 'Lorem ipsum 2...', value: '2' },
    ],
  },
  {
    label: 'Lorem ipsum 2...',
    options: [{ label: 'Lorem ipsum 3...', value: '3' }],
  },
]

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Select
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>Select height: 32.5px</ListItem>{' '}
        <ListItem color={EColor.RED}>
          Select with chip not compatible with groups
        </ListItem>
        <ListItem>
          The dropdown can be displayed on TOP / BOTTOM / AUTO. Default is
          "AUTO"
        </ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        select: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Select
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            name="select"
            options={options}
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      With third option disabled
    </Title>
    <Formik
      initialValues={{
        selectWithoutHelptext: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Select
            name="selectWithoutHelptext"
            label="Lorem ipsum"
            options={options.map((option) => ({
              isDisabled: option.value === '3',
              ...option,
            }))}
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without help text
    </Title>
    <Formik
      initialValues={{
        selectWithoutHelptext: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Select
            name="selectWithoutHelptext"
            label="Lorem ipsum"
            options={options}
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without label
    </Title>
    <Formik
      initialValues={{
        selectWithoutLabel: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Select name="selectWithoutLabel" options={options} required={true} />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      With groups
    </Title>
    <Formik
      initialValues={{
        selectWithGroups: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Select
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            name="selectWithGroups"
            options={optionsGroups}
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Multi-values
    </Title>
    <Formik
      initialValues={{
        selectMulti: '',
        selectGroupMulti: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Select
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            name="selectMulti"
            options={options}
            isMulti={true}
            required={true}
          />
          <Select
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            name="selectGroupMulti"
            options={optionsGroups}
            isMulti={true}
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Clearable
    </Title>
    <Formik
      initialValues={{
        selectClearable: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Select
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            name="selectClearable"
            options={options}
            isClearable={true}
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      With chips
    </Title>
    <Formik
      initialValues={{
        selectChips: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Select
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            name="selectChips"
            isClearable={true}
            required={true}
            options={[
              {
                chip: { color: EColor.BLUE, position: EChipPosition.LEFT },
                label: 'Lorem ipsum 1...',
                value: '1',
              },
              {
                chip: { color: EColor.GREEN, position: EChipPosition.RIGHT },
                label: 'Lorem ipsum 2...',
                value: '2',
              },
              {
                chip: {
                  color: EColor.YELLOW,
                  position: EChipPosition.LEFT,
                  text: 'Lorem ipsum',
                },
                label: 'Lorem ipsum 3...',
                value: '3',
              },
              {
                chip: {
                  color: EColor.RED,
                  position: EChipPosition.RIGHT,
                  text: 'Lorem ipsum',
                },
                label: 'Lorem ipsum 4...',
                value: '4',
              },
            ]}
          />
        </Form>
      )}
    />
    <Title type="h2" className="my-4">
      Searchable
    </Title>
    <Formik
      initialValues={{ searchable: '', notSearchable: '' }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Row>
            <Col md={6}>
              <Select
                label="searchable"
                name="searchable"
                isSearchable={true}
                options={options}
              />
            </Col>
            <Col md={6}>
              <Select
                label="not searchable"
                name="notSearchable"
                options={options}
              />
            </Col>
          </Row>
        </Form>
      )}
    />
  </>
)

export default StorybookPage
