import { Form, FrenchRna, Title } from 'components'
import { Formik } from 'formik'
import { FC } from 'react'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / FrenchRna
    </Title>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        frenchRna: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <FrenchRna
            helpText="Lorem ipsum..."
            name="frenchRna"
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Disabled state
    </Title>
    <Formik
      initialValues={{
        frenchRnaDisabled: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <FrenchRna
            name="frenchRnaDisabled"
            helpText="Lorem ipsum..."
            disabled={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Custom label
    </Title>
    <Formik
      initialValues={{
        frenchRnaCustomLabel: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <FrenchRna
            name="frenchRnaCustomLabel"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without help text
    </Title>
    <Formik
      initialValues={{
        frenchRnaWithoutHelptext: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <FrenchRna name="frenchRnaWithoutHelptext" required={true} />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
