export type IPricesList = Array<{
  min: number
  max: number | string
  price?: number
  callPrice?: number
}>

export type IPlans =
  | 'communication'
  | 'comptabilite'
  | 'pro'
  | 'serenite'
  | 'serenity_us'
  | 'pro_us'

export type IPriceType = 'contacts' | 'flat'

export interface IPrices {
  communication: IPricesList
  comptabilite: IPricesList
  pro: IPricesList
  serenite: IPricesList
  pro_us: IPricesList
  serenity_us: IPricesList
}

export enum EPriceType {
  SERENITE_2024_04 = 'serenite_2024_04',
  SERENITE_2025_02 = 'serenite_2025_02',
  PRO_2024_04 = 'pro_2024_04',
  PRO_2025_02 = 'pro_2025_02',
  COMMUNICATION_2024_04 = 'communication_2024_04',
  COMMUNICATION_2025_02 = 'communication_2025_02',
  SERENITY_US_2024_04 = 'serenity_us_2024_04',
  SERENITY_US_2025_02 = 'serenity_us_2025_02',
  PRO_US_2024_04 = 'pro_us_2024_04',
  PRO_US_2025_02 = 'pro_us_2025_02',
  COMPTABILITE_2024_04 = 'comptabilite_2024_04',
  COMPTABILITE_2025_02 = 'comptabilite_2025_02',
}
