import { Background, Col, ListItem, Progress, Row, Title } from 'components'
import { FC } from 'react'
import { EColor } from 'types'
import { bootstrapColorParser } from 'utils'

const colors = [EColor.BLUE, EColor.GREEN, EColor.YELLOW, EColor.RED]

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Progress
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>Height size = 20px and not customizable</ListItem>
        <ListItem>If no color is specified, a gradient will be used</ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Progress value={50} className="mt-3" />

    <Title type="h2" className="my-4">
      Colors
    </Title>
    {colors.map((color, index) => (
      <Row key={index} className="align-items-center mb-1">
        <Col lg={2}>{color}</Col>
        <Col lg={10}>
          <Progress value={50} color={bootstrapColorParser(color)} />
        </Col>
      </Row>
    ))}
  </>
)

export default StorybookPage
