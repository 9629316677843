import {
  Background,
  ButtonCardIcon,
  ButtonCardText,
  Col,
  ListItem,
  Row,
  Title,
} from 'components'
import { EButtonCardSize } from 'components/Card/ButtonCard/type'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'
import { RawIntlProvider } from 'react-intl'
import { EColor } from 'types'

const StorybookPage: FC = () => {
  const intl = createIntl('pages_storybook')
  const translation = useTranslation(intl)

  return (
    <RawIntlProvider value={intl}>
      <Title type="h1" className="mb-4">
        Button Card
      </Title>

      <Background title="Information">
        <ul>
          <ListItem>
            This component looks like cards but the whole component is clickable
          </ListItem>
          <ListItem>
            It has two options : ButtonCardIcon or ButtonCardText
          </ListItem>
          <ListItem>
            Look all the different options below for more details
          </ListItem>
        </ul>
      </Background>

      <Title type="h2" className="my-2">
        ButtonCardIcon
      </Title>

      <Title type="h3" className="my-2">
        Background color
      </Title>
      <Row className="align-items-center">
        <Col lg={4}>
          <ButtonCardIcon
            text={translation.translate('card.button')}
            size={EButtonCardSize.BIG}
            svg="common/icon/multicolor/calculator"
            color={EColor.LIGHT_GREY}
          />
          <p className="text-center">BIG</p>
        </Col>
        <Col lg={4}>
          <ButtonCardIcon
            text={translation.translate('card.button')}
            size={EButtonCardSize.MEDIUM}
            svg="common/icon/multicolor/calculator"
            color={EColor.LIGHT_BLUE}
          />
          <p className="text-center">MEDIUM</p>
        </Col>
        <Col lg={4}>
          <ButtonCardIcon
            text={translation.translate('card.button')}
            size={EButtonCardSize.SMALL}
            svg="common/icon/multicolor/calculator"
            color={EColor.LIGHT_GREEN}
          />
          <p className="text-center">SMALL</p>
        </Col>
      </Row>

      <Title type="h3" className="my-2">
        Size
      </Title>
      {Object.keys(EButtonCardSize).map((size, index) => (
        <div key={index}>
          <Title type="h6" className="my-2">
            {size}
          </Title>
          <Row className="align-items-center">
            <Col lg={4}>
              <ButtonCardIcon
                title={translation.translate('card.title')}
                text={translation.translate('card.button')}
                size={size as EButtonCardSize}
                svg="common/icon/multicolor/calculator"
              />
              <p className="text-center">TITLE</p>
            </Col>
            <Col lg={4}>
              <ButtonCardIcon
                text={translation.translate('card.button')}
                size={size as EButtonCardSize}
                svg="common/icon/multicolor/calculator"
                tooltip={translation.translate('card.tooltip')}
              />
              <p className="text-center">TOOLTIP</p>
            </Col>
            <Col lg={4}>
              <ButtonCardIcon
                title={translation.translate('card.title')}
                text={translation.translate('card.button')}
                size={size as EButtonCardSize}
                svg="common/icon/multicolor/calculator"
                tooltip={translation.translate('card.tooltip')}
              />
              <p className="text-center">TITLE + TOOLTIP</p>
            </Col>
          </Row>
        </div>
      ))}

      <hr className="my-5" />

      <Title type="h3" className="my-2">
        Background color
      </Title>
      <Row className="align-items-center">
        <Col lg={4}>
          <ButtonCardText
            text={translation.translate('card.button')}
            size={EButtonCardSize.BIG}
            highlightText={{ text: 150, color: EColor.BLUE }}
            color={EColor.LIGHT_GREY}
          />
          <p className="text-center">BIG</p>
        </Col>
        <Col lg={4}>
          <ButtonCardText
            text={translation.translate('card.button')}
            size={EButtonCardSize.MEDIUM}
            highlightText={{ text: 150, color: EColor.BLUE }}
            color={EColor.LIGHT_BLUE}
          />
          <p className="text-center">MEDIUM</p>
        </Col>
        <Col lg={4}>
          <ButtonCardText
            text={translation.translate('card.button')}
            size={EButtonCardSize.SMALL}
            highlightText={{ text: 150, color: EColor.BLUE }}
            color={EColor.LIGHT_GREEN}
          />
          <p className="text-center">SMALL</p>
        </Col>
      </Row>
      <Title type="h3" className="my-2">
        Size
      </Title>
      {Object.keys(EButtonCardSize).map((size, index) => (
        <div key={index}>
          <Title type="h6" className="my-2">
            {size}
          </Title>
          <Row className="align-items-center">
            <Col lg={4}>
              <ButtonCardText
                title={translation.translate('card.title')}
                text={translation.translate('card.button')}
                size={size as EButtonCardSize}
                highlightText={{ text: 150, color: EColor.BLUE }}
              />
              <p className="text-center">TITLE</p>
            </Col>
            <Col lg={4}>
              <ButtonCardText
                text={translation.translate('card.button')}
                size={size as EButtonCardSize}
                highlightText={{ text: 150, color: EColor.DARK_GREY }}
                tooltip={translation.translate('card.tooltip')}
              />
              <p className="text-center">TOOLTIP</p>
            </Col>
            <Col lg={4}>
              <ButtonCardText
                title={translation.translate('card.title')}
                text={translation.translate('card.button')}
                size={size as EButtonCardSize}
                highlightText={{ text: 150, color: EColor.FLORIDA }}
                tooltip={translation.translate('card.tooltip')}
              />
              <p className="text-center">TITLE + TOOLTIP</p>
            </Col>
          </Row>
        </div>
      ))}
    </RawIntlProvider>
  )
}

export default StorybookPage
