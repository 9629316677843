import { API } from 'api/connector'
import * as Type from 'api/type'
import { IApiNonprofit } from 'api/type'
import { IFilterBag } from 'components/Table/type'

export const getTaxReceipts = async (
  nonProfitId: IApiNonprofit['@id'],
  params: IFilterBag
): Promise<{ data: Type.IApiTaxReceipts[]; totalItems: number }> => {
  const taxReceipts = await API.get(
    `/api/v1/nonprofits/${nonProfitId}/tax_receipts`,
    { params }
  )

  return {
    data: taxReceipts.data['hydra:member'],
    totalItems: taxReceipts.data['hydra:totalItems'],
  }
}

export const getTaxReceiptSettings = async (
  nonProfitId: IApiNonprofit['id']
): Promise<Type.IApiTaxReceiptSettings> => {
  const taxReceiptSettings = await API.get(
    `/api/v1/nonprofits/${nonProfitId}/tax_receipt_settings`
  )
  return taxReceiptSettings.data
}

export const updateNonprofitIdentificationNumber = async (
  nonProfitId: IApiNonprofit['id'],
  employerIdentificationNumber: string,
  name: string
): Promise<IApiNonprofit> => {
  const nonprofit = await API.put(`/api/v1/nonprofits/${nonProfitId}`, {
    employerIdentificationNumber,
    name,
  })
  return nonprofit.data
}

export const getNonProfitMarketingTracking = async (
  nonProfitId: IApiNonprofit['id']
): Promise<Type.INonProfitMarketingTracking> => {
  const nonprofit = await API.get(
    ` /api/v1/nonprofits/${nonProfitId}/marketing_tracking`
  )
  return nonprofit.data
}

export const updateNonProfitTracking = async (
  trackingId: string,
  params: {
    demoBookedAt?: string | null
    estimatedSize?: string | null
    initialNeeds?: string
  }
): Promise<Type.INonProfitMarketingTracking> => {
  const tracking = await API.put(
    `/api/v1/nonprofit_marketing_trackings/${trackingId}`,
    { ...params }
  )
  return tracking.data
}

export const getNonprofitAccountingSettings = async (
  id: IApiNonprofit['id']
): Promise<Type.IApiNonprofitAccountingSettings> => {
  const settings = await API.get(`/api/v1/nonprofits/${id}/accounting_settings`)
  return settings.data
}

export const getNonprofitAccountingSpecification = async (
  id: IApiNonprofit['id']
): Promise<Type.IApiNonprofitAccountingSpecification> => {
  const specification = await API.get(
    `/api/v1/nonprofits/${id}/accounting_specification`
  )
  return specification.data
}
