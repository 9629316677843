import { Form, SelectBankAccounts, Title } from 'components'
import { Formik } from 'formik'
import { FC } from 'react'

import bankAccounts from './data'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Select bank accounts
    </Title>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        selectBankAccounts: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <SelectBankAccounts
            name="selectBankAccount"
            label="Lorem ipsum"
            bankAccounts={bankAccounts}
            displayKycStatus={true}
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without label
    </Title>
    <Formik
      initialValues={{
        selectBankAccountWithoutLabel: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <SelectBankAccounts
            name="selectBankAccountWithoutLabel"
            bankAccounts={bankAccounts}
            displayKycStatus={true}
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without KYC status
    </Title>
    <Formik
      initialValues={{
        selectBankAccountWithoutStatus: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <SelectBankAccounts
            name="selectBankAccountWithoutStatus"
            label="Lorem ipsum"
            bankAccounts={bankAccounts}
            required={true}
          />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
