import { Background, Button, Form, Input, Title, Toucan } from 'components'
import { Formik } from 'formik'
import { FC, useState } from 'react'

const StorybookPage: FC = () => {
  const [viewId, setViewId] = useState('')
  const [organizationId, setOrganizationId] = useState('')
  const [lang, setLang] = useState('')

  return (
    <>
      <Title type="h1" className="mb-4">
        Toucan
      </Title>

      <Formik
        initialValues={{
          viewId,
          organizationId,
          lang,
        }}
        onSubmit={(values) => {
          setViewId(values.viewId)
          setOrganizationId(values.organizationId)
          setLang(values.lang)
        }}
        children={() => (
          <Form>
            <Input name="viewId" label="View ID" required={true} />
            <Input
              name="organizationId"
              label="Organization ID"
              required={true}
            />
            <Input
              name="lang"
              label="Lang"
              helpText="shortcode e.g fr, en"
              required={true}
            />
            <Button type="submit">Update</Button>
          </Form>
        )}
      />

      {viewId && organizationId && lang && (
        <>
          <Title type="h2" className="mt-4">
            Result
          </Title>
          <Background>
            <Toucan
              viewId={viewId}
              organizationId={organizationId}
              lang={lang}
            />
          </Background>
        </>
      )}
    </>
  )
}

export default StorybookPage
