import { IconProp } from '@fortawesome/fontawesome-svg-core'
import classNames from 'classnames'
import { Col, HTMLContent, Icon, Radio, Row } from 'components'
import { FormikValues, useFormikContext } from 'formik'
import { useTranslation } from 'hooks'
import {
  AdyenApplePay,
  AdyenCard,
  AdyenInstallment,
  AdyenSepa,
} from 'pages/Payment/components'
import { EIntent, EPaymentMethods, EPaymentType } from 'pages/Payment/type'
import { FC } from 'react'
import { RawIntlProvider, useIntl } from 'react-intl'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'
import { getPaymentMethodIcons } from './utils'

const getOfflinePaymentIcon = (
  paymentMethod: EPaymentMethods
): null | IconProp => {
  let icon: null | IconProp = null
  switch (paymentMethod) {
    case EPaymentMethods.CASH:
      icon = ['fas', 'coins']
      break
    case EPaymentMethods.CHECK:
      icon = ['fas', 'money-check-edit']
      break
  }
  return icon
}

const PaymentMethod: FC<Type.IPaymentMethod> = ({
  adyenContainersIds,
  brand,
  checkout,
  applePayComponent,
  dinero,
  hasRadio,
  installmentPlan,
  intent,
  locale,
  country,
  offlineInstructions,
  onChange,
  paymentMethod,
  paymentMethods,
  paymentType,
  updatePaymentMethod,
  ...rest
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)

  const { values, setFieldValue } = useFormikContext<FormikValues>()
  const offlinePaymentIcon = getOfflinePaymentIcon(paymentMethod)

  const getPaymentMethodLabel = () => {
    // Specific ADYEN method
    if (paymentMethod === EPaymentMethods.ADYEN && intent === EIntent.CAPTURE) {
      return translation.translate(`capture.label`)
    }

    return translation.translate(
      `paymentMethod.${
        intent === EIntent.METHOD_CHANGE ? intent : paymentMethod
      }.label`
    )
  }

  const paymentMethodLabel = getPaymentMethodLabel()

  return (
    <RawIntlProvider value={intl}>
      <Style.PaymentBlockBackground
        color={EColor.BACKGROUND_COLOR}
        className="py-2 my-3"
        isSelected={paymentMethod === values.paymentMethod}
        onClick={() => {
          if (!hasRadio) {
            return
          }
          void setFieldValue('paymentMethod', paymentMethod)
          updatePaymentMethod(paymentMethod)
        }}
        {...rest}
      >
        <Row
          className={`align-items-${
            hasRadio && paymentType === EPaymentType.ONLINE
              ? 'baseline'
              : 'center'
          }`}
        >
          {hasRadio ? (
            <Col>
              <Radio
                name="paymentMethod"
                value={paymentMethod}
                label={paymentMethodLabel}
                className="mr-2"
                onChange={() => {
                  void setFieldValue('paymentData', '')
                  updatePaymentMethod(paymentMethod)
                }}
              />
            </Col>
          ) : (
            <Col className={classNames({ 'pb-3': !hasRadio })}>
              <input name="paymentMethod" type="hidden" value={paymentMethod} />
              <strong>{paymentMethodLabel}</strong>
            </Col>
          )}

          <Col
            xs={paymentType === EPaymentType.ONLINE ? 'auto' : '1'}
            className="pl-0"
          >
            <div className="d-none d-md-flex align-items-center justify-content-center">
              {paymentType === EPaymentType.ONLINE &&
                getPaymentMethodIcons(paymentMethod)}
              {paymentType === EPaymentType.OFFLINE && offlinePaymentIcon && (
                <Icon
                  icon={offlinePaymentIcon}
                  color={
                    values.paymentMethod === paymentMethod
                      ? EColor.BLUE
                      : EColor.GREY
                  }
                  size="2x"
                />
              )}
            </div>
          </Col>
        </Row>
        {paymentType === EPaymentType.OFFLINE &&
          values.paymentMethod === paymentMethod &&
          offlineInstructions && (
            <div className="pb-3">
              {Object.entries(offlineInstructions).map((instruction, index) => (
                <Style.PaymentBlockDetails key={index}>
                  <HTMLContent
                    html={translation.translate('offlineInstruction', {
                      key: instruction[0],
                      value: instruction[1],
                    })}
                  />
                </Style.PaymentBlockDetails>
              ))}
            </div>
          )}

        {adyenContainersIds.card && (
          <>
            {values.paymentMethod === EPaymentMethods.ADYEN_CB_INSTALLMENT &&
              paymentMethod === EPaymentMethods.ADYEN_CB_INSTALLMENT &&
              installmentPlan && (
                <Style.PaymentBlockDetails>
                  <AdyenInstallment
                    adyenCardComponentId={adyenContainersIds.card}
                    checkout={checkout}
                    installmentPlan={installmentPlan}
                    onChange={onChange}
                    paymentMethod={paymentMethod}
                    paymentMethods={paymentMethods}
                    country={country}
                  />
                </Style.PaymentBlockDetails>
              )}

            {values.paymentMethod === EPaymentMethods.ADYEN &&
              paymentMethod === EPaymentMethods.ADYEN &&
              paymentMethods && (
                <Style.PaymentBlockDetails>
                  <AdyenCard
                    adyenContainerId={adyenContainersIds.card}
                    checkout={checkout}
                    onChange={onChange}
                    paymentMethod={paymentMethod}
                    paymentMethods={paymentMethods}
                    country={country}
                  />
                </Style.PaymentBlockDetails>
              )}
          </>
        )}

        {paymentMethod === EPaymentMethods.ADYEN_APPLE_PAY &&
          applePayComponent &&
          adyenContainersIds.applePay &&
          values.paymentMethod === EPaymentMethods.ADYEN_APPLE_PAY && (
            <Style.PaymentBlockDetails>
              <AdyenApplePay
                adyenContainerId={adyenContainersIds.applePay}
                applePayComponent={applePayComponent}
                brand={brand}
              />
            </Style.PaymentBlockDetails>
          )}

        {adyenContainersIds.sepa &&
          values.paymentMethod === EPaymentMethods.ADYEN_SEPA &&
          paymentMethod === EPaymentMethods.ADYEN_SEPA && (
            <Style.PaymentBlockDetails>
              <AdyenSepa
                adyenContainerId={adyenContainersIds.sepa}
                checkout={checkout}
                onChange={onChange}
                paymentMethod={paymentMethod}
              />
            </Style.PaymentBlockDetails>
          )}
      </Style.PaymentBlockBackground>
    </RawIntlProvider>
  )
}

export default PaymentMethod
