import { EModalSize } from 'components/Modal/type'
import { FormikValues } from 'formik'
import { Dispatch, ReactElement, SetStateAction } from 'react'

export enum EModalType {
  ALERT = 'ALERT',
  BLANK = 'BLANK',
  CONFIRM = 'CONFIRM',
  DELETE = 'DELETE',
  REQUIRED_KEYWORD = 'REQUIRED_KEYWORD',
}

export interface IModalContext {
  modal: TModal | null
  setModal: <T extends EModalType>(modal: TModal<T>) => void
  closeModal: () => void
  isProcessingCallback: boolean
  setIsProcessingCallback: Dispatch<SetStateAction<boolean>>
}

interface IModalGlobalProps {
  callbackClose?: (values?: FormikValues) => Promise<void> | void
  size?: EModalSize
  title?: string
  content: ReactElement | string
}

interface IModalConfirm extends IModalGlobalProps {
  confirmButtonText?: string
  callbackConfirm: () => Promise<void>
}

interface IModalAlert extends IModalGlobalProps {
  confirmButtonText?: string
}

interface IModalRequiredKeyword extends IModalConfirm {
  requiredKeyword: string
}

interface IModalTypeMap {
  [EModalType.ALERT]: IModalAlert
  [EModalType.BLANK]: IModalGlobalProps
  [EModalType.CONFIRM]: IModalConfirm
  [EModalType.DELETE]: IModalConfirm
  [EModalType.REQUIRED_KEYWORD]: IModalRequiredKeyword
}

export type TModal<T extends EModalType = EModalType> = {
  type: T
} & IModalTypeMap[T]

export type TNewModal<T extends EModalType> = TModal<T> & {
  onClose?: () => void
}
