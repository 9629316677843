import {
  Address,
  Background,
  City,
  Col,
  Country,
  Form,
  ListItem,
  Postal,
  Row,
  Street,
  Title,
} from 'components'
import { getAddressFormik } from 'components/Form/Address/utils'
import { Formik } from 'formik'
import { FC } from 'react'
import { EColor } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / Address
    </Title>

    <Background title="Information">
      <ul>
        <ListItem color={EColor.RED}>
          This component does not have a disabled state
        </ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        ...getAddressFormik(),
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Address />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Only city
    </Title>
    <Formik
      initialValues={{
        city: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <City name="city" required={true} />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Only country
    </Title>
    <Formik
      initialValues={{
        country: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Country name="country" required={true} />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Only postal (country is mandatory)
    </Title>
    <Formik
      initialValues={{
        postalCountry: '',
        postal: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={({ values }) => (
        <Form>
          <Row>
            <Col>
              <Country name="postalCountry" required={true} />
            </Col>
            <Col>
              <Postal
                name="postal"
                country={values.postalCountry}
                required={true}
              />
            </Col>
          </Row>
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Only street
    </Title>
    <Formik
      initialValues={{
        street: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <Street name="street" required={true} />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
