import { Card } from '@adyen/adyen-web'
import '@adyen/adyen-web/styles/adyen.css'
import { FormikValues, useFormikContext } from 'formik'
import { useTranslation } from 'hooks'
import { IAdyenCheckoutState } from 'pages/Payment/type'
import { hasBillingAddressRequired } from 'pages/Payment/utils'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { useAsync } from 'react-use'

import * as Type from './type'

const AdyenCard: FC<Type.IAdyenCard> = ({
  adyenContainerId,
  checkout,
  onChange,
  paymentMethod,
  paymentMethods,
  country,
}) => {
  const { setFieldValue, values } = useFormikContext<FormikValues>()
  const billingAddressRequired = hasBillingAddressRequired(country)
  const intl = useIntl()
  const translation = useTranslation(intl)

  useAsync(async () => {
    const handleOnChange = (state: IAdyenCheckoutState) => {
      if (!state.isValid) {
        return
      }
      // Set browser info for 3DS
      if (!values.paymentAdditionalData.browserInfo) {
        setFieldValue('paymentAdditionalData', {
          deviceFingerprint: state.data.riskData?.clientData,
          browserInfo: state.data.browserInfo,
          origin: window.location.origin,
          ...(billingAddressRequired && {
            billingAddress: state.data.billingAddress,
          }),
        })
      }
      onChange &&
        onChange(state.data.paymentMethod, paymentMethod, setFieldValue)
    }

    const paymentMethodsWithBrands = paymentMethods.filter(
      (method) => method.type === 'scheme' && method.brands
    )

    const cardBrands = paymentMethodsWithBrands.length
      ? { brands: paymentMethodsWithBrands[0].brands }
      : {}

    const cardConfiguration = {
      ...cardBrands,
      paymentMethods,
      onChange: handleOnChange,
      hasHolderName: true,
      holderNameRequired: true,
      billingAddressRequired,
      showPayButton: false,
      placeholders: {
        holderName: translation.translate('paymentMethod.ADYEN.holderName'),
        cardNumber: translation.translate('paymentMethod.ADYEN.cardNumber'),
        expiryDate: translation.translate('paymentMethod.ADYEN.expiryDate'),
        securityCodeThreeDigits: translation.translate(
          'paymentMethod.ADYEN.securityCodeThreeDigits'
        ),
        securityCodeFourDigits: translation.translate(
          'paymentMethod.ADYEN.securityCodeFourDigits'
        ),
      },
    }

    new Card(checkout, cardConfiguration).mount(`#${adyenContainerId}`)
  }, [])

  return <div id={adyenContainerId} />
}

export default AdyenCard
