import {
  getCurrentAdmin,
  getOrganizationApps,
  getOrganizationNotifications,
} from 'api'

export const fetchInitialData = async (organizationId: string) => {
  const [personData, applicationsData, notificationsData] = await Promise.all([
    getCurrentAdmin(),
    getOrganizationApps(organizationId),
    getOrganizationNotifications(),
  ])

  return { personData, applicationsData, notificationsData }
}
