import { Input } from 'components'
import { FormikValues } from 'formik'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'

import * as Type from './type'

const FrenchRna: FC<Type.IFrenchRna> = ({ label, ...rest }) => {
  const intl = createIntl('components_frenchrna')
  const translation = useTranslation(intl)

  const validate = (value: FormikValues): undefined | string => {
    if (!String(value).length) {
      return
    }

    if (value.match(/^W\d{9}$/) === null) {
      return translation.translate('validate.format')
    }
  }

  return (
    <Input
      label={label === undefined ? translation.translate('label') : label}
      placeholder={translation.translate('placeholder')}
      validate={validate}
      {...rest}
    />
  )
}

export default FrenchRna
