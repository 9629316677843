import { Background, Card, Col, ListItem, Row, Title } from 'components'
import { createIntl, useTranslation } from 'hooks'
import { FC } from 'react'
import { RawIntlProvider } from 'react-intl'
import { EColor } from 'types'

const statutes = ['todo', 'todoImportant', 'pending', 'success', 'error']

const StorybookPage: FC = () => {
  const intl = createIntl('pages_storybook')
  const translation = useTranslation(intl)

  return (
    <RawIntlProvider value={intl}>
      <Title type="h1" className="mb-4">
        Card
      </Title>

      <Background title="Information">
        <ul>
          <ListItem>
            The title text, button text and description text contain string
          </ListItem>
          <ListItem>The button cannot contain an icon</ListItem>
          <ListItem>
            Only the todo and todoImportant status contains a duration
          </ListItem>
          <ListItem>
            The duration is customizable and contains an integer and is optional
          </ListItem>
          <ListItem>The icon are from CDN</ListItem>
          <ListItem color={EColor.RED}>
            The button color and badge is not customizable, it depends on the
            status
          </ListItem>
        </ul>
      </Background>

      <Title type="h2" className="my-4">
        Component
      </Title>
      <Row>
        <Col lg={3}>
          <Card
            buttonTranslationId={translation.translate('card.button')}
            descriptionTranslationId="card.description"
            titleTranslationId="card.title"
            status="todo"
            svg="common/icon/multicolor/calculator"
            duration={10}
          />
        </Col>
      </Row>

      <Title type="h2" className="my-4">
        Status
      </Title>
      {statutes.map((status, index) => (
        <Row key={index} className="align-items-center">
          <Col lg={2}>{status}</Col>
          <Col lg={3}>
            <Card
              buttonTranslationId="card.button"
              descriptionTranslationId="card.description"
              titleTranslationId="card.title"
              status={status}
              svg="common/icon/multicolor/calculator"
              duration={10}
            />
          </Col>
        </Row>
      ))}

      <Title type="h2" className="my-4">
        Duration examples
      </Title>
      <Row>
        {[0, 1, 2, 10].map((duration, index) => (
          <Col key={index} lg={3}>
            <Card
              buttonTranslationId="card.button"
              descriptionTranslationId="card.description"
              titleTranslationId="card.title"
              status="todo"
              svg="common/icon/multicolor/calculator"
              duration={duration}
            />
          </Col>
        ))}
      </Row>
    </RawIntlProvider>
  )
}

export default StorybookPage
