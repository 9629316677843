import { Background, File, Form, ListItem, Title } from 'components'
import { adyenFilesSizesByMimeType } from 'components/Form/File/data'
import { Formik } from 'formik'
import { FC } from 'react'
import { EColor } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Form / File
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>
          To see the error and pending states, use the fields below
        </ListItem>
        <ListItem color={EColor.RED}>
          File input height: 60px and not customizable
        </ListItem>
        <ListItem color={EColor.RED}>
          The file input content is not customizable
        </ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Formik
      initialValues={{
        file: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <File
            name="file"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            required={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Disabled state
    </Title>
    <Formik
      initialValues={{
        fileDisabled: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <File
            name="fileDisabled"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            disabled={true}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Max size (eg: 10ko)
    </Title>
    <Formik
      initialValues={{
        fileMaxSize: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <File
            name="fileMaxSize"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            sizesByMimeType={{ '*': { min: 0, max: 10000 } }}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Extensions limited (eg: pdf and jpeg)
    </Title>
    <Formik
      initialValues={{
        fileLimitedExtensions: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <File
            name="fileLimitedExtensions"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
            accept={['application/pdf', 'image/jpeg']}
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Multi-files
    </Title>
    <Formik
      initialValues={{
        fileMulti: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <File
            name="fileMulti"
            label="Lorem ipsum"
            helpText="Lorem ipsum..."
          />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without help text
    </Title>
    <Formik
      initialValues={{
        fileWithoutHelpText: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <File name="fileWithoutHelpText" label="Lorem ipsum" />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      Without label
    </Title>
    <Formik
      initialValues={{
        fileWithoutLabel: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <File name="fileWithoutLabel" />
        </Form>
      )}
    />

    <Title type="h2" className="my-4">
      File for Adyen (multiple)
    </Title>
    <Formik
      initialValues={{
        fileForAdyen: '',
      }}
      onSubmit={(values) => {
        alert(JSON.stringify(values))
      }}
      children={() => (
        <Form>
          <File
            name="fileForAdyen"
            label="Lorem ipsum"
            helpText="For PDFs: minimum 1 KB, maximum 4 MB. For other formats: minimum 100 KB, maximum 4 MB"
            sizesByMimeType={adyenFilesSizesByMimeType}
          />
        </Form>
      )}
    />
  </>
)

export default StorybookPage
