import { ButtonGroup as ReactStrapButtonGroup } from 'reactstrap'
import styled, { css } from 'styled-components'

const horizontalButtonGroupStyle = css`
  flex-direction: row;
  align-items: initial;

  .btn {
    border-radius: 0 !important;

    &:not(:first-child) {
      border-top-width: 0;
      border-left-width: 1px;
    }
    &:first-child {
      border-radius: 10px 0 0 10px !important;
      border-left-width: 0;
    }
    &:last-child {
      border-radius: 0 10px 10px 0 !important;
    }
  }
`

export const ButtonGroup = styled(
  ({ childrenWidth, hasEqualWidth, isVertical, ...props }) => (
    <ReactStrapButtonGroup {...props} />
  )
)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .btn {
    width: 100%;
    border-radius: 0 !important;
    margin: 0;
    border: 0px;
    border-style: solid;
    border-color: ${(props) => props.theme.white} !important;

    &:not(:first-child) {
      border-top-width: 1px;
      margin-left: 0;
    }
    &:first-child {
      border-radius: 10px 10px 0 0 !important;
    }
    &:last-child {
      border-radius: 0 0 10px 10px !important;
    }
  }

  @media (min-width: ${(props) => props.theme['gridBreakpoints-md']}) {
    ${({ isVertical }) => !isVertical && horizontalButtonGroupStyle};
  }
`
