import { useTranslation } from 'hooks'
import { getBrandGTCUSUrl } from 'pages/Payment/utils'
import { FC, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { getBrandName } from 'utils'

import * as Type from './type'

const AdyenApplePay: FC<Type.IApplePay> = ({
  adyenContainerId,
  applePayComponent,
  brand,
  ...props
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)

  useEffect(() => {
    applePayComponent.mount(`#${adyenContainerId}`)
  }, [adyenContainerId, applePayComponent])

  return (
    <>
      <p>
        <small>
          {translation.translate('applePay.tos', {
            brand: getBrandName(brand),
            a: (string: string) => (
              <a
                href={getBrandGTCUSUrl(brand)}
                target="_blank"
                rel="noreferrer"
              >
                {string}
              </a>
            ),
          })}
        </small>
      </p>
      <div id={adyenContainerId} {...props} />
    </>
  )
}

export default AdyenApplePay
