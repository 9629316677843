import { AdyenCheckout, ApplePay, CoreConfiguration } from '@adyen/adyen-web'
import { sendPayment } from 'api'
import { ERetributionType } from 'api/type'
import {
  Background,
  Button,
  CheckboxIndividual,
  Col,
  Display,
  EmptySpace,
  Icon,
  Link,
  ModalConfirm,
  Row,
} from 'components'
import { EBackgroundBorderColor } from 'components/Background/type'
import { EModalSize } from 'components/Modal/type'
import { Dinero } from 'dinero.js'
import { FormikValues, useFormikContext } from 'formik'
import { useApiError, useInstallment, useTranslation } from 'hooks'
import { IInstallmentPlan } from 'hooks/useInstallment/type'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { RawIntlProvider, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useAsync } from 'react-use'
import { hideLoader, showLoader } from 'state'
import { EColor, EYado } from 'types'
import {
  convertObjectToDinero,
  EBrand,
  getBrandName,
  getFormattedAmount,
  uniqId,
} from 'utils'
import { ECountry } from 'utils/Intl/type'

import {
  Background3DSecureExplanations,
  PaymentMethod,
  PaymentSummary,
} from './components'
import BrandDisplay from './components/BrandDisplay'
import * as Type from './type'
import { EIntent, EPaymentMethods, IAdyenCheckoutState } from './type'
import * as Utils from './utils'
import { hasBillingAddressRequired } from './utils'

const Payment: FC<Type.IPayment> = ({
  submitHandler,
  pageData,
  paymentPageConfigurationId,
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)

  const apiError = useApiError()
  const dispatch = useDispatch()
  const adyenCardContainerId = useRef(uniqId('adyen-card'))
  const adyenSepaContainerId = useRef(uniqId('adyen-sepa'))
  const adyenApplePayContainerId = useRef(uniqId('adyen-apple-pay'))
  const installment = useInstallment()

  const [tipAmount, setTipAmount] = useState<undefined | Dinero>(undefined)
  const [installmentPlan, setInstallmentPlan] = useState<
    undefined | IInstallmentPlan
  >(undefined)
  const [areTipsAuthorized, setAreTipsAuthorized] = useState<
    undefined | boolean
  >(undefined)
  const [forcePayButtonDisabled, setForcePayButtonDisabled] = useState(false)
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)
  const [applePayMerchantConfiguration, setApplePayMerchantConfiguration] =
    useState<null | undefined | Type.TApplePayConfiguration>(undefined)
  const [displaySepaConsentModal, setDisplaySepaConsentModal] = useState(false)

  const { values, setFieldValue, submitForm, isSubmitting } =
    useFormikContext<FormikValues>()

  const paymentMethodTypes: Array<Type.EPaymentType> = Object.values(
    Type.EPaymentType
  )
  const totalNoTip = convertObjectToDinero(pageData.price)

  useEffect(() => {
    const defaultTip = Utils.getDefaultTip(pageData)
    setTipAmount(defaultTip)
  }, [pageData])

  useEffect(() => {
    setAreTipsAuthorized(
      pageData.paymentMethods &&
        Utils.getPaymentMethodType(pageData.paymentMethods[0]) ===
          Type.EPaymentType.ONLINE
    )
  }, [pageData])

  useAsync(async () => {
    if (!submitHandler.get()) {
      return null
    }
    await handleSubmit()
    return submitHandler.set(false)
  }, [submitHandler])

  const { value: adyenPaymentMethods } = useAsync(async () => {
    if (!tipAmount) {
      return {}
    }
    const paymentMethods: Type.IPaymentMethodsCollection = {}
    const params: Type.IMethodParams = {
      pageData,
      totalNoTip,
      tipAmount,
      apiError,
    }

    const adyenPaymentMethodsList = await Utils.getAdyenPaymentMethods(params)
    if (adyenPaymentMethodsList !== undefined) {
      paymentMethods[EPaymentMethods.ADYEN] = adyenPaymentMethodsList
    }
    const applePayConfiguration = Utils.getApplePayConfiguration(
      adyenPaymentMethodsList
    )
    setApplePayMerchantConfiguration(
      applePayConfiguration === null
        ? null
        : {
            merchantId: applePayConfiguration.merchantId,
            merchantName: pageData.organization.name,
          }
    )

    const adyenInstallmentsMethod = await Utils.getAdyenInstallmentsAndMethods(
      params,
      installment
    )
    setInstallmentPlan(adyenInstallmentsMethod?.plan)
    if (adyenInstallmentsMethod?.method !== undefined) {
      paymentMethods[EPaymentMethods.ADYEN_CB_INSTALLMENT] =
        adyenInstallmentsMethod.method
    }

    return paymentMethods
  }, [pageData, tipAmount])

  const { value: adyenCheckout } = useAsync(async () => {
    if (
      !adyenPaymentMethods ||
      !pageData ||
      !Object.keys(adyenPaymentMethods).length
    ) {
      return undefined
    }

    const firstPaymentMethodKey = Object.keys(adyenPaymentMethods)[0]

    const globalConfiguration = {
      paymentMethodsResponse: {
        paymentMethods: adyenPaymentMethods[firstPaymentMethodKey],
      },
      environment: process.env
        .REACT_APP_ADYEN_ENV as CoreConfiguration['environment'],
      clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      locale: pageData.organization.nonprofit.locale,
      countryCode: pageData.organization.nonprofit.country,
    }

    return AdyenCheckout(globalConfiguration)
  }, [adyenPaymentMethods])

  const { value: applePayComponent } = useAsync(async () => {
    if (
      applePayMerchantConfiguration === null ||
      pageData.organization.nonprofit.country === ECountry.US
    ) {
      return null
    }
    if (
      adyenCheckout === undefined ||
      applePayMerchantConfiguration === undefined ||
      !tipAmount
    ) {
      return undefined
    }

    const paymentAmount = Utils.getPaymentAmount(
      EPaymentMethods.ADYEN_APPLE_PAY,
      totalNoTip,
      tipAmount,
      installmentPlan
    )
    const applePayConfiguration = {
      amount: {
        value: paymentAmount.getAmount(),
        currency: paymentAmount.getCurrency(),
      },
      countryCode: pageData.organization.nonprofit.country,
      onSubmit: async (state: IAdyenCheckoutState) => {
        if (!state.isValid) {
          return
        }
        void setFieldValue('paymentData', state.data.paymentMethod)
        void setFieldValue('paymentAdditionalData', {
          deviceFingerprint: state.data.riskData?.clientData,
          // browserInfo: applePayStateData.data.browserInfo,
          browserInfo: {},
          origin: window.location.origin,
          ...(hasBillingAddressRequired(
            pageData.organization.nonprofit.country
          ) && {
            billingAddress: state.data.billingAddress,
          }),
        })

        setTimeout(() => {
          void submitForm()
        }, 500)
      },
      configuration: applePayMerchantConfiguration,
    }

    const adyenApplePayComponent: Type.IApplePayComponent = new ApplePay(
      adyenCheckout,
      {
        ...applePayConfiguration,
        onAuthorized(_, actions) {
          actions.resolve()
        },
      }
    )

    return adyenApplePayComponent
      .isAvailable()
      .then(() => adyenApplePayComponent)
      .catch(() => null)
  }, [applePayMerchantConfiguration, adyenCheckout, tipAmount])

  const orderedPaymentMethods = useMemo(
    () =>
      Utils.getOrderedPaymentMethods(
        pageData.publicContext.INTENT,
        pageData.paymentMethods,
        applePayComponent
      ),
    [applePayComponent, pageData.paymentMethods, pageData.publicContext.INTENT]
  )

  const hasRadio = useMemo(
    () =>
      orderedPaymentMethods[Type.EPaymentType.ONLINE].length +
        orderedPaymentMethods[Type.EPaymentType.OFFLINE].length >
      1,
    [orderedPaymentMethods]
  )

  const handleSubmit = async () => {
    if (!tipAmount) {
      return
    }

    if (Utils.isEmptyPaymentMethod(values)) {
      Utils.handleEmptyPaymentMethod(dispatch, translation)
      return
    }

    try {
      dispatch(showLoader({ text: null }))
      setForcePayButtonDisabled(true)

      const payment = await sendPayment(
        values,
        pageData,
        paymentPageConfigurationId,
        tipAmount
      )

      if (payment.action && adyenCheckout !== undefined) {
        adyenCheckout.createFromAction(payment.action)
        adyenCheckout
          .createFromAction(payment.action)
          .mount(`#${adyenCardContainerId.current}`)
      } else if (payment.redirectUrl) {
        window.location.href = payment.redirectUrl
      }
    } catch (error: unknown) {
      apiError.handleApiError(error)
    } finally {
      dispatch(hideLoader())
    }
  }

  const hasBrandingAndGTCUS = Utils.isBrandingAndGTCUSDisplayed(
    pageData.publicContext.INTENT,
    pageData.layout
  )

  if (!tipAmount) {
    return null
  }

  if (pageData.processed) {
    return (
      <EmptySpace
        message={translation.translate('abort.text')}
        title={translation.translate('abort.title')}
        yado={EYado.ACCOUNT_SUSPENDED}
      />
    )
  }

  return (
    <RawIntlProvider value={intl}>
      {pageData.errorUrl && (
        <div className="pt-0">
          <Link
            href={pageData.errorUrl}
            className="d-inline-block align-items-center"
            target="_self"
          >
            <Button
              color={EColor.TRANSPARENT}
              outline={true}
              size="sm"
              className="text-primary mt-3 ml-1"
            >
              <Icon
                icon={['fal', 'long-arrow-left']}
                size="1x"
                className="mr-2"
              />
              {translation.translate(
                `${
                  pageData.layout === Type.EPageDataLayout.BACKOPS
                    ? 'link.subscriptionPage'
                    : 'link.errorUrl'
                }`
              )}
            </Button>
          </Link>
        </div>
      )}
      <Row className="flex-row-reverse">
        <Col lg={5}>
          {pageData.publicContext.INTENT === EIntent.PAY && (
            <PaymentSummary
              className="d-none d-lg-block"
              pageData={pageData}
              tipAmountMethods={{
                set: (amount: Dinero) => setTipAmount(amount),
                get: () => tipAmount,
              }}
              installmentPlan={installmentPlan}
              totalNoTip={totalNoTip}
              areTipsAuthorized={areTipsAuthorized}
            />
          )}

          {hasBrandingAndGTCUS &&
            pageData.retributionMethod === ERetributionType.FEES &&
            pageData.publicContext.INTENT !== Type.EIntent.METHOD_CHANGE && (
              <Background
                border={EBackgroundBorderColor.SOLID}
                borderColor={EColor.BLUE}
                color={EColor.TRANSPARENT}
                className="d-none d-lg-block"
              >
                <div
                  onClick={() => {
                    setIsCollapseOpen(!isCollapseOpen)
                  }}
                >
                  <Row>
                    <Col>
                      <Display
                        type="h5"
                        color={EColor.BLUE}
                        className="font-weight-bold mb-0"
                      >
                        {translation.translate(
                          `explanations.${pageData.retributionMethod}.title`,
                          {
                            brand: getBrandName(pageData.organization.brand),
                          }
                        )}
                      </Display>
                    </Col>
                    <Col xs="auto" className="d-lg-none">
                      <Icon
                        icon={[
                          'fas',
                          isCollapseOpen ? 'chevron-up' : 'chevron-down',
                        ]}
                        color={EColor.BLUE}
                      />
                    </Col>
                  </Row>
                </div>
                <BrandDisplay
                  pageData={pageData}
                  isCollapseOpen={isCollapseOpen}
                />
              </Background>
            )}
          {!!orderedPaymentMethods.ONLINE.length &&
            pageData.organization.brand !== EBrand.SPRINGLY && (
              <Background className="d-none d-lg-block">
                <Background3DSecureExplanations />
              </Background>
            )}
        </Col>
        <Col lg={7}>
          <input type="hidden" name="paymentData" className="m-0" />
          {adyenPaymentMethods &&
            adyenCheckout !== undefined &&
            applePayComponent !== undefined && (
              <Background>
                {pageData.publicContext.INTENT !== EIntent.METHOD_CHANGE &&
                  orderedPaymentMethods[Type.EPaymentType.ONLINE].length +
                    orderedPaymentMethods[Type.EPaymentType.OFFLINE].length >
                    1 && (
                    <Display type="h5">
                      {translation.translate(
                        `paymentMethod.${pageData.publicContext.INTENT}.choice`
                      )}
                    </Display>
                  )}

                {paymentMethodTypes.map((paymentMethodType) => (
                  <div key={paymentMethodType}>
                    {orderedPaymentMethods[paymentMethodType].map(
                      (paymentMethod) => (
                        <PaymentMethod
                          adyenContainersIds={{
                            card: adyenCardContainerId.current,
                            sepa: adyenSepaContainerId.current,
                            applePay: adyenApplePayContainerId.current,
                          }}
                          brand={pageData.organization.brand}
                          key={paymentMethod}
                          checkout={adyenCheckout}
                          applePayComponent={applePayComponent}
                          dinero={Utils.getPaymentBlockDinero(
                            paymentMethod,
                            installmentPlan,
                            tipAmount,
                            totalNoTip,
                            areTipsAuthorized
                          )}
                          hasRadio={hasRadio}
                          paymentType={Utils.getPaymentMethodType(
                            paymentMethod
                          )}
                          locale={pageData.organization.nonprofit.locale}
                          country={pageData.organization.nonprofit.country}
                          offlineInstructions={
                            pageData.publicContext.OFFLINE_INSTRUCTION
                          }
                          onChange={(paymentData) =>
                            setFieldValue('paymentData', paymentData)
                          }
                          paymentMethod={paymentMethod}
                          intent={pageData.publicContext.INTENT}
                          paymentMethods={adyenPaymentMethods[paymentMethod]}
                          installmentPlan={installmentPlan}
                          updatePaymentMethod={(
                            method: Type.EPaymentMethods
                          ) => {
                            setAreTipsAuthorized(
                              Utils.getPaymentMethodType(method) ===
                                Type.EPaymentType.ONLINE
                            )
                          }}
                        />
                      )
                    )}
                  </div>
                ))}

                <Row className="align-items-center justify-content-between mt-0">
                  <Col md="auto" className="pt-3">
                    {Utils.getGTCUSCheckbox(
                      hasBrandingAndGTCUS,
                      values,
                      translation,
                      pageData.organization.brand as EBrand
                    )}
                  </Col>
                  {values.paymentMethod !== EPaymentMethods.ADYEN_APPLE_PAY && (
                    <Col md="auto" className="text-center text-lg-right">
                      <Button
                        className="w-100 w-lg-auto py-1 py-lg-0"
                        type={
                          values.paymentMethod === EPaymentMethods.ADYEN_SEPA
                            ? 'button'
                            : 'submit'
                        }
                        color={EColor.YELLOW}
                        onClick={() => {
                          if (
                            values.paymentMethod !== EPaymentMethods.ADYEN_SEPA
                          ) {
                            return
                          }
                          setDisplaySepaConsentModal(true)
                        }}
                        disabled={isSubmitting || forcePayButtonDisabled}
                      >
                        {translation.translate(
                          Utils.getPayButtonTranslationKey(
                            pageData.publicContext.INTENT,
                            values.paymentMethod
                          ),
                          {
                            total: getFormattedAmount(
                              Utils.getPaymentAmount(
                                values.paymentMethod,
                                totalNoTip,
                                tipAmount,
                                installmentPlan,
                                false
                              )
                            ),
                          }
                        )}
                      </Button>
                    </Col>
                  )}
                </Row>
              </Background>
            )}
        </Col>
      </Row>
      {!!orderedPaymentMethods.ONLINE.length &&
        pageData.organization.brand !== EBrand.SPRINGLY && (
          <div className="d-lg-none ">
            <Background3DSecureExplanations isMobile={true} />
          </div>
        )}
      <ModalConfirm
        title={translation.translate('sepa.confirm.title')}
        buttonsText={{
          confirm: translation.translate('sepa.confirm.buttons.confirm'),
          cancel: translation.translate('sepa.confirm.buttons.cancel'),
        }}
        description={
          <>
            {translation.translate('sepa.confirm.description', {
              brand: getBrandName(pageData.organization.brand),
              nonprofit: pageData.organization.name,
            })}
            <CheckboxIndividual
              label={translation.translate('sepa.confirm.label')}
              name="sepaTos"
              className="mt-4"
              required={true}
            />
          </>
        }
        isOpen={displaySepaConsentModal}
        callbackCancel={async () => {
          void setFieldValue('sepaTos', false)
          setDisplaySepaConsentModal(false)
          setForcePayButtonDisabled(false)
        }}
        callbackConfirm={async () => {
          await submitForm()
          setDisplaySepaConsentModal(false)
        }}
        size={EModalSize.LG}
      />
    </RawIntlProvider>
  )
}

export default Payment
