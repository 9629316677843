import { IAddress } from 'api/_external/Mapbox/type'
import { FormikValues } from 'formik'

export const getAddressApi = (values?: FormikValues): IAddress => ({
  administrativeArea1: values?.administrativeArea1 || null,
  administrativeArea2: values?.administrativeArea2 || null,
  city: values?.city || '',
  country: values?.country || '',
  postal: values?.postal || '',
  street1: values?.street1 || '',
  street2: values?.street2 || null,
  latitude: values?.latitude || null,
  longitude: values?.longitude || null,
})

export const getAddressFormik = (values?: FormikValues): IAddress => ({
  administrativeArea1: values?.administrativeArea1 || '',
  administrativeArea2: values?.administrativeArea2 || '',
  city: values?.city || '',
  country: values?.country || '',
  postal: values?.postal || '',
  street1: values?.street1 || '',
  street2: values?.street2 || '',
  latitude: values?.latitude || '',
  longitude: values?.longitude || '',
})
