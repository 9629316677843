import { SepaDirectDebit } from '@adyen/adyen-web'
import '@adyen/adyen-web/styles/adyen.css'
import { useFormikContext } from 'formik'
import { IAdyenCheckoutState } from 'pages/Payment/type'
import { FC } from 'react'
import { useAsync } from 'react-use'

import * as Type from './type'

const AdyenSepa: FC<Type.IAdyenSepa> = ({
  adyenContainerId,
  checkout,
  onChange,
  paymentMethod,
}) => {
  const { setFieldValue } = useFormikContext()

  useAsync(async () => {
    const handleOnChange = (state: IAdyenCheckoutState) => {
      if (!state.isValid) {
        return
      }
      onChange &&
        onChange(state.data.paymentMethod, paymentMethod, setFieldValue)
    }

    const sepaConfiguration = {
      onChange: handleOnChange,
      showPayButton: false,
    }

    new SepaDirectDebit(checkout, sepaConfiguration).mount(
      `#${adyenContainerId}`
    )
  }, [])

  return <div id={adyenContainerId} />
}

export default AdyenSepa
