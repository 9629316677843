import { Background, Button, ListItem, Title, Tooltip } from 'components'
import { FC } from 'react'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Tooltip
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>The text contains string and components</ListItem>
        <ListItem>
          The tooltip can be displayed from any components: button, span, div,
          ...
        </ListItem>
        <ListItem>
          The tooltip display position is automatic or customizable
        </ListItem>
        <ListItem>Height depends on content. Min height: 25px</ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <Button id="test-1" className="mb-2">
      Hover to open
    </Button>
    <Tooltip placement="right" target="test-1">
      Lorem ipsum...
    </Tooltip>
    <Button id="test-2" className="mb-2">
      Hover to open
    </Button>
    <Tooltip placement="right" target="test-2">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam facilisis,
      ipsum at gravida luctus, sem.
    </Tooltip>
    <Button id="test-3" className="mb-2">
      Hover to open (with a link)
    </Button>
    <Tooltip placement="right" target="test-3" autohide={false}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam facilisis,
      ipsum at gravida luctus, sem.
      <a href="https://assoconnect.com" target="_blank" rel="noreferrer">
        Link to assoconnect
      </a>
    </Tooltip>
  </>
)

export default StorybookPage
