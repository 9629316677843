import { EBackgroundBorderColor } from 'components/Background/type'
import { ENotificationStatus } from 'providers/type'
import { EColor } from 'types'

export const getBackgroundParams = (status: ENotificationStatus) => {
  if (status === ENotificationStatus.READ) {
    return {
      color: EColor.TRANSPARENT,
      borderColor: EColor.GREY,
      border: EBackgroundBorderColor.SOLID,
    }
  }

  return {}
}
