import { getOrganizationPspWallets } from 'api'
import { API } from 'api/connector'
import { IApiOrganization, IApiOrganizationPspWallet } from 'api/type'
import { FormikValues } from 'formik'

import * as Type from './type'

export const createPspWalletAdyen = async (
  organizationId: IApiOrganization['id'],
  personId: string,
  jobTitle: Type.EPspWalletAdyenJobTitle,
  lastDigitsUsSocialSecurityNumber?: string
): Promise<IApiOrganizationPspWallet> => {
  const newPspWalletAdyen = await API.post(`/api/v1/psp_wallets`, {
    pspName: 'adyen',
    organization: `/api/v1/organizations/${organizationId}`,
    person: `/api/v1/crm/people/${personId}`,
    jobTitle,
    ...(lastDigitsUsSocialSecurityNumber?.length && {
      lastDigitsUsSocialSecurityNumber,
    }),
  })

  return newPspWalletAdyen.data
}

export const updatePspWalletAdyen = async (
  organizationId: string,
  personId: string,
  jobTitle: Type.EPspWalletAdyenJobTitle,
  lastDigitsUsSocialSecurityNumber?: number
): Promise<IApiOrganizationPspWallet> => {
  const pspWallets = await getOrganizationPspWallets(organizationId)
  const adyenWallet = pspWallets.find((wallet) => wallet.pspName === 'ADYEN')
  if (!adyenWallet) {
    throw new Error('Adyen wallet not found')
  }
  const updatedPspWalletAdyen = await API.put(`${adyenWallet['@id']}`, {
    person: `/api/v1/crm/people/${personId}`,
    jobTitle,
    ...(lastDigitsUsSocialSecurityNumber && {
      lastDigitsUsSocialSecurityNumber,
    }),
  })
  return updatedPspWalletAdyen.data
}

export const sendPspWalletAdyenOrganizationDocument = async ({
  pspWalletId,
  documentId,
  isPeriodicReview = false,
}: Type.ISendPspWalletAdyenOrganizationDocument): Promise<{
  status: number
}> =>
  API.post(`/api/v1/psp/adyen/kyc/psp_wallets/${pspWalletId}/organization`, {
    documentUuid: documentId,
    ...(isPeriodicReview && { isPeriodicReview }),
  })

export const sendPspWalletAdyenShareholderDocument = async ({
  pspWalletId,
  idCardFrontId,
  idCardBackId,
  passportId,
  drivingLicenseFrontId,
  drivingLicenseBackId,
  isPeriodicReview = false,
}: Type.ISendPspWalletAdyenShareholderDocument): Promise<{ status: number }> =>
  API.post(`/api/v1/psp/adyen/kyc/psp_wallets/${pspWalletId}/shareholder`, {
    idCardFrontUuid: idCardFrontId,
    idCardBackUuid: idCardBackId,
    passportUuid: passportId,
    drivingLicenseFrontUuid: drivingLicenseFrontId,
    drivingLicenseBackUuid: drivingLicenseBackId,
    ...(isPeriodicReview && { isPeriodicReview }),
  })

export const sendPspWalletAdyenBankAccountDocument = async ({
  pspWalletId,
  bankAccountId,
  documentId,
}: Type.ISendPspWalletAdyenBankAccountDocument): Promise<{ status: number }> =>
  API.post(
    `/api/v1/psp/adyen/kyc/psp_wallets/${pspWalletId}/bank_accounts/${bankAccountId}`,
    {
      documentUuid: documentId,
    }
  )

export const getPspWalletAdyenPciLink = async (
  pspWalletId: string
): Promise<Type.IPspWalletAdyenPciLink> => {
  const pciUrl = await API.get(
    `/api/v1/psp/adyen/kyc/psp_wallets/${pspWalletId}/pci_url`
  )
  return pciUrl.data
}
export const sendPeriodicReviewMeetingMinutes = async (
  pspWalletId: IApiOrganizationPspWallet['id'],
  values: FormikValues
) =>
  API.post(
    `/api/v1/psp/adyen/periodic_review/psp_wallets/${pspWalletId}/organization`,
    {
      ...values,
    }
  )
