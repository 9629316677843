import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IButton } from 'components/Button/type'
import { EColor } from 'types'

export interface IResponsiveButton extends IButton {
  icon: IconProp
  id?: string
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right'
  color?: EColor
  buttonSize?: EResponsiveButtonSize
  outline?: boolean
}

export enum EResponsiveButtonSize {
  BIG = 'BIG',
  SMALL = 'SMALL',
}
