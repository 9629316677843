import classNames from 'classnames'
import { Alert, Col, Collapse, Display, ListItem, Row } from 'components'
import { ETitleFont } from 'components/Text/Title/type'
import { FC } from 'react'
import { EColor } from 'types'

import * as Style from './style'
import * as Type from './type'
import { EContentType } from './type'

const LongCardContent: FC<Type.ILongCardContent> = ({
  alert,
  items,
  isArchived,
  isColumn,
  isOpen,
}) => (
  <>
    <Collapse isOpen={isOpen}>
      <Style.CollapseWrapper
        isArchived={isArchived}
        className="mt-3 pt-2 overflow-hidden"
      >
        {alert && (
          <Alert color={alert.color} className="mt-2 mb-0">
            {alert.text}
          </Alert>
        )}
        <Row className={classNames(`pt-2 ${isColumn ? 'pt-lg-0' : 'pt-lg-3'}`)}>
          {items.map((item, i) => (
            <Col
              key={i}
              className={classNames(
                `col-12 pr-lg-5 ${i === 0 ? 'mt-0' : 'mt-4'}`,
                {
                  'mt-lg-3': isColumn,
                  'col-lg-auto': isColumn,
                }
              )}
            >
              <Row>
                <Col>
                  <Display
                    type="h6"
                    font={ETitleFont.POPPINS}
                    color={EColor.GREY}
                    className="pb-2 mb-0"
                  >
                    {item.label}
                  </Display>
                  {item.type === EContentType.ICON ? (
                    <>
                      <Style.ContentIcon
                        icon={item.icon}
                        color={item.color || EColor.GREEN}
                        className="mr-2"
                      />
                      {item.text}
                    </>
                  ) : (
                    <ul className="pl-0">
                      {item.items.map((listItem, listIndex) => (
                        <ListItem key={listIndex}>{listItem}</ListItem>
                      ))}
                    </ul>
                  )}
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Style.CollapseWrapper>
    </Collapse>
  </>
)

export default LongCardContent
