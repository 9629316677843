import { Row } from 'components'
import styled from 'styled-components'

export const ResponsiveButtonsRow = styled(({ isArchived, ...props }) => (
  <Row {...props} />
))`
  border-top: 1px solid
    ${({ isArchived, ...props }) =>
      isArchived ? props.theme.grey : props.theme.lightGrey};
`
