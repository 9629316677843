import { RouterContext } from 'providers'
import { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router'

import * as Type from './type'

const UseHistory = (): Type.IUseHistory => {
  const navigate = useNavigate()
  const location = useLocation()
  const { urlSearchParameters } = useContext(RouterContext)

  const push = (
    path: string,
    state?: Record<string, unknown> | null | undefined,
    saveRedirectOnPath = false
  ): void => {
    const redirectUrl = urlSearchParameters?.redirect

    // If saveRedirectOnPath is false and there's a redirect param, use it
    // Otherwise, append existing search params to the new path
    const pathWithSearch = path + location.search
    const targetPath =
      redirectUrl && !saveRedirectOnPath ? redirectUrl : pathWithSearch

    navigate(targetPath, { state })
  }

  return { push, location }
}

export default UseHistory
