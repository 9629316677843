import {
  Background,
  EmptySpace,
  EmptySpaceAccessDenied,
  ListItem,
  Title,
} from 'components'
import { FC } from 'react'
import { EColor, EYado } from 'types'

const StorybookPage: FC = () => (
  <>
    <Title type="h1" className="mb-4">
      Empty space
    </Title>

    <Background title="Information">
      <ul>
        <ListItem>The SVG width: 350px</ListItem>
        <ListItem>The button is optional</ListItem>
        <ListItem>The image are from CDN (common/yado directory)</ListItem>
        <ListItem>The title and message contains string</ListItem>
        <ListItem>The button contains string and no icon</ListItem>
        <ListItem>The button color is customizable</ListItem>
        <ListItem color={EColor.RED}>
          The access denied mode is not customizable
        </ListItem>
      </ul>
    </Background>

    <Title type="h2" className="my-4">
      Component
    </Title>
    <EmptySpace
      message="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In finibus ante quis tellus viverra, quis luctus eros aliquam. Sed feugiat."
      title="Lorem ipsum"
      yado={EYado.TEACHER}
      button={{
        href: '#',
        onClick: () => {
          // This is intentional to prevent eslint warning
        },
        text: 'Lorem ipsum',
      }}
    />

    <Title type="h2" className="my-4">
      Without button
    </Title>
    <EmptySpace
      message="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In finibus ante quis tellus viverra, quis luctus eros aliquam. Sed feugiat."
      title="Lorem ipsum"
      yado={EYado.TEACHER}
    />

    <Title type="h2" className="my-4">
      Access denied mode
    </Title>
    <EmptySpaceAccessDenied />
  </>
)

export default StorybookPage
