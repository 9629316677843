import { useApiError } from 'hooks'
import { useCallback, useEffect, useState } from 'react'

type UseAsyncDataResult<T> =
  | { isLoading: true }
  | { isLoading: false; isError: true }
  | { isLoading: false; isError: false; value: T; retry: () => void }

const useAsyncData = <T,>(
  asyncFunction: () => Promise<T>,
  /*eslint-disable @typescript-eslint/no-explicit-any */
  // dependencies are typed as any because we cannot predict the context where it is used
  dependencies?: Array<any>
  /*eslint-enable @typescript-eslint/no-explicit-any */
): UseAsyncDataResult<T> => {
  const apiError = useApiError()
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [value, setValue] = useState<T | null>(null)

  const execute = useCallback(async () => {
    setIsLoading(true)
    setValue(null)

    try {
      const result = await asyncFunction()
      setValue(result)
    } catch (error: unknown) {
      apiError.handleApiError(error)
      setValue(null)
      setIsError(true)
    } finally {
      setIsLoading(false)
    }
  }, [apiError, asyncFunction])

  useEffect(() => {
    void execute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies ?? [])

  const retry = () => execute()

  return {
    isLoading,
    isError,
    value: !isLoading ? (value as T) : null,
    retry,
  } as UseAsyncDataResult<T>
}
export default useAsyncData
