import { Col, Row, Svg } from 'components'
import { EPaymentMethods } from 'pages/Payment/type'
import { FC } from 'react'

import * as Style from './style'
import * as Type from './type'

const ApplePay = () => (
  <Style.ApplePayBackground>
    <Svg src="/common/icon/payment/apple-pay" width="30px" />
  </Style.ApplePayBackground>
)

const PaymentCardsSepa: FC = () => (
  <Style.PaymentCardBackground>
    <Svg src="/common/icon/payment/sepa" width="30px" />
  </Style.PaymentCardBackground>
)

const PaymentCards: FC = () => (
  <Row className="flex-row justify-content-end">
    {Object.values(Type.EPaymentCardBrands).map((paymentCard) => (
      <Col key={paymentCard} className="px-0" xs="auto">
        <Style.PaymentCardBackground>
          <Svg src={`/common/icon/payment/${paymentCard}`} width="30px" />
        </Style.PaymentCardBackground>
      </Col>
    ))}
  </Row>
)

export const getPaymentMethodIcons = (
  paymentMethod = EPaymentMethods.ADYEN
) => {
  switch (paymentMethod) {
    case EPaymentMethods.ADYEN_APPLE_PAY:
      return <ApplePay />
    case EPaymentMethods.ADYEN_SEPA:
      return <PaymentCardsSepa />
    default:
      return <PaymentCards />
  }
}
