import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReactNode } from 'react'
import { IStoreLoader } from 'state/type'

const loaderInitialState: IStoreLoader = {
  display: false,
  text: null,
  globalLoaderDisplay: false,
}

const loaderSlice = createSlice({
  name: 'loader',
  initialState: loaderInitialState,
  reducers: {
    setLoader: (state, action: PayloadAction<IStoreLoader>) =>
      Object.assign({}, state, action.payload),
    showLoader: (state, action: PayloadAction<{ text?: ReactNode }>) => ({
      ...state,
      display: true,
      ...action.payload,
    }),
    showGlobalLoader: (state, action: PayloadAction<{ text?: ReactNode }>) => ({
      ...state,
      globalLoaderDisplay: true,
      ...action.payload,
    }),
    hideLoader: (state) => ({ ...state, display: false, text: null }),
    hideGlobalLoader: (state) => ({
      ...state,
      globalLoaderDisplay: false,
      text: null,
    }),
  },
})

export const { showLoader, showGlobalLoader, hideLoader, hideGlobalLoader } =
  loaderSlice.actions
export default loaderSlice.reducer
