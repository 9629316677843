import { FormikFormProps, FormikValues } from 'formik'
import { FormikState } from 'formik/dist/types'

export type ISetFieldValue = (
  field: string,
  value: FormikValues | string | boolean | undefined,
  shouldValidate?: boolean
) => void

export type ISetFieldError = (
  field: string,
  message: string | undefined
) => void

export type IResetForm = (
  nextState?: Partial<FormikState<FormikValues>>
) => void

export type ISubmitForm = () => Promise<void>

export interface IForm extends FormikFormProps {
  preventErrorTracking?: boolean
  displayGenericError?: boolean
}

export const GLOBAL_FORM_ERROR_NAME = '_global_form_error'
