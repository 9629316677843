import { getPaymentMethods } from 'api'
import { ERetributionType, IApiPaymentMethods } from 'api/type'
import { Checkbox } from 'components'
import Dinero, { Dinero as TDinero } from 'dinero.js'
import { FormikValues } from 'formik'
import { IInstallmentPlan, IUseInstallment } from 'hooks/useInstallment/type'
import { IUseTranslation } from 'hooks/useTranslation/type'
import { IOrganization } from 'providers/type'
import { Dispatch } from 'redux'
import { addNotification } from 'state'
import { EColor } from 'types'
import {
  convertObjectToDinero,
  EBrand,
  getBrandName,
  getFormattedAmount,
} from 'utils'
import { ECountry, ELocale } from 'utils/Intl/type'

import * as Type from './type'
import {
  EIntent,
  EPaymentMethods,
  EPaymentMethodType,
  EPaymentTipValueType,
  IPaymentTip,
} from './type'

export const getPaymentAmount = (
  paymentMethod: Type.EPaymentMethods,
  totalNoTip: TDinero,
  tipAmount: TDinero,
  installmentPlan: undefined | IInstallmentPlan,
  fullAmount = true
): TDinero => {
  if (
    paymentMethod === Type.EPaymentMethods.ADYEN_CB_INSTALLMENT &&
    !fullAmount &&
    installmentPlan
  ) {
    return installmentPlan[0].dinero
  }
  return getPaymentMethodType(paymentMethod) === Type.EPaymentType.ONLINE
    ? totalNoTip.add(tipAmount)
    : totalNoTip
}

export const getSubscriptionPricingType = (
  subscription: string
): Type.ESubscriptionType =>
  subscription === 'FREE'
    ? Type.ESubscriptionType.FREE
    : Type.ESubscriptionType.NOT_FREE

export const getPaymentMethodType = (
  paymentMethod: Type.EPaymentMethods
): Type.EPaymentType =>
  [
    Type.EPaymentMethods.ADYEN,
    Type.EPaymentMethods.ADYEN_APPLE_PAY,
    Type.EPaymentMethods.ADYEN_CB_INSTALLMENT,
    Type.EPaymentMethods.ADYEN_SEPA,
  ].includes(paymentMethod)
    ? Type.EPaymentType.ONLINE
    : Type.EPaymentType.OFFLINE

export const isBrandingAndGTCUSDisplayed = (
  intent: Type.EIntent,
  layout: Type.EPageDataLayout
): boolean =>
  [Type.EIntent.PAY, Type.EIntent.METHOD_CHANGE].includes(intent) &&
  layout === Type.EPageDataLayout.COLLECT

const getUpdatedPaymentMethods = (
  paymentMethods: Array<Type.EPaymentMethods>
) => {
  /*
   * APPLE_PAY payment method is directly linked to ADYEN payment method
   * Find ADYEN index and add APPLE_PAY directly after
   */
  const adyenIndex = paymentMethods.indexOf(Type.EPaymentMethods.ADYEN)
  return [
    ...paymentMethods.slice(0, adyenIndex + 1),
    Type.EPaymentMethods.ADYEN_APPLE_PAY,
    ...paymentMethods.slice(adyenIndex + 1),
  ]
}

export const getOrderedPaymentMethods = (
  intent: EIntent,
  paymentMethods?: Array<Type.EPaymentMethods>,
  adyenAppleCheckout?: Type.IApplePayComponent | null
): {
  ONLINE: Array<Type.EPaymentMethods>
  OFFLINE: Array<Type.EPaymentMethods>
} => {
  const paymentMethodsList =
    adyenAppleCheckout &&
    paymentMethods?.includes(Type.EPaymentMethods.ADYEN) &&
    intent === EIntent.PAY
      ? getUpdatedPaymentMethods(paymentMethods)
      : paymentMethods

  const orderedPaymentMethods: {
    ONLINE: Array<Type.EPaymentMethods>
    OFFLINE: Array<Type.EPaymentMethods>
  } = { ONLINE: [], OFFLINE: [] }

  if (paymentMethodsList) {
    for (const method of paymentMethodsList) {
      orderedPaymentMethods[getPaymentMethodType(method)].push(method)
    }
  }
  return orderedPaymentMethods
}

export const getPaymentBlockDinero = (
  paymentMethod: Type.EPaymentMethods,
  installmentPlan: undefined | IInstallmentPlan,
  tipAmount: TDinero,
  totalNoTip: TDinero,
  areTipsAuthorized = false
): TDinero => {
  if (
    paymentMethod === Type.EPaymentMethods.ADYEN_CB_INSTALLMENT &&
    installmentPlan
  ) {
    return installmentPlan[0].dinero
  }
  return areTipsAuthorized ? totalNoTip.add(tipAmount) : totalNoTip
}

export const getGTCUSCheckbox = (
  hasBrandingAndGTCUS: boolean,
  values: FormikValues,
  translation: IUseTranslation,
  brand: EBrand
) => (
  <>
    {hasBrandingAndGTCUS &&
    getPaymentMethodType(values.paymentMethod) === Type.EPaymentType.ONLINE &&
    values.paymentMethod !== EPaymentMethods.ADYEN_APPLE_PAY ? (
      <Checkbox
        name="tos"
        label={translation.translate('tos.label', {
          brand: getBrandName(brand),
          a: (string: string) => (
            <a href={getBrandGTCUSUrl(brand)} target="_blank" rel="noreferrer">
              {string}
            </a>
          ),
        })}
        value="1"
        required={
          getPaymentMethodType(values.paymentMethod) ===
          Type.EPaymentType.ONLINE
        }
      />
    ) : (
      <input type="hidden" name="tos" value="1" />
    )}
  </>
)

const getPaymentMethodsByDinero = async (
  organizationId: IOrganization['id'],
  dinero: TDinero,
  locale: ELocale,
  country: ECountry
) =>
  getPaymentMethods({
    organizationId,
    countryCode: country,
    shopperLocale: locale,
    dinero,
  })

export const getBrandGTCUSUrl = (brand: EBrand) =>
  brand === EBrand.SPRINGLY
    ? 'https://www.springly.org/en-us/gtu-payment/'
    : 'https://www.assoconnect.com/cguv-utilisateurs/'

export const getBrandTipsHelpCenterArticle = (brand: EBrand) =>
  brand === EBrand.SPRINGLY
    ? 'https://help.springly.org/hc/en-us/articles/11282798036754'
    : 'https://help.assoconnect.com/hc/fr/articles/11281962699538'

export const isEmptyPaymentMethod = (values: FormikValues) => {
  // Escape check for Apple Pay
  if (values.paymentMethod === EPaymentMethods.ADYEN_APPLE_PAY) {
    return false
  }

  return (
    getPaymentMethodType(values.paymentMethod) === Type.EPaymentType.ONLINE &&
    !values.paymentData
  )
}
export const handleEmptyPaymentMethod = (
  dispatch: Dispatch,
  translation: IUseTranslation
) => {
  dispatch(
    addNotification({
      color: EColor.RED,
      text: translation.translate('paymentData.missing'),
    })
  )
}

export const getDefaultTip = (pageData: Type.IPageData) => {
  const tipsSuggestion = pageData.publicContext.TIPS_SUGGESTIONS
  const currency = pageData.price.currency

  if (!tipsSuggestion || pageData.retributionMethod !== ERetributionType.TIPS) {
    return Dinero({
      amount: 0,
      currency,
    })
  }
  const priceAmount = pageData.price.amount
  const tipAmount = getTipAmountFromPercentage(
    priceAmount,
    tipsSuggestion.tips_percent
  )

  return convertObjectToDinero(
    tipsSuggestion.type === Type.EPaymentTipValueType.PERCENT
      ? {
          amount: tipAmount * 100,
          currency,
        }
      : tipsSuggestion.tips
  )
}

export const getTipAmountFromPercentage = (
  priceAmount: number,
  percent: number
) => Math.round(priceAmount * (percent / 10000)) / 100

export const getCentsValueFromFloat = (floatValue: number) =>
  Math.round(floatValue * 100)

export const getAdyenPaymentMethods = async (params: Type.IMethodParams) => {
  try {
    // Get paymentMethods for ADYEN
    return await getPaymentMethodsByDinero(
      params.pageData.organization.id,
      params.totalNoTip.add(params.tipAmount),
      params.pageData.organization.nonprofit.locale,
      params.pageData.organization.nonprofit.country
    )
  } catch (error: unknown) {
    params.apiError.handleApiError(error)
  }
  return undefined
}

export const getAdyenInstallmentsAndMethods = async (
  params: Type.IMethodParams,
  installment: IUseInstallment
) => {
  const installmentsAndMethods: {
    plan: IInstallmentPlan
    method: undefined | Array<IApiPaymentMethods>
  } = {
    plan: [],
    method: undefined,
  }

  if (
    params.pageData.publicContext.INSTALLMENT &&
    params.pageData.paymentMethods &&
    params.pageData.paymentMethods.includes(
      Type.EPaymentMethods.ADYEN_CB_INSTALLMENT
    )
  ) {
    const plan = installment.createAllocationPlan(
      params.totalNoTip.add(params.tipAmount),
      params.pageData.publicContext.INSTALLMENT
    )
    installmentsAndMethods.plan = plan

    if (plan.length) {
      try {
        installmentsAndMethods.method = await getPaymentMethodsByDinero(
          params.pageData.organization.id,
          plan[0].dinero,
          params.pageData.organization.nonprofit.locale,
          params.pageData.organization.nonprofit.country
        )
      } catch (error: unknown) {
        params.apiError.handleApiError(error)
        return undefined
      }
    }
  }

  return installmentsAndMethods
}

export const getInitialTipsValues = (tip?: IPaymentTip) => {
  if (tip === undefined) {
    return {
      tip: 0,
      tip_type: Type.EPaymentTipValueType.PERCENT,
      tip_custom: '',
    }
  }

  if (tip.type === EPaymentTipValueType.PERCENT) {
    return {
      tip: tip.tips_percent,
      tip_type: Type.EPaymentTipValueType.PERCENT,
      tip_custom: '',
    }
  }

  return {
    tip: tip.tips.amount,
    tip_type: Type.EPaymentTipValueType.MONEY,
    tip_custom: '',
  }
}

export const getPayButtonTranslationKey = (
  intent: EIntent,
  paymentMethod: EPaymentMethods
) => {
  if (intent === EIntent.METHOD_CHANGE) {
    return 'button.confirm'
  }
  if (intent === 'CAPTURE') {
    return 'button.update'
  }

  return `button.total.${getPaymentMethodType(paymentMethod)}`
}

export const hasBillingAddressRequired = (country: ECountry) =>
  country === ECountry.US || country === ECountry.CA

const filterDuplicates = (
  array: {
    [key: string]: number | string
  }[],
  property: string
) =>
  array.filter(
    (object, index, self) =>
      index ===
      self.findIndex(
        (secondObject) => secondObject[property] === object[property]
      )
  )

export const getTipsPlaceHolder = (tip: IPaymentTip) => {
  if (tip.type === EPaymentTipValueType.PERCENT) {
    return `${tip.tips_percent / 100} %`
  }

  return getFormattedAmount(
    Dinero({ amount: tip.tips.amount, currency: tip.tips.currency })
  )
}

export const getSelectTipValues = (
  tip: IPaymentTip,
  translation: IUseTranslation
): { [key: string]: number | string }[] => {
  if (tip.type === EPaymentTipValueType.PERCENT) {
    const percentTips = [
      {
        label: `${tip.max_tips_percent / 100} %`,
        value: tip.max_tips_percent,
      },
      {
        label: `${tip.tips_percent / 100} %`,
        value: tip.tips_percent,
      },
      {
        label: `${tip.min_tips_percent / 100} %`,
        value: tip.min_tips_percent,
      },
      {
        label: translation.translate('tips.other'),
        value: 'other',
      },
    ]

    return filterDuplicates(percentTips, 'value')
  }

  const amountTips = [
    {
      label: getFormattedAmount(
        Dinero({
          amount: tip.max_tips.amount,
          currency: tip.max_tips.currency,
        })
      ),
      value: tip.max_tips.amount,
    },
    {
      label: getFormattedAmount(Dinero(tip.tips)),
      value: tip.tips.amount,
    },
    {
      label: getFormattedAmount(
        Dinero({
          amount: tip.min_tips.amount,
          currency: tip.min_tips.currency,
        })
      ),
      value: tip.min_tips.amount,
    },

    {
      label: translation.translate('tips.other'),
      value: 'other',
    },
  ]

  return filterDuplicates(amountTips, 'value')
}

export const getApplePayConfiguration = (
  adyenPaymentMethods?: Array<IApiPaymentMethods>
) => {
  if (adyenPaymentMethods === undefined) {
    return null
  }
  const applePayMethod = adyenPaymentMethods.find(
    (method) => method.type === EPaymentMethodType.APPLE_PAY
  )
  return applePayMethod?.configuration || null
}
