import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStoreSpecifications } from 'state/type'

const specificationsInitialState: IStoreSpecifications = {
  ACCOUNTING_HAS_ONGOING_PERIOD: undefined,
  ACCOUNTING_SETUP: undefined,
  COLLECT: undefined,
  SUBSCRIPTION: undefined,
  WALLET_VERIFIED: undefined,
  WALLET_CREATED: undefined,
  EMAILING_HAS_CAMPAIGNS: undefined,
  IS_MAIN_ORGANIZATION: undefined,
  HAS_ACTIVE_PRO_ACCOUNT_MEMBERSHIP: undefined,
  HAS_ACCESS_TO_BULK_IMPORT: undefined,
  HAS_SETUP_PRO_ACCOUNT_MEMBERSHIP: undefined,
}

const specificationsSlice = createSlice({
  name: 'specifications',
  initialState: specificationsInitialState,
  reducers: {
    resetSpecifications: () => specificationsInitialState,
    setSpecifications: (state, action: PayloadAction<IStoreSpecifications>) =>
      action.payload,
  },
})

export const { setSpecifications } = specificationsSlice.actions
export default specificationsSlice.reducer
