import { EApiKycStatus, TKycError } from 'api/type'
import { IOrganization } from 'providers/Organization/type'

export interface IApiBaseBankAccount {
  id: string
  bankName: string
  bic: string | null
  iban: string | null
  isUsedOnKycProcess: boolean
  name: string
  organization: string
  ownBankAccount: boolean
  ownerName: string | null
  type: EBankAccountType
}

export interface IApiBankAccount extends IApiBaseBankAccount {
  '@id': string
  accountNumber: string | null
  branchCode: string | null
  isArchived: boolean
  isUnarchivable: boolean
  isDeletable: boolean
  proAccountAccount: string | null
}

export interface IApiBankAccountWithPspDetails extends IApiBankAccount {
  pspBankAccount?: IPspDetails
}

type IPspDetails = {
  bankAccount: string
  id: string
  kycError: TKycError
  kycStatus: EApiKycStatus
}

export enum EBankAccountType {
  US = 'US',
  EUROPEAN = 'EUROPEAN',
}

interface IBankAccount {
  '@id': string
  '@type': string
  areBankDetailsEditable: boolean
  bankName: string
  bic: string
  iban: string
  id: string
  isArchived: boolean
  isDeletable: boolean
  isUsedOnKycProcess: boolean
  name: string
  organization: IOrganization['@id']
  ownerName: string
  proAccountAccount: null
  type: string
}

export interface IDetailedBankAccount extends IBankAccount {
  ownBankAccount: boolean
  pspBankAccount?: {
    '@id': string
    '@type': string
    id: string
    isDeletable: boolean
    kycStatus: EApiKycStatus
  }
}

export interface IDetailedBankAccounts {
  data: Array<IDetailedBankAccount>
  totalItems: number
}
