import { NewModal } from 'components'
import { ModalContext } from 'providers'
import { EModalType, TModal } from 'providers/type'
import React, { FC, useState } from 'react'

const ModalProvider: FC = ({ children }) => {
  const [modal, setModalState] = useState<TModal | null>(null)
  const [isProcessingCallback, setIsProcessingCallback] = useState(false)

  const setModal = <T extends EModalType>(updatedModal: TModal<T>) => {
    setModalState(updatedModal)
  }

  const closeModal = () => {
    setModalState(null)
  }

  return (
    <ModalContext.Provider
      value={{
        modal,
        setModal,
        closeModal,
        isProcessingCallback,
        setIsProcessingCallback,
      }}
    >
      {children}
      {modal && <NewModal {...modal} />}
    </ModalContext.Provider>
  )
}
export default ModalProvider
