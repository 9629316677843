import styled from 'styled-components'

export const Setting = styled((props) => <div {...props} />)`
  border-top: 1px solid rgba(51, 51, 51, 0.1);

  &:first-of-type {
    border-top: 0;
    margin-top: 0 !important;
  }
`
