import { Button, Display, Title } from 'components'
import { EModalSize } from 'components/Modal/type'
import { createIntl } from 'hooks'
import { useModal } from 'providers'
import { EModalType } from 'providers/type'
import { FC } from 'react'
import { RawIntlProvider } from 'react-intl'

const lorem = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
elementum, augue in porta interdum, lacus ligula interdum massa, et vulputate
felis turpis id mi. Praesent libero mi, volutpat dignissim ipsum et, porttitor
pharetra ligula. Etiam sem magna, iaculis id maximus et, laoreet nec velit.
In fermentum magna vitae arcu dictum, eget ornare enim volutpat. Phasellus
non nisi a turpis laoreet porta auctor ac odio. Maecenas et lorem ut justo
vulputate fringilla. Nulla viverra lacus urna, a malesuada felis cursus a.
Praesent sit amet lacinia felis. Vivamus scelerisque enim non condimentum
consequat. Nullam ac tellus varius, iaculis ligula et, consectetur sem.
Vivamus lacus lacus, porttitor ut sodales sit amet, aliquam id quam. Sed
lacinia pulvinar nulla consequat rutrum. Fusce vitae tellus ligula. Sed vitae
enim turpis. Fusce non ipsum nisi. Nulla ac mi vitae lorem elementum consequat.`

const StorybookPage: FC = () => {
  const intl = createIntl('pages_storybook')
  const { setModal } = useModal()

  const callbackConfirm = async () => {
    alert('CALLBACK CONFIRM')
  }

  const callbackClose = async () => {
    alert('CALLBACK CLOSE')
  }

  const openAlertModal = () => {
    setModal({
      type: EModalType.ALERT,
      content: lorem,
      confirmButtonText: 'CONFIRM',
    })
  }

  const openConfirmModal = () => {
    setModal({
      type: EModalType.CONFIRM,
      content: lorem,
      callbackConfirm,
      confirmButtonText: 'CUSTOM CONFIRM',
    })
  }

  const openBlankModal = () => {
    setModal({
      content: lorem,
      type: EModalType.BLANK,
    })
  }

  const openDeleteModal = () => {
    setModal({
      content: lorem,
      type: EModalType.DELETE,
      callbackConfirm,
    })
  }

  const openSubmitKeywordsModal = () => {
    setModal({
      content: lorem,
      type: EModalType.REQUIRED_KEYWORD,
      requiredKeyword: 'Lorem ipsum!',
      callbackConfirm,
    })
  }

  const openCustomModal = () => {
    setModal({
      content: lorem,
      title: 'Lorem',
      type: EModalType.BLANK,
      size: EModalSize.XL,
      callbackClose,
    })
  }

  return (
    <RawIntlProvider value={intl}>
      <Title type="h1" className="mb-4">
        Modal Provider
      </Title>

      <div className="py-3">
        <Display type="h5">Blank modal</Display>
        <Button onClick={openBlankModal}>OPEN</Button>
      </div>
      <div className="py-3">
        <Display type="h5">Alert modal</Display>
        <Button onClick={openAlertModal}>OPEN</Button>
      </div>
      <div className="py-3">
        <Display type="h5">Confirm modal</Display>
        <Button onClick={openConfirmModal}>OPEN</Button>
      </div>
      <div className="py-3">
        <Display type="h5">Delete modal</Display>
        <Button onClick={openDeleteModal}>OPEN</Button>
      </div>
      <div className="py-3">
        <Display type="h5">Submit keywords Modal</Display>
        <Button onClick={openSubmitKeywordsModal}>OPEN</Button>
      </div>
      <div className="py-3">
        <Display type="h5">
          Blank modal with custom size, title, close callback
        </Display>
        <Button onClick={openCustomModal}>OPEN</Button>
      </div>
    </RawIntlProvider>
  )
}

export default StorybookPage
