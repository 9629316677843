import { ICore } from '@adyen/adyen-web'
import { IApiPaymentMethods } from 'api/type'
import { ISetFieldValue } from 'components/Form/type'
import { Dinero } from 'dinero.js'
import { FormikValues } from 'formik'
import { IInstallmentPlan } from 'hooks/useInstallment/type'
import {
  EIntent,
  EPaymentMethods,
  EPaymentType,
  IApplePayComponent,
} from 'pages/Payment/type'
import { EBrand } from 'utils'
import { ECountry, ELocale } from 'utils/Intl/type'

export interface IPaymentMethod {
  adyenContainersIds: { [key: string]: string }
  brand: EBrand
  checkout: ICore
  applePayComponent: null | IApplePayComponent
  dinero: Dinero
  hasRadio: boolean
  installmentPlan: undefined | IInstallmentPlan
  intent: EIntent
  locale?: ELocale
  country: ECountry
  offlineInstructions: Array<{ [key: string]: string }> | undefined
  onChange?: (
    values: FormikValues,
    paymentType: string,
    setFieldValue: ISetFieldValue
  ) => void
  paymentMethod: EPaymentMethods
  paymentMethods?: Array<IApiPaymentMethods>
  paymentType: EPaymentType
  updatePaymentMethod: (paymentMethod: EPaymentMethods) => void
}

export enum EPaymentCardBrands {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
  CARTE_BANCAIRE = 'carte-bancaire',
}
