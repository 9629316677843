import { Col, Row } from 'components'
import { useTranslation } from 'hooks'
import { AdyenCard } from 'pages/Payment/components'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { getFormattedAmount } from 'utils'

import * as Style from './style'
import * as Type from './type'

const AdyenInstallment: FC<Type.IAdyenInstallment> = ({
  adyenCardComponentId,
  checkout,
  onChange,
  paymentMethod,
  paymentMethods,
  installmentPlan,
  country,
}) => {
  const intl = useIntl()
  const translation = useTranslation(intl)
  return (
    <>
      <Row>
        <Col>
          {paymentMethods && (
            <AdyenCard
              adyenContainerId={adyenCardComponentId}
              checkout={checkout}
              onChange={onChange}
              paymentMethod={paymentMethod}
              paymentMethods={paymentMethods}
              country={country}
            />
          )}
        </Col>
      </Row>
      <Row className="mx-0 mb-2 mb-md-3 installmentPlan">
        <Col>
          <Row className="mt-3 pt-1">
            {installmentPlan.map((installmentItem, index) => (
              <Style.InstallmentBlock
                key={index}
                className="float-left text-center px-0 w-100"
                xs="auto"
              >
                <small className="d-block">
                  {intl.formatDate(installmentItem.date, {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                  })}
                </small>
                <strong>{getFormattedAmount(installmentItem.dinero)}</strong>
              </Style.InstallmentBlock>
            ))}
          </Row>
        </Col>
      </Row>
      <small>{translation.translate('tos.paymentMethod')}</small>
    </>
  )
}

export default AdyenInstallment
