import { EStepType, IStepIntroduction } from 'components/Stepper/type'
import { IUseTranslation } from 'hooks/useTranslation/type'
import { EYado } from 'types'

import ProAccountOnboardingIntroduction from './Components/Introduction'

export const getSteps = (translation: IUseTranslation) => {
  const steps: Array<IStepIntroduction> = [
    {
      type: EStepType.INTRODUCTION,
      yado: EYado.PIGGY_BANK,
      title: translation.translate('introduction.title'),
      content: <ProAccountOnboardingIntroduction />,
    },
  ]
  return steps
}
