import { Autocomplete, Form, Title } from 'components'
import { Formik } from 'formik'
import { FC, useState } from 'react'

const items = ['One', 'Two', 'Three']

const StorybookPage: FC = () => {
  const [inputItems, setInputItems] = useState(items)

  return (
    <>
      <Title type="h1" className="mb-4">
        Form / Autocomplete
      </Title>

      <Title type="h2" className="my-4">
        Component
      </Title>

      <p>Items list : One, Two, Three</p>

      <Formik
        initialValues={{
          autocomplete: '',
          autocompleteRequired: '',
        }}
        onSubmit={(values) => {
          alert(JSON.stringify(values))
        }}
        children={() => (
          <Form>
            <Autocomplete
              label="Lorem ipsum"
              helpText="Lorem ipsum..."
              name="autocomplete"
              items={inputItems}
              onInputValueChange={async ({ inputValue }) => {
                setInputItems(
                  inputValue
                    ? items.filter((item) =>
                        item.toLowerCase().startsWith(inputValue?.toLowerCase())
                      )
                    : []
                )
              }}
            />
            <Autocomplete
              label="Lorem ipsum"
              helpText="Lorem ipsum..."
              name="autocompleteRequired"
              required={true}
              items={inputItems}
              onInputValueChange={async ({ inputValue }) => {
                setInputItems(
                  inputValue
                    ? items.filter((item) =>
                        item.toLowerCase().startsWith(inputValue?.toLowerCase())
                      )
                    : []
                )
              }}
            />
          </Form>
        )}
      />
    </>
  )
}
export default StorybookPage
