import { IApiNonprofit } from 'api/type'
import { NonprofitContext } from 'providers'
import { useContext } from 'react'

const useNonprofit = (): {
  nonprofit: IApiNonprofit
} => {
  const context = useContext(NonprofitContext)
  const { nonprofit } = context

  return {
    nonprofit,
  }
}

export default useNonprofit
