import { Currency } from 'dinero.js'

export type Nullable<T> = T | null

export enum EColor {
  BLUE = 'blue',
  LIGHT_GREY = 'lightGrey',
  LIGHT_BLUE = 'lightBlue',
  LIGHT_GREEN = 'lightGreen',
  LIGHT_YELLOW = 'lightYellow',
  LIGHT_FLORIDA = 'lightFlorida',
  LIGHT_RED = 'lightRed',
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  TRANSPARENT = 'transparent',
  WHITE = 'white',
  GREY = 'grey',
  DARK_GREY = 'darkGrey',
  DARK_BLUE = 'darkBlue',
  DARK_GREEN = 'darkGreen',
  DARK_RED = 'darkRed',
  DARK_YELLOW = 'darkYellow',
  COSMOPOLITAN = 'cosmopolitan',
  FLORIDA = 'florida',
  BLACK = 'black',
  BACKGROUND_COLOR = 'backgroundColor',
  PUBLIC_MAIN_COLOR = 'publicMainColor',
}

export enum ESize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export enum EYado {
  ACCESS_DENIED = 'access-denied',
  ACCOUNT_BLOCKED = 'account-blocked',
  ACCOUNT_SUSPENDED = 'account-suspended',
  BOOKS = 'books',
  CAMERA = 'camera',
  COUCOU = 'coucou',
  CYCLOPS = 'cyclops',
  DEFAULT = 'default',
  DROPBOX = 'dropbox',
  EMPTY_DATA = 'empty-data',
  EMPTY_SEARCH = 'empty-search',
  EMPTY_WALLET = 'empty-wallet',
  EVENTS = 'events',
  GARDENER = 'gardener',
  GIFT = 'gift',
  GRADUATE = 'graduate',
  HAPPY_CHECK = 'happy-check',
  HEART_BALLOONS = 'heart-balloons',
  HELLO = 'hello',
  JUNIOR = 'junior',
  LAPTOP = 'laptop',
  LEAF = 'leaf',
  LOGO_HEART = 'logo-heart',
  MAINTENANCE = 'maintenance',
  NEWSPAPER = 'newspaper',
  OKPAL_ULULE = 'okpal-ulule',
  OLD = 'old',
  PIGGY_BANK = 'piggy-bank',
  POSTMAN = 'postman',
  SEED = 'seed',
  SHOPPING_BAGS = 'shopping-bags',
  STARS = 'stars',
  TEACHER_BLACKBOARD = 'teacher-blackboard',
  TEACHER_REVERSE = 'teacher-reverse',
  TEACHER = 'teacher',
  TRELLO = 'trello',
  WORKER = 'worker',
  YADO_KYC1 = 'yado_kyc1',
  YADO_KYC2 = 'yado_kyc2',
  YADO_KYC3 = 'yado_kyc3',
  YODA = 'yoda',
}

export enum EChipPosition {
  RIGHT = 'right',
  LEFT = 'left',
}

export enum EMenuPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  AUTO = 'auto',
}

export enum EOrderBy {
  ASC = 'asc',
  DESC = 'desc',
}

export enum EListType {
  BULLET = 'bullet',
  CHECK = 'check',
  HEART = 'heart',
}

export enum EProcessEnv {
  TEST = 'test',
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export interface Amount {
  amount: number
  currency: Currency
}
