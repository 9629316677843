import { getCrmPersonRoles, getCurrentAdmin } from 'api'
import { useApiError } from 'hooks'
import { useAsync } from 'react-use'

import * as Type from './type'

const UseMasterAdmin = (): Type.IisMasterAdmin => {
  const apiError = useApiError()

  const { value: currentAdmin } = useAsync(async () => {
    try {
      return await getCurrentAdmin()
    } catch (error: unknown) {
      apiError.handleApiError(error)
      return null
    }
  })

  const { value: isMasterAdmin } = useAsync(async () => {
    if (currentAdmin === undefined) {
      return undefined
    }
    if (currentAdmin === null) {
      return false
    }
    try {
      const crmPersonRoles = await getCrmPersonRoles(currentAdmin.contactId)
      return crmPersonRoles.isMasterAdmin
    } catch (error: unknown) {
      return false
    }
  }, [currentAdmin])

  return {
    isMasterAdmin: isMasterAdmin,
  }
}

export default UseMasterAdmin
