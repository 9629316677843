import LegacyRedirect from 'pages/LegacyRedirect'
import { FC, lazy } from 'react'
import { Route, Routes } from 'react-router'

import BusinessAccountRouter from './BusinessAccountRouter'

const AccountingRouter = lazy(() => import('./AccountingRouter'))
const ApplicationsRouter = lazy(() => import('./ApplicationsRouter'))
const CollectsRouter = lazy(() => import('./CollectsRouter'))
const CustomDashboardRouter = lazy(() => import('./CustomDashboardRouter'))
const DashboardRouter = lazy(() => import('./DashboardRouter'))
const ExportCenterRouter = lazy(() => import('./ExportCenterRouter'))
const DevRouter = lazy(() => import('./DevRouter'))
const DocumentsRouter = lazy(() => import('./DocumentsRouter'))
const NetworkRouter = lazy(() => import('./NetworkRouter'))
const OnboardingRouter = lazy(() => import('./OnboardingRouter'))
const EmailingRouter = lazy(() => import('./EmailingRouter'))
const HomeRouter = lazy(() => import('./HomeRouter'))
const NotificationsRouter = lazy(() => import('./NotificationsRouter'))
const OrderRouter = lazy(() => import('./OrderRouter'))
const PaymentRouter = lazy(() => import('./PaymentRouter'))
const PlatformRouter = lazy(() => import('./PlatformRouter'))
const ProAccountRouter = lazy(() => import('./ProAccountRouter'))
const SettingsRouter = lazy(() => import('./SettingsRouter'))
const StatisticsRouter = lazy(() => import('./StatisticsRouter'))
const SubscriptionRouter = lazy(() => import('./SubscriptionRouter'))
const TaxReceiptsRouter = lazy(() => import('./TaxReceiptsRouter'))
const UpgradeRouter = lazy(() => import('./UpgradeRouter'))
const WalletRouter = lazy(() => import('./WalletRouter'))

const AdminRouter: FC = () => (
  <Routes>
    <Route path="accounting/*" element={<AccountingRouter />} />
    <Route path="applications/*" element={<ApplicationsRouter />} />
    <Route path="business-account/*" element={<BusinessAccountRouter />} />
    <Route path="custom-dashboard/*" element={<CustomDashboardRouter />} />
    <Route path="collects/*" element={<CollectsRouter />} />
    <Route path="export-center/*" element={<ExportCenterRouter />} />
    <Route path="dev/*" element={<DevRouter />} />
    <Route path="documents/*" element={<DocumentsRouter />} />
    <Route path="network/*" element={<NetworkRouter />} />
    <Route path="emailing/*" element={<EmailingRouter />} />
    <Route path="home/*" element={<HomeRouter />} />
    <Route path="onboarding/*" element={<OnboardingRouter />} />
    <Route path="dashboard/*" element={<DashboardRouter />} />
    <Route path="notifications/*" element={<NotificationsRouter />} />
    <Route path="order/*" element={<OrderRouter />} />
    <Route path="payment/requests/*" element={<PaymentRouter />} />
    <Route path="platform/*" element={<PlatformRouter />} />
    <Route path="pro-account/*" element={<ProAccountRouter />} />
    <Route path="settings/*" element={<SettingsRouter />} />
    <Route path="statistics/*" element={<StatisticsRouter />} />
    <Route path="subscription/*" element={<SubscriptionRouter />} />
    <Route path="tax-receipts/*" element={<TaxReceiptsRouter />} />
    <Route path="upgrade/*" element={<UpgradeRouter />} />
    <Route path="wallet/*" element={<WalletRouter />} />
    {/*
          TMP Page to validate new dashboards
          TODO remove this route once the feature has been totally released
    */}
    <Route path="new-dashboard-demo/*" element={<CustomDashboardRouter />} />

    <Route path="/*" element={<LegacyRedirect />} />
  </Routes>
)

export default AdminRouter
